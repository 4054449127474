import React from 'react';
import { Card, Col, Row, Table } from 'antd';
import styles from '../style.module.scss';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import Filters from 'components/filters';
import useBilling from '../useBilling';

const AdminView = ({ isAdmin }) => {
  const {
    columns,
    data,
    loading,
    filters,
    columnsAdmin,
    onChangeTableFilter,
    ordersExportLoading,
    handleExport,
  } = useBilling();
  const { t } = useTranslation();
  return (
    <Card className={styles.card} title={t('Fatturazione')}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Filters filters={filters} />
          <br />
        </Col>
        <Col span={24}>
          <Row gutter={16} align={'middle'}>
            <Col>
              <Button onClick={handleExport} loading={ordersExportLoading}>
                {t('Scarica excel')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            onChange={onChangeTableFilter}
            pagination={data.pagination}
            bordered
            dataSource={data.orders}
            columns={isAdmin ? columnsAdmin : columns}
            scroll={{ x: 1000, y: 400 }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AdminView;
