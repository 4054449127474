import React, { useCallback, useState, useRef } from 'react';
import { Upload, Image as AntImage, Modal, Spin } from 'antd';
import {
  UploadOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import UploadImagePlaceholder from 'assets/images/upload-image-placeholder.png';
import GroupIcon from './components/GroupIcon';
import RotateIcon from './components/RotateIcon';
import DeleteIcon from './components/DeleteIcon';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import './styles.scss';
import Button from '../../button';
import { debounce } from 'lodash';
import Helpers from 'helpers';

function toDataUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

function rotate(srcBase64, degrees, callback) {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  let image = new Image();
  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };
  image.src = srcBase64;
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const mime = arr[0].match(/:(.*?);/)[1],
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const { Dragger } = Upload;
const DragHandle = sortableHandle(() => <GroupIcon />);
const ImageDemo = ({
  isEditor,
  handleUpdateImg,
  originalImg,
  handleRotateImage,
  addImage,
  src,
  onChange,
  onDelete,
  isDefault,
  loading,
  defaultText,
  draggerProps,
  isSuperAdmin,
}) => {
  const inputFile = useRef(null);
  if (addImage) {
    return (
      <Dragger
        {...draggerProps}
        className={classNames({
          'img-upload-lef-icon': true,
          'disable-whole-section': isSuperAdmin,
        })}
        fileList={[]}
      >
        <AntImage
          width={128}
          height={128}
          src='error'
          fallback={src || UploadImagePlaceholder}
          placeholder={
            // <Spin>
            <AntImage
              preview={false}
              src={UploadImagePlaceholder}
              // width={200}
            />
            // </Spin>
          }
        />
      </Dragger>
    );
  }

  return (
    <div className='image-demo'>
      <Spin spinning={loading}>
        <AntImage
          className={'rootClassName'}
          width={128}
          height={128}
          src={src}
          fallback={src || UploadImagePlaceholder}
          placeholder={
            // <Spin>
            <AntImage
              preview={false}
              src={UploadImagePlaceholder}
              // width={200}
            />
            // </Spin>
          }
        />
      </Spin>
      {src && !isSuperAdmin && (
        <>
          <div
            className={classNames({
              'image-options': true,
              'disable-whole-section': isSuperAdmin,
            })}
          >
            <DragHandle />
            <RotateIcon onClick={handleRotateImage} />
            <DeleteIcon onClick={onDelete} />
          </div>
          {isEditor && (
            <div
              className={classNames({
                'download-options': true,
              })}
            >
              <DownloadOutlined
                className='svg-icon'
                onClick={async () => {
                  const originalImage = originalImg;
                  const image = await fetch(originalImage);

                  //Split image name
                  const nameSplit = originalImage.split('/');
                  const duplicateName = nameSplit.pop();

                  const imageBlog = await image.blob();
                  const imageURL = URL.createObjectURL(imageBlog);
                  const link = document.createElement('a');
                  link.href = imageURL;
                  link.download = '' + duplicateName + '';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              />
              <CloudUploadOutlined
                className='svg-icon'
                onClick={() => {
                  inputFile.current.click();
                }}
              />
              <input
                multiple={false}
                onChange={(e) => {
                  handleUpdateImg(e.target.files[0]);
                }}
                type='file'
                id='file'
                ref={inputFile}
                style={{ display: 'none' }}
              />
            </div>
          )}
          <div
            className={classNames(
              'set-default-container',
              isDefault && 'is-default',
            )}
          >
            <Checkbox
              disabled={isSuperAdmin}
              checked={isDefault}
              onChange={onChange}
            >
              {isDefault && defaultText}
            </Checkbox>
          </div>
        </>
      )}
    </div>
  );
};

const SortableItem = SortableElement(ImageDemo);

const SortableImageContainer = SortableContainer(
  ({
    isEditor,
    handleUpdateImg,
    draggerProps,
    isSuperAdmin,
    rowData,
    images,
    handleChangeCheckbox,
    handleDeleteImage,
    t,
    handleRotateImage,
  }) => {
    return (
      <div
        style={images?.length === 1 ? { width: 'auto' } : {}}
        className={classNames(
          'upload-images-left-side',
          isSuperAdmin && 'upload-images-left-side-for-admin',
        )}
      >
        {images.map((img, index) => {
          return (
            <SortableItem
              isEditor={isEditor}
              key={`item-${index}`}
              index={index}
              onChange={() => handleChangeCheckbox(img.id)}
              onDelete={() => handleDeleteImage(rowData.id, img.id)}
              handleRotateImage={() =>
                handleRotateImage(img.id, img.image_original)
              }
              handleUpdateImg={(file) => handleUpdateImg(img.id, file)}
              isDefault={img.by_default}
              loading={!!img.loading}
              isSuperAdmin={isSuperAdmin}
              src={img.image_mobile || img.image_original}
              originalImg={img.image_original}
              defaultText={t('home.imageModal.setAsDefault')}
              draggerProps={draggerProps}
            />
          );
        })}
        {6 - images.length > 0 &&
          !isSuperAdmin &&
          new Array(6 - images.length)
            .fill()
            .map((i, index) => (
              <ImageDemo
                addImage
                key={index}
                isSuperAdmin={isSuperAdmin}
                loading={false}
                draggerProps={draggerProps}
              />
            ))}
      </div>
    );
  },
);

export const Images = (props) => {
  const {
    images = [],
    rowData,
    setSetCurrentProduct,
    handleUploadImages,
    handleSetDefaultImage,
    handleDeleteImage,
    handleChangePosition,
    isSuperAdmin,
    t,
    handleRotateImage: handleRotateImageRequest,
    disableModalOpen,
    isEditor,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const hasData = images?.length > 0;
  const onSubmit = useCallback(() => {}, []);

  const handleCancel = useCallback(() => {
    setShowModal(false);
  }, [images]);

  const handleChangeCheckbox = (imgId) => {
    handleSetDefaultImage(rowData.id, imgId);
  };
  const debouncedDraggerOnChange = debounce((e) => {
    handleUploadImages(rowData.id, e.fileList, images.length + 1);
  }, 400);
  const draggerProps = {
    name: 'file',
    multiple: true,
    className: 'upload-images-drag',
    maxCount: 6,
    accept: 'image/png, image/jpeg',
    progress: { strokeWidth: 2, showInfo: false },
    showUploadList: false,
    disabled:
      !isSuperAdmin && (images.length > 5 || images.some((i) => i.loading)),
    onChange: (e) => {
      debouncedDraggerOnChange(e);
    },
    beforeUpload: () => {
      return false;
    },
    fileList: [],
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    handleChangePosition(rowData.id, images[oldIndex].id, oldIndex, newIndex);
  };

  const handleRotateImage = (id, imgUrl) => {
    toDataUrl(imgUrl, (base64) => {
      rotate(base64, 90, function (resultBase64) {
        const file = dataURLtoFile(resultBase64, 'newFile.png');
        handleRotateImageRequest(rowData.id, id, file);
      });
    });
  };

  const handleUpdateImg = (id, file) => {
    handleRotateImageRequest(rowData.id, id, file);
  };

  return (
    <div>
      {hasData ? (
        <>
          <div
            className={classNames(
              'default-image',
              images.length > 1 && 'has-more-images',
              disableModalOpen && 'disabled-cursor-item',
            )}
            onClick={() => {
              if (!disableModalOpen) {
                setShowModal(true);
                setSetCurrentProduct();
              }
            }}
          >
            <AntImage
              preview={false}
              width={40}
              height={40}
              src={
                images.filter((i) => i.by_default)[0]?.image_mobile ||
                images[0].image_mobile ||
                images[0].image_original
              }
            />
          </div>
        </>
      ) : (
        <>
          <Button
            className={disableModalOpen ? 'disabled-cursor-item' : ''}
            icon={<UploadOutlined />}
            onClick={() => {
              if (!disableModalOpen) {
                setShowModal(true);
                setSetCurrentProduct();
              }
            }}
          />
        </>
      )}
      <Modal
        // title={
        //   isSuperAdmin ? (
        //     ''
        //   ) : (
        //     <span>
        //       {rowData.product_number}, {rowData.title}, {<span dangerouslySetInnerHTML={{ __html: rowData.description }} />},{' '}
        //       {rowData.main_color}, {rowData.gender}{' '}
        //     </span>
        //   )
        // }
        visible={showModal}
        onOk={onSubmit}
        onCancel={handleCancel}
        footer={null}
        className='upload-images-modal'
        width={isSuperAdmin ? 500 : 1164}
      >
        {!isSuperAdmin && <p className='cancel-text'>Salva e chiudi</p>}

        <div
          className={classNames(
            'upload-images-modal-content',
            isSuperAdmin && 'upload-images-modal-content-superAdmin',
          )}
        >
          <SortableImageContainer
            isEditor={isEditor}
            images={images}
            rowData={rowData}
            isSuperAdmin={isSuperAdmin}
            handleChangeCheckbox={handleChangeCheckbox}
            handleDeleteImage={handleDeleteImage}
            handleRotateImage={handleRotateImage}
            handleUpdateImg={handleUpdateImg}
            t={t}
            onSortEnd={onSortEnd}
            shouldUseDragHandle={true}
            useDragHandle
            axis='xy'
            draggerProps={draggerProps}
          />
          {/* {new Ar- */}
          {!isSuperAdmin && (
            <Dragger
              {...draggerProps}
              className={classNames({
                'disable-whole-section': isSuperAdmin,
              })}
            >
              <Spin spinning={false}>
                <p className='ant-upload-drag-icon'>
                  <CloudUploadOutlined />
                </p>
                <p className='ant-upload-text'>
                  {t('home.imageModal.firstText')}
                </p>
                <p className='ant-upload-hint'>
                  {t('home.imageModal.secondText')}
                </p>
                <br />
                <Button size='large'>
                  {t('home.imageModal.browseImages')}
                </Button>
              </Spin>
            </Dragger>
          )}
        </div>
      </Modal>
    </div>
  );
};
