import { Col, Input, Modal, Row, Select, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.module.scss';
import { useParams } from 'react-router-dom';
import { ReactComponent as DeliveryIcon } from 'assets/icons/delivery.svg';
import { useTranslation } from 'react-i18next';
import { useFilter } from 'hooks/useFilter';
import Button from 'components/button';
import { CalendarModal } from '../calendarModal';

const { Option } = Select;

const OrderTrackModal = ({
  onCancel,
  handleOk,
  loading,
  handleConfirmTime,
}) => {
  const { t } = useTranslation();
  const { filterData } = useFilter();
  const [data, setData] = useState({
    carrier: '',
    track_number: '',
  });
  const { id } = useParams();
  const [isClicked, setIsClicked] = useState(false);
  const [flowData, setFlowData] = useState({
    isOpen: false,
    isCompleted: true,
    typeValue: null,
    label: null,
  });
  const [value, setValue] = useState();
  const [loadingFlow, setLoadingFlow] = useState(false);

  const containerRef = useRef();

  const handleClickOk = async () => {
    await handleOk(data, id, setData);
    if (data.track_number) {
      setIsClicked(true);
    }
  };

  const confirmTimeFlow = async () => {
    setLoadingFlow(true);
    if (flowData.typeValue && value) {
      const response = await handleConfirmTime(id, flowData);
      if (response) {
        return onCancel();
      }
      setLoadingFlow(false);
    }
  };

  const handleOpenCalendar = () => {
    setFlowData((prev) => ({ ...prev, isOpen: true, isCompleted: false }));
  };

  const changeSelectValue = (event) => {
    if (!value) {
      return;
    }
    if (event == 1) {
      setFlowData((prev) => ({
        ...prev,
        label: 'In mattinata entro le 12:00',
        typeValue: {
          day: value.toString(),
          time_from1: 9,
          time_to1: 12,
        },
      }));
    }
    if (event == 2) {
      setFlowData((prev) => ({
        ...prev,
        label: 'Nel pomeriggio entro le 18:00',
        typeValue: {
          day: value.toString(),
          time_from2: 13,
          time_to2: 18,
        },
      }));
    }
  };

  useMemo(() => {
    setFlowData((prev) => ({
      ...prev,
      typeValue: {
        ...prev.typeValue,
        day: value ? value.toString() : null,
      },
    }));
  }, [value]);

  console.log('datatime', data?.track_number);

  return (
    <Modal
      width={!flowData.isCompleted ? 800 : 600}
      visible={true}
      // onOk={handleClickOk}
      onCancel={onCancel}
      // okButtonProps={{
      //   loading,
      // }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
    >
      <Row justify={'center'} gutter={[16, 16]}>
        {flowData.isCompleted ? (
          <Col className={styles.iconContainer}>
            <DeliveryIcon width={200} />
          </Col>
        ) : (
          <Col
            style={{
              textAlign: 'center',
              fontWeight: '600',
              marginTop: '20px',
              fontSize: '23px',
            }}
          >
            Seleziona il giorno e l’orario per il pick up del corriere.
          </Col>
        )}

        <Col span={24}>
          <Row justify={'center'}>
            {!flowData.isCompleted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <div className={styles.calendar_modal}>
                  <CalendarModal
                    value={value}
                    setValue={setValue}
                    setFlowData={setFlowData}
                  />
                  <div ref={containerRef}>
                    <p style={{ fontWeight: '500' }}>
                      Seleziona la fascia oraria desiderata
                    </p>
                    <Select
                      disabled={!value}
                      style={{ width: containerRef?.current?.clientWidth }}
                      placeholder='Seleziona la fascia oraria'
                      value={flowData.label}
                      onChange={changeSelectValue}
                    >
                      <Select.Option key={1}>
                        In mattinata entro le 12:00
                      </Select.Option>
                      <Select.Option key={2}>
                        Nel pomeriggio entro le 18:00
                      </Select.Option>
                    </Select>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Button
                    height={35}
                    width={115}
                    onClick={confirmTimeFlow}
                    disabled={!flowData.typeValue | !value | loadingFlow}
                  >
                    Conferma
                  </Button>
                  <Button
                    height={35}
                    width={115}
                    className={styles.white_button_modal}
                  >
                    Anulla
                  </Button>
                </div>
              </div>
            )}

            {flowData.isCompleted && (
              <Col>
                {!isClicked && !data.track_number ? (
                  <Button
                    className='red'
                    onClick={handleClickOk}
                    disabled={isClicked}
                  >
                    Ottieni codice traccia
                  </Button>
                ) : (
                  <Button
                    disabled={!data.track_number}
                    className='red'
                    onClick={handleOpenCalendar}
                  >
                    Scegli la data del ritiro
                  </Button>
                )}
                {/* <Typography>{t('Delivery Type')}</Typography> */}
                {/* <Select
                value={data.carrier}
                onSelect={(v) => setData((s) => ({ ...s, carrier: v }))}
                className={styles.selectDelivery}
                options={filterData?.courier_services?.map((i) => ({
                  value: i.value,
                  label: i.title,
                }))}
              >
                <Option value=''>{t('Selezioni opzione')}</Option>
                <Option value='jack'>Jack</Option>
                <Option value='lucy'>Lucy</Option>
              </Select> */}
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={'center'}>
            {flowData.isCompleted && (
              <Col>
                <Typography style={{ textAlign: 'center' }}>
                  {t('Track ID')}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  {data.track_number}
                </Typography>
                {/* <Input
                placeholder={t('Inserisci testo')}
                className={styles.selectDelivery}
                onChange={(e) => {
                  setData((s) => ({
                    ...s,
                    track_number: e.target.value,
                  }));
                }}
              /> */}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default OrderTrackModal;
