import { Col, Row } from 'antd';
import StatisticCard from '../../../components/statisticCard';
import { useTranslation } from 'react-i18next';
import useHome from './useHome';

const AdminView = () => {
  const { t } = useTranslation();
  const { statistic, loading } = useHome();
  return (
    <Row gutter={16}>
      <Col span={6}>
        <StatisticCard
          loading={loading}
          count={statistic?.product_sales_count}
          text={t('Articoli venduti')}
        />
      </Col>
      <Col span={6}>
        <StatisticCard
          count={statistic?.orders_count}
          loading={loading}
          text={t('Ordini')}
        />
      </Col>
      <Col span={6}>
        <StatisticCard
          loading={loading}
          count={statistic?.shops_count}
          text={t('Negozi iscritti')}
        />
      </Col>
      <Col span={6}>
        <StatisticCard
          loading={loading}
          count={statistic?.users_count}
          text={t('Utenti iscritti')}
        />
      </Col>
    </Row>
  );
};

export default AdminView;
