import React from 'react';
import { Descriptions, Image, Modal, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';
import useShops from '../../useShops';

const ViewModal = () => {
  const { t } = useTranslation();
  const { singleItem } = useShops({ isMainPage: false });
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname) + search);
  };
  console.log('singleItem=', singleItem);
  return (
    <Modal
      title={t('Dettagli insegna')}
      visible={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Descriptions column={1} bordered>
        {singleItem ? (
          <>
            <Descriptions.Item label={t('Nome insegna')}>
              {singleItem.name}
            </Descriptions.Item>

            <Descriptions.Item label={t('Società associata')}>
              {singleItem.company?.title}
            </Descriptions.Item>
            <Descriptions.Item label={t('Categorie')}>
              {!!singleItem.categories_shop?.length &&
                singleItem.categories_shop.reduce(
                  (total, shop) =>
                    singleItem.categories_shop.length === 1
                      ? shop.category.title
                      : `${total} ${shop.category.title}`,
                  '',
                )}
            </Descriptions.Item>

            <Descriptions.Item label={t('Punti vendita')}>
              {singleItem.sale_points?.map((i) => i.address).join(', ')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Email')}>
              {singleItem.email_support}
            </Descriptions.Item>
            <Descriptions.Item label={t('Numero telefono')}>
              {singleItem.phone_support}
            </Descriptions.Item>
            <Descriptions.Item label={t('Sito web')}>
              {singleItem.website}
            </Descriptions.Item>
            <Descriptions.Item label={t('Descrizione attività')}>
              {singleItem.description_it}
            </Descriptions.Item>
            <Descriptions.Item label={`${t('Termine per ritiro')} ${t('Click & Collect')}`}>
              {singleItem.shop_policy_click_collect_it}
            </Descriptions.Item>
            <Descriptions.Item label={`${t('Termine per ritiro')} ${t('Click & Reserve')}`}>
              {singleItem.shop_policy_click_reserve_it}
            </Descriptions.Item>

            <Descriptions.Item label={t('Immagine')}>
              {!!singleItem.image && (
                <Image width={200} src={singleItem.image} alt={singleItem.name} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('Servizi / modalità evasione')}>
              {singleItem.is_click_collect && (
                <p style={{ whiteSpace: 'nowrap' }}>Click Collect</p>
              )}
              {singleItem.is_click_reserve && (
                <p style={{ whiteSpace: 'nowrap' }}>Click Reserve</p>
              )}
              {singleItem.is_online_shop && (
                <p style={{ whiteSpace: 'nowrap' }}>Shop online</p>
              )}
            </Descriptions.Item>
          </>
        ) : (
          <Spin size={'large'} />
        )}
      </Descriptions>
    </Modal>
  );
};

export default ViewModal;
