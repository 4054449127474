import React from 'react';
import styles from './style.module.scss';
import Footer from 'components/footer';
import { Row, Col, Typography } from 'antd';
import { EastLombardyIcon } from 'icons';
import LoginForm from './form';
import useAuth from './useAuth';
import ResetForm from './resetForm';

const ForgotPassword = () => {
  const { handleLogin, loadingBtn, errors, searchParams, handleResetPassword } =
    useAuth();

  const onSubmit = async (values) => {
    await handleLogin(values);
  };

  const isSuccessPage = searchParams.get('success') === 'true';
  const isResetPassword = !!searchParams.get('token');
  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.loginPageContainer_content}>
        <div>
          <EastLombardyIcon fill={'#D9322E'} />
        </div>
        {isSuccessPage && !isResetPassword && (
          <Row>
            <Col>
              <Typography.Title level={3}>
                Reset della password
              </Typography.Title>
              <Typography.Text>
                {' '}
                Abbiamo inviato una mail all’indirizzo che hai fornito.
                Controlla la tua posta elettronica.
              </Typography.Text>
            </Col>
          </Row>
        )}
        {!isSuccessPage && !isResetPassword && (
          <LoginForm
            errors={errors}
            loadingBtn={loadingBtn}
            onSubmit={onSubmit}
          />
        )}
        {isResetPassword && (
          <>
            <Typography.Title level={3}>Reset della password</Typography.Title>
            <ResetForm
              errors={errors}
              loadingBtn={loadingBtn}
              onSubmit={handleResetPassword}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
