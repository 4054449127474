import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import { useNavigate } from 'hooks/useNavigate';
import { Route, Routes } from 'react-router-dom';
import AddTeam from './company/addCompany';
import { getUser } from 'hooks/useUser';
import OwnerView from './ownerView';
import CompaniesList from './company/companiesList';
import ShopsList from './shop/shopsList';
import AddShop from './shop/addShop';
import AddCompany from './company/addCompany';
import RefundInfoPage from './RefundInfoPage';

const ManagerView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(getUser);

  const sectionData = useMemo(
    () => [
      {
        title: t('Gestore'),
        description: '',
        btnText: t('Vedi dettagli'),
        btnAction: () => navigate('owner'),
      },
      {
        title: t('Società'),
        description: '',
        btnText: t('Vedi dettagli'),
        secondBtnText: user.isOwner ? t('Crea nuova società') : null,
        secondBtnAction: user.isOwner
          ? () => {
              navigate('companies/add');
            }
          : null,
        btnAction: () => {
          navigate('companies/list');
        },
      },
      {
        title: t('Insegne'),
        description: '',
        btnText: t('Vedi elenco'),
        btnAction: () => {
          navigate('shops/list');
        },
      },
      {
        title: t('Condizioni di reso della piattaforma'),
        description: '',
        btnText: t('Consulta le condizioni'),
        btnAction: () => {
          navigate('refund-info');
        },
      },
    ],
    [t],
  );

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection title={t('Profilo')} sectionData={sectionData} />
      </Col>
      <Col span={18}>
        <Routes>
          <Route path={'owner'} element={<OwnerView />} />

          <Route path={'add/*'} element={<AddTeam />} />
          <Route path={'add/:id/edit'} element={<AddTeam isEdit />} />
          <Route path={'companies/list/*'} element={<CompaniesList />} />
          {user.isOwner && (
            <Route path={'companies/add/*'} element={<AddCompany />} />
          )}
          {user.isOwner && (
            <Route
              path={'companies/list/:id/edit'}
              element={<AddCompany isEdit />}
            />
          )}

          <Route path={'shops/list/*'} element={<ShopsList />} />
          {(user.isOwner || user.isAdmin) && (
            <Route path={'shops/add'} element={<AddShop />} />
          )}
          <Route path={'shops/list/:id/edit'} element={<AddShop isEdit />} />
          <Route path={'refund-info'} element={<RefundInfoPage />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default ManagerView;
