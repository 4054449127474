import { Select } from 'antd';
import styles from './style.module.scss';
import Button from '../button';
import { DownOutlined } from '@ant-design/icons';

const MySelect = ({ label, selected, onChange, options }) => {
  console.log('selected=', selected);
  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <Select
        value={selected}
        bordered={false}
        onChange={(value) => {
          onChange(value);
        }}
        clearIcon={false}
        suffixIcon={<Button icon={<DownOutlined />} />}
        options={options}
      />
    </div>
  );
};

export default MySelect;
