import { Col, Input, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';

const RefoundReasonModal = ({
  loading,
  onClose,
  onSubmit,
  refoundReason,
  handleChangeRefoundReason,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      footer={false}
      title={t('Gestisci reso')}
      visible={true}
      onCancel={onClose}
    >
      <Row>
        <Col span={24}>
          <Typography.Text>
            {t(
              'Specifica al cliente eventuali note per il reso e/o la motivazione per la quale sono stati rifiutati alcuni articoli.',
            )}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <br />
          <Input.TextArea
            value={refoundReason}
            onChange={(e) => handleChangeRefoundReason(e.target.value)}
            rows={7}
          />
        </Col>
        <Col span={24}>
          <br />
          <Row gutter={8} justify={'end'}>
            <Col>
              <Button loading={loading} onClick={onClose} type={'secondary'}>
                {t('Annulla')}
              </Button>
            </Col>
            <Col>
              <Button loading={loading} onClick={onSubmit}>
                {t('Salva e invia istruzioni di reso')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default RefoundReasonModal;
