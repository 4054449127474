import { Col, Row, Spin } from 'antd';
import StatisticCard from 'components/statisticCard';
import LineChart from 'components/charts/lineChart';
import { useTranslation } from 'react-i18next';
import useStatisticaStore from './useStatisticaStore';
import Filters from 'components/filters';

const StoreStatisticsView = () => {
  const { t } = useTranslation();
  const { data, loading, filters } = useStatisticaStore();
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Filters hideClearAll filters={filters} />
          <br />
        </Col>
        <Col span={6}>
          <StatisticCard
            big
            count={data.shops_count}
            text={t('Negozi iscritti')}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            big
            count={data.shops_with_order_count}
            text={t('Negozi che hanno effettuato ordini')}
          />
        </Col>
        <Col span={12}>
          <LineChart
            colors={['#D9322E']}
            title={t('Evoluzione temporale negozi iscritti')}
            tableData={[]}
            data={data.lineChartData}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default StoreStatisticsView;
