import { Col, Row, Spin } from 'antd';
import StatisticCard from 'components/statisticCard';
import LineChart from 'components/charts/lineChart';
import BarChangeChart from 'components/charts/barChange';
import { useTranslation } from 'react-i18next';
import useStatisticaManager from './useStatisticaManager';
import Helpers from 'helpers';
import Filters from 'components/filters';
import { OrderTypesFullNames, OrderTypesNames } from 'constants/orders';

const OrdersAndReturnsStatisticsView = () => {
  const { t } = useTranslation();
  const {
    barChartByOrderType,
    barChartByOrderFullTypes,
    data,
    filters,
    loading,
  } = useStatisticaManager();
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Filters filters={filters} />
          <br />
        </Col>
        <Col xs={12} xl={6}>
          <StatisticCard big count={data.orders_count} text={t('Ordini')} />
        </Col>
        <Col xs={12} xl={6}>
          <StatisticCard
            big
            count={data.refund_orders_count}
            text={t('Resi')}
          />
        </Col>
        <Col xs={24} xl={12}>
          <LineChart
            colors={['#faac18']}
            title={t('Evoluzione temporale ordini')}
            tableData={data.lineChartOrders.data || []}
            data={data.lineChartOrders}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col xl={24} xs={24}>
              <BarChangeChart
                title={t('Distribuzione ordini per stato')}
                keys={[
                  OrderTypesFullNames.created_by_day,
                  OrderTypesFullNames.canceled_by_day,
                  OrderTypesFullNames.shipped_by_day,
                  OrderTypesFullNames.completed_by_day,
                  OrderTypesFullNames.refunded_by_day,
                  OrderTypesFullNames.expired_by_day,
                  OrderTypesFullNames.ask_to_refund_by_day,
                  OrderTypesFullNames.waiting_for_products_refund,
                ]}
                data={barChartByOrderFullTypes}
                itemWidth={130}
                translateXBar={-20}
                hideLine
                indexBy={'date'}
                groupMode={'stacked'}
              />
            </Col>
            <Col xl={24} xs={24}>
              <BarChangeChart
                title={t('Distribuzione ordini modalità di evasione')}
                keys={[
                  OrderTypesNames.click_and_collect,
                  OrderTypesNames.click_and_ship,
                  OrderTypesNames.click_and_reserve,
                ]}
                data={barChartByOrderType}
                itemWidth={130}
                translateXBar={-20}
                hideLine
                indexBy={'date'}
                groupMode={'stacked'}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={6}>
          <StatisticCard
            big
            count={`${Helpers.numberWithCommas(data.total_amount)} €`}
            text={t('Entrate')}
            smallTextSize
          />
        </Col>
        <Col xs={24} xl={18}>
          <LineChart
            colors={['#faac18']}
            title={t('Evoluzione temporale entrate')}
            tableData={[]}
            data={data.lineChartAmountByDay}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default OrdersAndReturnsStatisticsView;
