import { useState } from 'react';
import AuthApi from 'libs/api/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helpers from 'helpers';

const api = new AuthApi();

const useAuth = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoadingBtn(true);
    try {
      await api.sendResetPassword(values);
      setSearchParams({ success: true });
      // navigate('/');
    } catch (e) {
      let errors = Helpers.getErrorsFromResponse(e);
      if (!errors.length) {
        errors = [t('Something went wrong!')];
      }
      setErrors(errors);
    } finally {
      setLoadingBtn(false);
    }
  };

  const handleResetPassword = async (values) => {
    setLoadingBtn(true);
    try {
      await api.resetPassword({
        ...values,
        token: searchParams.get('token'),
      });
      navigate('/login');
    } catch (e) {
      let errors = Helpers.getErrorsFromResponse(e);
      if (!errors.length) {
        errors = [t('Something went wrong!')];
      }
      setErrors(errors);
    } finally {
      setLoadingBtn(false);
    }
  };

  return {
    handleLogin,
    loadingBtn,
    errors,
    searchParams,
    handleResetPassword,
  };
};

export default useAuth;
