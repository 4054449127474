import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import UsersApi from 'libs/api/users';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'icons';
import { getUser } from 'hooks/useUser';

const api = new UsersApi();

const useTeam = ({ isMainPage = true, isEdit, isCustomers = false } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const [additionalFilters, setAdditionalFilters] = useState({
    by_shop_id: user?.currentShop?.id,
    by_role: isCustomers ? 'customer' : '',
  });
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
  } = useApi({
    resourceKey: 'users',
    singleResourceKey: 'user',
    api,
    isMainPage,
    additionalFilters: additionalFilters,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      width: isCustomers ? 100 : 200,
      render: (data, row) => (
        <Row
          gutter={16}
          justify={'left'}
          style={{ minWidth: isCustomers ? 50 : 200 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Col>
            <Link to={`${row.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </Link>
          </Col>
          {!isCustomers && (
            <>
              <Col>
                <Link to={`${row.id}/edit`}>
                  <Button icon={<EditIcon />} />
                </Link>
              </Col>
              <Col>
                <Link to={`${row.id}/delete`}>
                  <Button icon={<DeleteIcon />} />
                </Link>
              </Col>
            </>
          )}
        </Row>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: t('Cognome'),
      dataIndex: 'surname',
      sorter: true,
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: t('Numero telefono'),
      dataIndex: 'phone',
      sorter: true,
    },
    {
      title: t('Ruolo'),
      dataIndex: 'role',
      sorter: true,
    },
    {
      title: t('Insegna/e'),
      dataIndex: 'shops.title',
      render: (data, row) => row?.shops?.map((i) => i.title)?.join(','),
    },
  ];

  const filters = useMemo(() => {
    return isCustomers
      ? [
          {
            title: t('Cerca membro'),
            type: 'input',
            name: 'by_email',
            values: [],
          },
        ]
      : [
          {
            title: t('Cerca membro'),
            type: 'input',
            name: 'by_email',
            values: [],
          },
          {
            title: t('Filtro ruolo'),
            type: 'select',
            name: 'by_role',
            values: [
              { value: '', title: t('Selezioa ruolo') },
              ...filterData.available_admin_roles?.map(({ value, title }) => ({
                title: t(title),
                value,
              })),
            ],
          },
        ];
  }, [t, filterData, isCustomers]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          user: {
            ...values,
          },
        };
        if (!id) {
          await api.create({ data });
        } else {
          await api.put(id, { data });
        }
        navigate('/team/list');
      } catch (e) {
        form.setFields(ErrorHelper.getErrorsForForm(e?.response?.data || {}));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [form, navigate, setUpdateInProcess],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleItem, isEdit]);

  useEffect(() => {
    if (user?.currentShop?.id) {
      setAdditionalFilters((s) => {
        if (s.by_shop_id != user?.currentShop?.id) {
          return {
            ...s,
            by_shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id]);

  return {
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
  };
};

export default useTeam;
