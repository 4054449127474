import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';

import './autocomplete.scss';

const getBergamoCoords = () => ({
  lat: 45.695,
  lng: 9.67,
});

const getGeoDataByKey = (addressComponents = [], key) => {
  if (!key) {
    return '';
  }

  return addressComponents.find((i) => i.types.includes(key));
};

const Autocomplete = ({ address, placeholder, onSelect, error }) => {
  const bergamoCoordinates = getBergamoCoords();
  const [value, setValue] = useState(null);

  const [coords, setCoords] = useState({
    lat: '',
    lng: '',
    city: '',
  });

  useEffect(() => {
    // TODO remove this logic in base function
    onSelect && coords.lat && coords.lng && onSelect(coords);
  }, [coords]);

  const onSelectPlace = useCallback(async (data) => {
    setValue(data);
    try {
      const [address] = await geocodeByAddress(data.description);
      const latAndLng = await getLatLng(address);

      // TODO feature, if need get only city info
      const [geocode] = await geocodeByPlaceId(data.place_id);
      const address_components = geocode?.address_components;

      const street_name = getGeoDataByKey(address_components, 'route');
      const street_number = getGeoDataByKey(
        address_components,
        'street_number',
      );
      const city = getGeoDataByKey(
        address_components,
        'administrative_area_level_3',
      );

      const province = getGeoDataByKey(
        address_components,
        'administrative_area_level_2',
      );
      const zip = getGeoDataByKey(address_components, 'postal_code');

      setCoords({
        ...latAndLng,
        city: data?.description,
        municipality: city?.long_name || '',
        street: street_name?.long_name || '',
        building: street_number?.short_name || '',
        province: province?.long_name || '',
        zip: zip?.long_name || '',
        address: data.description,
      });
    } catch (e) {
      console.log('GoogleAutocomplete error', e);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <GooglePlacesAutocomplete
        autocompletionRequest={{
          componentRestrictions: {
            country: ['it'],
          },
          radius: 6000,
          location: bergamoCoordinates,
          types: ['address'],
        }}
        apiOptions={{ language: 'it', region: 'it' }}
        placeholder={placeholder}
        debounce={0}
        onSelect={onSelectPlace}
        initialValue={address}
        apiKey='AIzaSyAAG8pAewQz2mmdOqWk3vO7xOyJj0wlIyk'
      />
    </div>
  );
};

Autocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default Autocomplete;
