import React from 'react';
import { func, bool } from 'prop-types';
import ActionModal from 'components/actionModal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import ShowError from 'components/showError';

const ChangePasswordModal = ({
  onClickChangePassword,
  loading,
  onCancel,
  changePasswordErrors,
  setChangePasswordErrors,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const onFinish = (values) => {
    onClickChangePassword(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <ActionModal
      loading={loading}
      title={t('Modifica La Password')}
      description={''}
      yesBtnText={t('Invia')}
      noBtnText={t('Annulla')}
      onClickOk={handleOk}
      onCancel={handleCancel}
      type={'info'}
    >
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={
              {
                // remember: true,
              }
            }
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={() => setChangePasswordErrors([])}
          >
            <Form.Item
              label={t('Password corrente')}
              name='current_password'
              rules={[
                {
                  required: true,
                  message: 'Inserisci la tua password',
                },
              ]}
            >
              <Input.Password placeholder={'******'} />
            </Form.Item>
            <Form.Item
              label={t('Nuova password')}
              name='password'
              rules={[
                {
                  required: true,
                  message: t('Inserisci la tua password'),
                },
              ]}
            >
              <Input.Password placeholder={'******'} />
            </Form.Item>
            <Form.Item
              label={t('Conferma nuova password')}
              name='password_confirmation'
              rules={[
                {
                  required: true,
                  message: t('Inserisci la tua password'),
                },
              ]}
            >
              <Input.Password placeholder={'******'} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <ShowError errors={changePasswordErrors} />

          <br />
        </Col>
      </Row>
    </ActionModal>
  );
};

ChangePasswordModal.propTypes = {
  onClickChangePassword: func,
  loading: bool,
};

export default React.memo(ChangePasswordModal);
