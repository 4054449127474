import { useEffect, useMemo } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import styles from '../style.module.scss';
import AdminView from '../adminView';
import useBreadcrumb from 'hooks/useBreadcrumb';

const StockPickingPage = () => {
  const user = useRecoilValue(getUser);
  const { setBreadcrumb } = useBreadcrumb();

  const breadcrumb = useMemo(() => {
    return [
      {
        title: user?.currentShop?.title,
        link: '#',
      },
      {
        title: 'Stock picking',
        link: '/stock-picking  ',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        <AdminView />
      </div>
    </Layout>
  );
};

export default StockPickingPage;
