import React from 'react';
import { Descriptions, Image, Modal, Space, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';
import useArticles from '../useArticles';
import Helpers from '../../../../../helpers';

const ViewModal = () => {
  const { t } = useTranslation();
  const { singleItem } = useArticles({ isMainPage: false });
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname) + search);
  };

  return (
    <Modal
      title={t('Articoli')}
      visible={true}
      footer={false}
      width={1000}
      onCancel={handleCancel}
    >
      <Descriptions column={1} bordered>
        {singleItem ? (
          <>
            <Descriptions.Item label={t('Insegne')}>
              {singleItem.shop_title}
            </Descriptions.Item>
            <Descriptions.Item label={t('Codice articolo (SKU)')}>
              {singleItem.product_number}
            </Descriptions.Item>
            <Descriptions.Item label={t('Colore')}>
              {singleItem.main_color}
            </Descriptions.Item>
            <Descriptions.Item label={t('Taglia')}>
              {singleItem.size}
            </Descriptions.Item>
            <Descriptions.Item label={t('Nome prodotto')}>
              {singleItem.title}
            </Descriptions.Item>
            <Descriptions.Item label={t('Descrizione')}>
              {Helpers.deleteAllHtmlTags(singleItem.description || '')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Immagini')}>
              <Space>
                {singleItem?.product_variant_images?.map((i) => (
                  <Image src={i.image} />
                ))}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={t('Categoria merceologica')}>
              {singleItem?.categories_product_variants
                ?.map((i) => i.category.title)
                ?.join(',')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Brand')}>
              {singleItem.brand_name}
            </Descriptions.Item>
            <Descriptions.Item label={t('Stock')}>
              {singleItem.quantity}
            </Descriptions.Item>
            <Descriptions.Item label={t('Ordinato')}>
              {singleItem.ordered}
            </Descriptions.Item>
            <Descriptions.Item label={t('Venduto')}>
              {singleItem.sold}
            </Descriptions.Item>
            <Descriptions.Item label={t('Disponibile')}>
              {singleItem.quantity_available}
            </Descriptions.Item>
            <Descriptions.Item label={t('Prezzo')}>
              {Helpers.numberWithCommas(singleItem.retail_price, ' €')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Sconto %')}>
              {singleItem.discount_percentage} %
            </Descriptions.Item>
            <Descriptions.Item label={t('Prezzo scontato')}>
              {Helpers.numberWithCommas(singleItem.discounted_price, ' €')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Wishlist')}>
              {singleItem.wishing_list_count}
            </Descriptions.Item>
            <Descriptions.Item label={t('Dettagli')}>
              {singleItem.details}
            </Descriptions.Item>
            <Descriptions.Item label={t('Sesso')}>
              {singleItem.gender}
            </Descriptions.Item>
            <Descriptions.Item label={t('Prodotto in')}>
              {singleItem.made_in}
            </Descriptions.Item>
            <Descriptions.Item label={t('Peso (grammi)')}>
              {singleItem.product_weight_gram}
            </Descriptions.Item>
            <Descriptions.Item label={t('Guida alle taglie')}>
              {singleItem.size_instructions}
            </Descriptions.Item>
            <Descriptions.Item label={t('Informazioni aggiuntive')}>
              {singleItem.additional_information}
            </Descriptions.Item>
            <Descriptions.Item label={t('Stato prodotto')}>
              {singleItem.status}
            </Descriptions.Item>
          </>
        ) : (
          <Spin size={'large'} />
        )}
      </Descriptions>
    </Modal>
  );
};

export default ViewModal;
