import SetRoutes from 'components/setRoutes';

import routes from './routes';

const StripePages = () => {
  return (
    <>
      <SetRoutes routes={routes} />
    </>
  );
};

export default StripePages;
