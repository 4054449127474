import { useEffect, useMemo } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import styles from '../style.module.scss';
import useProducts from '../useProducts';
import AdminView from '../adminView';
import ManagerView from '../managerView';
import useBreadcrumb from 'hooks/useBreadcrumb';

const ProductsPage = () => {
  const user = useRecoilValue(getUser);
  const { columns, productData, rowSelection } = useProducts();
  const { setBreadcrumb } = useBreadcrumb();

  const breadcrumb = useMemo(() => {
    if (user.isAdmin) {
      return [
        {
          title: 'Super admin',
          link: '#',
        },
        {
          title: 'Elenco prodotti',
          link: '',
        },
      ];
    }

    if (user.isOwner) {
      return [
        {
          title: 'Owner',
          link: '#',
        },
        {
          title: 'Elenco prodotti',
          link: '',
        },
      ];
    }

    return [
      {
        title: user?.currentShop?.title,
        link: '#',
      },
      {
        title: 'Catalogo prodotti',
        link: '/products  ',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
  }, [user]);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        {(user.isManager || user.isCashier) && (
          <ManagerView
            columns={columns}
            productData={productData}
            rowSelection={rowSelection}
          />
        )}
        {(user.isAdmin || user.isOwner) && (
          <AdminView
            columns={columns}
            productData={productData}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </Layout>
  );
};

export default ProductsPage;
