import styles from './style.module.scss';
import { Typography } from 'antd';

const FilterWrapper = ({ children, title, onClose, input }) => {
  return (
    <div className={styles.wrapper}>
      {!input && (
        <Typography.Text className={styles.title}>{title}</Typography.Text>
      )}
      {children}
    </div>
  );
};

export default FilterWrapper;
