import React, { useEffect, useMemo } from 'react';
import styles from '../style.module.scss';
import { Alert, Col, Divider, Row, Select, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input/Input';
import useSteps from 'hooks/useSteps';
import { ArrowRightOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';
import useECommerce from '../useECommerce';

const { Option } = Select;

const ForthStep = () => {
  const { t } = useTranslation();
  const { setStep } = useSteps();

  const {
    categories,
    eCommerceData,
    group_categories,
    handleSetCategories,
    handleSaveForthStep,
    loading,
  } = useECommerce();

  useEffect(() => {
    setStep((s) => ({
      ...s,
      // current: 4,
      current: eCommerceData.currentECommerceType === 'shopify' ? 5 : 4,
    }));
  }, [eCommerceData]);

  const preparedOptions = useMemo(() => {
    console.log('group ', group_categories);
    const arr = [];
    group_categories?.forEach(i => {
      arr.push({
        label: i.title,
        options: i.childrens?.map(i => ({
          label: i.title,
          value: i.value,
        }))
      });
    });
    return arr;
  }, [group_categories]);

  return (
    <Row className={styles.row} justify={'space-between'}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text strong>{t('Match categorie')}</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>
              Ognuna delle categorie presenti sull’e-commerce sorgente dovrà
              essere associata a una categoria del marketplace Bergamo Shopping.
              Seleziona la categoria tra quelle disponibili.
            </Typography.Text>
          </Col>
          <Col span={24}>
            <br />
            <Row>
              <Col span={16}>
                <Row>
                  <Col span={10}>
                    <Typography.Text strong>
                      {t('E-commerce sorgente')}
                    </Typography.Text>
                  </Col>
                  <Col span={4} />
                  <Col span={10}>
                    <Typography.Text strong>{t('Bergamo Shopping')}</Typography.Text>
                  </Col>
                </Row>
                <Divider className={'custom-divider'} />
                {eCommerceData.merchantCategories.map((i) => {
                  const findCategory = eCommerceData.categories.find(
                    (j) => j.merchant_category_id === i.id,
                  );
                  return (
                    <React.Fragment key={uuid()}>
                      <Row>
                        <Col span={10}>
                          <Typography.Text>{i.title}</Typography.Text>
                        </Col>
                        <Col span={4}>
                          <ArrowRightOutlined />
                        </Col>
                        <Col span={10}>
                          <Select
                            value={
                              (findCategory && findCategory.category_id) || ''
                            }
                            style={{ width: '100%' }}
                            className={'standard-select'}
                            placeholder={t('Informazioni aggiuntive')}
                            onSelect={handleSetCategories(i.id)}
                            options={preparedOptions}
                          >
                            {/*<Option value={''}>{t('Selezioni opzione')}</Option>*/}

                            {/*{categories.map((j) => (*/}
                            {/*  <Option value={j.value}>{j.title}</Option>*/}
                            {/*))}*/}
                          </Select>
                        </Col>
                      </Row>
                      <Divider className={'custom-divider'} />
                    </React.Fragment>
                  );
                })}

                <Alert
                  message='Non è possibile lasciare una categoria senza una corrispondenza del marketplace'
                  type='info'
                  showIcon
                />
                <Divider className={'custom-divider'} />
                <Row>
                  <Col span={24}>
                    <Space direction={'vertical'}>
                      <Typography.Text strong>
                        {t(
                          'Manca una categoria di prodotto nel Marketplace Bergamo Shopping? Invia una segnalazione',
                        )}
                      </Typography.Text>

                      <Input placeholder={t('Testo richiesta')} />
                      <Button>{t('Invia segnalazione')}</Button>
                    </Space>
                  </Col>
                  <Col span={24}></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col flex={1}>
        <Row
          className={styles.btnsRow}
          align={'bottom'}
          justify={'end'}
          gutter={8}
        >
          <Col>
            <Link to={'/products'}>
              <Button type={'grey'}>{t('Annulla')}</Button>
            </Link>
          </Col>
          <Col>
            <Button onClick={handleSaveForthStep} loading={loading}>
              {t('Prosegui')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ForthStep;
