import { useCallback } from 'react';

export const useTableChangeFilterHook = () => {
  return useCallback((setData, paginate, sort, filter) => {
    let order = filter.order;
    if (order === 'ascend') {
      order = 'asc';
    } else if (order === 'descend') {
      order = 'desc';
    }
    setData((s) => ({
      ...s,
      pagination: {
        ...s.pagination,
        current: paginate.current,
      },
      sorting: {
        ...filter,
        order: order,
      },
    }));
  }, []);
};
