import routes from './routes';
import SetRoutes from '../../components/setRoutes';

const ProductsPages = () => {
  return (
    <>
      <SetRoutes routes={routes} />
    </>
  );
};

export default ProductsPages;
