import { useState, useEffect } from 'react';
import Api from '../../../libs/api';

const api = new Api('admin/dashboard');

const useHome = () => {
  const [loading, setLoading] = useState(false);
  const [statistic, setStatistic] = useState({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.list();
        setStatistic(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, statistic };
};

export default useHome;
