import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Table, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import useOrders from './useOrdersAdmin';
import OrderViewModal from 'components/orderViewModal';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { OrderTypeEnums } from '../../../../constants/orders';
import OrderViewModalConfirm from '../../../../components/orderViewModalConfirm';
import CheckCodeModal from './chechCodeModal';
import OrderTrackModal from './orderTrackModal';
import ClientViewModal from './clientViewModal';
import DeleteModal from '../../../../components/deleteModalView';

const { TabPane } = Tabs;

const NewOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    columns,
    loading,
    data,
    onChangeTableFilter,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    ordersExportLoading,
    handleExport,
    handelConfirmSubmitSuccess,
    handleConfirmCheckCode,
    handleConfirmDelivery,
    confirmationCodeError,
    setConfirmationCodeError,
    onCancel,
    updateInProcess,
    handleConfirmTime,
  } = useOrders();

  useLayoutEffect(() => {
    removeLastBreadcrumb('/orders/new');
    addNewBreadcrumb(t('Gestione richieste di ordine'), '/orders/new');
    return () => {
      removeLastBreadcrumb('/orders/new');
    };
  }, []);

  const {
    created_count = 0,
    created_click_and_collect_count = 0,
    created_click_and_reserve_count = 1,
    created_click_and_ship_count = 0,
  } = data.rest || {};

  return (
    <Card className={styles.card} title={t('Gestione richieste di ordine')}>
      <Row>
        <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
          <TabPane
            tab={`${t('Tutte le richieste')} (${created_count})`}
            key='all'
          />
          <TabPane
            tab={`${t('Click & Reserve')} (${created_click_and_reserve_count})`}
            key={OrderTypeEnums.click_and_reserve}
          />
          <TabPane
            tab={`${t('Click & Collect')} (${created_click_and_collect_count})`}
            key={OrderTypeEnums.click_and_collect}
          />
          <TabPane
            tab={`${t('Shop Online')} (${created_click_and_ship_count})`}
            key={OrderTypeEnums.click_and_ship}
          />
        </Tabs>
        <Col span={24}>
          <Filters filters={filters} />
          <br />
        </Col>
        <Col span={24}>
          <Button onClick={handleExport} loading={ordersExportLoading}>
            {t('Scarica excel')}
          </Button>
        </Col>
        <Col span={24}>
          <br />
          <Table
            loading={loading}
            bordered
            dataSource={data.orders}
            pagination={data.pagination}
            onChange={onChangeTableFilter}
            columns={columns}
            scroll={{ x: 3000 }}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={'cancel/:id'}
          element={
            <DeleteModal
              yesBtnText={'Si, annulla'}
              title={t('Sei sicuro di voler annullare l’ordine?')}
              loading={updateInProcess}
              onClickDelete={onCancel}
            />
          }
        />
        <Route
          path={'client/view/:id'}
          element={
            <ClientViewModal
              loading={loading}
              setCurrentOrderId={setCurrentOrderId}
              singleItem={singleItem}
            />
          }
        />
        <Route
          path={'view/:id'}
          element={
            <OrderViewModal
              shop={user?.currentShop}
              loading={loading}
              onClose={() => navigate('/orders/new')}
              order={singleItem}
              variants={singleItem?.order_product_variants}
              setCurrentOrderId={setCurrentOrderId}
              currentOrderId={currentOrderId}
            />
          }
        />
        <Route
          path={'confirm/view/:id'}
          element={
            <OrderViewModalConfirm
              isConfirm={true}
              onClose={() => navigate('/orders/new')}
              handelSubmitSuccess={handelConfirmSubmitSuccess}
            />
          }
        />
        <Route
          path={'edit/view/:id'}
          element={
            <OrderViewModalConfirm
              edit
              onClose={() => navigate('/orders/new')}
              handelSubmitSuccess={handelConfirmSubmitSuccess}
            />
          }
        />
        <Route
          path={'check/view/:id'}
          element={
            <CheckCodeModal
              onCancel={() => navigate('/orders/new')}
              handleOk={handleConfirmCheckCode}
              loading={loading}
              confirmationCodeError={confirmationCodeError}
              setConfirmationCodeError={setConfirmationCodeError}
            />
          }
        />
        <Route
          path={'delivery/view/:id'}
          element={
            <OrderTrackModal
              onCancel={() => navigate('/orders/new')}
              handleOk={handleConfirmDelivery}
              handleConfirmTime={handleConfirmTime}
              loading={loading}
            />
          }
        />
      </Routes>
    </Card>
  );
};

export default NewOrders;
