import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'icons';
import { getUser } from 'hooks/useUser';
import CompaniesApi from 'libs/api/companies';
import LinkWithParams from 'components/linkWithParams';

const api = new CompaniesApi();

const useCompanies = ({ isMainPage = true, isEdit } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const user = useRecoilValue(getUser);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
    handleDownloadExcel,
  } = useApi({
    resourceKey: 'companies',
    singleResourceKey: 'company',
    api,
    isMainPage,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      render: (data, row) => (
        <Row gutter={16} justify={'left'} onClick={(e) => e.stopPropagation()}>
          <Col>
            <LinkWithParams to={`${pathname}/${row.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </LinkWithParams>
          </Col>
          {/*<Col>*/}
          {/*  <Link to={`/team/add/${row.id}/edit`}>*/}
          {/*    <Button icon={<EditIcon />} type={'secondary'} />*/}
          {/*  </Link>*/}
          {/*</Col>*/}
          {/*<Col>*/}
          {/*  <LinkWithParams to={`${pathname}/${row.id}/delete`}>*/}
          {/*    <Button icon={<DeleteIcon />} />*/}
          {/*  </LinkWithParams>*/}
          {/*</Col>*/}
        </Row>
      ),
    },
    {
      title: t('Ragione sociale'),
      dataIndex: 'title',
    },
    {
      title: t('Codice Ateco'),
      dataIndex: 'ateco_code',
    },
    {
      title: t('Partita IVA'),
      dataIndex: 'invoice_code',
    },
    {
      title: t('Codice SDI'),
      dataIndex: 'tax_code',
    },
    {
      title: t('Sede legale'),
      dataIndex: 'address',
    },
    {
      title: t('Insegna'),
      dataIndex: 'shops',
      render: (data) => data.map((i) => i.title).join(','),
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca società'),
        type: 'input',
        name: 'by_title',
        values: [],
        allowClear: true,
      },
    ];
  }, [t]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          company: {
            ...values,
            user_id: user.id,
          },
        };
        if (!id) {
          await api.create({ ...data, lombardy: true });
        } else {
          await api.put(id, { ...data, lombardy: true });
        }
        navigate('/team/list');
      } catch (e) {
        form.setFields(ErrorHelper.getErrorsForForm(e?.response?.data || {}));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [user, form, navigate, setUpdateInProcess],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
  }, [singleItem, isEdit]);

  return {
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
    handleDownloadExcel,
  };
};

export default useCompanies;
