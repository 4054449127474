import Main from './main';

const routes = [
  {
    path: '*',
    Component: Main,
    props: {
      exact: true,
    },
  },
];

export default routes;
