import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Table, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import useRefoundAdmin from './useRefoundAdmin';
import { Route, Routes } from 'react-router-dom';
import OrderViewModal from '../../../../components/orderViewModal';

const { TabPane } = Tabs;

const Refound = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    data,
    loading,
    onChangeTableFilter,
    handleChangeTab,
    columns,
    filters,
    handleCancelRefoundModal,
    user,
    singleItem,
    setCurrentOrderId,
    ordersExportLoading,
    handleExport,
  } = useRefoundAdmin();


  useLayoutEffect(() => {
    removeLastBreadcrumb('/orders/return-practices');
    addNewBreadcrumb(t('Pratiche di reso'), '/orders/return-practices');
    return () => {
      removeLastBreadcrumb('/orders/return-practices');
    };
  }, []);

  const {
    refund_orders_count = 0,
    waiting_for_products_refund_orders_count = 0,
    refunded_orders_count = 0,
    ask_to_refund_orders_count,
  } = data?.rest || {};

  return (
    <Card className={styles.card} title={t('Pratiche di reso')}>
      <Row>
        <Tabs
          style={{
            width: '100%',
          }}
          defaultActiveKey='1'
          onChange={handleChangeTab}
        >
          <TabPane
            tab={`${t('Tutte le pratiche')} (${refund_orders_count})`}
            key='all'
          />
          <TabPane
            tab={`${t(
              'Pratiche in attesa di approvazione',
            )} (${ask_to_refund_orders_count})`}
            key='ask_to_refund'
          />
          <TabPane
            tab={`${t(
              'Pratiche elaborate in attesa di ricevere prodotti',
            )} (${waiting_for_products_refund_orders_count})`}
            key='waiting_for_products_refund'
          />
          <TabPane
            tab={`${t('Pratiche chiuse')} (${refunded_orders_count})`}
            key='refunded'
          />
        </Tabs>
        <Col span={24}>
          <Filters hideClearAll={true} filters={filters} />
          <br />
        </Col>
        <Col span={24}>
          <Button onClick={handleExport} loading={ordersExportLoading}>
            {t('Scarica excel')}
          </Button>
        </Col>
        <Col span={24}>
          <br />
          <Table
            bordered
            dataSource={data.orders}
            pagination={data.pagination}
            columns={columns}
            onChange={onChangeTableFilter}
            loading={loading}
            scroll={{ x: 2000, y: 400 }}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={'view/:id'}
          element={
            <OrderViewModal
              admin
              refound
              shop={user?.currentShop}
              loading={loading}
              onClose={handleCancelRefoundModal}
              order={singleItem}
              variants={singleItem?.order_product_variants}
              setCurrentOrderId={setCurrentOrderId}
            />
          }
        />
      </Routes>
    </Card>
  );
};

export default Refound;
