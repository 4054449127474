import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ResponsiveBarCanvas } from '@nivo/bar';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const barAriaLabel = function (e) {
  return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
};

const axisLeft = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: '',
  legendPosition: 'middle',
  legendOffset: -40,
  format: (e) => (Math.floor(e) === e ? e : ''),
};

const labelTextColor = {
  from: 'color',
  modifiers: [['darker', 1.6]],
};

const axisBottom = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: -45,
  legendPosition: 'middle',
  legendOffset: 32,
};

const borderColor = {
  from: 'color',
  modifiers: [['darker', 1.6]],
};

const fill = [
  {
    match: {
      id: 'fries',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'sandwich',
    },
    id: 'lines',
  },
];

const defs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: '#38bcb2',
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: '#eed312',
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
  },
];

const BarChangeChart = ({
  data,
  keys = [],
  title,
  className = '',
  indexBy = '',
  groupMode = 'grouped',
  colors,
  hideLegend = false,
  itemWidth = 100,
}) => {
  const legends = [
    {
      dataFrom: 'keys',
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 50,
      translateY: 100,
      itemsSpacing: 2,
      itemWidth: itemWidth,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ];

  return (
    <Row className={`${styles.barChange} ${className}`}>
      <Col span={24}>
        <div className={classNames(styles.card)}>
          <Typography.Text strong>{title}</Typography.Text>
          <ResponsiveBarCanvas
            data={data}
            keys={keys}
            indexBy={indexBy}
            colors={colors || { scheme: 'nivo' }}
            margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
            padding={0.3}
            groupMode={groupMode}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            animate={false}
            defs={defs}
            fill={fill}
            borderColor={borderColor}
            axisTop={null}
            axisRight={null}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={labelTextColor}
            legends={hideLegend ? [] : legends}
            height={300}
            role='application'
            ariaLabel='Nivo bar chart demo'
            barAriaLabel={barAriaLabel}
          />
        </div>
      </Col>
    </Row>
  );
};

BarChangeChart.propTypes = {
  lineItemWidth: PropTypes.number,
  translateXLine: PropTypes.number,
};

export default React.memo(BarChangeChart);
