import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
const dataSource = [
  {
    key: '1',
    text: 'lorem',
  },
  {
    key: '2',
    text: 'lorem',
  },
];

const useProducts = () => {
  const [productData] = useState({
    data: dataSource,
  });
  const { t } = useTranslation();

  const columns = [
    {
      title: t('Insegna'),
      dataIndex: 'text',
    },
    {
      title: t('Codice articolo (SKU)'),
      dataIndex: 'text',
    },
    {
      title: t('Colore'),
      dataIndex: 'text',
    },
    {
      title: t('Taglia'),
      dataIndex: 'text',
    },
    {
      title: t('Nome prodotto'),
      dataIndex: 'text',
    },
    {
      title: t('Descrizione'),
      dataIndex: 'text',
    },
    {
      title: t('Immagine'),
      dataIndex: 'text',
    },
    {
      title: t('Categoria merceologica'),
      dataIndex: 'text',
    },
    {
      title: t('Brand'),
      dataIndex: 'text',
    },
    {
      title: t('Stock'),
      dataIndex: 'text',
    },
    {
      title: t('Ordinato'),
      dataIndex: 'text',
    },
    {
      title: t('Venduto'),
      dataIndex: 'text',
    },
    {
      title: t('Disponibile'),
      dataIndex: 'text',
    },
    {
      title: t('Prezzo'),
      dataIndex: 'text',
    },
    {
      title: t('Sconto %'),
      dataIndex: 'text',
    },
    {
      title: t('Prezzo scontato'),
      dataIndex: 'text',
    },
    {
      title: t('Wishlist'),
      dataIndex: 'text',
    },
    {
      title: t('Dettagli'),
      dataIndex: 'text',
    },
    {
      title: t('Peso (grammi)'),
      dataIndex: 'text',
    },
    {
      title: t('Guida alle taglie'),
      dataIndex: 'text',
    },
    {
      title: t('Informazioni aggiuntive'),
      dataIndex: 'text',
    },
    {
      title: t('Stato prodotto'),
      dataIndex: 'text',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca prodotto'),
        type: 'select',
        name: 'by_shopping_center',
        values: [],
      },
      {
        title: t('Filtro categoria'),
        type: 'select',
        name: 'group_by',
        values: [],
      },
      {
        title: t('Filtro data caricamento'),
        type: 'date',
        name: 'by_day_from',
      },
    ];
  }, [t]);

  return { columns, productData, rowSelection, filters };
};

export default useProducts;
