import { useRef, useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import FiltersApi from 'libs/api/filters';
import { atom, selector, useRecoilState } from 'recoil';

const defaultState = {
  available_admin_roles: [],
  categories: [],
  companies: [],
  order_ages: [],
  order_genders: [],
  order_order_type_statuses: [],
  order_refund_reasons: [],
  order_statuses: [],
  product_variant_colors: [],
  product_variant_sizes: [],
  product_variant_statuses: [],
  shops: [],
  delivery_types: [],
  return_types: [],
};

const filterState = atom({
  key: 'filterState',
  default: defaultState,
});

export const getFilter = selector({
  key: 'getFilter',
  get: ({ get }) => {
    return get(filterState);
  },
});

export const useFilter = (page = '', getFilters = false) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const apiRef = useRef(new FiltersApi(page));
  const [filterData, setFilterData] = useRecoilState(filterState);
  const [filterLoading, setFilterLoading] = useState(false);

  const handleGetFilters = () => {
    setFilterLoading(true);
    (async () => {
      const filters = await apiRef.current.list();
      setFilterData(filters.data);
      setFilterLoading(false);
    })();
  };

  useEffect(() => {
    if (getFilters) {
      setFilterLoading(true);
      handleGetFilters();
    }
  }, [getFilters]);

  const handleChangeFilters = useCallback(
    (filter, e) => {
      const { type, keys, name } = filter;
      const currentParams = Object.fromEntries([...searchParams]);
      if (type === 'input') {
        const { value } = e.target;
        if (value) {
          setSearchParams({
            ...currentParams,
            [name]: value,
          });
        } else {
          setSearchParams({
            ...currentParams,
            [name]: '',
          });
        }
      } else if (type === 'range') {
        if (e) {
          setSearchParams({
            ...currentParams,
            [keys[0]]: e[0].format('DD-MM-YYYY'),
            [keys[1]]: e[1].format('DD-MM-YYYY'),
          });
        } else {
          setSearchParams({
            ...currentParams,
            [keys[0]]: '',
            [keys[1]]: '',
          });
        }
      } else if (type === 'select') {
        setSearchParams({
          ...currentParams,
          [name]: e || '',
        });
      } else if (type === 'date') {
        setSearchParams({
          ...currentParams,
          [name]: e.format(filter.format || 'DD-MM-YYYY'),
        });
      } else if (type === 'checkbox') {
        const { checked } = e.target;

        setSearchParams({
          ...currentParams,
          [name]: checked ? 'on' : '',
        });
      }
    },
    [searchParams, setSearchParams],
  );

  const handleCloseFilter = useCallback(
    (filter) => {
      const currentParams = Object.fromEntries([...searchParams]);
      if (filter.type === 'range') {
        filter.keys.forEach((i) => {
          delete currentParams[i];
        });
      } else {
        delete currentParams[filter.name];
      }
      setSearchParams({
        ...currentParams,
      });
    },
    [searchParams, setSearchParams],
  );

  return {
    searchParams,
    filterData,
    setFilterData,
    filterLoading,
    handleChangeFilters,
    handleCloseFilter,
    setSearchParams,
    handleGetFilters,
  };
};
