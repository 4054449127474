import { useEffect, useState, useCallback } from 'react';
import { atom, selector, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import AuthApi from 'libs/api/auth';
import AuthService from 'services/Auth';
import pagesConstant from 'constants/pages';
import ShopsApi from 'libs/api/shops';

const api = new AuthApi();
const shopsAPi = new ShopsApi();

const defaultState = {
  loading: false,
  shops: [],
  currentShop: {},
  available_companies: [],
  availableShops: [],
};

const userState = atom({
  key: 'userState',
  default: defaultState,
});

const pagesState = atom({
  key: 'pageState',
  default: pagesConstant,
});

export const getUser = selector({
  key: 'getUser',
  get: ({ get }) => {
    return get(userState);
  },
});

export const getPages = selector({
  key: 'getPages', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    return get(pagesState);
  },
});

const useUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const [, setPages] = useRecoilState(pagesState);
  const [getShopsLoading, setGetShopsLoading] = useState(false);
  const navigate = useNavigate();
  const [changePasswordErrors, setChangePasswordErrors] = useState([]);
  const changeCurrentUserPassword = useCallback(
    async (data) => {
      setUser((s) => ({ ...s, loading: true }));
      try {
        await api.updatePassword({ ...data }, user?.user?.id);
      } catch (e) {
        console.log(e?.response?.data || []);
        setChangePasswordErrors(e?.response?.data || []);
      } finally {
        setUser((s) => ({ ...s, loading: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  useEffect(() => {
    (async () => {
      if (AuthService.getToken()) {
        setUser((s) => ({ ...s, loading: true }));
        try {
          const { data } = await api.me();
          setUser({
            ...data,
            isManager: data.role === 'manager',
            isAdmin: data.role === 'superadmin',
            isOwner: data.role === 'owner',
            isCashier: data.role === 'cashier',
            loading: false,
            currentShop:
              data.shops.find((i) => i.id === +AuthService.getCurrentShop()) ||
              {},
            currentCompany:
              data.available_companies.find(
                (i) => i.id === +AuthService.getCurrentCompany(),
              ) || {},
          });
          if (data.role === 'superadmin') {
            setPages((s) => s.filter((i) => i.isAdmin));
          } else if (data.role === 'manager') {
            setPages((s) => s.filter((i) => i.isManager));
          } else if (data.role === 'cashier') {
            setPages((s) => s.filter((i) => i.isCashier));
          } else if (data.role === 'owner') {
            setPages((s) => s.filter((i) => i.isOwner));
          }
        } catch (e) {
          setUser(defaultState);
          if (e.response.status === 401) {
            AuthService.logout();
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSetCurrentShop = (id, companyId) => {
    AuthService.setCurrentCompany(companyId);
    AuthService.setCurrentShop(id);
    setUser((s) => ({
      ...s,
      currentShop: s.shops.find((i) => i.id === id) || {},
      currentCompany: s.available_companies.find((i) => i.id === +companyId),
    }));
  };

  const handleChangeCompany = async (id) => {
    if (id) {
      AuthService.setCurrentCompany(id);
      setGetShopsLoading(true);
      try {
        const { data } = await shopsAPi.list({
          params: {
            company_id: id,
            per: 100,
          },
        });
        setUser((s) => {
          return {
            ...s,
            availableShops: data?.shops || [],
            currentCompany:
              s.available_companies.find((i) => i.id === +id) || {},
          };
        });
      } catch (e) {
        console.log(e);
      } finally {
        setGetShopsLoading(false);
      }
    } else {
      setUser((s) => ({
        ...s,
        availableShops: [],
        currentCompany: {},
      }));
    }
  };

  useEffect(() => {
    (async () => {
      if (AuthService.getCurrentCompany()) {
        await handleChangeCompany(AuthService.getCurrentCompany());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    setUser(defaultState);
    setPages(pagesConstant);
  };

  return {
    user,
    handleSetCurrentShop,
    handleChangeCompany,
    getShopsLoading,
    logout,
    changePasswordErrors,
    setChangePasswordErrors,
    changeCurrentUserPassword,
  };
};

export default useUser;
