import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, DatePicker, Typography, Select, Spin } from 'antd';
import { InputVariants } from 'constants/products';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { timeFormat } from 'helpers/utils';
import ReactQuill from 'react-quill';

const { Option } = Select;

export const HTMLEditor = ({ data, onChange, onFocus, onBlur }) => {
  const [value, setValue] = useState(data);

  return (
    <ReactQuill
      theme='snow'
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      onChange={(v) => {
        setValue(v);
        onChange(v);
      }}
    />
  );
};

const Editable = ({
  value,
  variant,
  onChange,
  onBlur,
  onFocus,
  inputType,
  onOpenChange,
  longText,
}) => {
  if (variant === InputVariants.input) {
    if (longText) {
      return (
        <Input.TextArea
          rows={3}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          inputMode={inputType}
        />
      );
    }
    return (
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        inputMode={inputType}
      />
    );
  }

  if (variant === InputVariants.calendar) {
    return (
      <DatePicker
        onChange={onChange}
        onOpenChange={onOpenChange}
        value={moment(value)}
        onBlur={onBlur}
      />
    );
  }

  return null;
};

let timeoutId = null;

const TableColumnItem = ({
  text,
  isEditable = false,
  inputType = 'text',
  variant = InputVariants.input,
  onSubmit,
  longText = false,
  isDate,
  isSelect,
  list,
  isCategory,
  selectedCategoryId,
  isMultiSelect,
  loading,
  prefix,
  isHTML,
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(text);
  const [showEditable, setShowEditable] = useState(false);
  const [focused, setIsFocused] = useState(false);
  const ref = useRef();

  const onChange = useCallback(
    (e) => {
      if (isSelect) {
        return setLocalValue(e);
      }
      if (variant === InputVariants.html) {
        return setLocalValue(e);
      }
      if (variant === InputVariants.input) {
        return setLocalValue(e.target.value);
      }

      setLocalValue(e.format('YYYY-MM-DD'));
    },
    [variant, isSelect],
  );

  const onMouseOver = useCallback(() => {
    if (isEditable) {
      setShowEditable(true);
    }
  }, [isEditable]);

  const onMouseLeave = useCallback(() => {
    if (showEditable && !focused) {
      setShowEditable(false);
    }
  }, [showEditable, focused]);

  const onBlur = useCallback(() => {
    if (showEditable) {
      setShowEditable(false);
      setIsFocused(false);
      const isSame = text === localValue;
      console.log('is', isSame);
      if (!isSame && onSubmit) {
        onSubmit(localValue);
      }
    }
  }, [showEditable, onSubmit, localValue, text]);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    const { current } = ref;
    if (!current) {
      return;
    }
    current?.addEventListener('mouseover', onMouseOver);
    current?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      current?.removeEventListener('mouseover', onMouseOver);
      current?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [onMouseLeave, onMouseOver, ref]);

  useEffect(() => {
    setLocalValue(text);
  }, [text]);

  const onOpenChange = useCallback(
    (open) => {
      setIsFocused(open);
    },
    [onSubmit, localValue, text],
  );

  let renderComponent;

  if (isHTML) {
    renderComponent = <HTMLEditor onBlur={onBlur} onFocus={onFocus} data={localValue} onChange={onChange} />;
  } else {
    // TODO remove this fucking logic
    if (!isSelect) {
      renderComponent = (
        <Editable
          longText={longText}
          value={localValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          variant={variant}
          inputType={inputType}
          onOpenChange={onOpenChange}
        />
      );
    } else {
      if (!isCategory) {
        renderComponent = (
          <Select
            defaultValue={localValue}
            style={{ width: 120 }}
            // onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(value) => {
              onChange(value, true);
            }}
          >
            {list.map((i) => (
              <Option key={i} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        );
      } else {
        renderComponent = (
          <Select
            defaultValue={selectedCategoryId}
            style={{ width: 120 }}
            // onChange={onChange}
            mode={isMultiSelect ? 'multiple' : ''}
            allowClear={isMultiSelect}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(value) => {
              onChange(value, true);
            }}
          >
            {list.map((i) => (
              <Option key={i.id} value={i.id}>
                {i.title}
              </Option>
            ))}
          </Select>
        );
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div
        ref={ref}
        style={{
          minWidth: 100,
          // minHeight: 40,
        }}
      >
        {showEditable ? (
          renderComponent
        ) : (
          <>
            <span ></span>
            {longText ? (
              <>
                {isHTML ? (
                  <div style={{ height: '70px', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: text }} />
                ) : (
                  <Typography.Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      symbol: `${t('main.more')}`,
                    }}
                  >
                    {text}
                  </Typography.Paragraph>
                )}
              </>
            ) : (
              <span className='table-column-item'>
                {isDate ? timeFormat(text) : text}
                {prefix}{' '}
              </span>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default TableColumnItem;
