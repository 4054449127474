import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useFilter } from 'hooks/useFilter';
import { useApi } from 'hooks/useApi';
import {
  getOrderTypeText,
  getOrderStatusText,
  timeFormat,
} from 'helpers/utils';

import OrdersApi from 'libs/api/orders';
import Helpers from 'helpers';
import { OrderTypeEnums } from 'constants/orders';
import { useNavigate } from 'hooks/useNavigate';
import Button from 'components/button';
import { EyeOutlined, QrcodeOutlined } from '@ant-design/icons';
import useDataExport from '../../../data-export/adminView/useDataExport';
import handleDownloadPDF from './getOrderPDF/getOrderPDF';

const api = new OrdersApi();

const archiveOrdersStatuses = {
  all: 'all',
  finished: 'completed,shipped',
  expired: 'expired',
  canceled: 'canceled',
};

const useOrders = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const {
    downloadFile,
    downloadTypes,
    loading: ordersExportLoading,
  } = useDataExport();
  const { searchParams } = useFilter();
  const navigate = useNavigate();
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [additionalFilters, setAdditionalFilters] = useState({
    shop_id: user?.currentShop?.id,
    status:
      'completed,shipped,expired,canceled,ask_to_refund,waiting_for_products_refund,refunded,refused',
  });
  const { loading, data, onChangeTableFilter, updateInProcess, singleItem } =
    useApi({
      resourceKey: 'orders',
      singleResourceKey: 'order',
      api,
      isMainPage: true,
      additionalFilters,
      findByAdditionalId: currentOrderId,
    });

  const columns = useMemo(
    () => [
      {
        title: 'L’etichetta',
        dataIndex: 'id',
        render: (data, shop_id) => (
          <Button
            onClick={() => {
              handleDownloadPDF(shop_id.shop_id, data);
            }}
            type={'primary-white'}
            icon={<QrcodeOutlined />}
          />
        ),
      },
      {
        title: t('Azioni'),
        dataIndex: 'id',
        render: (data) => (
          <Button
            onClick={() => {
              navigate(`view/${data}`);
            }}
            type={'primary-white'}
            icon={<EyeOutlined />}
          />
        ),
      },
      {
        title: t('ID ordine'),
        dataIndex: 'id',
        width: 200,
        sorter: true,
      },
      {
        title: t('Tipo ordine'),
        dataIndex: 'order_type',
        width: 200,
        sorter: true,
        render: (data, row) => getOrderTypeText(data, row?.is_stock_picking),
      },
      {
        title: t('Data ordine'),
        width: 250,
        dataIndex: 'created_at',
        sorter: true,
        render: (data) => timeFormat(data),
      },
      {
        title: t('Codice cliente'),
        width: 200,
        dataIndex: 'user_id',
        sorter: true,
        render: (data, row) => row.customer_id,
      },
      {
        title: t('Nome'),
        width: 200,
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t('Cognome'),
        width: 200,
        dataIndex: 'surname',
        sorter: true,
      },
      {
        title: t('Email'),
        width: 200,
        dataIndex: 'email',
        sorter: true,
      },
      {
        title: t('Cellulare'),
        width: 200,
        dataIndex: 'phone',
        sorter: true,
      },
      {
        title: t('Nr articoli'),
        width: 200,
        dataIndex: 'product_variants_count',
        sorter: true,
        render: (data, row) => row.count,
      },
      {
        title: t('Importo ordine €'),
        width: 200,
        dataIndex: 'amount_cents',
        render: (data, row) => Helpers.numberWithCommas(row.final_amount, ' €'),
        sorter: true,
      },
      {
        title: t('Costo spedizione'),
        width: 200,
        dataIndex: 'delivery_amount_cents',
        render: (data, row) =>
          Helpers.numberWithCommas(row.delivery_amount, ' €'),
        sorter: true,
      },
      {
        title: t('Insegna'),
        width: 200,
        dataIndex: 'shops.title',
        render: (data, row) => row?.shop?.title || '-',
        sorter: true,
      },
      {
        title: t('Numero ordine'),
        width: 200,
        dataIndex: 'order_id',
        sorter: true,
        // render: (data) => data?.title,
      },
      {
        title: t('Info avvenuto pagamento'),
        width: 300,
        dataIndex: 'payments.status',
        sorter: true,
        render: (data, row) => {
          return (
            (row.payment_status === 'succeeded' && 'Effettuato') ||
            (row.payment_status === 'paid' && 'Pagato') ||
            (row.payment_status === 'unpaid' && 'Non pagato')
          );
        },
      },
      {
        title: t('Indirizzo spedizione'),
        width: 300,
        dataIndex: 'address',
        sorter: true,
        render: (data, row) => {
          // row.order_type === OrderTypeEnums.click_and_ship ? data : '-',
          return `${row.country || ''} ${row.province || ''} ${
            row.city || ''
          } ${row.zip_code || ''} ${row.address || ''} ${row.building || ''}`;
        },
      },
      {
        title: t('Stato ordine'),
        dataIndex: 'status',
        width: 200,
        render: (data) => getOrderStatusText(data),
        sorter: true,
      },
      {
        title: t('Codice spedizione'),
        dataIndex: 'track_number',
        width: 200,
        render: (data, row) => {
          return row.track_number || '';
        },
        sorter: true,
      },
    ],
    [t],
  );
  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca ordine'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data ordine'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  const handleChangeTab = (key) => {
    switch (key) {
      case 'all': {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status:
            'completed,shipped,expired,canceled,ask_to_refund,waiting_for_products_refund,refunded,refused',
        });
        break;
      }
      case archiveOrdersStatuses.finished: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: archiveOrdersStatuses.finished,
        });
        break;
      }
      case archiveOrdersStatuses.expired: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: archiveOrdersStatuses.expired,
        });
        break;
      }
      case archiveOrdersStatuses.canceled: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: archiveOrdersStatuses.canceled,
        });
        break;
      }
    }
  };

  useEffect(() => {
    if (user?.currentShop?.id) {
      setAdditionalFilters((s) => {
        if (s.shop_id !== user?.currentShop?.id) {
          return {
            shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id]);

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalFilters },
      downloadTypes.orders,
    );
  }, [searchParams, downloadTypes, additionalFilters]);

  return {
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    archiveOrdersStatuses,
    ordersExportLoading,
    handleExport,
  };
};

export default useOrders;
