import React from 'react';

const GroupIcon = () => {
  return (
    <div className='svg-icon image-group-icon'>
      <svg
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='#D9322E'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.66671 0.333374C7.02033 0.333374 7.35947 0.47385 7.60952 0.723898C7.85957 0.973947 8.00004 1.31309 8.00004 1.66671C8.00004 2.02033 7.85957 2.35947 7.60952 2.60952C7.35947 2.85957 7.02033 3.00004 6.66671 3.00004C6.31309 3.00004 5.97395 2.85957 5.7239 2.60952C5.47385 2.35947 5.33337 2.02033 5.33337 1.66671C5.33337 1.31309 5.47385 0.973947 5.7239 0.723898C5.97395 0.47385 6.31309 0.333374 6.66671 0.333374Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.66671 5.66675C7.02033 5.66675 7.35947 5.80722 7.60952 6.05727C7.85957 6.30732 8.00004 6.64646 8.00004 7.00008C8.00004 7.3537 7.85957 7.69284 7.60952 7.94289C7.35947 8.19294 7.02033 8.33341 6.66671 8.33341C6.31309 8.33341 5.97395 8.19294 5.7239 7.94289C5.47385 7.69284 5.33337 7.3537 5.33337 7.00008C5.33337 6.64646 5.47385 6.30732 5.7239 6.05727C5.97395 5.80722 6.31309 5.66675 6.66671 5.66675Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.33333 11C1.68695 11 2.02609 11.1405 2.27614 11.3905C2.52619 11.6406 2.66667 11.9797 2.66667 12.3333C2.66667 12.687 2.52619 13.0261 2.27614 13.2761C2.02609 13.5262 1.68695 13.6667 1.33333 13.6667C0.979711 13.6667 0.640572 13.5262 0.390524 13.2761C0.140476 13.0261 0 12.687 0 12.3333C0 11.9797 0.140476 11.6406 0.390524 11.3905C0.640572 11.1405 0.979711 11 1.33333 11Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.66671 11C7.02033 11 7.35947 11.1405 7.60952 11.3905C7.85957 11.6406 8.00004 11.9797 8.00004 12.3333C8.00004 12.687 7.85957 13.0261 7.60952 13.2761C7.35947 13.5262 7.02033 13.6667 6.66671 13.6667C6.31309 13.6667 5.97395 13.5262 5.7239 13.2761C5.47385 13.0261 5.33337 12.687 5.33337 12.3333C5.33337 11.9797 5.47385 11.6406 5.7239 11.3905C5.97395 11.1405 6.31309 11 6.66671 11Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.33333 0.333374C1.68695 0.333374 2.02609 0.47385 2.27614 0.723898C2.52619 0.973947 2.66667 1.31309 2.66667 1.66671C2.66667 2.02033 2.52619 2.35947 2.27614 2.60952C2.02609 2.85957 1.68695 3.00004 1.33333 3.00004C0.979711 3.00004 0.640572 2.85957 0.390524 2.60952C0.140476 2.35947 0 2.02033 0 1.66671C0 1.31309 0.140476 0.973947 0.390524 0.723898C0.640572 0.47385 0.979711 0.333374 1.33333 0.333374Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
        <path
          fillRule='even.odd'
          clipRule='evenodd'
          d='M1.33333 5.66675C1.68695 5.66675 2.02609 5.80722 2.27614 6.05727C2.52619 6.30732 2.66667 6.64646 2.66667 7.00008C2.66667 7.3537 2.52619 7.69284 2.27614 7.94289C2.02609 8.19294 1.68695 8.33341 1.33333 8.33341C0.979711 8.33341 0.640572 8.19294 0.390524 7.94289C0.140476 7.69284 0 7.3537 0 7.00008C0 6.64646 0.140476 6.30732 0.390524 6.05727C0.640572 5.80722 0.979711 5.66675 1.33333 5.66675Z'
          fill='#1C1C20'
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
};

export default GroupIcon;
