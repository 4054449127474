import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  Layout as AntLayout,
  Menu,
  Dropdown,
  Spin,
  Breadcrumb,
  Typography,
  Row,
  Col,
} from 'antd';
import { Avatar } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { LogoDarkIcon } from 'icons';
import styles from './style.module.scss';
import AuthService from '../../../services/Auth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import uuid from 'react-uuid';
import Button from '../../button';
import ChangeShopModal from './changeShopModal';
import ChangePasswordModal from './changePasswordModal';
import useBreadcrumb from '../../../hooks/useBreadcrumb';
import { Steps } from 'antd';
import useSteps from '../../../hooks/useSteps';

const { Step } = Steps;

export const nativeBackRoutes = ['finance', 'invoices'];

const { Header: AntHeader } = AntLayout;

const customDot = (dot, { status, index }) => '';

const Header = ({ fullWidth }) => {
  const { breadcrumb } = useBreadcrumb();
  const { step } = useSteps();
  const navigate = useNavigate();
  const [showChangeShopModal, setShowChangeShopModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { t } = useTranslation();
  const {
    user: userInfo,
    changeCurrentUserPassword,
    changePasswordErrors,
    setChangePasswordErrors,
    handleSetCurrentShop,
    handleChangeCompany,
    getShopsLoading,
    logout,
  } = useUser();
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);
  const onClickMenu = useCallback(
    (value) => {
      if (value.key === 'logout') {
        AuthService.logout();
        logout();
        navigate('/login');
      }
    },
    [navigate],
  );

  const toggleChangePasswordModal = useCallback(() => {
    setDropdownMenuVisible(false);
    setShowChangePasswordModal((state) => !state);
  }, []);

  const toggleDropdown = useCallback((flag) => {
    setDropdownMenuVisible(flag);
  }, []);

  const menu = useMemo(() => {
    return (
      <Menu onClick={onClickMenu}>
        <Menu.Item key='0'>
          <span onClick={toggleChangePasswordModal}>
            {t('Cambia password')}
          </span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='logout'>{t('Logout')}</Menu.Item>
      </Menu>
    );
  }, [onClickMenu, toggleChangePasswordModal, t]);

  const renderSteps = () => {
    const stepsComponent = [];

    for (let i = 0; i <= step.count; i++) {
      stepsComponent.push(<Step key={i} />);
    }
    return stepsComponent;
  };

  useEffect(() => {
    if (
      !AuthService.getCurrentShop() &&
      !userInfo.isAdmin &&
      userInfo?.id &&
      !userInfo.isOwner
    ) {
      setShowChangeShopModal(true);
    }
  }, [userInfo]);

  return (
    <AntHeader
      fixed
      className={classNames(styles.header, {
        [styles.header_full]: fullWidth,
      })}
    >
      {fullWidth && (
        <div className={styles.logo}>
          <LogoDarkIcon height={'49px'} width={'auto'} />
        </div>
      )}
      <Row className={styles.headerRow}>
        {step.show && (
          <Col spnan={24} style={{ width: '100%' }}>
            <Steps
              className={styles.steps}
              current={step.current}
              progressDot={customDot}
            >
              {renderSteps()}
            </Steps>
          </Col>
        )}
        <Col span={24} className={styles.bottomRow}>
          <div className={styles.leftSide}>
            <Breadcrumb>
              {breadcrumb.map((i) => {
                return (
                  <Breadcrumb.Item key={uuid()}>
                    <Link to={i.link}>{i.title}</Link>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
          <div className={styles.rightSide}>
            {(userInfo?.isManager || userInfo?.isCashier) && (
              <Row gutter={16}>
                <Col>
                  <Typography>{userInfo.currentShop?.title}</Typography>
                </Col>
                <Col>
                  <Button
                    onClick={() => setShowChangeShopModal(true)}
                    className={styles.changeShopBtn}
                  >
                    {t('Cambia')}
                  </Button>
                </Col>
              </Row>
            )}
            <Avatar
              style={{ backgroundColor: ' #FAAC18' }}
              icon={<UserOutlined />}
            />
            {userInfo?.name ? (
              <Dropdown
                overlay={menu}
                trigger={['click']}
                onVisibleChange={toggleDropdown}
                visible={dropdownMenuVisible}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={styles.userName}
                  onClick={(e) => e.preventDefault()}
                >
                  {userInfo.name} {userInfo.surname} <DownOutlined />
                </a>
              </Dropdown>
            ) : (
              <Spin size={'small'} />
            )}
          </div>
        </Col>
      </Row>

      {showChangeShopModal && (
        <ChangeShopModal
          handleChangeCompany={handleChangeCompany}
          loadingShops={getShopsLoading}
          handleCancel={() => setShowChangeShopModal(false)}
          shops={userInfo?.availableShops || []}
          companies={userInfo?.available_companies || []}
          show={true}
          currentShopId={userInfo.currentShop?.id}
          currentCompanyId={userInfo?.currentCompany?.id}
          onChangeShop={(id, currentCompany) => {
            handleSetCurrentShop(id, currentCompany);
            setShowChangeShopModal(false);
          }}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          setChangePasswordErrors={setChangePasswordErrors}
          loading={userInfo.loading}
          onClickChangePassword={changeCurrentUserPassword}
          onCancel={toggleChangePasswordModal}
          changePasswordErrors={changePasswordErrors}
        />
      )}
    </AntHeader>
  );
};

export default Header;
