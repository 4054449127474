import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { languages } from 'constants/languages';

export const Languages = [languages.itKey, languages.engKey];

export default function configureLanguage() {
  i18n.t('key');
  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: languages.itKey,
      lang: languages.itKey,
      debug: false,
      backend: {
        loadPath: `${process.env.REACT_APP_FRONT_URL}/locales/{{lng}}/{{ns}}.json`,
      },
      react: {
        useSuspense: false,
      },
      whitelist: Languages,
      keySeparator: '.',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}
