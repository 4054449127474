import React, { useEffect } from 'react';
import { Descriptions, Modal, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UIHelper from '../../../../../helpers/UIHelper';

const ClientViewModal = ({ singleItem = {}, setCurrentOrderId, loading }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    setCurrentOrderId('');
    history(UIHelper.getListRoute(pathname) + search);
  };

  useEffect(() => {
    if (id) {
      setCurrentOrderId(id);
    }
  }, [id, setCurrentOrderId]);

  return (
    <Modal
      title={t('Contatta cliente')}
      visible={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Spin size={'large'} spinning={loading}>
        <Descriptions column={1} bordered>
          <Descriptions.Item label={t('Codice cliente')}>
            {singleItem?.customer_id}
          </Descriptions.Item>

          <Descriptions.Item label={t('Nome')}>
            {singleItem.name}
          </Descriptions.Item>

          <Descriptions.Item label={t('Cognome')}>
            {singleItem.surname}
          </Descriptions.Item>

          <Descriptions.Item label={t('Email')}>
            {singleItem.email}
          </Descriptions.Item>

          <Descriptions.Item label={t('Numero telefono')}>
            {singleItem.phone}
          </Descriptions.Item>

          <Descriptions.Item label={t('Indirizzo spedizione')}>
            {`${singleItem.country || ''} ${singleItem.province || ''} ${
              singleItem.city || ''
            } ${singleItem.zip_code || ''} ${singleItem.address || ''} ${
              singleItem.building || ''
            }`}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  );
};

export default ClientViewModal;
