import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/loading';

const PrivateRoute = ({ admin, owner, manager, cashier, path, Element }) => {
  const { user } = useUser();
  let navigate = useNavigate();

  if (!user.id) {
    return <Loading />;
  }

  if (!cashier && user.isCashier) {
    navigate('/404');
  }

  if (!admin && user.isAdmin) {
    navigate('/404');
  }

  if (!owner && user.isOwner) {
    navigate('/404');
  }

  if (!manager && user.isManager) {
    navigate('/404');
  }

  return <Element />;
};

export default PrivateRoute;
