import {
  CatalogProducts,
  DashboardIcon,
  ExportIcon,
  InvoiceIcon,
  OrdersIcon,
  ProfileIcon,
  PromotionIcon,
  StatisticsIcon,
  StockIcon,
  TeamIcon,
  ProfileNewIcon,
  FaturazioneIcon,
} from '../icons';

export const paths = {
  dashboardViewOrder: '/view/:id',
  dashboard: '/',
  login: '/login/*',
  products: '/products/*',
  stripe: '/stripe/*',
  stockPicking: '/stock-picking/*',
  orders: '/orders/*',
  statistica: '/statistica/*',
  promotions: '/promotions/*',
  billing: '/billing/*',
  team: '/team/*',
  exportData: '/export-data/*',
  forgotPassword: '/password-recover/*',
  profile: '/profile/*',
  notFoundPage: '/404',
};

const pages = [
  {
    type: 'halfDone',
    title: 'Dashboard',
    link: '/',
    basePath: '/',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: false,
    icon: <DashboardIcon />,
  },
  {
    type: 'halfDone',
    title: 'Catalogo prodotti',
    link: '/products',
    basePath: '/products',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: true,
    icon: <CatalogProducts />,
  },
  {
    type: 'halfDone',
    title: 'Ordini e resi',
    link: '/orders',
    basePath: '/orders',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: true,
    icon: <OrdersIcon />,
  },
  {
    type: 'halfDone',
    title: 'Stock picking',
    link: '/stock-picking',
    basePath: '/stock-picking',
    isAdmin: false,
    isManager: true,
    isCashier: true,
    isOwner: false,
    icon: <StockIcon />,
  },
  {
    type: 'halfDone',
    title: 'Statistiche',
    link: '/statistica',
    basePath: '/statistica',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: false,
    icon: <StatisticsIcon />,
  },
  {
    type: 'halfDone',
    title: 'Promozioni',
    link: '/promotions',
    basePath: '/promotions',
    isAdmin: true,
    isOwner: false,
    isManager: false,
    isCashier: false,
    icon: <PromotionIcon />,
  },
  {
    type: 'halfDone',
    title: 'Fatturazione',
    link: '/billing',
    basePath: '/billing',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: false,
    icon: <FaturazioneIcon />,
  },
  {
    type: 'halfDone',
    title: 'Team',
    link: '/team',
    basePath: '/team',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: false,
    icon: <TeamIcon />,
  },
  {
    type: 'halfDone',
    title: 'Export dati',
    link: '/export-data',
    basePath: '/export-data',
    isAdmin: true,
    isOwner: true,
    isManager: true,
    isCashier: false,
    icon: <ExportIcon />,
  },
  {
    type: 'halfDone',
    title: 'Profilo',
    link: '/profile',
    basePath: '/profile',
    hide: true,
    isAdmin: true,
    isManager: true,
    isOwner: true,
    isCashier: false,
    icon: <ProfileNewIcon />,
  },
];

export default pages;
