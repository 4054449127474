import React from 'react';
import styles from './style.module.scss';
import { Form, Input } from 'antd';
import { KeyIcon } from 'icons';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import ShowError from '../../../components/showError';
import { Link } from 'react-router-dom';

const ResetForm = ({ onSubmit, loadingBtn, errors }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.formWrapper}>
      <Form
        form={form}
        name='basic'
        initialValues={{
          email: '',
        }}
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          label={t('Nuova password (minimo 6 caratteri)')}
          name='password'
          rules={[
            {
              required: true,
              message: t('Inserisci la tua password!'),
            },
          ]}
        >
          <Input.Password
            prefix={<KeyIcon />}
            placeholder={t('Inserisci la password')}
            size={'large'}
          />
        </Form.Item>
        <Form.Item
          label={t('Conferma password')}
          name='password_confirmation'
          rules={[
            {
              required: true,
              message: t('Inserisci la tua password!'),
            },
          ]}
        >
          <Input.Password
            prefix={<KeyIcon />}
            placeholder={'******'}
            size={'large'}
          />
        </Form.Item>
        <ShowError errors={errors} />
        <Form.Item>
          <Link to={'/login'} className={styles.forgotPassword}>
            {t('Log in')}
          </Link>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Button
            className={styles.button}
            type='primary'
            htmlType='submit'
            size={'large'}
            loading={loadingBtn}
          >
            {t('Cambia la mia password')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetForm;
