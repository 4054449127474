import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import { Route, Routes } from 'react-router-dom';
import DeleteModal from 'components/deleteModalView';
import ViewModal from './viewModal';
import useCompanies from '../useCompanies';
import Button from 'components/button';

const CompaniesList = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    columns,
    data,
    loading,
    onChangeTableFilter,
    handleDownloadExcel,
    updateInProcess,
    onDelete,
    singleItem,
  } = useCompanies({ isMainPage: true });

  useLayoutEffect(() => {
    addNewBreadcrumb(t('Anagrafica società'), '/companies/list');
    return () => {
      removeLastBreadcrumb('/companies/list');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={styles.card} title={t('Anagrafica società')}>
      <Row>
        <Col span={24}>
          <Filters hideClearAll filters={filters} />
        </Col>
        <Col span={24}>
          <Row gutter={16} align={'middle'}>
            <Col>
              <br />
              <Button onClick={() => handleDownloadExcel('Societa.xlsx')}>
                {t('Scarica excel')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <br />
          <Table
            bordered
            dataSource={data.companies}
            pagination={data.pagination}
            onChange={onChangeTableFilter}
            columns={columns}
            scroll={{ x: 1200, y: 400 }}
            loading={loading}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={':id/delete'}
          element={
            <DeleteModal loading={updateInProcess} onClickDelete={onDelete} />
          }
        />
        <Route
          path={':id/view'}
          element={<ViewModal singleItem={singleItem} />}
        />
      </Routes>
    </Card>
  );
};

export default CompaniesList;
