import { notification } from 'antd';
import moment from 'moment';
class UIHelper {
  static successMessage = (message) => {
    if (message) {
      notification.success({ message });
    } else {
      notification.success({
        message: `E' stato salvato con successo${''}`,
      });
    }
    // stata salvata con successo
  };

  static errorMessages = (messages) => {
    notification.success({ message: messages?.join(', ') });
  };

  static showErrorFromAxios = (e, notificationApi) => {
    if (e?.response?.data) {
      console.log('e', e?.response?.data);
      if (typeof e?.response?.data === 'string') {
        (notificationApi || notification).error({
          message: e?.response?.data,
        });
        return;
      }
      Object.keys(e?.response?.data).forEach(key => {
        (notificationApi || notification).error({
          message: e?.response?.data[key]?.join(''),
        });
      });
    }
  };

  static openNotificationWithIcon = (type, message, description) => {
    notification.config({
      duration: 10,
    });
    notification[type]({
      message,
      description,
    });
  };
  static serverError = () => {
    notification.config({
      duration: 4,
    });
    notification['error']({
      message: 'Errore, per favore contatta la direzione ',
    });
  };

  static getListRoute = (pathname, skipPathsLength = 2) => {
    const split = pathname.split('/').filter(Boolean);
    const mainList = split.slice(0, split.length - skipPathsLength);
    return `/${mainList.join('/')}`;
  };

  static formattedDate = (data, format = 'DD MMMM YYYY') => {
    return !format ? moment(data) : moment(data).format(format);
  };

  static typeNumber = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9,\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static typeInteger = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static correctionNumber = (field) => {
    try {
      const res = parseFloat(field.toString().replace(',', '.'));
      if (isNaN(res)) {
        return 0;
      }
      return Number(res);
    } catch (e) {
      return 0;
    }
  };

  static removeDot = (field) => {
    if (typeof field === 'string') {
      return field.replace('.', '');
    }
    return field;
  };

  static formattedNumber = (num) => {
    try {
      return num.replace(/[.,]00$/, '');
    } catch (e) {
      return 0;
    }
  };

  static percentage(percent, total) {
    return Number(((percent / 100) * total).toFixed(2));
  }

  static assignTire = (columns) => {
    columns?.map((i) => {
      if (!i.render) {
        i.render = (data, row) => {
          if (typeof data === 'number') {
            return data;
          }
          return data || '-';
        };
      } else {
        const oldRender = i.render;
        i.render = (data, row) => {
          const renderedData = oldRender(data, row);
          if (typeof renderedData === 'number') {
            return renderedData;
          }
          return renderedData || '-';
        };
      }
      return i;
    });

    return columns;
  };

  static showAnalyticsError = (e) => {
    const dayErrors = e?.response?.data?.by_date_from_ly || [];
    const dayErrors2 = e?.response?.data?.by_date_from_ytd || [];
    const dayErrors3 = e?.response?.data?.by_date_to_ly || [];
    const dayErrors4 = e?.response?.data?.by_date_to_ytd || [];
    const showErrors = [];
    if (dayErrors.length) {
      showErrors.push('Periodo LY Dal ' + dayErrors.join(', '));
    }
    if (dayErrors2.length) {
      showErrors.push('Periodo YTD Dal ' + dayErrors2.join(', '));
    }
    if (dayErrors3.length) {
      showErrors.push('Periodo LY Al ' + dayErrors3.join(', '));
    }
    if (dayErrors4.length) {
      showErrors.push('Periodo YTD Al ' + dayErrors4.join(', '));
    }
    return showErrors;
  };

  static humanizeString(data = '') {
    if (!data) {
      return '';
    }
    return (
      typeof data === 'string' &&
      data
        .split('_')
        .map((w) => w[0].toUpperCase() + w.substring(1))
        .join(' ')
    );
  }

  static formatPrice(price) {
    return price + ' €';
  }
}

export default UIHelper;
