import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Image, Row, Typography } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'icons';
import ShopsApi from 'libs/api/shops';
import LinkWithParams from '../../../../components/linkWithParams';
import Helpers from '../../../../helpers';
import UIHelper from '../../../../helpers/UIHelper';
const { Paragraph } = Typography;

const api = new ShopsApi();

const useShops = ({ isMainPage = true, isEdit } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const [salePointsAttributes, setSalePointsAttributes] = useState([]);
  const [errorMessageSalePoints, setErrorMessageSalePoints] = useState(null);
  const { t } = useTranslation();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    searchParams,
    setLoading,
    setUpdateInProcess,
  } = useApi({
    resourceKey: 'shops',
    singleResourceKey: 'shop',
    api,
    isMainPage,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      render: (data, row) => (
        <Row
          gutter={16}
          style={{ minWidth: 150 }}
          justify={'left'}
          onClick={(e) => e.stopPropagation()}
        >
          <Col span={8}>
            <LinkWithParams to={`${row?.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </LinkWithParams>
          </Col>
          <Col span={8}>
            <Link to={`${row?.id}/edit`}>
              <Button icon={<EditIcon />} />
            </Link>
          </Col>
          <Col span={8}>
            <LinkWithParams to={`${row?.id}/delete`}>
              <Button icon={<DeleteIcon />} />
            </LinkWithParams>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Nome insegna'),
      dataIndex: 'name',
    },
    {
      title: t('Società associata'),
      dataIndex: 'company',
      render: (data) => data?.title || '',
    },
    {
      title: t('Categorie'),
      dataIndex: 'categories_shop',
      render: (data) =>
        data?.length ? data.map((i) => i?.category?.title).join(', ') : '',
    },
    {
      title: t('Punti vendita'),
      dataIndex: 'sale_points',
      render: (data) =>
        data?.length
          ? data.reduce(
              (total, point) =>
                data?.length === 1
                  ? point.address
                  : `${total}, ${point.address}`,
              '',
            )
          : '',
    },
    {
      title: t('Email'),
      dataIndex: 'email_support',
    },
    {
      title: t('Numero telefono'),
      dataIndex: 'phone_support',
    },
    {
      title: t('Sito web'),
      dataIndex: 'website',
      render: (data) => (
        <>
          {data ? (
            <a href={data} target='_blank' rel='noreferrer'>
              {data}
            </a>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: t('Descrizione attività'),
      dataIndex: 'description_it',
      width: 300,
      render: (data) => (
        <>
          {data ? (
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                suffix: '',
              }}
            >
              {data}
            </Paragraph>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: t('Immagine'),
      dataIndex: 'image',
      render: (data) => <>{data && <Image width={50} src={data} />}</>,
    },
    {
      title: t('Servizi / modalità evasione'),
      dataIndex: '',
      render: (_, row) => (
        <>
          {row?.is_click_collect && (
            <p style={{ whiteSpace: 'nowrap' }}>Click Collect</p>
          )}
          {row?.is_click_reserve && (
            <p style={{ whiteSpace: 'nowrap' }}>Click Reserve</p>
          )}
          {row?.is_online_shop && (
            <p style={{ whiteSpace: 'nowrap' }}>Shop online</p>
          )}
        </>
      ),
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca insegna'),
        type: 'input',
        name: 'by_text',
        values: [],
        allowClear: true,
      },
    ];
  }, [t]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      const existingCategories =
        singleItem?.categories_shop?.map((i) => i.category.id) || [];
      const newValues = { ...values };
      newValues.categories_shop_attributes =
        values.categories_shop_attributes.filter(
          (i) => !existingCategories.includes(i),
        );
      const deletedCategories =
        singleItem?.categories_shop?.filter(
          (i) => !values.categories_shop_attributes.includes(i.category.id),
        ) || [];
      newValues.categories_shop_attributes = [
        ...newValues.categories_shop_attributes,
        ...deletedCategories.map((i) => ({
          id: i.id,
          _destroy: true,
        })),
      ];
      const existingSalesPoints =
        singleItem?.sale_points?.map((i) => i.id) || [];
      const newSalePointsAttributes = salePointsAttributes.filter(
        (i) => !existingSalesPoints.includes(i.id),
      );
      const deletedSalesPoints =
        singleItem?.sale_points?.filter(
          (i) => !salePointsAttributes.map((i) => i.id).includes(i.id),
        ) || [];
      newValues.sale_points_attributes = [
        ...newSalePointsAttributes,
        ...deletedSalesPoints.map((i) => ({
          id: i.id,
          _destroy: true,
        })),
      ];

      setUpdateInProcess(true);
      const formData = new FormData();

      Object.keys(newValues).forEach((key) => {
        switch (key) {
          case 'image':
            if (newValues[key] && newValues[key][0]) {
              formData.append(`shop[${key}]`, newValues[key][0]?.originFileObj);
            }
            break;
          case 'categories_shop_attributes': {
            newValues[key].forEach((i, index) => {
              if (i.id) {
                formData.append(`shop[${key}][${index}][id]`, i.id);
                formData.append(`shop[${key}][${index}][_destroy]`, true);
              } else {
                formData.append(`shop[${key}][${index}][category_id]`, i);
              }
            });
            break;
          }
          case 'sale_points_attributes': {
            newValues[key].forEach((i, index) => {
              if (i._destroy) {
                formData.append(`shop[${key}][${index}][id]`, i.id);
                formData.append(`shop[${key}][${index}][_destroy]`, true);
              } else {
                formData.append(
                  `shop[sale_points_attributes][${index}][address]`,
                  i.address,
                );
                formData.append(
                  `shop[sale_points_attributes][${index}][lat]`,
                  i.lat,
                );
                formData.append(
                  `shop[sale_points_attributes][${index}][lon]`,
                  i.lon,
                );
              }
            });
            break;
          }
          default: {
            console.log('key', key);
            formData.append(`shop[${key}]`, newValues[key]);
          }
        }
      });

      try {
        if (!id) {
          await api.createShop(formData);
        } else {
          await api.updateShop(id, formData);
        }
        navigate('/profile/shops/list');
      } catch (e) {
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data || {}, {
            company: 'company_id',
            return_product_it: 'return_product',
            shop_policy_click_collect_it: 'shop_policy_click_collect',
          }),
        );

        if (e?.response?.data?.sale_points) {
          setErrorMessageSalePoints(e.response.data.sale_points[0]);
        }
      } finally {
        setUpdateInProcess(false);
      }
    },
    [form, navigate, setUpdateInProcess, salePointsAttributes, singleItem],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        console.log('sin', singleItem);
        switch (key) {
          case 'image': {
            // fieldsValues.push({
            //   name: 'image',
            //   value: singleItem[key],
            // });
            break;
          }
          // case 'return_product_it': {
          //   fieldsValues.push({
          //     name: 'return_product',
          //     value: singleItem[key],
          //   });
          //   break;
          // }
          // case 'shop_policy_click_collect_it': {
          //   fieldsValues.push({
          //     name: 'shop_policy_click_collect',
          //     value: singleItem[key],
          //   });
          //   break;
          // }
          case 'company': {
            fieldsValues.push({
              name: 'company_id',
              value: singleItem[key]?.id,
            });
            break;
          }
          case 'categories_shop': {
            fieldsValues.push({
              name: 'categories_shop_attributes',
              value: singleItem[key]?.map((i) => i.category.id),
            });
            break;
          }
          case 'sale_points': {
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key] ?? '',
            });
          }
        }
      }
      form.setFields(fieldsValues);
      setSalePointsAttributes(singleItem?.sale_points || []);
    }
  }, [singleItem, isEdit]);

  const handleDownloadExcel = async () => {
    setLoading(true);
    try {
      const params = {
        ...Object.fromEntries([...searchParams]),
      };
      if (data.sorting.order) {
        params.sortable_column = data.sorting.field;
        params.direction = data.sorting.order;
      }
      const response = await api.getExcel({ params });
      Helpers.downloadFile(response, 'Insegne.xlsx');
    } catch (e) {
      const error = e?.response?.data?.errors || [t('Something went wrong!')];
      UIHelper.openNotificationWithIcon('error', error.join('\n'));
    } finally {
      setLoading(false);
    }
  };

  return {
    handleDownloadExcel,
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
    salePointsAttributes,
    setSalePointsAttributes,
    errorMessageSalePoints,
    setErrorMessageSalePoints,
  };
};

export default useShops;
