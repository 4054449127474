import { Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WarningModal = ({ visible, onCancel, onClickOk }) => {
  const { t } = useTranslation();
  return (
    <Modal
      cancelText={t('Annulla')}
      okText={t('Prosegui')}
      title={t('Attenzione')}
      visible={visible}
      onCancel={onCancel}
      onOk={onClickOk}
    >
      <Typography.Title level={3}>
        {t(
          'Attenzione cliccando prosegui verrà azzerata tutta la configurazione. Tutti prodotti verranno cancellati.',
        )}
      </Typography.Title>
    </Modal>
  );
};

export default WarningModal;
