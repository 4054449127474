import React, { useEffect } from 'react';
import styles from '../style.module.scss';
import { Col, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import useSteps from 'hooks/useSteps';
import useECommerce from '../useECommerce';
import PrestashopForm from './prestashopForm';
import ShopifyForm from './shopifyForm';
import WoocommerceForm from './woocommerceForm';
import WixForm from './wixForm';

const SecondStep = () => {
  const { t } = useTranslation();
  const { setStep } = useSteps();
  const navigate = useNavigate();
  const {
    eCommerceData,
    eCommercesTypes,
    loading,
    handleChangeDataECommerce,
    handleSaveSecondStepData,
    handleSetDefaultState,
  } = useECommerce();

  useEffect(() => {
    setStep((s) => ({
      ...s,
      current: eCommerceData.currentECommerceType === 'shopify' ? 3 : 2,
    }));
  }, [setStep, eCommerceData]);

  useEffect(() => {
    if (!eCommerceData.currentECommerceType) {
      navigate('/products/synchronize-e-commerce');
    }
  }, [eCommerceData]);

  return (
    <Row className={styles.row} justify={'space-between'}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text strong>
              {t('Inserisci i dati di accesso')}
            </Typography.Text>
          </Col>
          <Col span={16}>

          </Col>
          <Col span={24}>
            <br />
            {eCommerceData.currentECommerceType ===
              eCommercesTypes.prestashop && (
              <PrestashopForm
                handleChangeData={handleChangeDataECommerce}
                eCommerceData={eCommerceData}
              />
            )}
            {eCommerceData.currentECommerceType === eCommercesTypes.shopify && (
              <ShopifyForm
                handleChangeData={handleChangeDataECommerce}
                eCommerceData={eCommerceData}
              />
            )}
            {eCommerceData.currentECommerceType ===
              eCommercesTypes.woocommerce && (
              <WoocommerceForm
                handleChangeData={handleChangeDataECommerce}
                eCommerceData={eCommerceData}
              />
            )}
            {eCommerceData.currentECommerceType === eCommercesTypes.wix && (
              <WixForm
                handleChangeData={handleChangeDataECommerce}
                eCommerceData={eCommerceData}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col flex={1}>
        <Row
          className={styles.btnsRow}
          align={'bottom'}
          justify={'end'}
          gutter={8}
        >
          <Col>
            <Link to={'/products'}>
              <Button onClick={handleSetDefaultState}>{t('Annulla')}</Button>
            </Link>
          </Col>
          <Col>
            <Button loading={loading} onClick={handleSaveSecondStepData}>
              {t('Salva e continua')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SecondStep;
