import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { ResponsiveLine } from '@nivo/line';
import Helpers from 'helpers';
import classNames from 'classnames';
import styles from './style.module.scss';

const LineChart = ({
  colors,
  data,
  format = (v) => v,
  title,
  tableData = [],
}) => {
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  return (
    <Row>
      <Col span={24}>
        <div className={classNames(styles.card)}>
          <Typography.Text strong>{title}</Typography.Text>
          <ResponsiveLine
            colors={colors || ['#61cdbb', '#f47560']}
            data={localData}
            margin={{ top: 10, right: 30, bottom: 80, left: 80 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            yFormat=' >-.2f'
            axisTop={null}
            axisRight={null}
            curve='monotoneX'
            enableGridX={false}
            enableSlices='x'
            sliceTooltip={({ slice }) => {
              const date = slice.points[0].data.x;
              return (
                <div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                  }}
                >
                  <div>{date}</div>
                  {slice.points.map((point) => (
                    <div
                      key={point.id}
                      style={{
                        color: point.serieColor,
                        padding: '3px 0',
                      }}
                    >
                      <strong>{point.serieId}</strong> {format(point.data.y)}
                    </div>
                  ))}
                </div>
              );
            }}
            axisBottom={{
              format: (tick) =>
                Helpers.hideTicksByCount(tableData, tick) ? tick : '',
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: '',
              // legendOffset: 36,
              legendPosition: 'middle',
              tickValues: 5,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
              // format,
              format: (e) => Math.floor(e) === e && e,
            }}
            lineWidth={2}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            // useMesh={true}
            height={220}
          />
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(LineChart);
