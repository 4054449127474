import Main from './main';
import Success from './success';
import Failure from './failure';

const routes = [
  {
    path: '/*',
    Component: Main,
    props: {},
  },
  {
    path: '/success',
    Component: Success,
    props: {},
  },
  {
    path: '/failure',
    Component: Failure,
    props: {},
  },
];

export default routes;
