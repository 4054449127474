import './styles/global.scss';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';
import 'react-quill/dist/quill.snow.css';

import Pages from 'pages';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        {/*TODO use hook for multi lang change*/}
        <ConfigProvider locale={itIT}>
          <Pages />
        </ConfigProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
