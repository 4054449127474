import React, { useCallback, useLayoutEffect, useState } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { useNavigate } from 'react-router-dom';
import WarningModal from './warningModal';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';

const UploadProductsPage = () => {
  const user = useRecoilValue(getUser);
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [navigateUrl, setNavigateUrl] = useState('');
  const navigate = useNavigate();

  console.log('user', user);

  useLayoutEffect(() => {
    addNewBreadcrumb(t('Carica prodotti'), '/products/upload-products');
    return () => {
      removeLastBreadcrumb('/products/upload-products');
    };
  }, []);

  const eccomerceList = ['prestashop', 'woocommerce', 'shopify', 'wix_commerce']; // none, manual

  const handleOnClick = (url, type) => {
    //[none, prestashop, woocommerce, shopify, wix_commerce, manual]
    return () => {
      if (user?.currentShop?.merchant === 'none') {
        navigate(url);
        return;
      }
      if (type === user?.currentShop?.merchant) {
        navigate(url);
        return;
      }
      if (user?.currentShop?.merchant) {
        setNavigateUrl(url);
        setShowWarningModal(true);
      }
      // if (user?.currentShop?.merchant) {
      //   setNavigateUrl(url);
      //   setShowWarningModal(true);
      // } else {
      //   navigate(url);
      // }
    };
  };

  const onClickWarningModal = useCallback(() => {

  }, [user]);

  return (
    <Card className={styles.card} title={t('Carica prodotti')}>
      <Row
        className={styles.row}
        align={'middle'}
        justify={'center'}
        gutter={[16, 16]}
      >
        <Col span={12}>

        </Col>
        <Col span={24}>
          <Row align={'middle'} justify={'center'} gutter={8}>
            <Col>
              <Button
                onClick={handleOnClick('/products/synchronize-e-commerce', 'ecomerce')}
              >
                {t('Sincronizza articoli da e-commerce')}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleOnClick('/products/moderation?openUpload=true', 'manual')}
              >
                {t('Aggiungi articoli manualmente')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <WarningModal
        onCancel={() => setShowWarningModal(false)}
        visible={showWarningModal}
        onClickOk={() => navigate(navigateUrl)}
        // TODO спрасить может здесь для первого раза показывать модал что если выбрать то или другое после этого обновления все снесет
        // onClickOk={onClickWarningModal}
      />
    </Card>
  );
};

export default UploadProductsPage;
