import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsApi from 'libs/api/analytics';
import moment from 'moment';
import Helpers from 'helpers';
import { useFilter } from 'hooks/useFilter';

const api = new AnalyticsApi();

const useStatisticaProductManager = () => {
  let { searchParams, setSearchParams } = useFilter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    product_variants_refunded: 0,
    product_variants_sold: 0,
    barChartData: [],
    lineChartData: [],
    tableData: [],
  });

  const fetchData = async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!currentParams.by_year) {
      return;
    }
    setLoading(true);
    let format = 'DD MMMM YYYY';
    let params;
    if (!currentParams.by_month) {
      params = {
        date_from: moment(currentParams.by_year, 'YYYY')
          .startOf('year')
          .format('YYYY-MM-DD'),
        date_to: moment(currentParams.by_year, 'YYYY')
          .endOf('year')
          .format('YYYY-MM-DD'),
        type: 'month',
      };
      format = 'MMMM YYYY';
    } else {
      params = {
        date_from: moment(
          `${currentParams.by_year}-${currentParams.by_month}`,
          'YYYY-DD-MM',
        )
          .startOf('month')
          .format('YYYY-MM-DD'),
        date_to: moment(
          `${currentParams.by_year}-${currentParams.by_month}`,
          'YYYY-DD-MM',
        )
          .endOf('month')
          .format('YYYY-MM-DD'),
      };
    }
    try {
      const { data } = await api.get('product_variants', {
        params: {
          ...params,
        },
      });
      const orderLineChartData = {
        id: 'Articoli venduti',
        data: [],
      };
      const barChartData = [];

      Object.entries(data.order_product_variants_sold_by_day || {}).forEach(
        (i) => {
          console.log('i[0]=', i[0], moment(i[0], 'YYYY-MM-DD').format(format));
          orderLineChartData.data.push({
            x: moment(i[0], 'YYYY-MM-DD').format(format),
            y: i[1],
          });
        },
      );

      Object.entries(data.order_product_variants_category_by_day || {}).forEach(
        (i) => {
          barChartData.push({
            category: i[0],
            categoryColor: '#D9322E',
            'Articoli venduti': i[1],
            'Articoli vendutiColor': 'rgb(244, 117, 96)',
          });
        },
      );
      setData({
        ...data,
        lineChartData: [orderLineChartData],
        // tableData: Object.keys(
        //   data.order_product_variants_sold_by_day || {},
        // ).map((i) => moment(i, 'YYYY-DD-MM').format(format)),
        barChartData,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [searchParams]);

  const filters = useMemo(() => {
    return [
      {
        title: t('Filtro anno'),
        type: 'select',
        name: 'by_year',
        valueType: 'number',
        values: Helpers.getYears(
          moment().year() - 2022 + 1,
          moment().year(),
        ).map((i) => ({
          value: i,
          title: i,
        })),
      },
      {
        title: t('Filtro mese'),
        type: 'select',
        name: 'by_month',
        values: Helpers.getMonths(),
      },
    ];
  }, [t]);

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!currentParams.by_year) {
      setSearchParams({
        by_year: moment().year(),
      });
    }
  }, []);

  return {
    loading,
    data,
    filters,
  };
};

export default useStatisticaProductManager;
