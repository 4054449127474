import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { getFilter } from '../../../hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from '../../../hooks/useApi';
import UsersApi from '../../../libs/api/users';
import { useNavigate } from '../../../hooks/useNavigate';
import ErrorHelper from '../../../helpers/ErrorHelper';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from '../../../icons';
import ShopsApi from '../../../libs/api/shops';
import { getUser } from '../../../hooks/useUser';
const dataSource = [
  {
    key: '1',
    text: 'lorem',
  },
  {
    key: '2',
    text: 'lorem',
  },
];

const api = new UsersApi();
const shopsAPi = new ShopsApi();

const useProfile = ({
  isMainPage = true,
  isEdit,
  isOwnerPage = false,
} = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const user = useRecoilValue(getUser);
  const [productData] = useState({
    data: dataSource,
  });
  const [ownerData, setOwnerData] = useState({});
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
    setLoading,
  } = useApi({
    resourceKey: 'users',
    singleResourceKey: 'user',
    api,
    isMainPage,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      render: (data, row) => (
        <Row gutter={16} justify={'left'} onClick={(e) => e.stopPropagation()}>
          <Col>
            <Link to={`${pathname}/${row.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </Link>
          </Col>
          <Col>
            <Link to={`/team/add/${row.id}/edit`}>
              <Button icon={<EditIcon />} />
            </Link>
          </Col>
          <Col>
            <Link to={`${pathname}/${row.id}/delete`}>
              <Button icon={<DeleteIcon />} />
            </Link>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'name',
    },
    {
      title: t('Cognome'),
      dataIndex: 'surname',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
    },
    {
      title: t('Numero telefono'),
      dataIndex: 'phone',
    },
    {
      title: t('Ruolo'),
      dataIndex: 'role',
    },
    {
      title: t('Insegna/e'),
      dataIndex: 'shops',
      render: (data) => data?.map((i) => i.title)?.join(','),
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca membro'),
        type: 'input',
        name: 'by_email',
        values: [],
      },
      {
        title: t('Filtro ruolo'),
        type: 'select',
        name: 'by_role',
        values: filterData.available_admin_roles?.map(({ value, title }) => ({
          title,
          value,
        })),
      },
    ];
  }, [t, filterData]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          user: {
            ...values,
          },
        };
        if (!id) {
          await api.create({ data });
        } else {
          await api.put(id, { data });
        }
        navigate('/team/list');
      } catch (e) {
        form.setFields(ErrorHelper.getErrorsForForm(e?.response?.data || {}));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [form, navigate, setUpdateInProcess],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
  }, [singleItem, isEdit]);

  //profile logic
  useEffect(() => {
    if (isOwnerPage) {
      (async () => {
        setLoading(true);
        try {
          const {
            data: { shop },
          } = await shopsAPi.get(user.currentShop.id);
          setOwnerData(shop.owner || {});
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [isOwnerPage]);

  return {
    handleCreateOrUpdate,
    form,
    columns,
    productData,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,

    ownerData,
  };
};

export default useProfile;
