import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from 'hooks/useBreadcrumb';

const RefundInfoPage = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();

  useLayoutEffect(() => {
    addNewBreadcrumb(
      t('Condizioni di reso della piattaforma'),
      '/profile/refund-info',
    );
    return () => {
      removeLastBreadcrumb('/profile/refund-info');
    };
  }, []);

  return (
    <Card
      className={styles.card}
      title={t('Condizioni di reso della piattaforma')}
    >
      <Row className={styles.row} gutter={[16, 16]}>
        <Col span={18} className={styles.title}>
          Termini e condizioni d’uso di “Bergamo Shoppig” TERMINI E CONDIZIONI
          D’USO - versione applicata dal giorno 20 aprile 2024
        </Col>

        <Col span={18}>
          La piattaforma shop.eastlombardy.it (di seguito anche solo il “
          <span className={styles.boldDescription}>Sito</span>”) è di proprietà,
          è gestita ed è ospitata da{' '}
          <span className={styles.boldDescription}>
            Agenzia per lo sviluppo e la promozione turistica della provincia di
            Bergamo
          </span>
          , con sede in Viale Vittorio Emanuele II n. 20, Bergamo, Italia, C.F e
          P.IVA 02910070164, (di seguito anche solO “VisitBergamo”).
        </Col>

        <Col span={18}>
          Prima di iscriversi al Sito, ogni Utente è tenuto a prendere visione
          ed accettare le presenti Condizioni Generali (di seguito anche solo le
          “ <span className={styles.boldDescription}>Condizioni Generali</span>
          ”), che regolano l’accesso e l’uso del Sito da parte dell’Utente.
          L’Utente è tenuto ad accettare le Condizioni Generali quando viene a
          lui richiesto di farlo durante la procedura di registrazione. Ogni
          Utente deve inoltre leggere l’Informativa sulla Privacy prestando il
          necessario consenso all’utilizzo dei suoi dati personali da parte del
          Sito, di VisitBergamo e di terzi suoi incaricati.
        </Col>

        <Col span={18} className={styles.title}>
          1 DEFINIZIONI
        </Col>

        <Col span={18} className={styles.descriptions}>
          Quando i termini in maiuscolo che seguono sono utilizzati nelle
          presenti Condizioni Generali, avranno il significato stabilito dalle
          definizioni riportate in questa sezione. <br />
          <span className={styles.boldDescription}>
            Servizio/i Aggiuntivo/i:{' '}
          </span>
          indica i servizi aggiuntivi a pagamento forniti da VisitBergamo ai
          Venditori, che richiedono un costo aggiuntivo e che potrebbero essere
          soggetti a termini aggiuntivi come precisati nel separato contratto
          che VisitBergamo e il Venditore sottoscrivono; <br />{' '}
          <span className={styles.boldDescription}>Termini Aggiuntivi: </span>{' '}
          ha il significato stabilito nella sezione 3.6 e indica le condizioni
          che il Venditore deve accettare separatamente per poter fruire dei
          Servizi Aggiuntivi; <br />{' '}
          <span className={styles.boldDescription}>Acquirente:</span> indica
          qualsiasi Utente e Visitatore che acquista o desidera acquistare uno o
          più Oggetti sul Sito ed ha accettato le condizioni del Fornitore di
          Servizi di Pagamento; Pulsante “Acquista”: indica il pulsante
          visualizzato sulla pagina di ciascun Articolo, che permette agli
          Acquirenti di esprimere la loro volontà di procedere con un ordine per
          un Articolo sul Sito; <br />{' '}
          <span className={styles.boldDescription}>Catalogo:</span> indica il
          catalogo elettronico degli Articoli che i Venditori hanno messo in
          vendita tramite il Sito; <br />
          <span className={styles.boldDescription}>
            Regole del Catalogo:
          </span>{' '}
          indica le regole per il Catalogo, inclusi gli Articoli che gli Utenti
          possono mettere in vendita sul Sito. <br />
          <span className={styles.boldDescription}>Pagamento:</span> indica il
          processo di pagamento a disposizione degli Acquirenti per acquistare
          Articoli sul Sito. Il processo di Pagamento: (i) viene avviato dopo
          che l’Acquirente clicca sul pulsante "Acquista", (ii) richiede
          all’Acquirente di selezionare un metodo di pagamento e di indicare un
          indirizzo di consegna o il ritiro presso il Venditore, e (iii) si
          conclude quando l’Acquirente clicca sul pulsante "Paga" nella pagina
          di Pagamento. Il pagamento è regolato dalle condizioni praticate dal
          Fornitore dei Servizi di Pagamento; <br />
          <span className={styles.boldDescription}>
            Periodo di Presentazione del Reclamo:
          </span>{' '}
          ha il significato stabilito nella Sezione 5.5.1; <br />
          <span className={styles.boldDescription}>
            Sistema di Pagamento Integrato:
          </span>{' '}
          indica il sistema di pagamento online che consente agli utenti di
          pagare Articoli e Servizi sul sito. Il Servizio di Pagamento Integrato
          non è fornito da VisitBergamo ma dal Fornitore di Servizi di
          Pagamento; <br />
          <span className={styles.boldDescription}>Articoli:</span> indica i
          beni/articoli che vengono inseriti nel Catalogo, al fine di venderli;{' '}
          <br />
          <span className={styles.boldDescription}>Servizi di Hosting:</span>
          indica i servizi di hosting offerti da VisitBergamo sul o tramite il
          Sito, che consentono di elencare gli Oggetti nel Catalogo, di trovare
          e valutare le offerte di vendita sul sito. <br />
          <span className={styles.boldDescription}>
            Fornitore di Servizi di Pagamento:
          </span>{' '}
          indica il fornitore di servizi di pagamento di VisitBergamo: STRIPE
          PAYMENTS EUROPE LIMITED, una società a responsabilità limitata con
          sede legale C/O A&L GOODBODY, IFSC, NORTH - DUBLINO. STRIPE PAYMENTS
          EUROPE LIMITED, è un istituto di moneta elettronica autorizzato e
          regolato dal ICC International Court of Arbitration. <br />
          <span className={styles.boldDescription}>
            Fornitore dei Servizi di Trasporto e Consegna:
          </span>{' '}
          indica il fornitore dei servizi di consegna e trasporto degli Articoli
          acquistati tramite il Sito, ossia General Logistics Systems BV,
          Breguetlaan 28-30 1438 a.C., Oude Meer, Paesi Bassi (GLS) a cui i
          Venditori dovranno garantire esclusiva. <br />
          <span className={styles.boldDescription}>Venditore:</span> indica
          VisitBergamo che inserisce uno o più Articoli nel relativo Catalogo
          del Sito; <br />
          <span className={styles.boldDescription}>Servizi:</span> indica tutti
          i servizi che vengono offerti attraverso il Sito, inclusi i Servizi di
          Hosting e i Servizi Aggiuntivi, ognuno dei quali è fornito allo scopo
          di garantire un’esperienza sicura e affidabile in relazione al Sito;{' '}
          <br />
          <span className={styles.boldDescription}>
            Significativamente non conforme alla descrizione:
          </span>{' '}
          ha il significato riportato nella Sezione 4.9; <br />
          <span className={styles.boldDescription}>Sito:</span> indica il sito
          web di VisitBergamo, le app e qualsiasi altro mezzo di accesso alla
          piattaforma di Visitbergamo. Il "Sito" comprende i Servizi e tutti i
          contenuti, i tools, le caratteristiche e le funzionalità offerte su o
          attraverso la piattaforma; <br />
          <span className={styles.boldDescription}>
            Account Social di VisitBergamo:
          </span>{' '}
          indica gli account di VisitBergamo su Facebook, Tik Tok, Twitter,
          Instagram, Telegram, YouTube, e sugli altri social network dove
          vengono fornite informazioni relative al Sito e ai Servizi offerti e
          vengono conColisi i contenuti pubblicati dagli Utenti; <br />
          <span className={styles.boldDescription}>
            Condizioni Generali:
          </span>{' '}
          indica l’accordo vincolante tra VisitBergamo e l’Utente, come definito
          nel primo paragrafo delle presenti Condizioni Generali; <br />
          <span className={styles.boldDescription}>Prezzo Totale:</span> indica
          l'importo totale dovuto per una Transazione, che comprende i) il
          prezzo per l'Articolo (o gli Articoli) stabilito dal Venditore; ii) le
          spese di spedizione; iii) qualsiasi tassa o spesa applicabile. <br />
          <span className={styles.boldDescription}>Transazione:</span> indica
          qualsiasi transazione tra gli Utenti effettuata utilizzando il
          pulsante "Acquista", attraverso la quale gli Utenti concludono un
          contratto di acquisto per il relativo Articolo. L’oggetto della
          Transazione è quindi rappresentato dal contenuto del “Carrello”
          dell’Acquirente nel momento in cui si conclude il processo di
          check-out, cliccando sul pulsante "Paga" nella pagina di Pagamento;{' '}
          <br />
          <span className={styles.boldDescription}>
            Account Utente, Utente o Account:
          </span>{' '}
          indica l'account dell'Utente creato al momento della registrazione al
          Sito; <br />
          <span className={styles.boldDescription}>Visitatore:</span> indica
          qualsiasi persona che visita il Sito, ma che non ha creato un Account
          perciò accetta le presenti Condizioni Generali, presta il consenso
          previsto a seguito di lettura dell’Informativa sulla Privacy e
          conferma la lettura dell’Informativa Consumo, solo prima di compiere
          una Transazione; <br />
          <span className={styles.boldDescription}>
            Informativa sulla Privacy:
          </span>{' '}
          indica il documento consultabile qui che definisce le modalità con le
          quali VisitBergamo raccoglie, utilizza, elabora, memorizza e
          trasferisce i dati personali; <br />
          <span className={styles.boldDescription}>
            Informativa Consumo:
          </span>{' '}
          indica il documento consultabile qui di informativa preventiva al
          consumatore per la vendita a distanza.
        </Col>
      </Row>
    </Card>
  );
};

export default RefundInfoPage;
