import routes from './routes';
import SetRoutes from '../../components/setRoutes';

const OrderPages = () => {
  return (
    <>
      <SetRoutes routes={routes} />
    </>
  );
};

export default OrderPages;
