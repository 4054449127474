import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useFilter } from 'hooks/useFilter';
import { useApi } from 'hooks/useApi';
import { getOrderTypeText, timeFormat } from 'helpers/utils';
import OrdersApi from 'libs/api/orders';
import Helpers from 'helpers';
import { OrderTypeEnums } from 'constants/orders';
import { useNavigate } from 'hooks/useNavigate';
import Button from 'components/button';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import useDataExport from '../../../data-export/adminView/useDataExport';
import { Col, Dropdown, Menu, Row } from 'antd';
import OrdersConfirmationApi from 'libs/api/ordersConfirmationApi';
import { Link } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';

const api = new OrdersApi();

const useOrders = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const {
    downloadFile,
    downloadTypes,
    loading: ordersExportLoading,
  } = useDataExport();
  const { searchParams } = useFilter();
  const navigate = useNavigate();
  const [confirmationCodeError, setConfirmationCodeError] = useState({});
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [additionalFilters, setAdditionalFilters] = useState({
    shop_id: user?.currentShop?.id,
    status: 'created',
  });
  const {
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    setUpdateInProcess,
    singleItem,
    fetchList,
    setLoading,
  } = useApi({
    resourceKey: 'orders',
    singleResourceKey: 'order',
    api,
    isMainPage: true,
    additionalFilters,
    findByAdditionalId: currentOrderId,
  });

  console.log('singleItem=', singleItem, currentOrderId);

  const ordersApi = useMemo(
    () => new OrdersConfirmationApi(user?.currentShop?.id),
    [user?.currentShop?.id],
  );

  const confirmationApi = new OrdersConfirmationApi(user?.currentShop?.id);

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: 'id',
        width: 200,
        render: (data, row) => {
          const menu = (
            <Menu>
              {row.order_type !== OrderTypeEnums.click_and_ship && (
                <Link to={`check/view/${data}`}>
                  <Menu.Item>Evasione</Menu.Item>
                </Link>
              )}
              {row.order_type === OrderTypeEnums.click_and_ship && (
                <Link to={`delivery/view/${data}`}>
                  <Menu.Item>Evasione</Menu.Item>
                </Link>
              )}
              <Link
                to={`edit/view/${row.order_id}/?shop_id=${user?.currentShop?.id}&order=${row.order_id}`}
              >
                <Menu.Item>Modifica ordine</Menu.Item>
              </Link>
              <Link to={`client/view/${data}`}>
                <Menu.Item>Contatta cliente</Menu.Item>
              </Link>
              <Link to={`cancel/${data}`}>
                <Menu.Item>Annulla ordine</Menu.Item>
              </Link>
            </Menu>
          );

          return (
            <Row gutter={8}>
              <Col>
                <Button
                  onClick={() => {
                    navigate(`view/${data}`);
                  }}
                  type={'primary-white'}
                  icon={<EyeOutlined />}
                />
              </Col>
              <Col>
                <Dropdown trigger={['click']} overlay={menu}>
                  <Button type={'primary-white'} icon={<SettingOutlined />} />
                </Dropdown>
              </Col>
            </Row>
          );
        },
      },
      {
        title: t('ID ordine'),
        dataIndex: 'id',
        width: 200,
        sorter: true,
      },
      {
        title: t('Tipologia ordine'),
        dataIndex: 'order_type',
        width: 200,
        sorter: true,
        render: (data, row) => getOrderTypeText(data, row?.is_stock_picking),
      },
      {
        title: t('Data ordine'),
        width: 250,
        dataIndex: 'created_at',
        sorter: true,
        render: (data) => timeFormat(data),
      },
      {
        title: t('Codice cliente'),
        width: 200,
        dataIndex: 'user_id',
        sorter: true,
        render: (data, row) => row.customer_id,
      },
      {
        title: t('Nome'),
        width: 200,
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t('Cognome'),
        width: 200,
        dataIndex: 'surname',
        sorter: true,
      },
      {
        title: t('Email'),
        width: 200,
        dataIndex: 'email',
        sorter: true,
      },
      {
        title: t('Cellulare'),
        width: 200,
        dataIndex: 'phone',
        sorter: true,
      },
      {
        title: t('Nr articoli'),
        width: 200,
        dataIndex: 'product_variants_count',
        sorter: true,
        render: (data, row) => row.count,
      },
      {
        title: t('Importo ordine €'),
        width: 200,
        dataIndex: 'amount_cents',
        render: (data, row) => Helpers.numberWithCommas(row.final_amount, ' €'),
        sorter: true,
      },
      {
        title: t('Costo spedizione'),
        width: 200,
        dataIndex: 'delivery_amount_cents',
        render: (data, row) =>
          Helpers.numberWithCommas(row.delivery_amount, ' €'),
        sorter: true,
      },
      {
        title: t('Insegna'),
        width: 200,
        dataIndex: 'shops.title',
        render: (data, row) => row?.shop?.title || '-',
        sorter: true,
      },
      {
        title: t('Numero ordine'),
        width: 200,
        dataIndex: 'order_id',
        sorter: true,
        // render: (data) => data?.title,
      },
      {
        title: t('Info avvenuto pagamento'),
        width: 300,
        dataIndex: 'payments.status',
        sorter: true,
        render: (data, row) => {
          return (
            (row.payment_status === 'succeeded' && 'Effettuato') ||
            (row.payment_status === 'paid' && 'Pagato') ||
            (row.payment_status === 'unpaid' && 'Non pagato')
          );
        },
      },
      {
        title: t('Indirizzo spedizione'),
        width: 300,
        dataIndex: 'address',
        sorter: true,
        render: (data, row) => {
          // row.order_type === OrderTypeEnums.click_and_ship ? data : '-',
          return `${row.country || ''} ${row.province || ''} ${
            row.city || ''
          } ${row.zip_code || ''} ${row.address || ''} ${row.building || ''}`;
        },
      },
      //old
      // {
      //   title: t('Stato ordine'),
      //   dataIndex: 'status',
      //   width: 200,
      //   render: (data) => getOrderStatusText(data),
      // },
      //
      // {
      //   title: t('Note'),
      //   width: 200,
      //   dataIndex: 'comment',
      // },
    ],
    [t, user],
  );
  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca ordine'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data ordine'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  const handleChangeTab = (key) => {
    switch (key) {
      case 'all': {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: 'created',
        });
        break;
      }
      case OrderTypeEnums.click_and_reserve: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          order_type: OrderTypeEnums.click_and_reserve,
          status: 'created',
        });
        break;
      }
      case OrderTypeEnums.click_and_collect: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          order_type: OrderTypeEnums.click_and_collect,
          status: 'created',
        });
        break;
      }
      case OrderTypeEnums.click_and_ship: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          order_type: OrderTypeEnums.click_and_ship,
          status: 'created',
        });
        break;
      }
    }
  };

  useEffect(() => {
    if (user?.currentShop?.id) {
      setAdditionalFilters((s) => {
        if (s.shop_id !== user?.currentShop?.id) {
          return {
            shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id]);

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalFilters },
      downloadTypes.orders,
    );
  }, [searchParams, downloadTypes, additionalFilters]);

  const handelConfirmSubmitSuccess = async () => {
    await fetchList();
    setCurrentOrderId('');
  };

  const handleConfirmCheckCode = async (code, orderId) => {
    setLoading(true);
    setConfirmationCodeError([]);
    try {
      const {
        data: { order_id },
      } = await confirmationApi.confirmation(orderId, {
        confirmation_number: code.join(''),
      });
      navigate(
        `confirm/view/${order_id}/?shop_id=${user?.currentShop?.id}&order=${order_id}`,
      );
    } catch (e) {
      console.log(e);
      setConfirmationCodeError([t('Codice di conferma errato')]);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelivery = async (data, orderId, setData) => {
    setLoading(true);

    try {
      const response = await confirmationApi.dispatchTrackOrder(orderId, data);
      await fetchList();
      setData({
        carrier: '',
        track_number: response.data.order.track_number,
      });
      // navigate('/orders/new');
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async (id) => {
    try {
      await ordersApi.cancelOrder(id);
      await fetchList();
      navigate('/orders/new');
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      console.log(e);
    }
  };

  const handleConfirmTime = async (id, data) => {
    try {
      await confirmationApi.dataFLow(id, data.typeValue);
      return true;
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      console.log(e);
      return false;
    }
  };

  return {
    onCancel,
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    ordersExportLoading,
    handleExport,
    handelConfirmSubmitSuccess,
    handleConfirmCheckCode,
    confirmationCodeError,
    setConfirmationCodeError,
    handleConfirmDelivery,
    handleConfirmTime,
  };
};

export default useOrders;
