import Api from 'libs/api';

class ExportApi extends Api {
  constructor() {
    super('admin/');
  }

  exportProducts(params = {}) {
    return this.buildQuery(
      'get',
      {
        headers: {},
        data: {},
        params,
      },
      'product_variants.xlsx',
    );
  }

  exportOrders(params = {}) {
    return this.buildQuery(
      'get',
      {
        headers: {},
        data: {},
        params,
      },
      'orders.xlsx',
    );
  }

  exportUsers(params = {}) {
    return this.buildQuery(
      'get',
      {
        headers: {},
        data: {},
        params,
      },
      'users.xlsx',
    );
  }

  getUploadStatus(id) {
    return this.buildQuery(
      'get',
      {
        headers: {},
        data: {},
      },
      `export_logs/${id}`,
    );
  }
}

export default ExportApi;
