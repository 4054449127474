export const tokenKey = 'auth_token';
export const shopKey = 'current_shop_id';
export const companyKey = 'current_company_id';

class AuthService {
  static getToken = () => {
    return localStorage.getItem(tokenKey);
  };

  static setToken = (token) => {
    localStorage.setItem(tokenKey, token);
  };

  static logout = () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(companyKey);
    localStorage.removeItem(shopKey);
  };

  static setCurrentShop = (id) => {
    localStorage.setItem(shopKey, id);
  };

  static getCurrentShop = () => {
    return localStorage.getItem(shopKey);
  };

  static setCurrentCompany = (id) => {
    localStorage.setItem(companyKey, id);
  };

  static getCurrentCompany = () => {
    return localStorage.getItem(companyKey);
  };
}

export default AuthService;
