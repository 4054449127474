import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Row, Col, Modal } from 'antd';
import { DeleteIcon } from 'icons';
import Button from '../button';
import { InfoCircleOutlined } from '@ant-design/icons';

const ActionModal = ({
  show = true,
  onClickOk,
  onCancel,
  value,
  title,
  description,
  yesBtnText,
  noBtnText,
  type = 'delete',
  loading,
  children,
}) => {
  const handleOk = () => {
    onClickOk(value);
  };

  return (
    <Modal visible={show} onCancel={onCancel} footer={false}>
      <div className={styles.modalContent}>
        <div className={styles.actionIcon}>
          {type === 'info' && <InfoCircleOutlined />}
          {type === 'delete' && <DeleteIcon fill={'#fff'} />}
        </div>
        <div className={styles.title}>{title}</div>
        <span className={styles.description}>{description}</span>
        {children ? <Row gutter={14}>{children}</Row> : null}
        <Row gutter={14}>
          <Col>
            <Button loading={loading} onClick={handleOk} width={'170px'} big>
              {yesBtnText}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={onCancel}
              width={'170px'}
              type={'primary-white'}
              big
            >
              {noBtnText}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

ActionModal.propTypes = {
  onClickOk: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  description: PropTypes.string,
  yesBtnText: PropTypes.string,
  noBtnText: PropTypes.string,
  show: PropTypes.bool,
  type: PropTypes.oneOfType(['delete', 'info']),
  loading: PropTypes.bool,
};

export default ActionModal;
