import React from 'react';
import { useNavigate as useNavigate_, useSearchParams } from 'react-router-dom';

export function useNavigate() {
  let navigate = useNavigate_();
  let [searchParams] = useSearchParams();
  return React.useCallback(
    (to, ...reset) => {
      return navigate(to + `?${searchParams}`, ...reset);
    },
    [navigate, searchParams]
  );
}
