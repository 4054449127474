import Layout from 'components/layout';
import { Card } from 'antd';

import styles from '../style.module.scss';

const StripeSuccessful = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <Card title={'Stripe onboarding'}>
          Onboarding di Stripe completato con successo
        </Card>
      </div>
    </Layout>
  );
};

export default StripeSuccessful;
