import { useState, useMemo, useCallback } from 'react';
import { useApi } from 'hooks/useApi';
import ProductVariantsApi from 'libs/api/productVariants';
import { useTranslation } from 'react-i18next';
import { Images } from 'components/products/actions';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import Helpers from 'helpers';
import { useFilter } from 'hooks/useFilter';
import { Col, Row, Typography } from 'antd';
import useDataExport from '../../data-export/adminView/useDataExport';
import { Link } from 'react-router-dom';
import Button from '../../../components/button';
import { EyeOutlined } from '@ant-design/icons';
import { getOrderStatusText } from 'helpers/utils';

const api = new ProductVariantsApi();

const rejectedStatuses = ['bad_image', 'bad_info', 'bad_image_info'];

const useArticles = ({ isMainPage = true }) => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const { filterData } = useFilter();
  const { loading: dataExportLoading, handleDownloadExcelProducts } =
    useDataExport();
  const [showModal, setShowModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [additionalFilters] = useState({
    quantity_greater_or_equal: 1,
    shop_id: user.currentShop.id,
  });
  const onSubmit = useCallback(async () => {
    setShowModal(false);
    setSelectedKeys([]);
  }, []);

  const onSelect = useCallback((record, selected) => {
    setSelectedKeys((s) => {
      if (selected) {
        return [...s, { ...record }];
      }
      const find = s.find((item) => item.id === record.id);
      if (find && !selected) {
        return s.filter((f) => f.id !== record.id);
      }
    });
  }, []);
  const {
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    setUpdateInProcess,
    fetchList,
    searchParams,
    singleItem,
  } = useApi({
    resourceKey: 'product_variants',
    singleResourceKey: 'product_variant',
    api,
    isMainPage,
    additionalFilters,
  });

  const onSelectAll = useCallback(
    (selected, selectedRows) => {
      if (selected) {
        return setSelectedKeys(data.product_variants);
      }

      setSelectedKeys([]);
    },
    [data.product_variants],
  );

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: '',
        width: 100,
        render: (data, row) => (
          <Row
            gutter={16}
            justify={'left'}
            onClick={(e) => e.stopPropagation()}
          >
            <Col>
              <Link to={`${row.id}/view`}>
                <Button type={'primary-white'} icon={<EyeOutlined />} />
              </Link>
            </Col>
          </Row>
        ),
      },
      {
        title: t('Codice articolo (SKU)'),
        dataIndex: 'product_number',
        width: 200,
        sorter: true,
      },
      {
        title: t('Insegna'),
        width: 200,
        dataIndex: 'shop_title',
        render: (data, row) => data || '-',
        sorter: true,
      },
      {
        title: t('Colore'),
        dataIndex: 'main_color',
        width: 200,
        sorter: true,
      },
      {
        title: t('Taglia'),
        dataIndex: 'size',
        width: 200,
        sorter: true,
      },
      {
        title: t('Nome prodotto'),
        dataIndex: 'title',
        width: 200,
        sorter: true,
      },
      {
        title: t('Stato prodotto'),
        dataIndex: 'status',
        width: 200,
        render: (data) => getOrderStatusText(data),
        sorter: true,
      },
      {
        title: t('Descrizione'),
        dataIndex: 'description',
        width: 200,
        sorter: true,
        render: (text) => {
          return (
            <Typography.Paragraph
              ellipsis={{ rows: 2, expandable: true }}
              // dangerouslySetInnerHTML={{ __html: 'asd' }}
            >
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </Typography.Paragraph>
          );
        },
      },
      {
        title: t('Immagine'),
        dataIndex: 'product_variant_images',
        width: 130,
        // sorter: (a, b) => a.image - b.image,
        // sortDirections: ['ascend', 'descend'],
        render: (text, data) => {
          return (
            <Images
              isEditor={false} //TODO ADD LOGIC IF WE NEED EDITOR
              disableModalOpen={rejectedStatuses.includes(data.status)}
              isSuperAdmin={user.isAdmin}
              handleUploadImages={() => {}}
              handleSetDefaultImage={() => {}}
              handleDeleteImage={() => {}}
              rowData={data}
              images={text}
              setSetCurrentProduct={() => {}}
              t={t}
              handleChangePosition={() => {}}
              handleRotateImage={() => {}}
            />
          );
        },
      },
      {
        title: t('Data inizio vendita'),
        dataIndex: 'sell_from',
        width: 200,
      },
      {
        title: t('Data termine vendita'),
        dataIndex: 'sell_to',
        width: 200,
      },
      {
        title: t('Categoria merceologica'),
        dataIndex: 'category_name_it',
        sorter: true,
        width: 200,
        render: (data, row) => {
          return row?.categories_product_variants
            ?.map((i) => i.category.title)
            ?.join(',');
        },
      },
      {
        title: t('Brand'),
        dataIndex: 'brand_name',
        width: 200,
        sorter: true,
      },
      {
        title: t('Stock'),
        dataIndex: 'quantity',
        sorter: true,
        width: 200,
      },
      {
        title: t('Ordinato'),
        dataIndex: 'ordered',
        sorter: true,
        width: 200,
      },
      {
        title: t('Venduto'),
        dataIndex: 'sold',
        sorter: true,
        width: 200,
      },
      {
        title: t('Disponibile'),
        dataIndex: 'quantity_available',
        sorter: true,
        width: 200,
      },
      {
        title: t('Prezzo'),
        dataIndex: 'retail_price',
        sorter: true,
        render: (data) => Helpers.numberWithCommas(data, ' €'),
        width: 200,
      },
      {
        title: t('Sconto %'),
        dataIndex: 'discount_percentage',
        sorter: true,
        width: 200,
        render: (data) => Helpers.numberWithCommas(data, '%'),
      },
      {
        title: t('Prezzo scontato'),
        dataIndex: 'discounted_price',
        sorter: true,
        render: (data) => Helpers.numberWithCommas(data, ' €'),
        width: 200,
      },
      {
        title: t('Wishlist'),
        dataIndex: 'wishing_list_count',
        sorter: true,
        width: 200,
      },
      {
        title: t('Prodotto in'),
        dataIndex: 'made_in',
        width: 200,
      },
      {
        title: t('Dettagli'),
        dataIndex: 'details',
        width: 200,
      },
      {
        title: t('Informazioni aggiuntive'),
        dataIndex: 'additional_information',
        width: 200,
      },
      {
        title: t('Peso (grammi)'),
        dataIndex: 'product_weight_gram',
        width: 200,
      },
      {
        title: t('Guida alle taglie'),
        dataIndex: 'size_instructions',
        width: 200,
      },
    ],
    [t],
  );

  const rowSelection = {
    selectedRowKeys: selectedKeys.map((row) => row.id),
    onSelect,
    onSelectAll,
  };

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca prodotto'),
        type: 'input',
        name: 'query',
      },
      {
        title: t('Filtro categoria'),
        type: 'select',
        name: 'category_ids',
        isSearch: true,
        values:
          filterData?.categories?.map((i) => ({
            title: i.title,
            value: i.value,
          })) || [],
      },
      {
        title: t('Filtro data caricamento'),
        type: 'range',
        name: 'by_day_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t, filterData]);
  const handleBulkDestroy = useCallback(async () => {
    setUpdateInProcess(true);
    try {
      await api.bulkDestroy(selectedKeys);
      await fetchList();
      setSelectedKeys([]);
    } catch (e) {
      console.log(e);
    } finally {
      setUpdateInProcess(false);
    }
  }, [selectedKeys]);

  const handleDownloadExcel = async () => {
    handleDownloadExcelProducts({
      ...additionalFilters,
      ...Object.fromEntries([...searchParams]),
    });
  };

  return {
    showModal,
    setShowModal,
    data,
    onChangeTableFilter,
    loading,
    columns,
    rowSelection,
    filters,
    selectedKeys,
    updateInProcess,
    handleBulkDestroy,
    onSubmit,
    user,
    handleDownloadExcel,
    dataExportLoading,
    singleItem,
  };
};

export default useArticles;
