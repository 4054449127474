import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import UploadModal from './uploadModal';
import useArticles from './useArticles';
import { Route, Routes } from 'react-router-dom';
import ViewModal from './viewModal';

const ModerationPage = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    showUploadModal,
    data,
    onChangeTableFilter,
    loading,
    columns,
    rowSelection,
    selectedKeys,
    handleBulkDestroy,
    updateInProcess,
    handleUploadProducts,
    jobData,
    errorTexts,
    progress,
    uploadLoading,
    onCloseUploadMedal,
    productsExportLoading,
    handleExport,
    additionalParams,
    singleItem,
    handleSetStatusOnline,
  } = useArticles({ isMainPage: true });

  useLayoutEffect(() => {
    removeLastBreadcrumb('/products/articles');
    addNewBreadcrumb(t('Carica prodotti'), '/products/articles');
    return () => {
      removeLastBreadcrumb('/products/articles');
    };
  }, []);

  return (
    <Card className={styles.card} title={t('Carica prodotti')}>
      <Row>
        <Col span={24}>
          <Filters filters={filters} />
        </Col>
        <Col span={24}>
          <br />
          <Row justify={'space-between'} gutter={[10, 10]}>
            <Col>
              <Space>
                <a
                  href={'/files/example.xlsx'}
                  download={'example.xlsx'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <Button>{t('Scarica template excel da compilare')}</Button>
                </a>
                <Button onClick={handleExport} loading={productsExportLoading}>
                  {t('Scarica lista dei prodotti')}
                </Button>
              </Space>
            </Col>
            {selectedKeys.length > 0 && (
              <Col>
                <Space>
                  <Typography.Text strong={true}>
                    {selectedKeys.length} {t('articoli selezionati')}
                  </Typography.Text>
                  <Button loading={updateInProcess} onClick={handleBulkDestroy}>
                    {t('Rimuovi')}
                  </Button>
                  {additionalParams.is_online !== false && (
                    <Button
                      loading={updateInProcess}
                      onClick={handleSetStatusOnline}
                    >
                      {t('Pubblica prodotti')}
                    </Button>
                  )}
                </Space>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <br />
          <Table
            loading={loading}
            onChange={onChangeTableFilter}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            bordered
            dataSource={data.product_variants}
            pagination={data.pagination}
            columns={columns}
            scroll={{ x: 1000, y: 1000 }}
            rowKey={'id'}
          />
        </Col>
      </Row>
      {showUploadModal && (
        <UploadModal
          handleUploadProducts={handleUploadProducts}
          handleCancel={onCloseUploadMedal}
          jobData={jobData}
          errorTexts={errorTexts}
          progress={progress}
          uploadLoading={uploadLoading}
        />
      )}
      <Routes>
        <Route
          path={':id/view'}
          element={<ViewModal singleItem={singleItem} />}
        />
      </Routes>
    </Card>
  );
};

export default ModerationPage;
