import { useCallback, useEffect, useState } from 'react';
import { useFilter } from './useFilter';
import { useLocation, useParams } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';
import { useTableChangeFilterHook } from './useTableChangeFilterHook';
import { atom, selector, useRecoilState } from 'recoil';
import { useNavigate } from './useNavigate';
import Helpers from '../helpers';
import { useTranslation } from 'react-i18next';

const pendingRequestState = atom({
  key: 'pendingRequestState',
  default: {
    inProgress: false,
  },
});

export const getPendingRequestState = selector({
  key: 'getPendingRequestState',
  get: ({ get }) => {
    return get(pendingRequestState);
  },
});

const defaultState = {
  pagination: {
    current: 1,
    pageSize: 30,
    position: ['bottomCenter'],
    hideOnSinglePage: true,
    showSizeChanger: false,
  },
  sorting: {
    field: '',
    order: '',
  },
};

const additionalFiltersDefault = {};

const getInitialState = (resourceKey) => ({
  ...defaultState,
  [resourceKey]: [],
});

export const useApi = ({
  resourceKey = '',
  singleResourceKey = '',
  api,
  adapter,
  isMainPage = false,
  additionalFilters = additionalFiltersDefault,
  findByAdditionalId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchParams, handleChangeFilters } = useFilter();
  const { pathname } = useLocation();
  const { id } = useParams();
  const onChangeTable = useTableChangeFilterHook();
  const [filtersFromBack, setFiltersFromBack] = useState({});
  const [data, setData] = useState(getInitialState(resourceKey));
  const [, setPendingRequest] = useRecoilState(pendingRequestState);
  const [loading, setLoading] = useState(true);
  const [singleItem, setSingleItem] = useState({});
  const [updateInProcess, setUpdateInProcess] = useState(false);

  const [formErrors, setFormErrors] = useState(null);

  const fetchList = async () => {
    const searchParamsObj = Object.fromEntries([...searchParams]);
   
    setLoading(true);
    try {
      let params = {
        per: data.pagination.pageSize,
        page: data.pagination.current,
        ...searchParamsObj,
      };
      if (additionalFilters) {
        params = {
          ...params,
          ...additionalFilters,
        };
      }
      if (data.sorting.order) {
        params.sortable_column = data.sorting.field;
        params.direction = data.sorting.order;
      }
      const {
        data: {
          [resourceKey]: resource,
          pagination = {
            total_count: 0,
          },
          filters = {},
          ...rest
        },
      } = await api.list({ params });
      setData((s) => ({
        ...s,
        [resourceKey]: [...(adapter ? adapter(resource) : resource)],
        pagination: {
          ...defaultState.pagination,
          ...pagination,
          total: pagination?.total_count,
        },
        rest,
      }));
      setFiltersFromBack(filters);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoading(false);
    }
  };

  const fetchItem = async (findByAdditionalId) => {
    setLoading(true);
    try {
      const { data } = await api.get(id || findByAdditionalId);
      let { [singleResourceKey]: resource } = data;
      resource = { ...resource, allData: data };
      setSingleItem(adapter ? adapter(resource) : resource);
      console.log('resource=', resource);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e?.response?.status === 404) {
        navigate('/404');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (findByAdditionalId) {
        await fetchItem(findByAdditionalId);
      } else {
        setSingleItem({});
      }
    })();
  }, [findByAdditionalId]);

  useEffect(() => {
    (async () => {
      if (isMainPage) {
        await fetchList();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMainPage,
    searchParams,
    data.pagination.current,
    data.sorting.order,
    additionalFilters,
  ]);

  useEffect(() => {
    setPendingRequest((s) => ({
      ...s,
      inProgress: loading || updateInProcess,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInProcess, loading]);

  useEffect(() => {
    (async () => {
      if (!isMainPage && id) {
        await fetchItem();
      }
    })();

    if (!isMainPage && !id) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isMainPage]);

  const onDelete = async (id) => {
    setUpdateInProcess(true);
    try {
      await api.destroy(id);
      navigate(UIHelper.getListRoute(pathname));
      await fetchList();
    } catch (e) {
      console.log(e?.response?.data?.base || []);
      const errors = e?.response?.data?.base || [];
      if (errors.length) {
        UIHelper.openNotificationWithIcon('error', errors.join('\n'));
      }
    } finally {
      setUpdateInProcess(false);
    }
  };

  const onUpdate = async (data) => {
    setUpdateInProcess(true);
    setPendingRequest({ inProgress: true });
    try {
      await api.put(id, { data });
      navigate(UIHelper.getListRoute(pathname));
      await fetchList();
    } catch (e) {
      setFormErrors(e?.response?.data);
    } finally {
      setUpdateInProcess(false);
    }
  };

  const resetFormErrors = () => {
    if (formErrors) {
      setFormErrors(null);
    }
  };

  const onChangeTableFilter = useCallback(
    (paginate, sort, filter) => {
      onChangeTable(setData, paginate, sort, filter);
    },
    [onChangeTable],
  );

  const handleApprove = async (values, paramKey, backCount) => {
    const data = {
      comment: values.description,
    };
    switch (values.status) {
      case 'Approva': {
        if (!paramKey) {
          data.confirmed = true;
        } else {
          data[paramKey] = 'approva';
        }
        break;
      }
      case 'Rifiutato': {
        if (!paramKey) {
          data.refused = true;
        } else {
          data[paramKey] = 'respingi';
        }
        break;
      }
      case 'Escalate to admin': {
        data.to_admin = true;
        break;
      }
      case 'Annulla Intervento': {
        data.refused = true;
        break;
      }
      case 'publish': {
        data.publish = true;
        break;
      }
      case 'unpublish': {
        data.unpublish = true;
        break;
      }
      default:
        break;
    }
    setUpdateInProcess(true);
    try {
      await api.approve(id, data);
      navigate(UIHelper.getListRoute(pathname, backCount));
    } catch (e) {
      console.log(e);
    } finally {
      setUpdateInProcess(false);
    }
  };

  const handleDownloadExcel = async (name) => {
    setLoading(true);
    try {
      const params = {
        ...additionalFilters,
        ...Object.fromEntries([...searchParams]),
      };
      if (data.sorting.order) {
        params.sortable_column = data.sorting.field;
        params.direction = data.sorting.order;
      }
      const response = await api.getExcel({ params });

      let fileName = 'file.xlsx';

      if (typeof name === 'string' && name) {
        fileName = name;
      }

      Helpers.downloadFile(response, fileName);
    } catch (e) {
      const error = e?.response?.data?.errors || [t('Something went wrong!')];
      UIHelper.openNotificationWithIcon('error', error.join('\n'));
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    singleItem,
    onDelete,
    updateInProcess,
    setLoading,
    formErrors,
    resetFormErrors,
    onUpdate,
    handleChangeFilters,
    onChangeTableFilter,
    setUpdateInProcess,
    setSingleItem,
    handleApprove,
    fetchList,
    searchParams,
    fetchItem,
    filtersFromBack,
    setData,
    handleDownloadExcel,
  };
};
