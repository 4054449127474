import { atom, selector, useRecoilState } from 'recoil';

const defaultState = {
  count: 0,
  show: false,
  current: 1,
};

const stepState = atom({
  key: 'stepState',
  default: defaultState,
});

const useSteps = () => {
  const [step, setStep] = useRecoilState(stepState);

  return {
    step,
    setStep,
  };
};

export default useSteps;
