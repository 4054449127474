import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Table, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import useOrders from './useOrdersAdmin';
import OrderViewModal from 'components/orderViewModal';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';

const { TabPane } = Tabs;

const Articles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    columns,
    loading,
    data,
    onChangeTableFilter,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    ordersExportLoading,
    handleExport,
  } = useOrders();

  useLayoutEffect(() => {
    removeLastBreadcrumb('/orders/archive');
    addNewBreadcrumb(t('Aggiungi articoli manualmente'), '/orders/archive');
    return () => {
      removeLastBreadcrumb('/orders/archive');
    };
  }, []);

  const {
    archive_orders_count = 0,
    completed_orders_count = 0,
    expired_orders_count = 0,
    canceled_orders_count = 0,
  } = data?.rest || {};

  return (
    <Card className={styles.card} title={t('Archivio ordini')}>
      <Row>
        <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
          <TabPane
            tab={`${t('Tutti gli ordini')} (${archive_orders_count})`}
            key='all'
          />
          <TabPane
            tab={`${t('Evasi')} (${completed_orders_count})`}
            key='completed'
          />
          <TabPane
            tab={`${t('Scaduti')} (${expired_orders_count})`}
            key='expired'
          />
          <TabPane
            tab={`${t('Annullati')} (${canceled_orders_count})`}
            key='canceled'
          />
        </Tabs>
        <Col span={24}>
          <Filters filters={filters} />
          <br />
        </Col>
        <Col span={24}>
          <Button onClick={handleExport} loading={ordersExportLoading}>
            {t('Scarica excel')}
          </Button>
        </Col>
        <Col span={24}>
          <br />
          <Table
            loading={loading}
            bordered
            dataSource={data.orders}
            pagination={data.pagination}
            onChange={onChangeTableFilter}
            columns={columns}
            scroll={{ x: 2000, y: 400 }}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={'view/:id'}
          element={
            <OrderViewModal
              shop={user?.currentShop}
              loading={loading}
              onClose={() => navigate('/orders/archive')}
              order={singleItem}
              variants={singleItem?.order_product_variants}
              setCurrentOrderId={setCurrentOrderId}
              currentOrderId={currentOrderId}
            />
          }
        />
      </Routes>
    </Card>
  );
};

export default Articles;
