import { OrderStatuses, OrderTypeEnums } from 'constants/orders';
import localization from 'moment/locale/it';
import moment from 'moment';

moment.updateLocale('it', localization);

export function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCode = (code = {}) => {
  return `${code.order_1}${code.order_2}${code.order_3}`;
};

export const getImage = (images = []) => {
  if (!images || !images.length) return {};

  const defaultImage = images.find((i) => i.by_default);

  if (defaultImage) {
    return defaultImage;
  }

  return images[0];
};

export const getOrderTypeText = (orderType, isStockPicking = false) => {
  let text = 'Click & Collect';
  if (isStockPicking) {
    text = 'Stock picking';
    return text;
  }
  if (orderType === OrderTypeEnums.click_and_collect) {
  }

  if (orderType === OrderTypeEnums.click_and_ship) {
    text = 'Shop online';
  }

  if (orderType === OrderTypeEnums.click_and_reserve) {
    text = 'Click & Reserve';
  }

  return text;
};

export const getOrderStatusText = (status) => {
  let text = status;
  if (status === OrderStatuses.completed) {
    text = 'Completato';
  }

  if (status === OrderStatuses.canceled) {
    text = 'Annullato';
  }
  if (status === OrderStatuses.ask_to_refund) {
    text = 'In attesa di approvazione';
  }

  if (status === OrderStatuses.expired) {
    text = 'Scaduto';
  }

  if (status === OrderStatuses.shipped) {
    text = 'Evaso';
  }

  if (status === OrderStatuses.refunded) {
    text = 'Reso chiuso';
  }
  if (status === OrderStatuses.created) {
    text = 'Nuovo';
  }
  if (status === OrderStatuses.waiting_for_products_refund) {
    text = 'In attesa di ricevere prodotti';
  }
  if (status === OrderStatuses.refused) {
    text = 'Rifiutato';
  }

  return text;
};

export const getProductStatusTest = (status) => {
  let text = status;
  switch (status) {
    case 'correct':
      text = 'online';
      break;
    case 'new':
      text = 'da completare';
      break;
    case 'pending':
      text = 'in approvazione ';
      break;
    case 'bad_description':
    case 'bad_info':
    case 'bad_image':
    case 'bad_image_description':
      text = 'respinto';
      break;
    case 'expired':
      text = 'Scaduto';
      break;
    case 'rejected':
      text = 'respinto';
      break;
    default: {
      break;
    }
  }

  return text;
};

export const isOrderCanceled = (status) => {
  return (
    status === 'bad_description' ||
    status === 'bad_info' ||
    status === 'bad_image' ||
    status === 'bad_image_description'
  );
};

export const timeFormat = (date, format = 'DD MMM YYYY') => {
  const d = moment(date);
  if (d.isValid()) {
    return d.format(format);
  }

  return '-';
};

export const getProductRowBg = (record) => {
  if (
    ['bad_info', 'bad_image', 'bad_image_info'].includes(record.status) ||
    !record.images?.length
  ) {
    return 'table-row-dark';
  }
  return 'table-row-light';
};

export const downloadExel = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getOrderStatuses = (status) => {
  const filterStatus = [];
  if (status !== 'allOrders' && status !== OrderStatuses.completed) {
    filterStatus.push(status);
  }
  if (status === OrderStatuses.completed) {
    filterStatus.push(OrderStatuses.shipped);
    filterStatus.push(OrderStatuses.completed);
  }
  if (status === 'allOrders') {
    filterStatus.push(OrderStatuses.canceled);
    filterStatus.push(OrderStatuses.shipped);
    filterStatus.push(OrderStatuses.completed);
    filterStatus.push(OrderStatuses.refunded);
    filterStatus.push(OrderStatuses.expired);
    filterStatus.push(OrderStatuses.ask_to_refund);
  }

  return filterStatus;
};

export const openOnNewTab = (link) => {
  const a = document.createElement('a');
  a.setAttribute('href', link);
  a.setAttribute('target', '_blank');
  a.click();
};

export const getErrorTexts = (errors = {}) => {
  const result = [];

  Object.keys(errors).forEach((key) => {
    const error = errors[key];
    result.push(error);
  });

  return result.join('\n');
};
