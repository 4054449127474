import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useTeam from '../useTeam';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';

const AddTeam = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, filterData, handleCreateOrUpdate, updateInProcess } = useTeam({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    addNewBreadcrumb(t('Aggiungi membro al team'), '/team/add');
    return () => {
      removeLastBreadcrumb('/team/add');
    };
  }, []);

  const onFinish = async (values) => {
    let newValues = { ...values };
    if (!newValues.password) {
      delete newValues.password;
      delete newValues.password_confirmation;
    }
    await handleCreateOrUpdate(newValues, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  console.log('iddd', id);

  return (
    <Card className={styles.card} title={t('Aggiungi membro al team')}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              shop_ids: [],
              role: '',
              password_confirmation: '',
              password: '',
              phone: '',
              email: '',
              surname: '',
              name: '',
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Nome')} name='name'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Cognome')} name='surname'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Email')} name='email'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Numero telefono')} name='phone'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Password')} name='password'>
                  <Input
                    autoComplete='new-password'
                    type={'password'}
                    placeholder={t('Inserisci testo')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  autoComplete='off'
                  label={t('Conferma password')}
                  name='password_confirmation'
                >
                  <Input type={'password'} placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Ruolo')} name='role'>
                  <Select placeholder={t('Selezioni opzione')}>
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.available_admin_roles.map(
                      ({ value, title }) => (
                        <Select.Option key={value} value={value}>
                          {title}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Insegna')} name='shop_ids'>
                  <Select
                    mode={'multiple'}
                    placeholder={t('Selezioni opzione')}
                  >
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.shops.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button type={'secondary'} onClick={() => navigate('/team')}>
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {!!id ? t('Salva') : t('Crea membro')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddTeam;
