import { useMemo, useEffect } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from '../../hooks/useUser';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import styles from './style.module.scss';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import ManagerView from './managerView';
import AdminView from './adminView';

const StatisticaPage = () => {
  const user = useRecoilValue(getUser);
  const { setBreadcrumb } = useBreadcrumb();
  const { t } = useTranslation();

  const breadcrumb = useMemo(() => {
    if (user.isAdmin) {
      return [
        {
          title: 'Super admin',
          link: '#',
        },
        {
          title: 'Statistiche',
          link: '#',
        },
      ];
    }
    if (user.isOwner) {
      return [
        {
          title: 'Owner',
          link: '#',
        },
        {
          title: 'Statistiche',
          link: '#',
        },
      ];
    }
    return [
      {
        title: user?.currentShop?.title,
        link: '#',
      },
      {
        title: 'Statistiche',
        link: '#',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
  }, [breadcrumb]);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        <Card className={styles.card} title={t('Statistiche')}>
          {(user.isAdmin || user.isOwner) && <AdminView />}
          {user.isManager && <ManagerView />}
        </Card>
      </div>
    </Layout>
  );
};

export default StatisticaPage;
