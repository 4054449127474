export const ProductsFilter = {
  all: 'not_asked',
  online: 'online',
  pending: 'pending',
  not_asked: 'not_asked',
  canceled: 'rifiutati',
  finished: 'only_ended',
  new: 'new',
  correct: 'correct',
  bad_image: 'bad_image',
  bad_info: 'bad_info',
  bad_image_info: 'bad_image_info',
};

export const InputVariants = {
  input: 'input',
  calendar: 'calendar',
  html: 'html',
};
