import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row, Select, DatePicker, Checkbox } from 'antd';
import Button from '../button';
import styles from './style.module.scss';
import FilterWrapper from './wrapper';
import locale from 'antd/es/date-picker/locale/it_IT';
import { useSearchParams } from 'react-router-dom';
import { useFilter } from 'hooks/useFilter';
import { debounce } from 'lodash';
import moment from 'moment';
import { DeleteIcon } from 'icons';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filters = ({ filters = [], hideClearAll = true }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(filters);
  const { handleChangeFilters: onChange, handleCloseFilter: onClose } =
    useFilter();
  const { t } = useTranslation();

  const handleDeselectMenuItem = ({ key }) => {
    setShowFilters((s) => s.filter((i) => i.name !== key));
    const filter = filters.find((i) => i.name === key);
    onClose(filter);
  };

  const handleDeleteFilter = ({ name }) => {
    return () => {
      handleDeselectMenuItem({ key: name });
    };
  };

  useEffect(() => {
    const selectedFilters = [];
    filters.forEach((i) => {
      switch (i.type) {
        case 'checkbox':
          break;
        case 'date':
          const value = searchParams.get(i.name) || '';
          let defaultValue = moment(value, 'DD-MM-YYYY');
          if (value.length === 7) {
            defaultValue = moment(value, 'MM-YYYY');
          }
          selectedFilters.push({
            ...i,
            defaultValue: defaultValue.isValid() ? defaultValue : '',
          });
          break;
        case 'range': {
          const { keys } = i;
          const currentParams = Object.fromEntries([...searchParams]);
          if (keys) {
            const defaultValue = [];
            if (searchParams.get(keys[0]) && searchParams.get(keys[1])) {
              defaultValue[0] = moment(
                searchParams.get(keys[0]),
                i.format || 'DD-MM-YYYY',
              );
              defaultValue[1] = moment(
                searchParams.get(keys[1]),
                i.format || 'DD-MM-YYYY',
              );
            }
            selectedFilters.push({
              ...i,
              defaultValue,
            });
          } else if (currentParams.hasOwnProperty(i.name)) {
            selectedFilters.push({
              ...i,
            });
          }

          break;
        }
        case 'select':
        case 'input': {
          let value = searchParams.get(i.name) || '';

          if (i.valueType === 'number' && value !== '') {
            value = parseInt(value);
          }
          if (value === 0) {
            selectedFilters.push({
              ...i,
              defaultValue: value,
            });
          } else {
            selectedFilters.push({
              ...i,
              defaultValue: value || '',
            });
          }

          break;
        }
        default: {
          break;
        }
      }
    });
    setShowFilters(selectedFilters);
  }, [filters, searchParams]);

  const debouncedSearch = debounce((i, ...values) => {
    onChange(i, ...values);
  }, 400);

  const onChangeFilters = (i, isInput = false) => {
    return (...values) => {
      isInput ? debouncedSearch(i, ...values) : onChange(i, ...values);
    };
  };

  const handleRemoveFilters = useCallback(() => {
    setSearchParams({});
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = showFilters.map((i, index) => {
    if (i.hide) return null;
    if (i.type === 'select') {
      let searchProps = {};
      const isCompanySearch = i.name.includes('company');
      if (i.isSearch || isCompanySearch) {
        searchProps = {
          optionFilterProp: 'title',
          showSearch: true,
          filterOption: (input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        };
      }

      const defaultValue =
        !isNaN(i.defaultValue) && i.defaultValue !== ''
          ? Number(i.defaultValue)
          : i.defaultValue ?? '';
      return (
        <Col key={uuid()}>
          <FilterWrapper title={i.title} onClose={handleDeleteFilter(i)}>
            <Select
              allowClear
              bordered={false}
              placeholder={i.title}
              // onSelect={onChangeFilters(i)}
              onChange={onChangeFilters(i)}
              style={{ minWidth: 120 }}
              dropdownMatchSelectWidth={false}
              defaultValue={defaultValue}
              {...searchProps}
            >
              {i?.values?.map((m, index) => (
                <Option key={index} value={m.value}>
                  {m.title}
                </Option>
              ))}
            </Select>
          </FilterWrapper>
        </Col>
      );
    } else if (i.type === 'range') {
      return (
        <Col key={uuid()}>
          <FilterWrapper title={i.title} onClose={handleDeleteFilter(i)}>
            <RangePicker
              format={i.format || 'DD-MMMM-YYYY'}
              bordered={false}
              locale={locale}
              onChange={onChangeFilters(i)}
              defaultValue={i.defaultValue || ''}
              picker={i.picker}
            />
          </FilterWrapper>
        </Col>
      );
    } else if (i.type === 'date') {
      return (
        <Col key={uuid()}>
          {/* <FilterWrapper title={i.title} onClose={handleDeleteFilter(i)}>
            <DatePicker
              format={i.showFormat || 'DD MMMM YYYY'}
              bordered={false}
              locale={locale}
              placeholder={i.title}
              onChange={onChangeFilters(i)}
              defaultValue={i.defaultValue || ''}
              picker={i.picker}
            />
          </FilterWrapper> */}
        </Col>
      );
    } else if (i.type === 'input') {
      console.log('i.defaultValue=', i.defaultValue);
      return (
        <Col key={index}>
          <FilterWrapper input title={i.title} onClose={handleDeleteFilter(i)}>
            <Input
              allowClear={i.allowClear || false}
              placeholder={i.title}
              bordered={false}
              onChange={onChangeFilters(i, true)}
              defaultValue={i.defaultValue || ''}
            />
          </FilterWrapper>
        </Col>
      );
    } else if (i.type === 'checkbox') {
      return (
        <Col key={uuid()}>
          <FilterWrapper title={i.title} onClose={handleDeleteFilter(i)}>
            <Checkbox
              className={styles.checkbox}
              onChange={onChangeFilters(i)}
            />
          </FilterWrapper>
        </Col>
      );
    }
    return null;
  });

  return (
    <Row gutter={[16, 16]}>
      {fields}
      {!hideClearAll && (
        <Col>
          <Button
            iconMargin={true}
            type={'primary'}
            icon={<DeleteIcon />}
            onClick={handleRemoveFilters}
          >
            {t('Cancella i filtri')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.oneOf(['select', 'range', 'input', 'date']).isRequired,
      name: PropTypes.string,
      isSearch: PropTypes.bool,
      values: PropTypes.arrayOf({
        value: PropTypes.any,
        title: PropTypes.string,
      }),
    }),
  ),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default React.memo(Filters);
