import React, { useEffect, useState } from 'react';
import { Image, Select, Button, Row, Col, Radio, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './style.scss';
import { capitalizeFirstLetter, getImage } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CashierOrderCard = ({
  data = {},
  onChange,
  onRemoveItem,
  isDisabled,
  order,
  isShowMode,
  article,
  onChangesSize,
  length,
  refound,
  admin,
}) => {
  const { t } = useTranslation();
  const [isModify, setIsModify] = useState(false);
  const [sizeVariant, setSizeVariants] = useState([]);

  useEffect(() => {
    if (data?.size_variants) {
      setSizeVariants(data.size_variants);
    }
  }, [data]);

  function handleChange(value, name) {
    onChange(data, { name, value });
  }

  const onClickModify = () => setIsModify((s) => !s);

  const defaultImage = getImage(data?.product_variant_images);
  return (
    <div className='cashier-order-card'>
      <Image width={200} src={defaultImage?.image} />
      <div className='cashier-card-body'>
        <div className='cashier-card-description'>
          <div>
            <div className='card-order-number'>
              Articolo {article} di {length}
            </div>
            <div className='card-order-title'>{data.title}</div>
          </div>
          <div className='card-order-details'>
            <span>
              <b>Code.</b> {data?.product_number}
            </span>
            <span>
              <b>Quantità:</b> {data.count}
            </span>
            {data?.retail_price && data?.retail_price !== '0,00' && (
              <p style={{ marginBottom: 6 }}>
                <b>{t('home.productsTable.retail_price')}:</b>{' '}
                {data?.retail_price} Euro
              </p>
            )}
            {data?.discounted_price && data?.discounted_price !== '0,00' && (
              <p style={{ marginBottom: 12 }}>
                <b>{t('home.productsTable.special_price')}:</b>{' '}
                {data?.discounted_price} Euro
              </p>
            )}
          </div>
          <div className='card-order-type'>
            <div className='type-color'></div>
            <div className='type-size'>
              {isModify && data.size_variants?.length && (
                <Select
                  defaultValue={
                    data.size_variants?.find((i) => i.size === data.size)
                      ?.product_variant_id
                  }
                  style={{ width: 206 }}
                  size='large'
                  onChange={(value) =>
                    !isDisabled && onChangesSize(data, value)
                  }
                >
                  {data.size_variants?.map((i) => (
                    <Option key={i.size} value={i.product_variant_id}>
                      {i.size}
                    </Option>
                  ))}
                  {/*{filters.sizes?.map((i) => (*/}
                  {/*  <Option value={i}>{capitalizeFirstLetter(i)}</Option>*/}
                  {/*))}*/}
                </Select>
              )}
            </div>
          </div>
        </div>
        {refound && !admin && (
          <Row justify={'middle'}>
            <Col>
              <Radio.Group
                onChange={(...rest) => onChange(...rest, data)}
                defaultValue={'refound'}
              >
                <Space direction='vertical'>
                  <Radio value={'refound'}>{t('Approva reso')}</Radio>
                  <Radio value={'cancele'}>{t('Rifiuta reso')}</Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        )}
        {isShowMode ? (
          <></>
        ) : (
          <div className='cashier-card-actions'>
            {!isModify && (
              <Button
                onClick={onClickModify}
                type='primary'
                className='order-modify-btn'
              >
                Modifica
              </Button>
            )}
            {isModify && (
              <Button
                onClick={!isDisabled ? onClickModify : () => {}}
                type='primary'
                className='order-modify-btn order-save-btn'
              >
                Salva
              </Button>
            )}
            <Button
              type=''
              disabled={isModify || length === 1}
              className='order-remove-btn'
              onClick={() => !isDisabled && onRemoveItem(data)}
              icon={<DeleteOutlined />}
            >
              Rimuovi articolo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CashierOrderCard;
