import React from 'react';
import { Alert, Button, Divider, Modal, Progress, Space, Spin } from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import FormHelper from '../../../../../helpers/FormHelper';
import uuid from 'react-uuid';
const { Dragger } = Upload;

const UploadModal = ({
  handleCancel,
  handleUploadProducts,
  jobData,
  errorTexts,
  progress,
  uploadLoading,
}) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    console.log(e.file);
    handleUploadProducts(e.file);
  };
  const slicedErrors = (errorTexts || []).slice(0, 20);
  const moreErrors = (errorTexts || []).slice(20);
  return (
    <Modal
      title={t('Scarica lista dei prodotti')}
      visible={true}
      onOk={(handleOk) => {}}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Annulla</Button>,
        <a
          style={{ margin: '0 8px' }}
          href={'/files/example.xlsx'}
          download={'example.xlsx'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <Button>Scarica file di esempio</Button>
        </a>,
        <Button type='primary' onClick={handleCancel}>
          OK
        </Button>,
      ]}
    >
      {uploadLoading && <Progress percent={progress} />}
      <Spin spinning={uploadLoading}>
        <Dragger
          fileList={[]}
          multiple={false}
          beforeUpload={FormHelper.beforeUpload}
          onChange={onChange}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            {t('Clicca o trascina il file in questa area di caricamento')}
          </p>
        </Dragger>
      </Spin>
      <Divider />
      {!!errorTexts?.length && (
        <>
          <Space
            direction={'vertical'}
            style={{ width: '100%', maxHeight: 400, overflowY: 'auto' }}
          >
            {slicedErrors.map((i) => (
              <Alert
                key={uuid()}
                message={<div dangerouslySetInnerHTML={{ __html: i }} />}
                type='error'
              />
            ))}
            {!!moreErrors?.length && (
              <Alert
                key={uuid()}
                message={<div>{moreErrors?.length} more errors</div>}
                type='error'
              />
            )}
          </Space>
        </>
      )}
    </Modal>
  );
};

export default UploadModal;
