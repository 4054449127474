import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { useFilter } from 'hooks/useFilter';
import ShopsApi from 'libs/api/shops';
import { getUser } from 'hooks/useUser';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';

const shopApi = new ShopsApi();

const eCommercesTypes = {
  prestashop: 'prestashop',
  shopify: 'shopify',
  woocommerce: 'woocommerce',
  wix: 'wix',
};

const defaultState = {
  colors: [],
  merchantAttributeValues: [],
  merchantCategories: [],
  merchantAttributes: [],
  categories: [],
  currentECommerceType: '',
  shopify_category_attribute: 'product_type',
  prestashopData: {
    prestashop_api_url: '',
    prestashop_api_key: '',
    prestashop_customer_group_id: '',
    prestashop_carrier_id: '',
    prestashop_lang_id: '',
  },
  shopifyData: {
    shopify_token: '',
    shopify_domain: '',
  },
  woocommerceData: {
    woocommerce_api_url: '',
    woocommerce_consumer_key: '',
    woocommerce_consumer_secret: '',
  },
  wixData: {
    wix_api_key: '',
    wix_site_id: '',
    wix_account_id: '',
  },
  errors: {},
};

const eCommerceState = atom({
  key: 'eCommerceState  ',
  default: defaultState,
});

const useECommerce = () => {
  const user = useRecoilValue(getUser);
  const [loading, setLoading] = useState(false);
  let [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { filterData } = useFilter();
  const [eCommerceData, setECommerceData] = useRecoilState(eCommerceState);
  const navigate = useNavigate();

  const handleSetCurrentECommerceType = useCallback((e) => {
    setECommerceData((s) => ({
      ...s,
      currentECommerceType: e.target.value,
    }));
  }, []);
  const handleSetShopifyAttribute = useCallback((e) => {
    setECommerceData((s) => ({
      ...s,
      shopify_category_attribute: e.target.value,
    }));
  }, []);

  const handleSetDefaultState = () => {
    setECommerceData(defaultState);
  };

  const handleChangeDataECommerce = (e) => {
    const { name, value } = e.target;
    const dataKey = eCommerceData.currentECommerceType + 'Data';
    setECommerceData((s) => ({
      ...s,
      [dataKey]: {
        ...s[dataKey],
        [name]: value,
      },
    }));
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setECommerceData((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const handleSaveFistStep = async () => {
    setLoading(true);
    try {
      if (eCommerceData.currentECommerceType === 'shopify') {
        navigate(pathname + '/choose-shopify-type');
      } else {
        navigate(pathname + '/step2');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleSaveShopifyStep = async () => {
    setLoading(true);
    try {
      // navigate(pathname + '/step2');
      await shopApi.put(user.currentShop.id, {
        data: {
          shop: {
            shopify_category_attribute:
              eCommerceData.shopify_category_attribute,
          },
        },
      });
      navigate(UIHelper.getListRoute(pathname, 1) + '/step2');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSecondStepData = async () => {
    setLoading(true);
    const dataKey = eCommerceData.currentECommerceType + 'Data';
    try {
      await shopApi.put(user.currentShop.id, {
        data: {
          shop: {
            ...eCommerceData[dataKey],
            merchant: eCommerceData.currentECommerceType,
          },
        },
      });
      const {
        data: { id: jobId },
      } = await shopApi.merchantAttributeImports(user.currentShop.id);
      const myInterval = setInterval(() => {
        (async () => {
          const { data } = await shopApi.showProgressMerchantAttributeImports(
            user.currentShop.id,
            jobId,
          );
          if (data.merchant_attribute_import?.job.status === 'complete') {
            const {
              data: { merchant_attributes = [] },
            } = await shopApi.merchantAttributes(user.currentShop.id);
            const {
              data: { merchant_categories = [] },
            } = await shopApi.getMerchantCategories(user.currentShop.id);

            const {
              data: { merchant_attribute_values },
            } = await shopApi.merchantAttributeValues(user.currentShop.id, {
              params: {
                merchant_attribute_feature: 'color',
              },
            });

            setECommerceData((s) => ({
              ...s,
              merchantAttributes: merchant_attributes,
              merchantCategories: merchant_categories,
              merchantAttributeValues: merchant_attribute_values,
            }));

            clearInterval(myInterval);
            setLoading(false);
            navigate(UIHelper.getListRoute(pathname, 1) + '/step3');
          }
          if (data.merchant_attribute_import?.job.status === 'failed') {
            UIHelper.errorMessages(['Oops, something went wrong!']);
            clearInterval(myInterval);
            setLoading(false);
          }
        })();
      }, 1000);

      // const {
      //   data: { merchant_categories = [] },
      // } = await shopApi.getMerchantCategories(user.currentShop.id);
      // setECommerceData((s) => ({
      //   ...s,
      //   merchantCategories: merchant_categories,
      // }));
      // navigate(UIHelper.getListRoute(pathname, 1) + '/step3');
    } catch (e) {
      console.log(e);
      setECommerceData((s) => ({
        ...s,
        errors: e?.response?.data || {},
      }));
      setLoading(false);
    }
  };

  const handleSetCategories = (eCommerceCategoryId) => {
    return (categoryId) => {
      setECommerceData((s) => {
        const findMerhcantCategory = s.categories.find(
          (i) => i.merchant_category_id === eCommerceCategoryId,
        );
        if (findMerhcantCategory) {
          return {
            ...s,
            categories: s.categories.map((i) => {
              if (i.merchant_category_id === eCommerceCategoryId) {
                return {
                  ...i,
                  category_id: categoryId,
                };
              }
              return i;
            }),
          };
        }

        // if (
        //   findCategory &&
        //   findCategory.merchant_category_id !== eCommerceCategoryId
        // ) {
        //   return {
        //     ...s,
        //     categories: s.categories.map((i) => {
        //       if (i.category_id === categoryId) {
        //         return {
        //           ...i,
        //           merchant_category_id: eCommerceCategoryId,
        //         };
        //       }
        //       return i;
        //     }),
        //   };
        // }

        return {
          ...s,
          categories: [
            ...s.categories,
            {
              merchant_category_id: eCommerceCategoryId,
              category_id: categoryId,
            },
          ],
        };
      });
    };
  };

  const handleSetColors = (colorId) => {
    return (feature) => {
      setECommerceData((s) => {
        const findCategory = s.colors.find((i) => i.id === colorId);

        if (findCategory && findCategory.feature !== feature) {
          return {
            ...s,
            colors: s.colors.map((i) => {
              if (i.id === colorId) {
                return {
                  ...i,
                  feature: feature,
                };
              }
              return i;
            }),
          };
        }

        return {
          ...s,
          colors: [
            ...s.colors,
            {
              id: colorId,
              feature: feature,
            },
          ],
        };
      });
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { merchant_categories = [] },
        } = await shopApi.getMerchantCategories(user.currentShop.id);
        const {
          data: { merchant_attributes = [] },
        } = await shopApi.merchantAttributes(user.currentShop.id);
        const {
          data: { merchant_attribute_values },
        } = await shopApi.merchantAttributeValues(user.currentShop.id, {
          params: {
            merchant_attribute_feature: 'color',
          },
        });
        const categories = merchant_categories
          .filter((i) => i.category)
          .map((i) => ({
            merchant_category_id: i.id,
            category_id: i.category.id,
          }));
        const colors = merchant_attribute_values
          .filter((i) => i.feature)
          .map((i) => ({
            id: i.id,
            feature: i.feature,
          }));
        setECommerceData((s) => ({
          ...s,
          merchantCategories: merchant_categories,
          merchantAttributes: merchant_attributes,
          merchantAttributeValues: merchant_attribute_values,
          colors,
          categories,
        }));
      } catch (e) {
        console.log(e);
      } finally {
      }
    })();
  }, []);

  const handleChangeMerchantAttributes = (id) => {
    return (feature) => {
      setECommerceData((s) => ({
        ...s,
        merchantAttributes: s.merchantAttributes.map((i) => {
          if (i.id === id) {
            return {
              ...i,
              feature,
            };
          }
          return i;
        }),
      }));
    };
  };

  const handleSaveThirdStep = async () => {
    setLoading(true);
    try {
      await shopApi.merchantAttributesBulkUpdate(user.currentShop.id, {
        merchant_attributes: eCommerceData.merchantAttributes.map((i) => ({
          id: i.id,
          feature: i.feature,
        })),
      });
      if (searchParams.get('only')) {
        navigate('/products');
      } else {
        navigate(UIHelper.getListRoute(pathname, 1) + '/step4');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleSaveForthStep = async () => {
    setLoading(true);
    try {
      await shopApi.merchantCategoriesRelations(user.currentShop.id, {
        categories: eCommerceData.categories,
      });
      if (searchParams.get('only')) {
        navigate('/products');
      } else {
        navigate(UIHelper.getListRoute(pathname, 1) + '/step5');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveFifthStep = async () => {
    setLoading(true);
    try {
      await shopApi.merchantAttributeValuesBulkUpdate(user.currentShop.id, {
        merchant_attribute_values: eCommerceData.colors,
      });
      if (searchParams.get('only')) {
        navigate('/products');
      } else {
        const {
          data: { id: jobId },
        } = await shopApi.productImports(user.currentShop.id);
        const myInterval = setInterval(() => {
          (async () => {
            const { data } = await shopApi.productImportsStatus(
              user.currentShop.id,
              jobId,
            );
            if (!data.product_import?.in_progress) {
              // TODO realise show error messages
              clearInterval(myInterval);
              setLoading(false);
              navigate('/products/moderation');
            }
            // if (data.product_import?.job.status === 'complete') {
            //   clearInterval(myInterval);
            //   setLoading(false);
            //   navigate('/products/articles');
            // }
          })();
        }, 1000);
        navigate(UIHelper.getListRoute(pathname, 1) + '/step6');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    eCommercesTypes,
    eCommerceData,
    handleSetCurrentECommerceType,
    handleSetDefaultState,
    handleChangeDataECommerce,
    handleChangeData,
    categories: filterData?.categories || [],
    group_categories: filterData?.group_categories || [],
    colors: filterData?.product_variant_colors || [],
    handleSaveSecondStepData,
    loading,
    handleSetCategories,
    handleSaveThirdStep,
    handleSaveFistStep,
    handleChangeMerchantAttributes,
    handleSaveForthStep,
    handleSetColors,
    handleSaveFifthStep,
    handleSetShopifyAttribute,
    handleSaveShopifyStep,
  };
};

export default useECommerce;
