import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import classNames from 'classnames';
import styles from './buton.module.scss';

const Button = (props) => {
  const {
    children,
    type = 'primary',
    className = '',
    big = false,
    style = {},
    width = '',
    height = '',
    color = '',
    border = '',
    iconMargin = false,
    upperCase,
    ...rest
  } = props;
  return (
    <AntButton
      style={{
        width,
        height,
        color,
        border,
        ...style,
      }}
      className={classNames({
        [styles.button]: true,
        [styles.primary]: type === 'primary',
        [styles.green]: type === 'green',
        [styles.red]: type === 'red',
        [styles.secondary]: type === 'secondary',
        [styles.grey]: type === 'grey',
        [styles.primaryWhite]: type === 'primary-white',
        [styles.big]: big,
        [styles.iconMargin]: iconMargin,
        [styles.upperCase]: upperCase,
        [className]: true,
      })}
      {...rest}
    >
      {children}
    </AntButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'green',
    'red',
    'secondary',
    'primary-white',
    'grey',
  ]),
  width: PropTypes.string,
  big: PropTypes.bool,
  iconMargin: PropTypes.bool,
  icon: PropTypes.element,
  upperCase: PropTypes.bool,
};

export default Button;
