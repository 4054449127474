import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Form, Input, Row, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import usePromotions from '../usePromotions';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';

const orderTypes = [
  {
    value: 'click_and_collect',
    title: 'Click & Collect',
  },
  {
    value: 'click_and_reserve',
    title: 'Click & Reserve',
  },
  {
    value: 'click_and_ship',
    title: 'Click & Ship',
  },
];

const AddTeam = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, handleCreateOrUpdate, updateInProcess } = usePromotions({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    const breadcrumb = {
      title: isEdit ? t('Modifica promozione') : t('Crea promozione'),
      path: isEdit ? `promotions/list/${id}/edit` : '/promotions/add',
    };

    addNewBreadcrumb(breadcrumb.title, breadcrumb.path);
    return () => {
      removeLastBreadcrumb(breadcrumb.path);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit]);

  const onFinish = async (values) => {
    let newValues = { ...values };
    if (!newValues.password) {
      delete newValues.password;
      delete newValues.password_confirmation;
    }
    await handleCreateOrUpdate(newValues, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  const title = isEdit ? t('Modifica promozione') : t('Crea promozione');

  return (
    <Card className={styles.card} title={title}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              title: '',
              total_promotion_amount_cents: '',
              description: '',
              value_cents: '',
              order_sum_amount: '',
              order_sum_count: '',
              order_more_than_amount: '',
              max_signup_invites: '',
              order_type: '',
              promotion_type: '',
              start_date: '',
              end_date: '',
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Titolo')} name='title'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={'total_promotion_amount_cents'}
                  name='total_promotion_amount_cents'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('Descrizione')} name='description'>
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'value_cents'} name='value_cents'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'order_sum_amount'} name='order_sum_amount'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  autoComplete='off'
                  label={'order_sum_count'}
                  name='order_sum_count'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={'order_more_than_amount'}
                  name='order_more_than_amount'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={'max_signup_invites'}
                  name='max_signup_invites'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'order_type'} name='order_type'>
                  <Select
                    {...FormHelper.addSearchProps()}
                    placeholder={t('Selezioni opzione')}
                  >
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {orderTypes.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'start_date'} name='start_date'>
                  <DatePicker
                    placeholder={t('Selezioni dati')}
                    format={'DD MMMM YYYY'}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'end_date'} name='end_date'>
                  <DatePicker
                    placeholder={t('Selezioni dati')}
                    format={'DD MMMM YYYY'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button type={'secondary'} onClick={() => navigate('/promotions')}>
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddTeam;
