import React, { useState } from 'react';
import { Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const ChangeShopModal = ({
  show,
  handleCancel = () => {},
  onChangeShop = () => {},
  shops,
  currentShopId = '',
  companies,
  handleChangeCompany,
  loadingShops,
  currentCompanyId = '',
}) => {
  const [currentShop, setCurrentShop] = useState(currentShopId);

  const [currentCompany, setCurrentCompany] = useState(currentCompanyId);
  const { t } = useTranslation();

  const handleOk = () => {
    onChangeShop(currentShop, currentCompany);
    window.location.reload();
  };

  const onClose = () => {
    //uncomment before pushing
    // if (currentShop && currentCompany) {
    handleCancel();
    // }
  };

  return (
    <Modal
      title={t('Seleziona il tuo negozio')}
      visible={show}
      onOk={handleOk}
      onCancel={onClose}
      okText={t('Conferma')}
      okButtonProps={{
        disabled: !currentShop || !currentCompany,
      }}
      cancelButtonProps={{
        disabled: !currentShop || !currentCompany,
      }}
    >
      <Space direction={'vertical'}>
        <Typography.Text strong={true}>
          {t('Seleziona la tua società')}
        </Typography.Text>
        <Select
          value={currentCompany}
          style={{ width: 200 }}
          onChange={(key) => {
            handleChangeCompany(key);
            setCurrentCompany(key);
          }}
        >
          <Option value=''>{t('Selezioni opzione')}</Option>
          {companies.map((i) => {
            return (
              <Option value={i.id} key={i.id}>
                {i.title}
              </Option>
            );
          })}
        </Select>
        <Typography.Text strong={true}>
          {t('Seleziona negozio')}
        </Typography.Text>
        <Select
          value={currentShop}
          style={{ width: 200 }}
          onChange={(key) => setCurrentShop(key)}
          loading={loadingShops}
          disabled={loadingShops}
        >
          <Option value=''>{t('Selezioni opzione')}</Option>
          {shops.map((i) => {
            return (
              <Option value={i.id} key={i.id}>
                {i.title}
              </Option>
            );
          })}
        </Select>
      </Space>
    </Modal>
  );
};

export default React.memo(ChangeShopModal);
