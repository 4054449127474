import { Col, Row, Slider, Table, Typography } from 'antd';
import StatisticCard from 'components/statisticCard';
import useHomeManager from './useHomeManager';
import { useTranslation } from 'react-i18next';
import OrderViewModal from 'components/orderViewModal';
import { Link, Route, Routes } from 'react-router-dom';
import MyTable from '../../../components/myTable';
import Button from '../../../components/button';

const ManagerView = () => {
  const {
    columnsOrderTable,
    statistic,
    data,
    onChangeTableFilter,
    refoundData,
    refoundOnChangeTableFilter,
    columnsProductTable,
    productVariantData,
    productVariantOnChangeTableFilter,
    handleChangeQuantityAvailable,
    onRowOrderTable,
    loading,
    refoundLoading,
    productVariantLoading,
    setCurrentOrderId,
    currentOrderId,
    singleItem,
    navigate,
    user,
  } = useHomeManager();
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <Col span={24}>
            <Row justify={'space-between'}>
              <Col>
                <Typography.Paragraph strong>
                  {t('Richieste di ordine')}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Link to={'/orders/new'}>
                  <Button>Gestisci richieste di ordine</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <StatisticCard
              count={statistic?.created_count}
              text={'Richieste di ordine'}
              loading={loading}
            />
          </Col>
          {/* <Col span={8}>
            <StatisticCard
              count={statistic?.created_click_and_reserve_count}
              text={'Richieste di ordine click&reserve'}
              loading={loading}
            />
          </Col>
          <Col span={8}>
            <StatisticCard
              count={statistic?.created_click_and_collect_count}
              text={'Richieste di ordine click&collect'}
              loading={loading}
            />
          </Col> */}
          {/* <Col span={8}>
            <StatisticCard
              count={statistic?.created_click_and_ship_count}
              text={'Richieste di ordine shop online'}
              loading={loading}
            />
          </Col> */}
          <Col span={24}>
            <MyTable
              loading={loading}
              bordered
              onChange={onChangeTableFilter}
              dataSource={data.orders}
              pagination={data.pagination}
              columns={columnsOrderTable}
              onRow={onRowOrderTable}
            />
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <Col span={24}>
            <Row justify='space-between'>
              <Col>
                <Typography.Paragraph strong>
                  {t('Richieste di reso')}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Link to={'/orders/refound'}>
                  <Button>Gestisci richieste di reso</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <StatisticCard
              loading={loading}
              count={statistic?.ask_to_refund_orders_count}
              text={t('Richieste di reso')}
            />
          </Col>
          <Col span={24}>
            <MyTable
              loading={refoundLoading}
              bordered
              dataSource={refoundData.orders}
              pagination={refoundData.pagination}
              onChange={refoundOnChangeTableFilter}
              columns={columnsOrderTable}
              onRow={onRowOrderTable}
            />
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} gutter={[16, 16]}>
              <Col>
                <Typography.Paragraph strong>
                  {t('Allerta prodotti con stock limitato')}
                </Typography.Paragraph>
              </Col>
              <Col span={12}>
                <Typography.Text>{t('Range allerta stock')}</Typography.Text>
                <Slider
                  max={1000}
                  defaultValue={100}
                  onChange={handleChangeQuantityAvailable}
                />
              </Col>
              <Col span={24}>
                <MyTable
                  shop={{}}
                  loading={productVariantLoading}
                  bordered
                  dataSource={productVariantData.product_variants}
                  pagination={productVariantData.pagination}
                  columns={columnsProductTable}
                  onChange={productVariantOnChangeTableFilter}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Routes>
        <Route
          path={'view/:id'}
          element={
            <OrderViewModal
              shop={user?.currentShop}
              loading={loading}
              onClose={() => navigate('/')}
              order={singleItem}
              variants={singleItem?.order_product_variants}
              setCurrentOrderId={setCurrentOrderId}
              currentOrderId={currentOrderId}
            />
          }
        />
      </Routes>
    </Row>
  );
};

export default ManagerView;
