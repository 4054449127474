import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Col, Layout as AntLayout, Menu, Row } from 'antd';
import { EastLombardyWhiteIcon, ProfileNewIcon } from 'icons';
import styles from './style.module.scss';
import { useLocation, Link } from 'react-router-dom';
import { FaStripe } from 'react-icons/fa';
import { getPages, getUser } from 'hooks/useUser';
import { useRecoilValue } from 'recoil';
import LinkWithParams from '../../linkWithParams';
import { useTranslation } from 'react-i18next';
import CompaniesApi from 'libs/api/companies';
import UIHelper from 'helpers/UIHelper';
import ChangeShopModal from 'components/layout/header/changeShopModal';
import StripeCompany from 'components/StripeCompany';

const { Sider } = AntLayout;

const companyApi = new CompaniesApi();

const Sidebar = () => {
  const { t } = useTranslation();
  const pages = useRecoilValue(getPages);
  const user = useRecoilValue(getUser);
  const { pathname = '' } = useLocation();
  const [showChangeShopModal, setShowChangeShopModal] = useState(false);
  const getShopsLoading = false;

  const currentPage = useMemo(
    () =>
      pages.find((i) => {
        return (pathname + '/').includes(i.basePath + '/');
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, pages],
  );

  const needToShowModal = useMemo(() => {
    return user.isOwner && user.available_companies.length > 1;
  }, [user]);

  const onClickStripe = useCallback(async (company) => {
    try {
      let response;
      if (company?.stripe_details_submitted) {
        response = await companyApi.get(`${company?.id}/stripe_login_link`, {
          lombardy: true,
        });
      } else {
        response = await companyApi.get(`${company?.id}/stripe_onboarding`, {
          lombardy: true,
        });
      }

      if (response?.data?.url) {
        return (window.location.href = response?.data?.url);
      } else {
        UIHelper.openNotificationWithIcon('error', 'Something went wrong!');
      }
      setShowChangeShopModal(false);
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
    }
  }, []);

  const onClickStripeLink = useCallback(async () => {
    if (needToShowModal) {
      setShowChangeShopModal(true);
      return;
    }

    onClickStripe(user.available_companies[0]);
  }, [user, needToShowModal, onClickStripe]);

  const handleChooseCompany = (id) => {
    const item = user.available_companies?.find((i) => i.id === +id);
    onClickStripe(item);
  };

  return (
    <Fragment>
      <Sider className={styles.sideBar} width={'244px'}>
        <div className={styles.logo}>
          <Link to={user.isCashier ? '/products' : '/'} style={{ width: '65%', height: '65%' }}>
            <EastLombardyWhiteIcon fill={'#FFF'} />
          </Link>
        </div>
        <Row justify={'space-between'} className={styles.row}>
          <Col span={24}>
            <Menu
              className={styles.menu}
              mode='inline'
              selectedKeys={[currentPage?.link]}
              defaultSelectedKeys={['4']}
            >
              {pages?.map((i) => {
                const otherProps = {};
                if (i.openNewTab) {
                  otherProps.onClick = (e) => {
                    e.preventDefault();
                    window.open(i.link);
                  };
                }
                let LinkComponent = Link;

                if (i.withParams) {
                  LinkComponent = LinkWithParams;
                }

                if (i.hide) {
                  return null;
                }

                return (
                  <Menu.Item className={styles.link} key={i.link}>
                    <LinkComponent {...otherProps} to={i.link}>
                      {i.icon}
                      <span>{i.title}</span>
                    </LinkComponent>
                  </Menu.Item>
                );
              })}
              {user.isOwner && (
                <Menu.Item
                  className={styles.link}
                  key={'stripe'}
                  onClick={onClickStripeLink}
                >
                  <span
                    style={{ display: 'flex', alignItems: 'center', gap: 15 }}
                  >
                    <FaStripe size={25} /> Stripe
                  </span>
                </Menu.Item>
              )}
            </Menu>
          </Col>
          {!user.isCashier && (
            <Col span={24} className={styles.linkProfile}>
              <Row justify={''} align={'bottom'} className={styles.rowBottom}>
                <Col>
                  <Menu
                    className={styles.menu}
                    mode='inline'
                    selectedKeys={[currentPage?.link]}
                    defaultSelectedKeys={['4']}
                  >
                    <Menu.Item className={styles.link} key={'/profile'}>
                      <Link to={'/profile'}>
                        <ProfileNewIcon />
                        <span style={{ marginLeft: 20 }}>
                          {user.isAdmin ? t('Amministrazione') : t('Profilo')}
                        </span>
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Sider>
      {showChangeShopModal && (
        <StripeCompany
          loadingShops={getShopsLoading}
          hideShop={true}
          handleCancel={() => setShowChangeShopModal(false)}
          companies={user?.available_companies || []}
          show={true}
          currentCompanyId={user?.currentCompany?.id}
          handleChooseCompany={handleChooseCompany}
        />
      )}
    </Fragment>
  );
};

export default React.memo(Sidebar);
