import Api from 'libs/api';

class AuthApi extends Api {
  constructor() {
    super('');
  }

  me = () => {
    return this.buildQuery('get', {}, 'users/me');
  };

  updateMe = (id, data) => {
    return this.buildQuery('put', data, `users/${id}`);
  };

  signIn = (data) => {
    return this.buildQuery('post', { data }, 'admin/auth/signin');
  };

  sendResetPassword = (data) => {
    return this.buildQuery('post', { data }, 'auth/send_reset_password');
  };

  resetPassword = (data) => {
    return this.buildQuery('post', { data }, 'auth/reset_password');
  };

  updatePassword = (data, id) => {
    return this.buildQuery('put', { data }, `users/${id}/update_password`);
  };
}

export default AuthApi;
