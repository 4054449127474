import { useState, useMemo, useCallback, useEffect } from 'react';
import AdminOrdersApi from 'libs/api/adminOrders';
import { useApi } from 'hooks/useApi';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ProductVariantsApi from 'libs/api/productVariants';
import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useNavigate } from 'hooks/useNavigate';

const api = new AdminOrdersApi();
const productVariantsApi = new ProductVariantsApi();

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const additionalFilters = {
  status: 'ask_to_refund',
};

const useHomeManager = () => {
  const user = useRecoilValue(getUser);
  const navigate = useNavigate();
  const [
    productVariantsAdditionalFilters,
    setProductVariantsAdditionalFilters,
  ] = useState({
    quantity_less_or_equal: 10,
  });
  const [currentOrderId, setCurrentOrderId] = useState('');

  const [ordersAdditionalFilter, setOrdersAdditionalFilter] = useState({
    shop_id: user?.currentShop?.id,
    status: 'created',
  });

  const { loading, data, onChangeTableFilter, singleItem } = useApi({
    resourceKey: 'orders',
    singleResourceKey: 'order',
    api,
    isMainPage: true,
    additionalFilters: ordersAdditionalFilter,
    findByAdditionalId: currentOrderId,
  });
  const {
    loading: refoundLoading,
    data: refoundData,
    onChangeTableFilter: refoundOnChangeTableFilter,
  } = useApi({
    resourceKey: 'orders',
    singleResourceKey: 'order',
    api,
    isMainPage: true,
    additionalFilters,
  });
  const {
    loading: productVariantLoading,
    data: productVariantData,
    onChangeTableFilter: productVariantOnChangeTableFilter,
  } = useApi({
    resourceKey: 'product_variants',
    singleResourceKey: 'product_variant',
    api: productVariantsApi,
    isMainPage: true,
    additionalFilters: productVariantsAdditionalFilters,
  });
  const [orderTableData] = useState({
    data: dataSource,
  });
  const { t } = useTranslation();

  const columnsOrderTable = useMemo(
    () => [
      {
        title: t('Data e ora'),
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: () => {},
        render: (data) => moment(data).format('DD MMMM YYYY'),
      },
      {
        title: t('Nome cliente'),
        dataIndex: 'name',
        key: 'name',
        sorter: () => {},
        render: (data, row) => `${data} ${row.surname}`,
      },
      {
        title: t('Telefono cliente'),
        dataIndex: 'phone',
        key: 'phone',
        sorter: () => {},
      },
    ],
    [t],
  );

  const columnsProductTable = useMemo(
    () => [
      {
        title: t('Titolo prodotto'),
        dataIndex: 'title',
        key: 'title',
        sorter: () => {},
      },
      {
        title: t('Categoria'),
        dataIndex: 'category_name_it',
        key: 'name',
        width: 200,
        render: (data, row) => {
          return row?.categories_product_variants
            ?.map((i) => i.category.title)
            ?.join(',');
        },
        sorter: () => {},
      },
      {
        title: t('Prodotti restanti'),
        dataIndex: 'quantity_available',
        key: 'quantity_available',
        sorter: () => {},
      },
    ],
    [t],
  );

  const handleChangeQuantityAvailable = debounce((i) => {
    setProductVariantsAdditionalFilters((s) => ({
      ...s,
      quantity_less_or_equal: i,
    }));
  }, 400);

  const onRowOrderTable = useCallback((record) => {
    return {
      onClick: () => {
        setCurrentOrderId(record.id);
        navigate(`/view/${record.id}`);
      }, // click row
    };
  }, []);

  useEffect(() => {
    if (user?.currentShop?.id) {
      setOrdersAdditionalFilter((s) => {
        if (s.shop_id !== user?.currentShop?.id) {
          return {
            shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id]);

  return {
    columnsOrderTable,
    orderTableData,
    statistic: data.rest,
    data,
    onChangeTableFilter,
    refoundData,
    refoundOnChangeTableFilter,
    columnsProductTable,
    productVariantData,
    productVariantOnChangeTableFilter,
    handleChangeQuantityAvailable,
    onRowOrderTable,
    loading,
    refoundLoading,
    productVariantLoading,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    navigate,
    user,
  };
};

export default useHomeManager;
