import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const LinkWithParams = ({ to, children, ...rest }) => {
  const [searchParams] = useSearchParams();

  return (
    <Link to={to + `?${searchParams}`} {...rest}>
      {children}
    </Link>
  );
};

export default LinkWithParams;
