import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Space, Table, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import UploadModal from './uploadModal';
import useArticles from './useArticles';
import { ProductTypesEnum } from 'constants/orders';
import { Route, Routes } from 'react-router-dom';
import ViewModal from './viewModal';

const { TabPane } = Tabs;

const Articles = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    showUploadModal,
    data,
    onChangeTableFilter,
    loading,
    columns,
    rowSelection,
    selectedKeys,
    handleBulkDestroy,
    updateInProcess,
    handleUploadProducts,
    jobData,
    errorTexts,
    progress,
    uploadLoading,
    onCloseUploadMedal,
    handleChangeTab,
    productsExportLoading,
    handleExport,
    activeTab,
    handleSetStatusOnline,
    additionalParams,
    handleSetStatusOffline,
    singleItem,
  } = useArticles({ isMainPage: true });

  useLayoutEffect(() => {
    removeLastBreadcrumb('/products/articles');
    addNewBreadcrumb(t('Elenco prodotti'), '/products/articles');
    return () => {
      removeLastBreadcrumb('/products/articles');
    };
  }, []);

  const {
    all = 0,
    offline = 0,
    online = 0,
    moderation = 0,
  } = data?.rest?.totals || {};

  return (
    <Card className={styles.card} title={t('Elenco prodotti')}>
      <Row>
        <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
          <TabPane tab={`${t('Tutti i prodotti')} (${all})`} key='all' />
          <TabPane
            tab={`${t('Online')} (${online})`}
            key={ProductTypesEnum.online}
          />
          <TabPane
            tab={`${t('Offline')} (${offline})`}
            key={ProductTypesEnum.offline}
          />
          <TabPane
            tab={`${t('In moderazione')} (${moderation})`}
            key={ProductTypesEnum.moderation}
          />
        </Tabs>
        <Col span={24}>
          <Filters filters={filters} />
        </Col>
        <Col span={24}>
          <br />
          <Row justify={'space-between'}>
            {activeTab !== 'moderation' ? (
              <Col>
                <Space>
                  <Button
                    onClick={handleExport}
                    loading={productsExportLoading}
                  >
                    {t('Scarica excel')}
                  </Button>
                </Space>
              </Col>
            ) : (
              <Col>
                <Space>
                  <a
                    href={'/files/example.xlsx'}
                    download={'example.xlsx'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    <Button>{t('Scarica template excel da compilare')}</Button>
                  </a>
                  <Button
                    onClick={handleExport}
                    loading={productsExportLoading}
                  >
                    {t('Scarica lista dei prodotti')}
                  </Button>
                </Space>
              </Col>
            )}
            {selectedKeys.length > 0 && (
              <>
                {activeTab !== 'moderation' ? (
                  <Space className={styles.amountDiv}>
                    <Typography.Text strong={true}>
                      {selectedKeys.length} {t('articoli selezionati')}
                    </Typography.Text>
                    <Button
                      loading={updateInProcess}
                      onClick={handleBulkDestroy}
                    >
                      {t('Rimuovi')}
                    </Button>
                    {additionalParams.is_online !== false && (
                      <Button
                        loading={updateInProcess}
                        onClick={handleSetStatusOffline}
                      >
                        {t('Metti offline')}
                      </Button>
                    )}
                  </Space>
                ) : (
                  <Space className={styles.amountDiv}>
                    <Typography.Text strong={true}>
                      {selectedKeys.length} {t('articoli selezionati')}
                    </Typography.Text>
                    <Button
                      loading={updateInProcess}
                      onClick={handleBulkDestroy}
                    >
                      {t('Rimuovi')}
                    </Button>
                    {additionalParams.is_online !== false && (
                      <Button
                        loading={updateInProcess}
                        onClick={handleSetStatusOnline}
                      >
                        {t('Pubblica prodotti')}
                      </Button>
                    )}
                  </Space>
                )}
              </>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <br />
          <Table
            loading={loading}
            onChange={onChangeTableFilter}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            bordered
            dataSource={data.product_variants}
            pagination={data.pagination}
            columns={columns}
            scroll={{ x: 1000, y: 1000 }}
            rowKey={'id'}
          />
        </Col>
      </Row>
      {showUploadModal && (
        <UploadModal
          handleUploadProducts={handleUploadProducts}
          handleCancel={onCloseUploadMedal}
          jobData={jobData}
          errorTexts={errorTexts}
          progress={progress}
          uploadLoading={uploadLoading}
        />
      )}
      <Routes>
        <Route
          path={':id/view'}
          element={<ViewModal singleItem={singleItem} />}
        />
      </Routes>
    </Card>
  );
};

export default Articles;
