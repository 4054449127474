import { func, bool } from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import ActionModal from 'components/actionModal';
import { useTranslation } from 'react-i18next';
import UIHelper from 'helpers/UIHelper';
import { useNavigate } from 'hooks/useNavigate';

const DeleteModal = ({ onClickDelete, loading, title, yesBtnText = 'Si, cancella' }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  let { id } = useParams();
  const { pathname } = useLocation();
  const handleOk = () => {
    onClickDelete(id);
  };
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname));
  };



  return (
    <ActionModal
      loading={loading}
      title={title || t('Sei sicuro di voler cancellare?')}
      description={''}
      yesBtnText={t(yesBtnText)}
      noBtnText={t('No lascia')}
      onClickOk={handleOk}
      onCancel={handleCancel}
      value={id}
    />
  );
};

DeleteModal.propTypes = {
  onClickDelete: func,
  loading: bool,
};

export default DeleteModal;
