import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Form, Input, Row, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useShops from '../shopsList/useShops';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';

const AddShop = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, filterData, handleCreateOrUpdate, updateInProcess } = useShops({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    const breadcrumb = {
      title: isEdit ? t('Modifica Insegna') : t('Crea nuova Insegna'),
      path: isEdit ? `/shops/list/${id}/edit` : '/shops/add',
    };

    addNewBreadcrumb(breadcrumb.title, breadcrumb.path);
    return () => {
      removeLastBreadcrumb(breadcrumb.path);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  const onFinish = async (values) => {
    let newValues = { ...values };
    if (!newValues.password) {
      delete newValues.password;
      delete newValues.password_confirmation;
    }
    await handleCreateOrUpdate(newValues, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  const title = isEdit ? t('Modifica Insegna') : t('Crea Insegna');

  return (
    <Card className={styles.card} title={title}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              business_address: '',
              business_code: '',
              business_name: '',
              email_support: '',
              description_it: '',
              name: '',
              is_click_collect: '',
              is_click_reserve: '',
              is_online_shop: '',
              return_product_it: '',
              shop_policy_click_collect: '',
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Nome')} name='name'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={t('Indirizzo Business')}
                  name='business_address'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Codice Business')} name='business_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Nome Business')} name='business_name'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Titolo')} name='title'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Email')} name='email_support'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Numero telefono')} name='phone_support'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Sito web')} name='website'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={t('Descrizione attività')}
                  name='description_it'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('Resi prodotto')} name='return_product_it'>
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={t('Shop Policy')}
                  name='shop_policy_click_collect'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Societa')} name='company_id'>
                  <Select placeholder={t('Selezioni opzione')}>
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.companies.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Category')} name='category_id'>
                  <Select placeholder={t('Selezioni opzione')}>
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.categories.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('Click & Collect')}
                  name='is_click_collect'
                  valuePropName='checked'
                >
                  <Checkbox mainLabel={t('Click & Collect')} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('Click & Reserve')}
                  name='is_click_reserve'
                  valuePropName='checked'
                >
                  <Checkbox mainLabel={t('Click & Reserve')} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('Shop online')}
                  name='is_online_shop'
                  valuePropName='checked'
                >
                  <Checkbox mainLabel={t('Shop online')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button
            type={'secondary'}
            onClick={() => navigate('/profile/shops/list')}
          >
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Manda invito')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddShop;
