import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import { useNavigate } from 'hooks/useNavigate';
import { Route, Routes } from 'react-router-dom';
import AddPromotions from './addPromotions';
import PromotionsList from './promotionsList';

const AdminView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sectionData = useMemo(
    () => [
      {
        title: t('Crea promozione'),
        description: '',
        btnText: t('Crea promozione'),
        btnAction: () => navigate('add'),
      },
      {
        title: t('Le tue promozioni'),
        description: '',
        btnText: t('Le tue promozioni'),
        btnAction: () => {
          navigate('list');
        },
      },
    ],
    [t, navigate],
  );

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection title={t('Promozioni')} sectionData={sectionData} />
      </Col>
      <Col span={18}>
        <Routes>
          <Route path={'add/*'} element={<AddPromotions />} />
          <Route path={'list/:id/edit'} element={<AddPromotions isEdit />} />
          <Route path={'list/*'} element={<PromotionsList />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default AdminView;
