import styles from '../style.module.scss';
import { Col, Row } from 'antd';
import TimeSelect from '../../../../../components/timeSelect';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';

const TimeSection = ({
  t,
  day,
  openingHoursAttributes,
  handleAddMoreHours,
  handleDeleteHours,
  onChangeTime,
}) => {
  const find = openingHoursAttributes.find((item) => item.day_number === day);

  if (!find) return null;

  return (
    <Row align={'middle'} gutter={12} className={styles.timeContainer}>
      <Col span={24}>
        {find.hours.map((item, index) => {
          return (
            <Row
              key={index}
              align={'middle'}
              gutter={12}
              className={styles.times}
            >
              <Col>
                <TimeSelect
                  onChange={(value) => onChangeTime(value, index, day, 'from')}
                  value={item.from}
                  label={t('Inizio')}
                />
              </Col>
              <Col>
                <TimeSelect
                  onChange={(value) => onChangeTime(value, index, day, 'to')}
                  value={item.to}
                  label={t('Fine')}
                />
              </Col>
              <Col>
                <span className={styles.deleteIcon}>
                  <DeleteOutlined
                    onClick={() => handleDeleteHours(index, day)}
                  />
                </span>
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col span={24}>
        <span
          className={styles.addMore}
          onClick={() => handleAddMoreHours(day)}
        >
          {t('+ Aggiungi orari')}
        </span>
      </Col>
    </Row>
  );
};

export default TimeSection;
