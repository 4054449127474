import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';
import useCompanies from '../useCompanies';

const AddCompany = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, handleCreateOrUpdate, updateInProcess } = useCompanies({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    addNewBreadcrumb(t('Crea società'), '/profile/companies/add');
    return () => {
      removeLastBreadcrumb('/profile/companies/add');
    };
  }, []);

  const onFinish = async (values) => {
    await handleCreateOrUpdate(values, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  return (
    <Card className={styles.card} title={t('Crea società')}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              title: '',
              tax_code: '',
              address: '',
              ateco_code: '',
              invoice_code: '',
              stripe_id: '',
              stripe_details_submitted: false,
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Titolo')} name='title'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'tax_code'} name='tax_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'address'} name='address'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'ateco_code'} name='ateco_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'invoice_code'} name='invoice_code'>
                  <Input
                    autoComplete='new-password'
                    placeholder={t('Inserisci testo')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  autoComplete='off'
                  label={'stripe_id'}
                  name='stripe_id'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={'stripe_details_submitted'}
                  name='stripe_details_submitted'
                  valuePropName='checked'
                >
                  <Checkbox mainLabel={'stripe_details_submitted'} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button type={'secondary'} onClick={() => navigate('/team')}>
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddCompany;
