import { TimePicker } from 'antd';
import moment from 'moment';
import styles from './style.module.scss';
import Button from '../button';
import { DownOutlined } from '@ant-design/icons';

const format = 'HH:mm';

const TimeSelect = ({ label, value = '', onChange }) => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <TimePicker
        value={moment(value, 'HH:mm')}
        bordered={false}
        onChange={(momentValue, stringValue) => {
          onChange(stringValue);
        }}
        format={format}
        clearIcon={false}
        suffixIcon={<Button icon={<DownOutlined />} />}
      />
    </div>
  );
};

export default TimeSelect;
