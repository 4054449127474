import { Col, Row, Spin } from 'antd';
import StatisticCard from 'components/statisticCard';
import LineChart from 'components/charts/lineChart';
import BarChangeChart from 'components/charts/barChange';
import { useTranslation } from 'react-i18next';
import useStatisticaProductManager from './useStatisticaProductManager';
import Filters from '../../../../../components/filters';

const ProductStatisticsView = () => {
  const { t } = useTranslation();
  const { data, loading, filters } = useStatisticaProductManager();
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Filters filters={filters} />
          <br />
        </Col>
        <Col xs={12} xl={6}>
          <StatisticCard
            big
            count={data.product_variants_sold}
            text={t('Articoli venduti')}
          />
        </Col>
        <Col xs={12} xl={6}>
          <StatisticCard
            big
            count={data.product_variants_refunded}
            text={t('Articoli restituiti')}
          />
        </Col>
        <Col xs={24} xl={12}>
          <LineChart
            colors={['#faac18']}
            title={t('Evoluzione temporale articoli venduti')}
            tableData={[]}
            data={data.lineChartData}
          />
        </Col>
        <Col span={24}>
          <BarChangeChart
            keys={['Articoli venduti']}
            title={t('Distribuzione articoli venduti per categoria')}
            data={data.barChartData}
            itemWidth={130}
            translateXBar={-20}
            hideLine
            indexBy={'category'}
            hideLegend
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ProductStatisticsView;
