import { useState, useEffect, useMemo, useCallback } from 'react';
import { useApi } from 'hooks/useApi';
import ProductVariantsApi from 'libs/api/productVariants';
import { useTranslation } from 'react-i18next';
import { Images } from 'components/products/actions';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import Helpers from 'helpers';
import { useFilter } from 'hooks/useFilter';
import TableColumnItem from 'components/TableColumnItem';
import { InputVariants } from 'constants/products';
import { Link, useSearchParams } from 'react-router-dom';
import useDataExport from '../../../data-export/adminView/useDataExport';
import { Col, Row } from 'antd';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import ShopsApi from 'libs/api/shops';
import UIHelper from 'helpers/UIHelper';

const api = new ProductVariantsApi();
const shopApi = new ShopsApi();

const rejectedStatuses = ['bad_image', 'bad_info', 'bad_image_info'];
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useArticles = ({ isMainPage = true }) => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const { filterData } = useFilter();
  let [searchParams, setSearchParams] = useSearchParams();
  const [additionalParams, setAdditionalParams] = useState({
    shop_id: user.currentShop.id,
    status: 'moderation',
  });
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [updateRowId, setUpdateRowId] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorTexts, setErrorTexts] = useState([]);
  const [jobData, setJobData] = useState({});
  const [uploadId, setUploadId] = useState(null);
  const [uploadInProcess, setUploadInProcess] = useState(false);
  const {
    downloadFile,
    downloadTypes,
    loading: productsExportLoading,
  } = useDataExport();
  const {
    loading,
    setData,
    data,
    onChangeTableFilter,
    updateInProcess,
    setUpdateInProcess,
    fetchList,
    singleItem,
  } = useApi({
    resourceKey: 'product_variants',
    singleResourceKey: 'product_variant',
    api,
    isMainPage: isMainPage,
    additionalFilters: additionalParams,
  });

  const handleLoadSingleImage = useCallback((productId, imgId, setFalse) => {
    setData((s) => ({
      ...s,
      product_variants: s.product_variants?.map((i) => {
        if (i.id === productId) {
          return {
            ...i,
            product_variant_images: i.product_variant_images.map((j) => {
              if (j.id === imgId) {
                return {
                  ...j,
                  loading: !setFalse,
                };
              }
              return j;
            }),
          };
        }
        return i;
      }),
    }));
  }, []);

  const handleSetUpdatedProductVariant = useCallback(
    (productId, product_variant) => {
      setData((s) => ({
        ...s,
        product_variants: s.product_variants?.map((i) => {
          if (i.id === productId) {
            return {
              ...product_variant,
            };
          }
          return i;
        }),
      }));
    },
    [],
  );

  const handleSetDefaultImage = useCallback(
    async (productId, imageId) => {
      const defaultImg = currentProduct.product_variant_images.find(
        (i) => i.by_default,
      );
      const formData = new FormData();
      formData.append(
        'product_variant[product_variant_images_attributes][0][id]',
        imageId,
      );
      formData.append(
        'product_variant[product_variant_images_attributes][0][by_default]',
        true,
      );
      if (defaultImg) {
        formData.append(
          'product_variant[product_variant_images_attributes][1][id]',
          defaultImg.id,
        );
        formData.append(
          'product_variant[product_variant_images_attributes][1][by_default]',
          false,
        );
      }
      try {
        const {
          data: { product_variant },
        } = await api.updateImage(productId, formData);
        handleSetUpdatedProductVariant(productId, product_variant);
        setCurrentProduct(product_variant);
      } catch (e) {
        console.log(e);
      }
    },
    [currentProduct],
  );

  const handleDeleteImage = async (productId, imageId) => {
    handleLoadSingleImage(productId, imageId);
    const formData = new FormData();
    formData.append(
      'product_variant[product_variant_images_attributes][0][id]',
      imageId,
    );
    formData.append(
      'product_variant[product_variant_images_attributes][0][_destroy]',
      true,
    );
    try {
      const {
        data: { product_variant },
      } = await api.updateImage(productId, formData);
      handleSetUpdatedProductVariant(productId, product_variant);
    } catch (e) {
      console.log(e);
    } finally {
      handleLoadSingleImage(productId, imageId, true);
    }
  };
  function handleSetCurrentProduct(currentProduct) {
    setCurrentProduct(currentProduct);
  }
  const handleChangePosition = async (
    productId,
    imgId,
    startIndex,
    endIndex,
  ) => {
    try {
      const formData = new FormData();
      formData.append(
        'product_variant[product_variant_images_attributes][0][id]',
        imgId,
      );
      formData.append(
        'product_variant[product_variant_images_attributes][0][position]',
        endIndex + 1,
      );
      const {
        data: { product_variant },
      } = await api.updateImage(productId, formData);
      handleSetUpdatedProductVariant(productId, product_variant);
      setCurrentProduct(product_variant);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRotateImage = useCallback(async (productId, imgId, file) => {
    try {
      handleLoadSingleImage(productId, imgId);
      const formData = new FormData();
      formData.append(
        'product_variant[product_variant_images_attributes][0][id]',
        imgId,
      );
      formData.append(
        'product_variant[product_variant_images_attributes][0][image]',
        file,
      );
      const {
        data: { product_variant },
      } = await api.updateImage(productId, formData);
      handleSetUpdatedProductVariant(productId, product_variant);
      setCurrentProduct(product_variant);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleUploadImages = useCallback(async (productId, file, position) => {
    let uploadFiles = [...file];
    if (uploadFiles.length >= 6 - (position - 1)) {
      uploadFiles = uploadFiles.slice(0, 6 - (position - 1));
    }
    setData((s) => ({
      ...s,
      product_variants: s.product_variants?.map((i) => {
        if (i.id === productId) {
          return {
            ...i,
            product_variant_images: [
              ...i.product_variant_images,
              ...uploadFiles.map((j) => ({
                image: URL.createObjectURL(j.originFileObj),
                loading: true,
                uid: j.uid,
              })),
            ],
          };
        }
        return i;
      }),
    }));
    const formData = new FormData();
    uploadFiles.forEach((i, index) => {
      formData.append(
        `product_variant[product_variant_images_attributes][${index}][position]`,
        position + index,
      );
      formData.append(
        `product_variant[product_variant_images_attributes][${index}][by_default]`,
        false,
      );
      formData.append(
        `product_variant[product_variant_images_attributes][${index}][image]`,
        i.originFileObj,
      );
    });
    const {
      data: { product_variant },
    } = await api.updateImage(productId, formData);
    handleSetUpdatedProductVariant(productId, product_variant);
  }, []);

  const handleUpdateRowData = useCallback(
    async ({ newValue, newValueKey, row }) => {
      setUpdateRowId(row.id);
      try {
        const {
          data: { product_variant },
        } = await api.put(row.id, {
          data: {
            product_variant: {
              [newValueKey]: newValue,
            },
          },
        });
        handleSetUpdatedProductVariant(row.id, product_variant);
      } catch (e) {
        console.log(e);
        UIHelper.showErrorFromAxios(e);
      } finally {
        setUpdateRowId('');
      }
    },
    [],
  );

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: '',
        width: 100,
        render: (data, row) => (
          <Row
            gutter={16}
            justify={'left'}
            onClick={(e) => e.stopPropagation()}
          >
            <Col>
              <Link to={`${row.id}/view`}>
                <Button type={'primary-white'} icon={<EyeOutlined />} />
              </Link>
            </Col>
          </Row>
        ),
      },
      {
        title: t('Codice articolo (SKU)'),
        dataIndex: 'product_number',
        width: 200,
      },
      {
        title: t('Colore'),
        dataIndex: 'main_color',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isSelect
            list={filterData?.product_variant_colors?.map((i) => i.title) || []}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'main_color',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Taglia'),
        dataIndex: 'size',
        width: 200,
        render: (data, row) => (
          row.status === 'moderation' ? <TableColumnItem
            isCategory
            isDate={false}
            isSelect
            list={
              filterData?.product_variant_sizes?.map((i) => ({
                id: i.value,
                title: i.title,
              })) || []
            }
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'size',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          /> : data
        ),
      },
      {
        title: t('Nome prodotto'),
        dataIndex: 'title',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'title',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Prodotto in'),
        dataIndex: 'made_in',
        width: 200,
      },
      {
        title: t('Descrizione'),
        dataIndex: 'description',
        width: 500,
        maxWidth: 500,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'description',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
            longText
          />
        ),
      },
      {
        title: t('Immagine'),
        dataIndex: 'product_variant_images',
        width: 130,
        // sorter: (a, b) => a.image - b.image,
        // sortDirections: ['ascend', 'descend'],
        render: (text, data) => {
          return (
            <Images
              isEditor={false}
              disableModalOpen={rejectedStatuses.includes(data.status)}
              isSuperAdmin={user.isAdmin}
              handleUploadImages={handleUploadImages}
              handleSetDefaultImage={handleSetDefaultImage}
              handleDeleteImage={handleDeleteImage}
              rowData={data}
              images={text}
              setSetCurrentProduct={() => handleSetCurrentProduct(data)}
              t={t}
              handleChangePosition={handleChangePosition}
              handleRotateImage={handleRotateImage}
            />
          );
        },
      },
      {
        title: t('Categoria merceologica'),
        dataIndex: 'categories_product_variants',
        width: 200,
        // render: (data) => {
        //   return data?.map((i) => i.category.title).join(',');
        // },
        render: (data, row) => (
          <TableColumnItem
            isCategory
            isDate={false}
            isMultiSelect
            isSelect
            list={
              filterData?.categories?.map((i) => ({
                id: i.value,
                title: i.title,
              })) || []
            }
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'categories_product_variants',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data?.map((i) => i.category.title).join(',')}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Brand'),
        dataIndex: 'brand_name',
        width: 200,
        render: (data, row) => (
          row.status === 'moderation' ? <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'brand_name',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          /> : data
        ),
      },
      {
        title: t('Stock'),
        dataIndex: 'quantity',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'quantity',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Ordinato'),
        dataIndex: 'ordered',
        width: 200,
      },
      {
        title: t('Venduto'),
        dataIndex: 'sold',
        width: 200,
      },
      {
        title: t('Disponibile'),
        dataIndex: 'quantity_available',
        width: 200,
      },
      {
        title: t('Prezzo'),
        dataIndex: 'retail_price',
        render: (data) => Helpers.numberWithCommas(data, ' €'),
        width: 200,
      },
      {
        title: t('Sconto %'),
        dataIndex: 'discount_percentage',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            prefix={'%'}
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'discount_percentage',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Prezzo scontato'),
        dataIndex: 'discounted_price',
        render: (data) => Helpers.numberWithCommas(data, ' €'),
        width: 200,
      },
      {
        title: t('Wishlist'),
        dataIndex: 'wishing_list_count',
        width: 200,
      },
      {
        title: t('Dettagli'),
        dataIndex: 'details',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'details',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Peso (grammi)'),
        dataIndex: 'product_weight_gram',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'product_weight_gram',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Guida alle taglie'),
        dataIndex: 'size_instructions',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'size_instructions',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Informazioni aggiuntive'),
        dataIndex: 'additional_information',
        width: 200,
        render: (data, row) => (
          <TableColumnItem
            isDate={false}
            isEditable={user.isManager}
            onSubmit={async (value) => {
              await handleUpdateRowData({
                newValue: value,
                newValueKey: 'additional_information',
                row,
              });
            }}
            variant={InputVariants.input}
            text={data}
            loading={updateRowId === row.id}
          />
        ),
      },
      {
        title: t('Stato prodotto'),
        dataIndex: 'status',
        width: 200,
      },
    ],
    [t, currentProduct, handleUpdateRowData, updateRowId, filterData],
  );

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
    },
  };

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca prodotto'),
        type: 'input',
        name: 'query',
      },
      {
        title: t('Filtro categoria'),
        type: 'select',
        name: 'category_ids',
        isSearch: true,
        values:
          filterData?.categories?.map((i) => ({
            title: i.title,
            value: i.value,
          })) || [],
      },
      {
        title: t('Filtro data caricamento'),
        type: 'range',
        name: 'by_day_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t, filterData]);

  const handleBulkDestroy = useCallback(async () => {
    setUpdateInProcess(true);
    try {
      await api.bulkDestroy(selectedKeys);
      await fetchList();
      setSelectedKeys([]);
    } catch (e) {
      console.log(e);
    } finally {
      setUpdateInProcess(false);
    }
  }, [selectedKeys]);

  const handleUploadProducts = async (file) => {
    setUploadLoading(true);
    try {
      if (user?.currentShop?.merchant === 'none') {
        await shopApi.put(user.currentShop.id, {
          data: {
            merchant: 'manual',
          },
        });
      }
      const formData = new FormData();
      formData.append('file', file);
      const {
        data: { id },
      } = await api.uploadProducts(user?.currentShop?.id, formData);
      setUploadId(id);
      setUploadInProcess(true);
    } catch (e) {
      console.log(e);
      setUploadLoading(false);
    }
  };

  useEffect(() => {
    let interval;

    const fetchStatus = async () => {
      try {
        const {
          data: { product_import },
        } = await api.getUploadStatus(user?.currentShop?.id, uploadId);
        setProgress(product_import.job.progress_percent || 0);
        setJobData(product_import.job);
        if (!product_import.in_progress) {
          setUploadLoading(false);
          setProgress(0);
          clearInterval(interval);
          await new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 300);
          });
          fetchList();
          if (product_import.errors_text?.length) {
          } else {
            setShowUploadModal(false);
          }
        }

        // if (product_import.job.status === 'complete') {
        //   setUploadLoading(false);
        //   setProgress(0);
        //   clearInterval(interval);
        //   await new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //     }, 300);
        //   });
        //   fetchList();
        //   if (product_import.errors_text?.length) {
        //   } else {
        //     setShowUploadModal(false);
        //   }
        // }
        if (product_import?.errors_text) {
          setErrorTexts(product_import?.errors_text || []);
        }
      } catch (e) {
        console.log('eeee', e);
      }
    };

    if (uploadId) {
      interval = setInterval(async () => {
        await fetchStatus();
      }, 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [uploadInProcess, uploadId]);

  const onCloseUploadMedal = () => {
    setShowUploadModal(false);
    setErrorTexts([]);
  };

  useEffect(() => {
    if (searchParams.get('openUpload')) {
      setShowUploadModal(true);
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    if (user.isCashier) {
      setAdditionalParams({
        shop_id: user.currentShop.id,
      });
    }
  }, [user]);

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalParams },
      downloadTypes.product,
    );
  }, [searchParams, additionalParams]);

  const handleSetStatusOnline = useCallback(async () => {
    setUpdateInProcess(true);
    try {
      await api.updateStatus({
        status: 'online',
        ids: selectedKeys,
      });
      await delay(3000);
      await fetchList();
      setSelectedKeys([]);
    } catch (e) {
      console.log(e);
      const errorMessage = e.response.data.base[0][Object.keys(e.response.data.base[0])[0]].product_weight_gram[0];
      console.log(errorMessage, 'errorMessage');
      UIHelper.errorMessages([`${errorMessage}`]);
    } finally {
      setUpdateInProcess(false);
    }
  }, [selectedKeys, fetchList, setUpdateInProcess]);

  return {
    productsExportLoading,
    handleExport,
    showUploadModal,
    setShowUploadModal,
    data,
    onChangeTableFilter,
    loading,
    columns,
    rowSelection,
    filters,
    selectedKeys,
    handleBulkDestroy,
    updateInProcess,
    handleUploadProducts,
    jobData,
    errorTexts,
    progress,
    uploadLoading,
    onCloseUploadMedal,
    additionalParams,
    handleSetStatusOnline,
    singleItem,
  };
};

export default useArticles;
