import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import ShopsApi from 'libs/api/shops';
import { Form } from 'antd';
import moment from 'moment';
import { useFilter } from '../../../../hooks/useFilter';
import UIHelper from 'helpers/UIHelper';
import ErrorHelper from 'helpers/ErrorHelper';

const api = new ShopsApi();

const weekDays = [
  { id: 0, title: 'Lunedì' },
  { id: 1, title: 'Martedì' },
  { id: 2, title: 'Mercoledì' },
  { id: 3, title: 'Giovedì' },
  { id: 4, title: 'Venerdì' },
  { id: 5, title: 'Sabato' },
  { id: 6, title: 'Domenica' },
];

const notificationTypes = [
  {
    id: 'whatsapp',
    name: 'notification_whatsapps',
    title: 'Messaggio whatsapp',
    label: 'Numero di telefono',
    addLabel: '+ Aggiungi numero di telefono',
    rules: [
      {
        type: 'integer',
        transform: (v) => +v,
        message: 'numero non valido',
      },
    ],
  },
  {
    id: 'email',
    name: 'notification_emails',
    title: 'Email',
    label: 'Indirizzo email',
    addLabel: '+ Aggiungi indirizzo email',
    rules: [
      {
        type: 'email',
        message: 'Dovrebbe essere l`e-mail',
      },
    ],
  },
];

const useSettings = () => {
  const [form] = Form.useForm();
  const user = useRecoilValue(getUser);
  const { filterData } = useFilter();
  const [openingHoursAttributes, setOpeningHoursAttributes] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState({
    courier_service_id: user?.currentShop?.courier_service_id || '',
    return_product_it: '',
  });
  const [deletedIds, setDeletedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeDay = (values) => {
    const openingHours = [];
    values.forEach((value) => {
      const find = openingHoursAttributes.find((i) => i.day_number === value);
      if (!find) {
        openingHours.push({
          day_number: value,
          hours: [
            {
              from: moment(new Date(), 'HH:mm').format('HH:mm'),
              to: moment(new Date(), 'HH:mm').format('HH:mm'),
            },
          ],
        });
      } else {
        openingHours.push(find);
      }
    });
    if (values.length === 0) {
      const deletedIds = [];
      openingHoursAttributes.forEach((i) => {
        i.hours.forEach((hour) => {
          if (hour.id) {
            deletedIds.push(hour.id);
          }
        });
      });
      setDeletedIds(deletedIds);
    }
    setOpeningHoursAttributes(openingHours);
  };

  const handleAddMoreHours = (dayNumber) => {
    const openingHours = openingHoursAttributes.map((i) => {
      if (i.day_number === dayNumber) {
        return {
          ...i,
          hours: [
            ...i.hours,
            {
              from: moment().format('HH:mm'),
              to: moment().format('HH:mm'),
            },
          ],
        };
      }
      return i;
    });
    setOpeningHoursAttributes(openingHours);
  };

  const handleDeleteHours = (index, dayNumber) => {
    const openingHours = openingHoursAttributes.map((i) => {
      if (i.day_number === dayNumber) {
        const hour = i.hours.find((item, idx) => idx === index);
        if (hour.id) {
          setDeletedIds((s) => [...s, hour.id]);
        }
        return {
          ...i,
          hours: i.hours.filter((item, idx) => idx !== index),
        };
      }
      return i;
    });
    setOpeningHoursAttributes(openingHours);
  };

  const handleChangeTime = (value, index, dayNumber, type) => {
    const openingHours = openingHoursAttributes.map((i) => {
      if (i.day_number === dayNumber) {
        return {
          ...i,
          hours: i.hours.map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [type]: value,
              };
            }
            return item;
          }),
        };
      }
      return i;
    });
    setOpeningHoursAttributes(openingHours);
  };

  const onChangeNotificationValue = (value, index, type) => {
    const newNotifications = notifications.map((i) => {
      if (i.type === type) {
        return {
          ...i,
          values: i.values.map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                value,
              };
            }
            return item;
          }),
        };
      }
      return i;
    });
    setNotifications(newNotifications);
  };

  const handleChangeNotificationTypes = (values) => {
    const newNotifications = [];
    values.forEach((value) => {
      const find = notifications.find((i) => i.type === value);
      if (!find) {
        newNotifications.push({
          type: value,
          values: [
            {
              value: '',
            },
          ],
        });
      } else {
        newNotifications.push(find);
      }
    });
    setNotifications(newNotifications);
  };

  const handleAddMoreNotificationValues = (type) => {
    const newNotifications = notifications.map((i) => {
      if (i.type === type) {
        return {
          ...i,
          values: [
            ...i.values,
            {
              value: '',
            },
          ],
        };
      }
      return i;
    });
    setNotifications(newNotifications);
  };

  const handleDeleteNotificationValues = (index, type) => {
    const newNotifications = notifications.map((i) => {
      if (i.type === type) {
        return {
          ...i,
          values: i.values.filter((item, idx) => idx !== index),
        };
      }
      return i;
    });
    setNotifications(newNotifications);
  };

  useEffect(() => {
    (async () => {
      const {
        data: { shop },
      } = await api.get(user?.currentShop?.id);
      const openingHours = [];
      const notifications = [];
      if (shop.notification_whatsapps.length > 0) {
        notifications.push({
          type: 'whatsapp',
          values: shop.notification_whatsapps.map((i) => ({
            value: i,
          })),
        });
      }

      if (shop.notification_emails.length > 0) {
        notifications.push({
          type: 'email',
          values: shop.notification_emails.map((i) => ({
            value: i,
          })),
        });
      }

      setNotifications(notifications);

      shop.opening_hours.forEach((i) => {
        const find = openingHours.find(
          (item) => item.day_number === i.day_number,
        );
        if (!find) {
          openingHours.push({
            day_number: i.day_number,
            hours: [
              {
                id: i.id,
                from: i.from,
                to: i.to,
              },
            ],
          });
        } else {
          find.hours.push({
            id: i.id,
            from: i.from,
            to: i.to,
          });
        }
      });
      setOpeningHoursAttributes(openingHours);
      const notificationsObj = {
        name: 'notifications_allow',
        value: [],
      };
      if (shop.notification_emails?.length) {
        notificationsObj.value.push('notification_emails');
      }
      if (shop.notification_whatsapps?.length) {
        notificationsObj.value.push('notification_whatsapps');
      }
      form.setFields([
        {
          name: 'is_click_collect',
          value: shop.is_click_collect,
        },
        {
          name: 'is_click_reserve',
          value: shop.is_click_reserve,
        },
        {
          name: 'is_online_shop',
          value: shop.is_online_shop,
        },
        {
          name: 'notification_emails',
          value: shop.notification_emails,
        },
        {
          name: 'notification_whatsapps',
          value: shop.notification_whatsapps,
        },
        {
          name: 'delivery_box_price',
          value: shop.delivery_box_price
            ? `${shop.delivery_box_price}`.replace('.', ',')
            : shop.delivery_box_price,
        },
        notificationsObj,
      ]);

      setData({
        courier_service_id: shop.courier_service?.id || '',
        return_product_it: shop.return_product_it,
        // delivery_box_price: shop.delivery_box_price,
      });
    })();
  }, [user?.currentShop?.id, form]);

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const opening_hours_attributes = [];
      openingHoursAttributes.forEach((i) => {
        i.hours.forEach((item) => {
          const hour = {
            day_number: i.day_number,
            from: item.from,
            to: item.to,
          };
          if (item.id) {
            hour.id = item.id;
          }
          opening_hours_attributes.push(hour);
        });
      });
      deletedIds.map((i) => {
        opening_hours_attributes.push({
          id: i,
          _destroy: true,
        });
      });
      // const notification_whatsapps = [];
      // const notification_emails = [];
      // const findWhatsapp = notifications.find((i) => i.type === 'whatsapp');
      // if (findWhatsapp) {
      //   findWhatsapp.values.forEach((i) => {
      //     notification_whatsapps.push(i.value);
      //   });
      // }

      // const findEmail = notifications.find((i) => i.type === 'email');
      // if (findEmail) {
      //   findEmail.values.forEach((i) => {
      //     notification_emails.push(i.value);
      //   });
      // }

      //notifications_allow

      const notification_emails = values.notifications_allow?.includes(
        'notification_emails',
      )
        ? values.notification_emails
        : [];
      const notification_whatsapps = values.notifications_allow?.includes(
        'notification_whatsapps',
      )
        ? values.notification_whatsapps
        : [];

      const response = await api.put(user?.currentShop?.id, {
        data: {
          shop: {
            is_click_collect: values.is_click_collect,
            is_click_reserve: values.is_click_reserve,
            is_online_shop: values.is_online_shop,
            opening_hours_attributes,
            notification_emails,
            notification_whatsapps,
            delivery_box_price: values.delivery_box_price,
            ...data,
          },
        },
      });
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      const errors = { ...(e?.response?.data || {}) };
      console.log(
        'ErrorHelper.getErrorsForForm(errors)',
        ErrorHelper.getErrorsForForm(errors),
      );
      form.setFields(ErrorHelper.getErrorsForForm(errors));
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    handleChangeDay,
    openingHoursAttributes,
    handleAddMoreHours,
    handleDeleteHours,
    handleChangeTime,
    weekDays,
    notificationTypes,
    notifications,
    handleChangeNotificationTypes,
    onChangeNotificationValue,
    handleAddMoreNotificationValues,
    handleDeleteNotificationValues,
    filterData,
    data,
    setData,
    handleUpdate,
    loading,
  };
};

export default useSettings;
