import React from 'react';
import { Descriptions, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import UIHelper from 'helpers/UIHelper';
import useCompanies from '../../useCompanies';
import { useNavigate } from 'hooks/useNavigate';

const ViewModal = () => {
  const { t } = useTranslation();
  const { singleItem } = useCompanies({ isMainPage: false });
  const { pathname } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname));
  };

  return (
    <Modal
      title={t('Dettagli società')}
      visible={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Descriptions column={1} bordered>
        {singleItem ? (
          <>
            <Descriptions.Item label={t('Ragione sociale')}>
              {singleItem.title}
            </Descriptions.Item>
            <Descriptions.Item label={t('Codice Ateco')}>
              {singleItem.ateco_code}
            </Descriptions.Item>
            <Descriptions.Item label={t('Partita IVA')}>
              {singleItem.tax_code}
            </Descriptions.Item>
            <Descriptions.Item label={t('Codice SDI')}>
              {singleItem.invoice_code}
            </Descriptions.Item>
            <Descriptions.Item label={t('Sede legale')}>
              {singleItem.address}
            </Descriptions.Item>
            <Descriptions.Item label={t('Stripe ID')}>
              {singleItem.stripe_id}
            </Descriptions.Item>

            <Descriptions.Item label={t('Insegne')}>
              {singleItem?.shops?.map((i) => i.title).join(',')}
            </Descriptions.Item>
          </>
        ) : (
          <Spin size={'large'} />
        )}
      </Descriptions>
    </Modal>
  );
};

export default ViewModal;
