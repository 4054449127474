import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Space, Typography } from 'antd';

const StripeCompany = ({
  currentCompanyId,
  handleCancel,
  show,
  companies = [],
  handleChooseCompany,
}) => {
  const [currentCompany, setCurrentCompany] = useState(currentCompanyId);
  const { t } = useTranslation();

  const onClose = () => {
    if (currentCompany) {
      handleCancel();
    }
  };

  const handleOk = useCallback(() => {
    handleChooseCompany(currentCompany);
  }, [handleChooseCompany, currentCompany]);

  return (
    <Modal
      title={t('Seleziona la tua società')}
      visible={show}
      onOk={handleOk}
      onCancel={onClose}
      okText={t('Conferma')}
      okButtonProps={{
        disabled: !currentCompany,
      }}
      cancelButtonProps={{
        disabled: !currentCompany,
      }}
    >
      <Space direction={'vertical'}>
        <Typography.Text strong={true}>
          {t('Seleziona la tua società')}
        </Typography.Text>
        <Select
          value={currentCompany}
          style={{ width: 200 }}
          onChange={(key) => {
            setCurrentCompany(key);
          }}
        >
          <Select.Option value=''>{t('Selezioni opzione')}</Select.Option>
          {companies.map((i) => {
            return (
              <Select.Option value={i.id} key={i.id}>
                {i.title}
              </Select.Option>
            );
          })}
        </Select>
      </Space>
    </Modal>
  );
};

export default StripeCompany;
