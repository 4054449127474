import React, { useLayoutEffect } from 'react';
import styles from '../style.module.scss';
import { Col, Divider, Row, Select, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import useSteps from 'hooks/useSteps';
import { ArrowRightOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';
import useECommerce from '../useECommerce';

const { Option } = Select;

const ThirdStep = () => {
  const { t } = useTranslation();
  const { setStep } = useSteps();
  const {
    eCommerceData,
    handleChangeMerchantAttributes,
    handleSaveThirdStep,
    loading,
  } = useECommerce();

  useLayoutEffect(() => {
    setStep((s) => ({
      ...s,
      // current: 3,
      current: eCommerceData.currentECommerceType === 'shopify' ? 4 : 3,
    }));
  }, [setStep, eCommerceData]);

  const selectList = [
    { title: 'Aggiungi a titolo', value: 'add_to_title' },
    { title: 'Aggiungi a descrizione', value: 'add_to_description' },
    { title: 'Ignora', value: 'ignore' },
    { title: 'Variante colore', value: 'color' },
    { title: 'Variante taglia', value: 'size' },
    { title: 'Variante sesso', value: 'gender' },
    { title: 'Variante misura (SOLO EAST)', value: 'measurement_option_east' },
  ];

  return (
    <Row className={styles.row} justify={'space-between'}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text strong>{t('Match custom fields')}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text>
              {t(
                'Ognuna delle categorie presenti sull’e-commerce sorgente dovrà essere associata a una categoria del marketplace Bergamo Shopping. Seleziona la categoria tra quelle disponibili.',
              )}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <br />
            <Row>
              <Col span={14}>
                <Row>
                  <Col span={10}>
                    <Typography.Text strong>
                      {t('E-commerce sorgente')}
                    </Typography.Text>
                  </Col>
                  <Col span={4} />
                  <Col span={10}>
                    <Typography.Text strong>
                      {t('Bergamo Shopping')}
                    </Typography.Text>
                  </Col>
                </Row>
                <Divider className={'custom-divider'} />
                {eCommerceData.merchantAttributes.map((i) => (
                  <React.Fragment key={uuid()}>
                    <Row>
                      <Col span={10}>
                        <Typography.Text>{i.name}</Typography.Text>
                      </Col>
                      <Col span={4}>
                        <ArrowRightOutlined />
                      </Col>
                      <Col span={10}>
                        <Select
                          className={'standard-select'}
                          placeholder={t('Informazioni aggiuntive')}
                          value={i.feature}
                          onSelect={handleChangeMerchantAttributes(i.id)}
                        >
                          <Option value={''}>{t('Selezioni opzione')}</Option>
                          {selectList.map((j) => (
                            <Option key={uuid()} value={j.value}>
                              {j.title}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <Divider className={'custom-divider'} />
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col flex={1}>
        <Row
          className={styles.btnsRow}
          align={'bottom'}
          justify={'end'}
          gutter={8}
        >
          <Col>
            <Link to={'/products'}>
              <Button type={'grey'}>{t('Annulla')}</Button>
            </Link>
          </Col>
          <Col>
            <Button onClick={handleSaveThirdStep} loading={loading}>
              {t('Prosegui')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ThirdStep;
