import { useEffect, useMemo } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import styles from '../style.module.scss';
import useTeam from '../useTeam';
import AdminView from '../adminView';
import ManagerView from '../managerView';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const user = useRecoilValue(getUser);
  const { columns, productData, rowSelection } = useTeam();
  const { setBreadcrumb } = useBreadcrumb();
  const { t } = useTranslation();

  const breadcrumb = useMemo(() => {
    if (user.isAdmin) {
      return [
        {
          title: t('Super Admin / Amministrazione'),
          link: '#',
        },
      ];
    }

    return [
      {
        title: 'Profilo',
        link: '#',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
  }, []);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        {user.isManager && (
          <ManagerView
            columns={columns}
            productData={productData}
            rowSelection={rowSelection}
          />
        )}
        {(user.isAdmin || user.isOwner) && (
          <AdminView
            columns={columns}
            productData={productData}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </Layout>
  );
};

export default ProfilePage;
