import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsApi from 'libs/api/analytics';
import { OrderStatuses, OrderTypesFullNames, OrderTypesNames } from 'constants/orders';
import moment from 'moment';
import { getOrderStatusText } from 'helpers/utils';
import Helpers from 'helpers';
import { useFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';

const api = new AnalyticsApi();

const useStatisticaManager = () => {
  let { searchParams, setSearchParams } = useFilter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(getUser);
  const [barChartByOrderType, setBarChartByOrderType] = useState([]);
  const [barChartByOrderFullTypes, setBarChartByOrderFullTypes] = useState([]);
  const [data, setData] = useState({
    amount_by_day: {},
    click_and_collect_by_day: {},
    click_and_reserve_by_day: {},
    click_and_ship_by_day: {},
    orders_by_day: {},
    orders_count: 0,
    refund_orders_count: 0,
    total_amount: 0,
    lineChartOrders: [],
    lineChartAmountByDay: [],
  });

  const fetchData = async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!currentParams.by_year) {
      return;
    }
    setLoading(true);
    let format = 'DD MMMM YYYY';
    let params;
    if (!currentParams.by_month) {
      params = {
        date_from: moment(currentParams.by_year, 'YYYY')
          .startOf('year')
          .format('YYYY-MM-DD'),
        date_to: moment(currentParams.by_year, 'YYYY')
          .endOf('year')
          .format('YYYY-MM-DD'),
        type: 'month',
      };
      format = 'MMMM YYYY';
    } else {
      params = {
        date_from: moment(
          `${currentParams.by_year}-${currentParams.by_month}`,
          'YYYY-DD-MM',
        )
          .startOf('month')
          .format('YYYY-MM-DD'),
        date_to: moment(
          `${currentParams.by_year}-${currentParams.by_month}`,
          'YYYY-DD-MM',
        )
          .endOf('month')
          .format('YYYY-MM-DD'),
      };
    }

    if (user.isManager) {
      params.shop_id = user?.currentShop?.id;
    }

    try {
      const { data } = await api.get('orders', {
        params: {
          ...params,
        },
      });
      const orderLineChartData = {
        id: 'Orders',
        data: [],
      };
      const lineChartAmountByDay = {
        id: 'Entrate',
        data: [],
      };
      const barChartOrderType = [];
      const barChartOrderStatus = [];
      const barChartOrderFullTypes = [];

      Object.entries(data.orders_by_day || {}).forEach((i) => {
        orderLineChartData.data.push({
          x: moment(i[0], 'DD-MM-YYYY').format(format),
          y: i[1],
        });
      });

      Object.entries(data.amount_by_day || {}).forEach((i) => {
        lineChartAmountByDay.data.push({
          x: moment(i[0], 'DD-MM-YYYY').format(format),
          y: i[1],
        });
      });

      Object.entries(data.click_and_reserve_by_day || {}).forEach((i) => {
        barChartOrderType.push({
          date: moment(i[0], 'DD-MM-YYYY').format(format),
          [OrderTypesNames.click_and_reserve]: i[1],
          [OrderTypesNames.click_and_collect]:
            data.click_and_collect_by_day[i[0]] || 0,
          [OrderTypesNames.click_and_ship]:
            data.click_and_ship_by_day[i[0]] || 0,
        });
      });
      try {
        Object.entries(data.created_by_day || {}).forEach((i) => {
          barChartOrderFullTypes.push({
            date: moment(i[0], 'DD-MM-YYYY').format(format),
            [OrderTypesFullNames.created_by_day]: i[1],

            [OrderTypesFullNames.canceled_by_day]:
            data.canceled_by_day[i[0]] || 0,
            [OrderTypesFullNames.shipped_by_day]:
            data.shipped_by_day[i[0]] || 0,
            [OrderTypesFullNames.completed_by_day]:
            data.completed_by_day[i[0]] || 0,

            [OrderTypesFullNames.refunded_by_day]:
            data.refunded_by_day[i[0]] || 0,
            [OrderTypesFullNames.expired_by_day]:
            data.expired_by_day[i[0]] || 0,
            [OrderTypesFullNames.ask_to_refund_by_day]:
            data.ask_to_refund_by_day[i[0]] || 0,
            [OrderTypesFullNames.waiting_for_products_refund]:
            data.waiting_for_products_refund[i[0]] || 0,

          });
        });
      } catch (e) {

      }
      Object.entries(data.orders_by_day || {}).forEach((i) => {
        barChartOrderStatus.push({
          date: moment(i[0], 'DD-MM-YYYY').format(format),
          [getOrderStatusText(OrderStatuses.created)]:
            data.created_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.canceled)]:
            data.canceled_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.shipped)]:
            data.shipped_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.completed)]:
            data.completed_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.refunded)]:
            data.refunded_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.expired)]:
            data.expired_by_day[i[0]] || 0,
          [getOrderStatusText(OrderStatuses.ask_to_refund)]:
            data.ask_to_refund_by_day[i[0]] || 0,
        });
      });
      setData({
        ...data,
        lineChartOrders: [orderLineChartData],
        lineChartAmountByDay: [lineChartAmountByDay],
      });

      setBarChartByOrderType(barChartOrderType);
      setBarChartByOrderFullTypes(barChartOrderFullTypes);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [searchParams, user?.currentShop?.id]);

  const filters = useMemo(() => {
    return [
      {
        title: t('Filtro anno'),
        type: 'select',
        name: 'by_year',
        values: Helpers.getYears(
          moment().year() - 2022 + 1,
          moment().year(),
        ).map((i) => ({
          value: i,
          title: i,
        })),
      },
      {
        title: t('Filtro mese'),
        type: 'select',
        name: 'by_month',
        values: Helpers.getMonths(),
      },
    ];
  }, [t]);

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!currentParams.by_year) {
      setSearchParams({
        by_year: moment().year(),
      });
    }
  }, []);

  return {
    barChartByOrderType,
    barChartByOrderFullTypes,
    data,
    filters,
    loading,
  };
};

export default useStatisticaManager;
