import React, { useLayoutEffect, useCallback, useState } from 'react';
import styles from './style.module.scss';
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Checkbox,
  Upload,
  Typography,
  Divider,
} from 'antd';
import {
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useShops from '../useShops';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';
import GoogleMap from 'components/GoogleMap';
import GoogleAutocomplete from 'components/GoogleAutocomplete';

const deliveryTypesNames = {
  free: 'Gratis',
  box: 'Prezzo fisso',
  table: 'Prezzo variabile',
};

const AddShop = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    form,
    filterData,
    handleCreateOrUpdate,
    updateInProcess,
    singleItem,
    salePointsAttributes,
    setSalePointsAttributes,
    errorMessageSalePoints,
    setErrorMessageSalePoints,
  } = useShops({
    isMainPage: false,
    isEdit,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  useLayoutEffect(() => {
    const breadcrumb = {
      title: isEdit ? t('Modifica Insegna') : t('Crea nuova Insegna'),
      path: isEdit ? `/shops/list/${id}/edit` : '/shops/add',
    };

    addNewBreadcrumb(breadcrumb.title, breadcrumb.path);
    return () => {
      removeLastBreadcrumb(breadcrumb.path);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  const onFileUpload = useCallback(
    (e) => {
      setUploadedFiles((prev) => [...prev, e.file]);
    },
    [setUploadedFiles],
  );

  const onFinish = async (values) => {
    await handleCreateOrUpdate(values, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  const title = isEdit ? t('Modifica Insegna') : t('Crea Insegna');
  const selectedCenter = singleItem?.lat &&
    singleItem?.lng && { lat: singleItem.lat, lng: singleItem.lng };

  const onSelectLocation = useCallback((coords) => {
    let fieldsValues = [];
    fieldsValues.push({
      name: 'city',
      value: coords.city,
    });
    fieldsValues.push({
      name: 'street',
      value: coords.street,
    });
    fieldsValues.push({
      name: 'building_number',
      value: coords.building,
    });
    fieldsValues.push({
      name: 'municipality',
      value: coords.municipality,
    });
    fieldsValues.push({
      name: 'zip_code',
      value: coords.zip,
    });
    fieldsValues.push({
      name: 'province',
      value: coords.province,
    });
    fieldsValues.push({
      name: 'lat',
      value: coords.lat,
    });
    fieldsValues.push({
      name: 'lng',
      value: coords.lng,
    });
    form.setFields(fieldsValues);
  }, []);

  const handleAddSalePoint = () => {
    setErrorMessageSalePoints(null);
    setSalePointsAttributes((s) => [
      ...s,
      { address: '', lat: '', lng: '', id: '' },
    ]);
  };

  const handleSetSalePointsAttributes = (index) => {
    return (coords) => {
      setErrorMessageSalePoints(null);
      setSalePointsAttributes((s) =>
        s.map((salePoint, i) => {
          if (i === index) {
            return {
              address: coords.address,
              lon: coords.lng,
              lat: coords.lat,
            };
          }
          return salePoint;
        }),
      );
    };
  };

  function handleRemoveSalePointsAttributes(position, id) {
    return () => {
      setErrorMessageSalePoints(null);
      setSalePointsAttributes((s) => {
        return s.filter((item, index) => {
          if (id) {
            return item.id !== id;
          }
          return index !== position;
        });
      });
    };
  }

  return (
    <Card className={styles.card} title={title}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              title: '',
              company_id: '',
              categories_shop_attributes: [],
              sale_points_attributes: [],
              email_support: '',
              phone_support: '',
              website: '',
              description_it: '',
              is_click_reserve: false,
              is_click_collect: false,
              is_online_shop: false,
              east_lombardy: false,

              shop_policy_click_collect_it: '',
              shop_policy_click_reserve_it: '',
              term_for_online_shop: '',

              business_address: '',
              building_number: '',
              city: '',
              province: '',
              zip_code: '',

              // //old logic
              //
              // backend_access_click_collect: false,
              // view_online_click_collect: false,
              // return_product: '',
              //
              // change_product: '',
              // guarantee_product: '',
              // delivery_type: '',
              // delivery_box_price: 0,
              // return_type: '',
              // return_box_price: '',
              // whatsapp: '',
              // shop_policy_click_collect: '',
              // business_name: '',
              // business_address: '',
              // business_code: '',
              //
              // lat: '',
              // lng: '',
              // product_sheet_model: 0, //TODO XZ INCHA
              // date_begin: '', //TODO XZ INCHA
              // date_end: '', //TODO XZ INCHA
              // products_limit: 3, //TODO XZ INCHA
              // size_table: '', //TODO XZ INCHA
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Nome insegna') + ' *'} name='title'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={t('Società associata') + ' *'}
                  name='company_id'
                >
                  <Select
                    {...FormHelper.addSearchProps()}
                    placeholder={t('Selezioni opzione')}
                  >
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.companies?.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={t('Categoria')}
                  name='categories_shop_attributes'
                >
                  <Select
                    mode={'multiple'}
                    placeholder={t('Selezioni opzione')}
                  >
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.child_categories?.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography className={'mb8'}>{t('Punti vendita')}</Typography>
                {salePointsAttributes?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Row gutter={16} align={'middle'}>
                      <Col span={20}>
                        <GoogleAutocomplete
                          key={index}
                          onSelect={handleSetSalePointsAttributes(index)}
                          name='city'
                          address={item.address}
                          placeholder='inserisci indirizzo completo di numero civico'
                          error={false}
                        />
                      </Col>
                      <Col span={4}>
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          onClick={handleRemoveSalePointsAttributes(
                            index,
                            item.id,
                          )}
                        />
                      </Col>
                    </Row>
                    <br />
                  </React.Fragment>
                ))}

                <Button
                  onClick={handleAddSalePoint}
                  style={{ width: '60%', marginBottom: 16 }}
                  icon={<PlusOutlined />}
                  type={'primary'}
                >
                  {t('Aggiungi')}
                </Button>

                {errorMessageSalePoints && (
                  <p
                    style={{ color: '#ff4d4f', margin: '0px', padding: '0px', marginTop: '-10px' }}
                  >
                    {errorMessageSalePoints}
                  </p>
                )}
              </Col>
              <Col span={9}>
                <Form.Item label={t('Email') + ' *'} name='email_support'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label={t('Numero telefono') + ' *'}
                  name='phone_support'
                >
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Sito web')} name='website'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>

              <Divider style={{ margin: '0px', marginBottom: '15px' }} />
              <Col span={18}>
                <Typography className={'mb8'}>
                  {t('Punto di ritiro')}
                </Typography>
              </Col>

              <Col span={18}>
                <Form.Item label={t('Via')} name='business_address'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>

              <Col span={18}>
                <Form.Item label={t('N. civico')} name='building_number'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('Città')} name='city'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('Provincia')} name='province'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('CAP')} name='zip_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Divider style={{ margin: '0px', marginBottom: '15px' }} />

              <Col span={18}>
                <Form.Item
                  label={t('Descrizione attività') + ' *'}
                  name='description_it'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={`${t('Termini per ritiro')} ${t('Click & Collect')}`}
                  name='shop_policy_click_collect_it'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={`${t('Termini per ritiro')} ${t('Click & Reserve')}`}
                  name='shop_policy_click_reserve_it'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={`${t('Termini per Shop online')}`}
                  name='term_for_online_shop'
                >
                  <Input.TextArea placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t('Immagine(jpg, png)')}>
                  <Form.Item
                    name='image'
                    valuePropName='fileList'
                    getValueFromEvent={FormHelper.normFile}
                    noStyle
                    multiple={false}
                    // rules={rules}
                  >
                    <Upload.Dragger
                      multiple={false}
                      name='file'
                      beforeUpload={FormHelper.beforeUpload}
                    >
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                      </p>
                      <p className='ant-upload-text'>
                        {t(
                          'Clicca o trascina il file in questa area di caricamento',
                        )}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={18}>
                <Typography className={'mb8'}>
                  {t('Servizi / modalità evasione')}
                </Typography>
              </Col>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={6}>
                    <Form.Item
                      label={t('Click & Collect')}
                      name='is_click_collect'
                      valuePropName='checked'
                    >
                      <Checkbox mainLabel={t('Click & Collect')} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={t('Click & Reserve')}
                      name='is_click_reserve'
                      valuePropName='checked'
                    >
                      <Checkbox mainLabel={t('Click & Reserve')} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={t('Shop online')}
                      name='is_online_shop'
                      valuePropName='checked'
                    >
                      <Checkbox mainLabel={t('Shop online')} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={t('Integrazione con eastlombardy')}
                      name='east_lombardy'
                      valuePropName='checked'
                    >
                      <Checkbox
                        mainLabel={t('Integrazione con eastlombardy')}
                      />
                    </Form.Item>
                  </Col>
                  {/*<Col span={6}>*/}
                  {/*  <Form.Item*/}
                  {/*    label={t('East Lomabrdy')}*/}
                  {/*    name='east_lombardy'*/}
                  {/*    valuePropName='checked'*/}
                  {/*  >*/}
                  {/*    <Checkbox mainLabel={t('East Lombardy')} />*/}
                  {/*  </Form.Item>*/}
                  {/*</Col>*/}
                </Row>
              </Col>
              {/*<Col span={9}>*/}
              {/*  <Form.Item*/}
              {/*    label={t('Indirizzo Business')}*/}
              {/*    name='business_address'*/}
              {/*  >*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={t('Codice Business')} name='business_code'>*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={t('Nome Business')} name='business_name'>*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={t('Whatsapp')} name='whatsapp'>*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={18}>*/}
              {/*  <Form.Item label={'change_product'} name='change_product'>*/}
              {/*    <Input.TextArea placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={18}>*/}
              {/*  <Form.Item label={'guarantee_product'} name='guarantee_product'>*/}
              {/*    <Input.TextArea placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={18}>*/}
              {/*  <Form.Item label={t('Resi prodotto')} name='return_product'>*/}
              {/*    <Input.TextArea placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={18}>*/}
              {/*  <Form.Item*/}
              {/*    label={t('Shop Policy')}*/}
              {/*    name='shop_policy_click_collect'*/}
              {/*  >*/}
              {/*    <Input.TextArea placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={12}>*/}
              {/*  <Typography.Title level={5}>*/}
              {/*    {t('Geolocalizzazione')}*/}
              {/*  </Typography.Title>*/}
              {/*  <GoogleAutocomplete*/}
              {/*    onSelect={onSelectLocation}*/}
              {/*    name='city'*/}
              {/*    address={singleItem?.city}*/}
              {/*    placeholder='inserisci indirizzo completo di numero civico'*/}
              {/*    error={false}*/}
              {/*  />*/}
              {/*  <Form.Item*/}
              {/*    hidden={true}*/}
              {/*    label={t('Via/Viale/Piazza')}*/}
              {/*    name='city'*/}
              {/*  >*/}
              {/*    <Input placeholder={t('Via Giosuè Carducci')} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item label={t('Via/Viale/Piazza')} name='street'>*/}
              {/*    <Input placeholder={t('Via Giosuè Carducci')} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item label={t('N° civico')} name='building_number'>*/}
              {/*    <Input placeholder={t('Via Giosuè Carducci')} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item label={t('CAP')} name='zip_code'>*/}
              {/*    <Input placeholder={''} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item label={t('Comune')} name='municipality'>*/}
              {/*    <Input placeholder={''} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item label={t('Provincia')} name='province'>*/}
              {/*    <Input placeholder={''} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item hidden={true} label={t('Provincia')} name='lat'>*/}
              {/*    <Input placeholder={''} />*/}
              {/*  </Form.Item>*/}
              {/*  <Form.Item hidden={true} label={t('Provincia')} name='lng'>*/}
              {/*    <Input placeholder={''} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={12}>*/}
              {/*  <GoogleMap*/}
              {/*    centerCoords={selectedCenter}*/}
              {/*    markers={selectedCenter ? [selectedCenter] : []}*/}
              {/*    onClickMarker={(i) => {*/}
              {/*      console.log('it ', i);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Col>*/}
              {/*<Col span={24}>*/}
              {/*  <Divider />*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={t('Tipo di consegna')} name='delivery_type'>*/}
              {/*    <Select placeholder={t('Selezioni opzione')}>*/}
              {/*      <Select.Option value={''}>*/}
              {/*        {t('Selezioni opzione')}*/}
              {/*      </Select.Option>*/}
              {/*      {filterData.delivery_types.map((value) => (*/}
              {/*        <Select.Option key={value} value={value}>*/}
              {/*          {deliveryTypesNames[value]}*/}
              {/*        </Select.Option>*/}
              {/*      ))}*/}
              {/*    </Select>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item*/}
              {/*    label={'delivery_box_price'}*/}
              {/*    name='delivery_box_price'*/}
              {/*  >*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={t('Modalità di reso')} name='return_type'>*/}
              {/*    <Select placeholder={t('Selezioni opzione')}>*/}
              {/*      <Select.Option value={''}>*/}
              {/*        {t('Selezioni opzione')}*/}
              {/*      </Select.Option>*/}
              {/*      {filterData.return_types.map((value) => (*/}
              {/*        <Select.Option key={value} value={value}>*/}
              {/*          {deliveryTypesNames[value]}*/}
              {/*        </Select.Option>*/}
              {/*      ))}*/}
              {/*    </Select>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={9}>*/}
              {/*  <Form.Item label={'return_box_price'} name='return_box_price'>*/}
              {/*    <Input placeholder={t('Inserisci testo')} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={8}>*/}
              {/*  <Form.Item*/}
              {/*    label={'backend_access_click_collect'}*/}
              {/*    name='backend_access_click_collect'*/}
              {/*    valuePropName='checked'*/}
              {/*  >*/}
              {/*    <Checkbox mainLabel={'backend_access_click_collect'} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={8}>*/}
              {/*  <Form.Item*/}
              {/*    label={'view_online_click_collect'}*/}
              {/*    name='view_online_click_collect'*/}
              {/*    valuePropName='checked'*/}
              {/*  >*/}
              {/*    <Checkbox mainLabel={'view_online_click_collect'} />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button
            type={'secondary'}
            onClick={() => navigate('/profile/shops/list')}
          >
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddShop;
