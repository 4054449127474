import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { useNavigate } from 'hooks/useNavigate';

import { Card, Col, Input, Row, Typography } from 'antd';
import styles from './style.module.scss';
import useFreeDelivery from './useFreeDelivery';

const CustomInput = ({
  amountForFreeDelivery,
  setAmountForFreeDelivery,
}) => {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const value = e.target.value;

    const regex = /^[0-9]*([,][0-9]{0,2})?$/;

    if (regex.test(value) || value === '') {
        setAmountForFreeDelivery(value);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <span className={styles.placeholderText}>
        {t('Valore in € della soglia da raggiungere')}
      </span>
      <Input
        placeholder='00,00'
        className={styles.input}
        onChange={handleInputChange}
        value={amountForFreeDelivery}
      />
    </div>
  );
};

const FreeDeliveryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();

  const {
    handleSubmit,
    updateInProcess,
    amountForFreeDelivery,
    setAmountForFreeDelivery,
  } = useFreeDelivery();

  useLayoutEffect(() => {
    addNewBreadcrumb(
      t('Imposta una soglia per la spedizione gratuita'),
      '/orders/free-delivery',
    );
    return () => {
      removeLastBreadcrumb('/orders/free-delivery');
    };
  }, []);

  return (
    <Card
      className={styles.card}
      title={t('Impostazione soglia per spedizione gratuita')}
    >
      <Row className={styles.row} justify={'space-between'} gutter={[16, 20]}>
        <Col span={24}>
          <Row
            className={styles.row}
            justify={'space-between'}
            gutter={[16, 5]}
          >
            <Col span={24}>
              <Typography.Text strong>{t('Soglia raggiunta')}</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>
                {t(
                  'Indica la soglia minima da raggiungere per poter usufruire della spedizione gratuita.',
                )}
              </Typography.Text>
            </Col>
          </Row>
        </Col>

        <Col span={14}>
          <CustomInput
            amountForFreeDelivery={amountForFreeDelivery}
            setAmountForFreeDelivery={setAmountForFreeDelivery}
          />
        </Col>
      </Row>

      <Row justify={'end'} gutter={10}>
        <Col>
          <button
            onClick={() => navigate('/orders')}
            className={styles.buttonFirst}
          >
            {t('Indietro')}
          </button>
        </Col>
        <Col>
          <Button
            className={styles.buttonSecond}
            onClick={() => handleSubmit(amountForFreeDelivery)}
            loading={updateInProcess}
            disabled={!amountForFreeDelivery}
          >
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default FreeDeliveryPage;
