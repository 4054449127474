class ErrorHelper {
  static getErrorsForForm = (data, changedParams = {}) => {
    const errorsData = [];

    Object.entries(data).forEach((i) => {
      errorsData.push({
        name: changedParams[i[0]] || i[0],
        errors: i[1],
      });
    });

    return errorsData;
  };
}

export default ErrorHelper;
