import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import { useNavigate } from 'hooks/useNavigate';
import { Route, Routes } from 'react-router-dom';
import AddTeam from './company/addCompany';
import CompaniesList from './company/companiesList';
import ShopsList from './shop/shopsList';
import OwnersList from './owner/ownerList';
import AddOwner from './owner/addOwner';
import AddShop from './shop/addShop';
import AddCompany from './company/addCompany';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import RefundInfoPage from './RefundInfoPage';

const AdminView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(getUser);

  const sectionData = useMemo(
    () => [
      {
        title: t('Gestori'),
        description: '',
        btnText: t('Vedi elenco'),
        btnAction: () => {
          if (user.isAdmin) {
            navigate('owners/list');
          } else {
            navigate(`owners/list/${user.id}/edit`);
          }
        },
        secondBtnText: user.isAdmin && t('Crea nuovo gestore'),
        secondBtnAction: () => {
          navigate('owners/add');
        },
      },
      {
        title: t('Società'),
        description: '',
        btnText: t('Vedi elenco'),
        secondBtnText: t('Crea nuova società'),
        secondBtnAction: () => {
          navigate('companies/add');
        },
        btnAction: () => {
          navigate('companies/list');
        },
      },
      {
        title: t('Insegne'),
        description: '',
        btnText: t('Vedi elenco'),
        secondBtnText: t('Crea insegna'),
        secondBtnAction: () => {
          navigate('shops/add');
        },
        btnAction: () => {
          navigate('shops/list');
        },
      },
      {
        title: t('Condizioni di reso della piattaforma'),
        description: '',
        btnText: t('Consulta le condizioni'),
        btnAction: () => {
          navigate('refund-info');
        },
      },
    ],
    [t, user],
  );

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection
          title={t('Amministrazione')}
          sectionData={sectionData}
        />
      </Col>
      <Col span={18}>
        <Routes>
          <Route path={'add/*'} element={<AddTeam />} />
          <Route path={'add/:id/edit'} element={<AddTeam isEdit />} />
          <Route path={'companies/list/*'} element={<CompaniesList />} />
          <Route path={'companies/add/*'} element={<AddCompany />} />
          <Route
            path={'companies/list/:id/edit'}
            element={<AddCompany isEdit />}
          />

          <Route path={'shops/list/*'} element={<ShopsList />} />
          <Route path={'shops/add'} element={<AddShop />} />
          <Route path={'shops/list/:id/edit'} element={<AddShop isEdit />} />
          <Route path={'owners/list/*'} element={<OwnersList />} />
          <Route path={'owners/add'} element={<AddOwner />} />
          <Route path={'owners/list/:id/edit'} element={<AddOwner isEdit />} />
          <Route path={'refund-info'} element={<RefundInfoPage />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default AdminView;
