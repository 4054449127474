import { useMemo } from 'react';
import { Col, Row, Tabs } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'react-i18next';
import ProductStatisticsView from './tabs/product-statistics';
import OrdersAndReturnsStatisticsView from './tabs/orders-and-returns-statistics';

const { TabPane } = Tabs;

const ManagerView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleChangeTab = (key) => {
    if (key === '/') {
      navigate('/statistica');
    } else {
      navigate(`/statistica/${key}`);
    }
  };
  const filters = useMemo(() => {
    return [
      {
        title: t('Filtro anno'),
        type: 'select',
        name: 'by_shopping_center',
        values: [],
      },
      {
        title: t('Filtro mese'),
        type: 'select',
        name: 'by_day_from',
        values: [],
      },
    ];
  }, [t]);
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <Col span={24}>
            <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
              <TabPane tab='Statistiche prodotti' key='/' />
              <TabPane
                tab='Statistiche ordini e resi'
                key='orders-and-returns-statistics'
              />
            </Tabs>
          </Col>
          <Col span={24}>
            <Routes>
              <Route path={'/'} element={<ProductStatisticsView />} />
              <Route
                path={'/orders-and-returns-statistics'}
                element={<OrdersAndReturnsStatisticsView />}
              />
            </Routes>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ManagerView;
