import React from 'react';
import { Card, Col, Row, Space, Table, Tabs, Typography } from 'antd';
import styles from '../style.module.scss';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import useArticles from './useArticles';
import Filters from 'components/filters';
import { Route, Routes } from 'react-router-dom';
import ViewModal from '../managerView/articles/viewModal';
const { TabPane } = Tabs;

const AdminView = () => {
  const { t } = useTranslation();
  const {
    data,
    columns,
    onChangeTableFilter,
    loading,
    filters,
    selectedKeys,
    updateInProcess,
    handleBulkDestroy,
    rowSelection,
    handleChangeTab,
    productsExportLoading,
    handleExport,
    singleItem,
  } = useArticles();

  const totals = data?.rest?.totals || {
    all: 0,
    offline: 0,
    online: 0,
  };

  return (
    <Card className={styles.card} title={t('Elenco prodotti')}>
      <Row gutter={[16, 16]}>
        <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
          <TabPane tab={`${t('Tutti i prodotti')} (${totals.all})`} key='all' />
          <TabPane tab={`${t('Online')} (${totals.online})`} key='online' />
          <TabPane tab={`${t('Offline')} (${totals.offline})`} key='offline' />
        </Tabs>
        <Col span={24}>
          <Row justify={'space-between'} gutter={[16, 16]}>
            <Col>
              <Filters hideClearAll filters={filters} />
            </Col>
            <Col>
              {selectedKeys.length > 0 && (
                <Space>
                  <Typography.Text strong={true}>
                    {selectedKeys.length} {t('articoli selezionati')}
                  </Typography.Text>
                  <Button loading={updateInProcess} onClick={handleBulkDestroy}>
                    {t('Rimuovi')}
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
          <br />
          <Row gutter={16} align={'middle'}>
            <Col>
              <Button onClick={handleExport} loading={productsExportLoading}>
                {t('Scarica excel')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            loading={loading}
            bordered
            dataSource={data.product_variants}
            columns={columns}
            pagination={data.pagination}
            scroll={{ x: 2000, y: 600 }}
            onChange={onChangeTableFilter}
            rowKey={'id'}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={':id/view'}
          element={<ViewModal singleItem={singleItem} />}
        />
      </Routes>
    </Card>
  );
};

export default AdminView;
