import routes from './routes';
import SetRoutes from 'components/setRoutes';

const ProfilePages = () => {
  return (
    <>
      <SetRoutes routes={routes} />
    </>
  );
};

export default ProfilePages;
