import { Input } from 'antd';
import styles from './style.module.scss';

const InputWithLabel = ({
  label,
  value = '',
  onChange,
  type = 'text',
  placeholder = '',
}) => {
  if (type === 'textarea') {
    return (
      <div className={styles.container}>
        <span className={styles.label}>{label}</span>
        <Input.TextArea
          placeholder={placeholder}
          value={value}
          bordered={false}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          rows={8}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <Input
        type={type}
        value={value}
        bordered={false}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default InputWithLabel;
