import { Routes, Route } from 'react-router-dom';
import { paths } from 'constants/pages';
import LoginPage from 'pages/login';
import DashboardPage from './home';
import ProductsPage from './products';
import StripePage from './stripe';
import OrderPages from './orders';
import StatisticaPage from './statistica';
import PromotionsPage from './promotions';
import BillingPages from './billing';
import TeamPage from './team/main';
import StockPickingPages from './stockPicking';
import DataExportPage from './data-export';
import ProfilePages from './profile';
import ForgotPassword from './forgotPassword';
import NotFoundPage from './notFoundPage';
import PrivateRoute from '../routes';

function Pages() {
  return (
    <Routes>
      <Route
        path={paths.dashboard}
        element={<PrivateRoute admin manager owner Element={DashboardPage} />}
      />
      <Route path={paths.login} element={<LoginPage />} />
      <Route path={paths.forgotPassword} element={<ForgotPassword />} />
      <Route
        path={paths.products}
        element={
          <PrivateRoute cashier admin manager owner Element={ProductsPage} />
        }
      />
        <Route
          path={paths.stripe}
          element={
              <PrivateRoute owner Element={StripePage} />
          }
        />
      <Route
        path={paths.stockPicking}
        element={<PrivateRoute cashier manager Element={StockPickingPages} />}
      />
      <Route
        path={paths.orders}
        element={
          <PrivateRoute cashier admin manager owner Element={OrderPages} />
        }
      />
      <Route
        path={paths.statistica}
        element={<PrivateRoute admin manager owner Element={StatisticaPage} />}
      />
      <Route
        path={paths.promotions}
        element={<PrivateRoute admin Element={PromotionsPage} />}
      />
      <Route
        path={paths.billing}
        element={<PrivateRoute admin manager owner Element={BillingPages} />}
      />
      <Route
        path={paths.team}
        element={<PrivateRoute admin manager owner Element={TeamPage} />}
      />
      <Route
        path={paths.exportData}
        element={<PrivateRoute admin manager owner Element={DataExportPage} />}
      />
      <Route
        path={paths.profile}
        element={<PrivateRoute admin manager owner Element={ProfilePages} />}
      />
      <Route path={paths.notFoundPage} element={<NotFoundPage />} />
      <Route path={'/*'} element={<NotFoundPage />} />
    </Routes>
  );
}

export default Pages;
