import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import styles from './style.module.scss';
import Button from 'components/button';
import uuid from 'react-uuid';

const LeftCardsSection = ({ sectionData = [], title = '', loading }) => {
  console.log('sectionData', sectionData);
  return (
    <Card className={styles.card} title={title}>
      {sectionData.map((i) => {
        if (i.hide) return null;
        return (
          <Card.Grid key={uuid()} className={styles.cardSection}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Typography.Text
                  strong
                  className={styles.circle_parent_element}
                >
                  {i.title}
                  {!loading && i.count > 0 && (
                    <p className={styles.circle_count_red}>{i.count}</p>
                  )}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text>{i.description}</Typography.Text>
              </Col>
              <Col span={24}>
                <Button
                  onClick={i.btnAction ? i.btnAction : () => {}}
                  loading={i.loading}
                >
                  {i.btnText}
                </Button>
              </Col>
              {i.secondBtnText && (
                <Col>
                  <Button
                    onClick={i.secondBtnAction ? i.secondBtnAction : () => {}}
                  >
                    {i.secondBtnText}
                  </Button>
                </Col>
              )}
            </Row>
          </Card.Grid>
        );
      })}
    </Card>
  );
};

export default LeftCardsSection;
