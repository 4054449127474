import styles from 'pages/orders/managerView/settings/style.module.scss';
import { Col, Row, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/inputWithLabel';

const NotificationsV2 = ({ name, addLabel, label, rules = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row align={'middle'} gutter={12} className={styles.timeContainer}>
        <Col span={24}>
          <Form.List name={name}>
            {(fields, { add, remove, ...field }, { errors }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      required={false}
                      key={field.key}
                      style={{ marginBottom: 12 }}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              'Compila questo campo',
                          },
                          ...rules,
                        ]}
                        noStyle
                      >
                        <InputWithLabel
                          label={label || t('Nome ingrediente')}
                          style={{ width: '60%' }}
                        />
                      </Form.Item>
                      <span className={styles.deleteIcon} style={{ marginLeft: 12 }} onClick={() => remove(field.name)}>
                        <DeleteOutlined />
                      </span>
                    </Form.Item>
                  ))}
                  <span
                    className={styles.addMore}
                    onClick={() => add()}
                  >
                    {addLabel || t('+ Aggiungi orari')}
                  </span>
                  {!!errors?.length && (
                    <Form.Item>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}

                </>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </>
  );
};

export default NotificationsV2;
