import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Footer as AntFooter } from 'antd/es/layout/layout';
import styles from './style.module.scss';
import moment from 'moment';

const Footer = () => {
  return (
    <AntFooter className={styles.footer}>
      <Row justify={'center'}>
        <Col span={24}>
          <div className={styles.textWrapper}>
            <Typography className={styles.titles}>
              <p>
                © Copyright {moment().year()}
                <a href={'#'} target={'_blank'} className={styles.link}>
                  {' '}
                  Informativa Policy
                </a>{' '}
                |{' '}
                <a href={'#'} target={'_blank'} className={styles.link}>
                  Condizioni d’uso
                </a>
              </p>
            </Typography>
          </div>
        </Col>
      </Row>
      {/*  <Col span={5}>*/}
      {/*    <div className={styles.textWrapper}>*/}
      {/*      <ClockIcon fill={'#78A9BE'} height='12x' width='12px' />*/}
      {/*      <Typography className={styles.titles}>*/}
      {/*        <p>*/}
      {/*          <b>Dal lunedì al venerdì</b> dalle ore 10 alle ore 20*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          <b>Sabato e domenica</b> dalle 10 alle 21*/}
      {/*        </p>*/}
      {/*      </Typography>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*  <Col span={5}>*/}
      {/*    <div className={styles.textWrapper}>*/}
      {/*      <PhoneIcon fill={'#78A9BE'} height='12x' width='12px' />*/}
      {/*      <Typography className={styles.titles}>+39 0935 950040</Typography>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*  <Col span={5}>*/}
      {/*    <div className={styles.textWrapper}>*/}
      {/*      <MailIcon fill={'#78A9BE'} height='12x' width='12px' />*/}
      {/*      <Typography className={styles.titles}>*/}
      {/*        info@siciliaoutletvillage.it*/}
      {/*      </Typography>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<Row justify={'center'} className={styles.footer_bottom}>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>azienda</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>informativa sulla privacy</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>informativa infopoint</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>termini e condizioni del vip club</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>informativa wifi</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*  <Col span={3}>*/}
      {/*    <Typography className={styles.links}>*/}
      {/*      <Link to={'#'}>informazioni legali</Link>*/}
      {/*    </Typography>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </AntFooter>
  );
};

export default Footer;
