import routes from './routes';
import SetRoutes from '../../components/setRoutes';

const HomePage = () => {
  return (
    <>
      <SetRoutes routes={routes} />
    </>
  );
};

export default HomePage;
