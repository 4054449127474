import React, { useEffect } from 'react';
import styles from './style.module.scss';
import { Col, Row, Typography } from 'antd';
import useSteps from 'hooks/useSteps';
import { SyncOutlined } from '@ant-design/icons';
import useECommerce from 'pages/products/managerView/synchronize-e-commerce/useECommerce';

const SixthStep = () => {
  const { setStep } = useSteps();
  const {
    eCommerceData,
  } = useECommerce();

  useEffect(() => {
    setStep((s) => ({
      ...s,
      // current: 6,
      current: eCommerceData.currentECommerceType === 'shopify' ? 7 : 6,
      show: false,
    }));
  }, [setStep, eCommerceData]);
  return (
    <Row
      className={styles.row}
      align={'middle'}
      justify={'center'}
      gutter={[16, 16]}
    >
      <Col className={styles.col} span={8}>
        <SyncOutlined className={styles.iconAnimation} />
        <Typography.Text className={styles.text}>
          Sincronizzazione in corso. Non abbandonare la pagina fino al termine
          della procedura.
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default SixthStep;
