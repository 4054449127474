import { Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import { useParams } from 'react-router-dom';
import ShowError from 'components/showError';
import { KeyOutlined } from '@ant-design/icons';

const CheckCodeModal = ({
  onCancel,
  handleOk,
  loading,
  confirmationCodeError,
  setConfirmationCodeError,
}) => {
  const { id } = useParams();
  const [values, setValues] = useState(['', '', '']);
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const btnRef = useRef(null);

  const onChangeInput = (index) => {
    return (e) => {
      const value = e.target.value;
      if (!/^\d*$/.test(value)) return;
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (index === 0) {
        secondInputRef.current.focus();
      }
      if (index === 1) {
        thirdInputRef.current.focus();
      }
      if (index === 2) {
        btnRef.current.focus();
      }
    };
  };

  const handleClickOk = () => {
    handleOk(values, id);
  };

  useEffect(() => {
    setConfirmationCodeError([]);
  }, []);

  return (
    <Modal
      visible={true}
      onOk={handleClickOk}
      onCancel={onCancel}
      okButtonProps={{
        ref: btnRef,
        loading,
      }}
    >
      <Row justify={'center'} gutter={16}>
        <Col span={24}>
          <Row justify={'center'} gutter={16}>
            <Col className={styles.iconContainer}>
              <KeyOutlined />
            </Col>
          </Row>
        </Col>
        <Col>
          <Input
            value={values[0]}
            onChange={onChangeInput(0)}
            ref={firstInputRef}
            className={styles.inputWidth}
          />
        </Col>
        <Col>
          <Input
            value={values[1]}
            onChange={onChangeInput(1)}
            ref={secondInputRef}
            className={styles.inputWidth}
          />
        </Col>
        <Col>
          <Input
            value={values[2]}
            onChange={onChangeInput(2)}
            ref={thirdInputRef}
            className={styles.inputWidth}
          />
        </Col>
      </Row>
      <ShowError errors={confirmationCodeError} />
    </Modal>
  );
};

export default CheckCodeModal;
