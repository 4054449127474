import { useEffect, useState } from 'react';
import AuthApi from 'libs/api/auth';
import AuthService from 'services/Auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helpers from '../../helpers';

const api = new AuthApi();

const useAuth = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoadingBtn(true);
    try {
      console.log('lalal', values);
      const {
        data: { token, role },
      } = await api.signIn(values);
      AuthService.setToken(token);
      if (role === 'cashier') {
        navigate('/products');
      } else {
        navigate('/');
      }
    } catch (e) {
      let errors = Helpers.getErrorsFromResponse(e);
      if (!errors.length) {
        errors = [t('Something went wrong!')];
      }
      setErrors(errors);
      navigate('/products');
    } finally {
      // setLoadingBtn(false);
      navigate('/products');
    }
  };

  useEffect(() => {
    if (AuthService.getToken()) {
      navigate('/');
    }
  }, []);

  return {
    handleLogin,
    loadingBtn,
    errors,
    navigate,
  };
};

export default useAuth;
