import React from 'react';
import { Alert, Divider, Modal, Progress, Space, Spin } from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import FormHelper from '../../../../../helpers/FormHelper';
import uuid from 'react-uuid';
const { Dragger } = Upload;

const UploadModal = ({
  handleCancel,
  handleUploadProducts,
  jobData,
  errorTexts,
  progress,
  uploadLoading,
}) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    console.log(e.file);
    handleUploadProducts(e.file);
  };
  return (
    <Modal
      title={t('Scarica lista dei prodotti')}
      visible={true}
      onOk={(handleOk) => {}}
      onCancel={handleCancel}
    >
      {uploadLoading && <Progress percent={progress} />}
      <Spin spinning={uploadLoading}>
        <Dragger
          fileList={[]}
          multiple={false}
          beforeUpload={FormHelper.beforeUpload}
          onChange={onChange}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            {t('Clicca o trascina il file in questa area di caricamento')}
          </p>
        </Dragger>
      </Spin>
      <Divider />
      <Space direction={'vertical'}>
        {errorTexts.map((i) => (
          <Alert
            key={uuid()}
            message={<div dangerouslySetInnerHTML={{ __html: i }} />}
            type='warning'
          />
        ))}
      </Space>
    </Modal>
  );
};

export default UploadModal;
