import { Col, Row, Space, Typography } from 'antd';
import Input from 'antd/es/input/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShowError from '../../../../../components/showError';

const WoocommerceForm = ({ eCommerceData, handleChangeData }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={14}>
        <Space direction={'vertical'}>
          <Typography.Text strong>{t('Woocommerce api url')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            status={eCommerceData?.errors['woocommerce_api_url'] ? 'error' : ''}
            placeholder={t('Inserisci il testo')}
            name={'woocommerce_api_url'}
            value={eCommerceData.woocommerceData.woocommerce_api_url}
          />
          <ShowError errors={eCommerceData?.errors['woocommerce_api_url']} />
          <Typography.Text strong>
            {t('Woocommerce consumer key')}
          </Typography.Text>
          <Input
            onChange={handleChangeData}
            status={
              eCommerceData?.errors['woocommerce_consumer_key'] ? 'error' : ''
            }
            placeholder={t('Inserisci il testo')}
            name={'woocommerce_consumer_key'}
            value={eCommerceData.woocommerceData.woocommerce_consumer_key}
          />
          <ShowError
            errors={eCommerceData?.errors['woocommerce_consumer_key']}
          />

          <Typography.Text strong>
            {t('Woocommerce consumer secret')}
          </Typography.Text>
          <Input
            onChange={handleChangeData}
            status={
              eCommerceData?.errors['woocommerce_consumer_secret']
                ? 'error'
                : ''
            }
            placeholder={t('Inserisci il testo')}
            name={'woocommerce_consumer_secret'}
            value={eCommerceData.woocommerceData.woocommerce_consumer_secret}
          />
          <ShowError
            errors={eCommerceData?.errors['woocommerce_consumer_secret']}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default WoocommerceForm;
