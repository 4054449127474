import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UIHelper from 'helpers/UIHelper';
import AuthService from 'services/Auth';
import ShopsApi from 'libs/api/shops';

const api = new ShopsApi();

const currentShopFilter = {
  by_shop_id: +AuthService.getCurrentShop(),
};

const useFreeDelivery = () => {
  const { t } = useTranslation();
  const [updateInProcess, setUpdateInProcess] = useState(false);
  const [amountForFreeDelivery, setAmountForFreeDelivery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(currentShopFilter?.by_shop_id);
        setAmountForFreeDelivery(formatServerValue(data?.shop?.amount_for_free_delivery));
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleSubmit = async (value) => {
    try {
      setUpdateInProcess(true);

      const formattedValue = value.replace(',', '.');
      const valueToCents = parseFloat(formattedValue) * 100;

      const formData = new FormData();
      formData.append('shop[amount_for_free_delivery_cents]', valueToCents);

      const { data } = await api.updateShop(
        currentShopFilter?.by_shop_id,
        formData,
      );

      setAmountForFreeDelivery(formatServerValue(data?.shop?.amount_for_free_delivery));
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
    } finally {
      setUpdateInProcess(false);
    }
  };

  const formatServerValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2).replace('.', ',');
    }
    return value;
  };

  return {
    handleSubmit,
    updateInProcess,
    amountForFreeDelivery,
    setAmountForFreeDelivery,
  };
};

export default useFreeDelivery;
