import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useFilter } from 'hooks/useFilter';
import { useApi } from 'hooks/useApi';
import { getOrderStatusText, timeFormat } from 'helpers/utils';

import OrdersApi from 'libs/api/orders';
import { useNavigate } from 'hooks/useNavigate';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import useDataExport from '../../../data-export/adminView/useDataExport';
import Helpers from '../../../../helpers';
import { Col, Row } from 'antd';

const api = new OrdersApi();

const useRefoundAdmin = () => {
  const {
    downloadFile,
    downloadTypes,
    loading: ordersExportLoading,
  } = useDataExport();
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const { searchParams } = useFilter();
  const navigate = useNavigate();
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [additionalFilters, setAdditionalFilters] = useState({
    status: 'ask_to_refund,refunded,waiting_for_products_refund,refused',
  });

  const { loading, data, onChangeTableFilter, updateInProcess, singleItem } =
    useApi({
      resourceKey: 'orders',
      singleResourceKey: 'order',
      api,
      isMainPage: true,
      additionalFilters,
      findByAdditionalId: currentOrderId,
    });

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: 'id',
        width: 80,
        render: (data) => (
          <Button
            onClick={() => {
              navigate(`view/${data}`);
            }}
            type={'primary-white'}
            icon={<EyeOutlined />}
          />
        ),
      },
      {
        title: t('ID ordine'),
        dataIndex: 'id',
        sorter: true,
        width: 200,
        render: (data, row) => row.order_id,
      },
      {
        title: t('Data richiesta reso'),
        width: 250,
        dataIndex: 'refund_created_at',
        sorter: true,
        render: (data) => timeFormat(data),
      },
      {
        title: t('Quantità articoli'),
        width: 200,
        dataIndex: 'product_variants_count',
        sorter: true,
        render: (data, row) => row.count,
      },
      {
        title: t('Stato pratica'),
        dataIndex: 'status',
        width: 200,
        render: (data) => getOrderStatusText(data),
        sorter: true,
      },
      {
        title: t('Articoli Importo (x ogni articolo)'),
        width: 200,
        dataIndex: 'final_amount',
        // render: (data, row) => Helpers.numberWithCommas(row.final_amount, ' €'),
        sorter: () => {},
        render: (data, row) => {
          return (
            <Row>
              {row.refund_order_product_variants.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    {item.title} - {item.price} €
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
      {
        title: t('Quantità (x ogni articolo)'),
        width: 200,
        dataIndex: 'product_variants_count',
        sorter: true,
        render: (data, row) => {
          return (
            <Row>
              {row.refund_order_product_variants.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    {item.title} - {item.count}
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
      // {
      //   title: t('Motivo richiesta di reso'),
      //   width: 200,
      //   dataIndex: 'refund_reason',
      //   sorter: true,
      // },
      //old
      // {
      //   title: t('Codice cliente'),
      //   width: 200,
      //   dataIndex: 'user_id',
      //   sorter: true,
      //   render: (data, row) => row.customer_id,
      // },
      // {
      //   title: t('Nome'),
      //   width: 200,
      //   dataIndex: 'name',
      //   sorter: true,
      // },
      // {
      //   title: t('Cognome'),
      //   width: 200,
      //   dataIndex: 'surname',
      //   sorter: true,
      // },
      // {
      //   title: t('Email'),
      //   width: 200,
      //   dataIndex: 'email',
      //   sorter: true,
      // },
      // {
      //   title: t('Numero telefono'),
      //   width: 200,
      //   dataIndex: 'phone',
      //   sorter: true,
      // },
      // {
      //   title: t('Importo'),
      //   width: 200,
      //   dataIndex: 'refund_reason',
      // },
      // {
      //   title: t('Quantità'),
      //   width: 200,
      //   dataIndex: 'refund_reason',
      // },
      // {
      //   title: t('Motivo richiesta di reso'),
      //   width: 200,
      //   dataIndex: 'refund_reason',
      // },
    ],
    [t, navigate],
  );
  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca reso'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data richiesta reso'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  const handleChangeTab = (key) => {
    switch (key) {
      case 'all': {
        setAdditionalFilters({
          status: 'ask_to_refund,refunded,waiting_for_products_refund,refused',
        });
        break;
      }
      case 'ask_to_refund': {
        setAdditionalFilters({
          status: 'ask_to_refund',
        });
        break;
      }
      case 'refunded': {
        setAdditionalFilters({
          status: 'refunded',
        });
        break;
      }
      case 'waiting_for_products_refund': {
        setAdditionalFilters({
          status: 'waiting_for_products_refund',
        });
        break;
      }
    }
  };
  const handleCancelRefoundModal = () => {
    navigate('/orders/return-practices');
  };

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalFilters },
      downloadTypes.orders,
    );
  }, [searchParams, downloadTypes, additionalFilters]);

  return {
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    handleCancelRefoundModal,
    ordersExportLoading,
    handleExport,
  };
};

export default useRefoundAdmin;
