import React from 'react';
import styles from './style.module.scss';
import { Form, Input } from 'antd';
import { SecondaryMailIcon } from 'icons';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import ShowError from 'components/showError';
import { Link } from 'react-router-dom';

const LoginForm = ({ onSubmit, loadingBtn, errors }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.formWrapper}>
      <Form
        form={form}
        name='basic'
        initialValues={{
          email: '',
        }}
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          label={t('E-mail')}
          name='email'
          rules={[
            {
              required: true,
              message: t('Inserisci il tuo indirizzo e-mail!'),
            },
          ]}
        >
          <Input
            prefix={<SecondaryMailIcon />}
            placeholder={'example@mail.com'}
            size={'large'}
          />
        </Form.Item>
        <ShowError errors={errors} />
        <Form.Item>
          <Link to={'/login'} className={styles.forgotPassword}>
            {t('Log in')}
          </Link>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Button
            className={styles.button}
            type='primary'
            htmlType='submit'
            size={'large'}
            loading={loadingBtn}
          >
            {t('Invia istruzioni per il reset della password')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
