import React from 'react';
import { Card, Col, Row, Space, Table, Typography } from 'antd';
import styles from '../style.module.scss';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import useArticles from './useArticles';
import Filters from 'components/filters';
import StockModal from './Modal';
import { Route, Routes } from 'react-router-dom';
import ViewModal from './viewModal';

const AdminView = () => {
  const { t } = useTranslation();
  const {
    data,
    columns,
    onChangeTableFilter,
    loading,
    filters,
    selectedKeys,
    updateInProcess,
    rowSelection,
    showModal,
    setShowModal,
    onSubmit,
    user,
    handleDownloadExcel,
    dataExportLoading,
  } = useArticles({ isMainPage: true });
  return (
    <Card className={styles.card} title={t('Stock picking')}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row justify={'space-between'} gutter={[16, 16]}>
            <Col>
              <Filters filters={filters} />
            </Col>
            <Col span={24}>
              <Row gutter={16} align={'middle'}>
                <Col>
                  <Button
                    onClick={handleDownloadExcel}
                    loading={dataExportLoading}
                  >
                    {t('Scarica excel')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              {selectedKeys.length > 0 && (
                <Space>
                  <Typography.Text strong={true}>
                    {t('Articoli selezionati')}: {selectedKeys.length}
                  </Typography.Text>
                  <Button
                    loading={updateInProcess}
                    onClick={() => setShowModal(true)}
                  >
                    {t('Aggiungi al carrello')}
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
          <br />
        </Col>
        <Col span={24}>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            loading={loading}
            bordered
            dataSource={data.product_variants}
            columns={columns}
            pagination={data.pagination}
            scroll={{ x: 2000, y: 600 }}
            onChange={onChangeTableFilter}
            rowKey={'id'}
          />
        </Col>
      </Row>
      <StockModal
        // orderId={orderId}
        // customerId={customerId}
        // customerName={customerName}
        userData={user}
        visible={showModal}
        handleCancel={() => setShowModal(false)}
        data={selectedKeys}
        handleOk={onSubmit}
        loading={updateInProcess}
      />
      <Routes>
        <Route path={':id/view'} element={<ViewModal />} />
      </Routes>
    </Card>
  );
};

export default AdminView;
