import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import { useNavigate } from 'hooks/useNavigate';
import { Route, Routes } from 'react-router-dom';
import NewOrders from './newOrders';
import ArchiveOrders from './archiveOrders';
import RefoundOrders from './refoundOrders';
import SettingsPage from './settings';
import FreeDeliveryPage from './freeDelivery';

const ManagerView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sectionData = useMemo(
    () => [
      {
        title: t('Richieste di ordine'),
        description: '',
        btnText: t('Gestisci'),
        btnAction: () => navigate('new'),
      },
      {
        title: t('Archivio ordini'),
        description: '',
        btnText: t('Vedi'),
        btnAction: () => navigate('archive'),
      },
      {
        title: t('Pratiche di reso'),
        description: '',
        btnText: t('Gestisci'),
        btnAction: () => navigate('refound'),
      },
      {
        title: t('Impostazioni ordini e resi'),
        description: '',
        btnText: t('Modifica'),
        btnAction: () => navigate('settings'),
      },
      {
        title: t('Imposta una soglia per la spedizione gratuita'),
        description: '',
        btnText: t('Imposta soglia'),
        btnAction: () => navigate('free-delivery'),
      },
    ],
    [t],
  );

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection
          title={t('Ordini e resi')}
          sectionData={sectionData}
        />
      </Col>
      <Col span={18}>
        <Routes>
          <Route path={'new/*'} element={<NewOrders />} />
          <Route path={'archive/*'} element={<ArchiveOrders />} />
          <Route path={'refound/*'} element={<RefoundOrders />} />
          <Route path={'settings/*'} element={<SettingsPage />} />
          <Route path={'free-delivery/*'} element={<FreeDeliveryPage />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default ManagerView;
