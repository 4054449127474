import React, { useEffect, useState } from 'react';
import {
  Image,
  Select,
  Button,
  Row,
  Col,
  Radio,
  Space,
  Input,
  Form,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './style.scss';
import { capitalizeFirstLetter } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { OrderRefundReasonsNames } from 'constants/orders';

const { Option } = Select;

const CashierOrderCard = ({
  data = {},
  onChange,
  onRemoveItem,
  isDisabled,
  order,
  isShowMode,
  article,
  onChangesSize,
  length,
  refound,
  admin,
  onChangeRefundItem,
  hideActionButtons,
}) => {
  const { t } = useTranslation();
  const [isModify, setIsModify] = useState(false);
  const [showReason, setShowReason] = useState(!data?.refunded);
  const [sizeVariant, setSizeVariants] = useState([]);

  useEffect(() => {
    if (data?.size_variants) {
      setSizeVariants(data.size_variants);
    }
  }, [data]);

  const onClickModify = () => setIsModify((s) => !s);

  const disableActions =
    order.status === 'waiting_for_products_refund' &&
    !!data?.refund_reason_for_rejection;

  return (
    <div className='cashier-order-card'>
      <Image
        width={75}
        // src='https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        src={data?.image}
      />
      <div className='cashier-card-body'>
        <div className='cashier-card-description'>
          <div>
            <div className='card-order-number'>
              Articolo {article} di {length}
            </div>
            <div className='card-order-title'>{data.title}</div>
          </div>
          <div className='card-order-details'>
            <p style={{ marginBottom: 6 }}>
              <b>SKU.</b> {data?.product_number}
            </p>
            <p style={{ marginBottom: 6 }}>
              <b>Quantità:</b> {refound ? data.wants_a_refund : data.count}
            </p>
            {refound && (
              <p style={{ marginBottom: 6 }}>
                <b>Motivo del reso: </b>
                {OrderRefundReasonsNames[data.refund_reason]}
              </p>
            )}
            <p style={{ marginBottom: 6 }}>
              <b>Prezzo:</b> {data.retail_price} Euro
            </p>
            {/*{data?.retail_price && data?.retail_price !== '0,00' && (*/}
            {/*  <p style={{ marginBottom: 6 }}>*/}
            {/*    <b>{t('home.productsTable.retail_price')}:</b>{' '}*/}
            {/*    {data?.retail_price} Euro*/}
            {/*  </p>*/}
            {/*)}*/}
            {data?.discounted_price && data?.discounted_price !== '0,00' && (
              <p style={{ marginBottom: 12 }}>
                <b>{t('Prezzo scontato')}:</b> {data?.price} Euro
              </p>
            )}
            {/*<p style={{ marginBottom: 12 }}>*/}
            {/*  <b>{t('main.deliveryPrice')}:</b> {order?.delivery_amount} Euro*/}
            {/*</p>*/}
          </div>
          <div className='card-order-type'>
            <div className='type-color'>
              {/* <div className='type-label'>Colore:</div> */}
              {/*{!isModify && (*/}
              {/* <div className='order-type-placeholder'>
                {capitalizeFirstLetter(data.main_color || '----')}
              </div> */}
              {/*)}*/}
              {/*{isModify && (*/}
              {/*<Select*/}
              {/*  defaultValue={data.main_color}*/}
              {/*  style={{ width: 206 }}*/}
              {/*  size='large'*/}
              {/*  value={data.main_color}*/}
              {/*  onChange={(value) => handleChange(value, 'main_color')}*/}
              {/*>*/}
              {/*  {filters.main_colors?.map((i) => (*/}
              {/*    <Option value={i}>{capitalizeFirstLetter(i)}</Option>*/}
              {/*  ))}*/}
              {/*</Select>*/}
              {/*)}*/}
            </div>
            {/* <div className='type-size'>
              <div className='type-label'>Taglia:</div>
              {!isModify && (
                <div className='order-type-placeholder'>
                  {data?.size || '----'}
                </div>
              )}
              {isModify && (
                <Select
                  defaultValue={
                    data.size_variants?.find((i) => i.size === data.size)
                      ?.product_variant_id
                  }
                  style={{ width: 206 }}
                  size='large'
                  onChange={(value) =>
                    !isDisabled && onChangesSize(data, value)
                  }
                >
                  {data.size_variants?.map((i) => (
                    <Option value={i.product_variant_id}>{i.size}</Option>
                  ))}
                  {filters.sizes?.map((i) => (
                    <Option value={i}>{capitalizeFirstLetter(i)}</Option>
                  ))}
                </Select>
              )}
            </div> */}
          </div>
          {refound && !admin && (
            <Row justify={'middle'} style={{ marginTop: 20 }}>
              <Col>
                <Radio.Group
                  disabled={hideActionButtons || disableActions}
                  onChange={(...rest) => {
                    // onChange(...rest, data);
                    onChangeRefundItem(
                      {
                        name: 'refunded',
                        value: rest[0].target.value === 'refound',
                      },
                      data,
                    );
                    setShowReason(rest[0].target.value === 'cancele');
                  }}
                  defaultValue={data?.refunded ? 'refound' : 'cancele'}
                >
                  <Space direction='vertical'>
                    <Radio value={'refound'}>{t('Approva reso')}</Radio>
                    <Radio value={'cancele'}>{t('Rifiuta reso')}</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          )}
          {showReason && refound && !admin && (
            <Row justify={'middle'} style={{ marginTop: 20 }}>
              <Col span={24}>
                <Typography.Paragraph>{'Motivo'}</Typography.Paragraph>
                <Input.TextArea
                  defaultValue={data.refund_reason_for_rejection}
                  disabled={hideActionButtons || disableActions}
                  rows={3}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChangeRefundItem(
                      { name: 'refund_reason_for_rejection', value },
                      data,
                    );
                  }}
                />
              </Col>
            </Row>
          )}
        </div>

        {isShowMode ? (
          <></>
        ) : (
          <div className='cashier-card-actions'>
            {!isModify && (
              <Button
                onClick={onClickModify}
                type='primary'
                className='order-modify-btn'
              >
                Modifica
              </Button>
            )}
            {isModify && (
              <Button
                onClick={!isDisabled ? onClickModify : () => {}}
                type='primary'
                className='order-modify-btn order-save-btn'
              >
                Salva
              </Button>
            )}
            <Button
              type=''
              disabled={isModify || length === 1}
              className='order-remove-btn'
              onClick={() => !isDisabled && onRemoveItem(data)}
              icon={<DeleteOutlined />}
            >
              Rimuovi articolo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CashierOrderCard;
