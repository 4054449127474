import { Col, Row, Space, Typography } from 'antd';
import Input from 'antd/es/input/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShowError from '../../../../../components/showError';

const PrestashopForm = ({ eCommerceData, handleChangeData }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={14}>
        <Space direction={'vertical'}>
          <Typography.Text strong>{t('Prestashop api url')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'prestashop_api_url'}
            value={eCommerceData.prestashopData.prestashop_api_url}
            status={eCommerceData?.errors['prestashop_api_url'] ? 'error' : ''}
          />
          <ShowError errors={eCommerceData?.errors['prestashop_api_url']} />
          <Typography.Text strong>{t('Prestashop api key')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'prestashop_api_key'}
            value={eCommerceData.prestashopData.prestashop_api_key}
            status={eCommerceData?.errors['prestashop_api_key'] ? 'error' : ''}
          />
          <ShowError errors={eCommerceData?.errors['prestashop_api_key']} />
          <Typography.Text strong>
            {t('Prestashop customer group')}
          </Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'prestashop_customer_group_id'}
            value={eCommerceData.prestashopData.prestashop_customer_group_id}
            status={
              eCommerceData?.errors['prestashop_customer_group_id']
                ? 'error'
                : ''
            }
          />
          <ShowError
            errors={eCommerceData?.errors['prestashop_customer_group_id']}
          />
          <Typography.Text strong>{t('Prestashop carrier')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'prestashop_carrier_id'}
            value={eCommerceData.prestashopData.prestashop_carrier_id}
            status={
              eCommerceData?.errors['prestashop_carrier_id'] ? 'error' : ''
            }
          />
          <ShowError errors={eCommerceData?.errors['prestashop_carrier_id']} />
          <Typography.Text strong>{t('Prestashop lang')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'prestashop_lang_id'}
            value={eCommerceData.prestashopData.prestashop_lang_id}
            status={eCommerceData?.errors['prestashop_lang_id'] ? 'error' : ''}
          />
          <ShowError errors={eCommerceData?.errors['prestashop_lang_id']} />
        </Space>
      </Col>
    </Row>
  );
};

export default PrestashopForm;
