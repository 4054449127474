import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import { useNavigate } from 'hooks/useNavigate';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import UploadProductsPage from './upload-products';
import SynchronizeECommerce from './synchronize-e-commerce';
import Articles from './articles';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import ModerationPage from './moderation';
import ShopsApi from 'libs/api/shops';

const api = new ShopsApi();

const ManagerView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(getUser);

  const [counts, setCounts] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await api.countProductsImports(user?.currentShop?.id);
        setCounts(data?.data);
      } catch (e) {
        console.log('error', e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const sectionData = useMemo(() => {
    if (!user.currentShop.merchant || user.isCashier) {
      return [
        {
          title: t('Carica prodotti'),
          description: '',
          btnText: 'Carica',
          btnAction: () => navigate('upload-products'),
          hide: !user.isManager,
        },
        {
          title: t('I tuoi prodotti'),
          description: '',
          btnText: t('Vedi elenco'),
          btnAction: () => {
            navigate('articles');
          },
        },
      ];
    }

    return [
      {
        title: t('Carica prodotti'),
        description: '',
        btnText: 'Carica',
        btnAction: () => navigate('upload-products'),
        hide: !user.isManager,
      },
      {
        title: t('I tuoi prodotti'),
        description: '',
        btnText: t('Vedi elenco'),
        btnAction: () => {
          navigate('articles');
        },
      },
      {
        title: t('Match custom fields'),
        description: '',
        count: counts?.attributes_count,
        btnText: t('Prosegui'),
        btnAction: () => {
          navigate('/products/synchronize-e-commerce/step3?only=true');
        },
      },
      {
        title: t('Match categorie'),
        description: '',
        count: counts?.categories_count,
        btnText: t('Prosegui'),
        btnAction: () => {
          navigate('/products/synchronize-e-commerce/step4?only=true');
        },
      },
      {
        title: t('Match colori'),
        description: '',
        count: counts?.colors_count,
        btnText: t('Prosegui'),
        btnAction: () => {
          navigate('/products/synchronize-e-commerce/step5?only=true');
        },
      },
    ];
  }, [t, user, navigate]);

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection
          loading={loading}
          title={t('Catalogo prodotti')}
          sectionData={sectionData}
        />
      </Col>
      <Col span={18}>
        <Routes>
          <Route path={'upload-products/*'} element={<UploadProductsPage />} />
          <Route
            path={'synchronize-e-commerce/*'}
            element={<SynchronizeECommerce />}
          />
          <Route path={'articles/*'} element={<Articles />} />
          <Route path={'moderation/*'} element={<ModerationPage />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default ManagerView;
