import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import PromotionsApi from 'libs/api/promotions';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import UIHelper from 'helpers/UIHelper';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'icons';
import { getUser } from 'hooks/useUser';

const api = new PromotionsApi();

const usePromotions = ({ isMainPage = true, isEdit } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const user = useRecoilValue(getUser);
  const { t } = useTranslation();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
  } = useApi({
    resourceKey: 'promotions',
    singleResourceKey: 'promotion',
    api,
    isMainPage,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      render: (_, row) => (
        <Row
          gutter={16}
          justify={'left'}
          style={{ minWidth: 150 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Col>
            <Link to={`${row.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </Link>
          </Col>
          <Col>
            <Link to={`${row.id}/edit`}>
              <Button icon={<EditIcon />} />
            </Link>
          </Col>
          <Col>
            <Link to={`${row.id}/delete`}>
              <Button icon={<DeleteIcon />} />
            </Link>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Titolo'),
      dataIndex: 'title',
    },
    {
      title: t('Descrizione'),
      dataIndex: 'description',
    },
    {
      title: 'total_promotion_amount_cents',
      dataIndex: 'total_promotion_amount_cents',
    },
    {
      title: 'value_cents',
      dataIndex: 'value_cents',
    },
    {
      title: 'order_sum_amount',
      dataIndex: 'order_sum_amount',
    },
    {
      title: 'order_sum_count',
      dataIndex: 'order_sum_count',
    },
    {
      title: 'order_more_than_amount',
      dataIndex: 'order_more_than_amount',
    },
    {
      title: 'max_signup_invites',
      dataIndex: 'max_signup_invites',
    },
    {
      title: 'order_type',
      dataIndex: 'order_type',
      render: (data) => UIHelper.humanizeString(data),
    },
    {
      title: 'promotion_type',
      dataIndex: 'promotion_type',
      render: (data) => UIHelper.humanizeString(data),
    },
    {
      title: 'start_date',
      dataIndex: 'start_date',
      render: (data) => UIHelper.formattedDate(data),
    },
    {
      title: 'end_date',
      dataIndex: 'end_date',
      render: (data) => UIHelper.formattedDate(data),
    },
  ];

  const filters = useMemo(() => {
    return [
      // {
      //   title: t('Cerca membro'),
      //   type: 'input',
      //   name: 'by_email',
      //   values: [],
      // },
      // {
      //   title: t('Filtro ruolo'),
      //   type: 'select',
      //   name: 'by_role',
      //   values: [
      //     { value: '', title: t('Selezioni role') },
      //     ...filterData.available_admin_roles,
      //   ],
      // },
    ];
  }, [t, filterData]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          promotion: {
            ...values,
            user_id: user.id,
          },
        };
        if (!id) {
          await api.create({ data });
        } else {
          await api.put(id, { data });
        }
        navigate('/promotions/list');
      } catch (e) {
        form.setFields(ErrorHelper.getErrorsForForm(e?.response?.data || {}));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [form, navigate, setUpdateInProcess, user],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          case 'start_date':
          case 'end_date':
            fieldsValues.push({
              name: key,
              value: UIHelper.formattedDate(singleItem[key], false),
            });
            break;
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleItem, isEdit]);

  return {
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
  };
};

export default usePromotions;
