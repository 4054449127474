import { useEffect, useState } from 'react';
import OrdersConfirmationApi from '../../../../../libs/api/ordersConfirmationApi';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

import './style.scss';

export const CalendarModal = ({
  data,
  handleClick,
  setFlowData,
  value,
  setValue,
}) => {
  const changeCalendar = (event) => {
    setValue(moment(event).format('YYYY-MM-DD'));
  };

  return (
    <div>
      <Calendar
        locale='it-IT'
        formatMonthYear={(locale, date) => {
          const parts = date.toDateString().split(' ');
          const month = parts[1];
          return month;
        }}
        onChange={changeCalendar}
        value={value}
      />
    </div>
  );
};
