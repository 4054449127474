import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useFilter } from 'hooks/useFilter';
import { useApi } from 'hooks/useApi';
import {
  getOrderTypeText,
  getOrderStatusText,
  timeFormat,
} from 'helpers/utils';

import OrdersApi from 'libs/api/orders';
import Helpers from 'helpers';
import { useNavigate } from 'hooks/useNavigate';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import useDataExport from '../../../data-export/adminView/useDataExport';
import { OrderTypeEnums } from '../../../../constants/orders';

const api = new OrdersApi();

const useOrders = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const {
    downloadFile,
    downloadTypes,
    loading: ordersExportLoading,
  } = useDataExport();
  const { searchParams } = useFilter();
  const navigate = useNavigate();
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [additionalFilters, setAdditionalFilters] = useState({
    status: 'completed,expired,canceled',
  });
  const { loading, data, onChangeTableFilter, updateInProcess, singleItem } =
    useApi({
      resourceKey: 'orders',
      singleResourceKey: 'order',
      api,
      isMainPage: true,
      additionalFilters,
      findByAdditionalId: currentOrderId,
    });

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: 'id',
        width: 80,
        render: (data) => (
          <Button
            onClick={() => {
              navigate(`view/${data}`);
            }}
            type={'primary-white'}
            icon={<EyeOutlined />}
          />
        ),
      },
      {
        title: t('ID ordine'),
        dataIndex: 'id',
        sorter: () => {},
        width: 200,
        render: (data, row) => row.order_id,
      },
      {
        title: t('Stato ordine'),
        dataIndex: 'status',
        sorter: () => {},
        width: 200,
        render: (data) => getOrderStatusText(data),
      },
      {
        title: t('Tipo ordine (servizio)'),
        dataIndex: 'order_type',
        width: 200,
        sorter: () => {},
        render: (data, row) => getOrderTypeText(data, row?.is_stock_picking),
      },
      {
        title: t('Data ordine'),
        width: 250,
        dataIndex: 'created_at',
        sorter: () => {},
        render: (data) => timeFormat(data),
      },
      {
        title: t('Codice cliente'),
        width: 200,
        dataIndex: 'user_id',
        sorter: () => {},
        render: (data, row) => row.customer_id,
      },
      {
        title: t('Nome'),
        width: 200,
        sorter: () => {},
        dataIndex: 'name',
      },
      {
        title: t('Cognome'),
        width: 200,
        sorter: () => {},
        dataIndex: 'surname',
      },
      {
        title: t('Email'),
        width: 200,
        sorter: () => {},
        dataIndex: 'email',
      },
      {
        title: t('Cellulare'),
        width: 200,
        dataIndex: 'phone',
        sorter: () => {},
      },
      {
        title: t('Nr articoli'),
        width: 200,
        dataIndex: 'product_variants_count',
        sorter: () => {},
        render: (data, row) => row.count,
      },
      {
        title: t('Importo ordine €'),
        width: 200,
        dataIndex: 'amount_cents',
        render: (data, row) => Helpers.numberWithCommas(row.final_amount, ' €'),
        sorter: () => {},
      },
      {
        title: t('Costo spedizione'),
        width: 200,
        dataIndex: 'delivery_amount_cents',
        render: (data, row) =>
          Helpers.numberWithCommas(row.delivery_amount, ' €'),
        sorter: () => {},
      },
      {
        title: t('Insegna'),
        width: 200,
        dataIndex: 'shops.title',
        render: (data, row) => row?.shop?.title || '-',
        sorter: () => {},
      },
      {
        title: t('Info avvenuto pagamento'),
        width: 200,
        dataIndex: 'payments.status',
        sorter: () => {},
        render: (data, row) => {
          return (
            (row.payment_status === 'succeeded' && 'Effettuato') ||
            (row.payment_status === 'paid' && 'Pagato') ||
            (row.payment_status === 'unpaid' && 'Non pagato')
          );
        },
      },
      {
        title: t('Indirizzo spedizione'),
        width: 200,
        sorter: () => {},
        dataIndex: 'address',
        render: (data, row) => {
          if (row.order_type === OrderTypeEnums.click_and_ship) {
            return `${row.country || ''} ${row.province || ''} ${
              row.city || ''
            } ${row.zip_code || ''} ${row.address || ''} ${row.building || ''}`;
          }
          return '-';
        },
      },
      {
        title: t('Codice spedizione'),
        dataIndex: 'track_number',
        width: 200,
        render: (data, row) => {
          return row.track_number || '';
        },
        sorter: true,
      },
    ],
    [t, navigate],
  );
  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca ordine'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data ordine'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  const handleChangeTab = (key) => {
    switch (key) {
      case 'all': {
        setAdditionalFilters({
          status: 'completed,expired,canceled',
        });
        break;
      }
      case 'completed': {
        setAdditionalFilters({
          status: 'completed,shipped',
        });
        break;
      }
      case 'expired': {
        setAdditionalFilters({
          status: 'expired',
        });
        break;
      }
      case 'canceled': {
        setAdditionalFilters({
          status: 'canceled',
        });
        break;
      }
    }
  };

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalFilters },
      downloadTypes.orders,
    );
  }, [searchParams, downloadTypes, additionalFilters]);

  return {
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    ordersExportLoading,
    handleExport,
  };
};

export default useOrders;
