import { useEffect, useMemo } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import styles from '../style.module.scss';
import AdminView from '../adminView';
import ManagerView from '../managerView';
import useBreadcrumb from 'hooks/useBreadcrumb';


const ProductsPage = () => {
  const user = useRecoilValue(getUser);
  const { setBreadcrumb } = useBreadcrumb();

  const breadcrumb = useMemo(() => {
    if (user.isAdmin) {
      return [
        {
          title: 'Super admin',
          link: '#',
        },
        {
          title: 'Ordini e resi',
          link: '/orders',
        },
      ];
    }

    if (user.isOwner) {
      return [
        {
          title: 'Owner',
          link: '#',
        },
        {
          title: 'Ordini e resi',
          link: '/orders',
        },
      ];
    }

    return [
      {
        title: user?.currentShop?.title,
        link: '#',
      },
      {
        title: 'Ordini e resi',
        link: '/orders  ',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
  }, [breadcrumb]);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        {(user.isManager || user.isCashier) && <ManagerView />}
        {(user.isAdmin || user.isOwner) && <AdminView />}
      </div>
    </Layout>
  );
};

export default ProductsPage;
