import styles from './style.module.scss';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <Spin spinning={true} />
    </div>
  );
};

export default Loading;
