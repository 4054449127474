import React, { useEffect, useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useSteps from 'hooks/useSteps';
import SetRoutes from 'components/setRoutes';
import routes from './routes';
import useECommerce from './useECommerce';

const SynchronizeECommerce = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { setStep, step } = useSteps();

  const { handleSetDefaultState, eCommerceData } = useECommerce();

  useLayoutEffect(() => {
    removeLastBreadcrumb('/products/upload-products');
    addNewBreadcrumb(
      t('Sincronizza articoli da e-commerce'),
      '/products/synchronize-e-commerce',
    );
    return () => {
      removeLastBreadcrumb('/products/synchronize');
    };
  }, []);

  console.log('step', eCommerceData.currentECommerceType);

  useEffect(() => {
    setStep({
      show: true,
      count: eCommerceData.currentECommerceType === 'shopify' ? 7 : 6,
      current: 1,
    });
    return () => {
      setStep({
        show: false,
        count: 5,
        current: 1,
      });
    };
  }, [eCommerceData.currentECommerceType]);

  useEffect(() => {
    return () => {
      handleSetDefaultState();
    };
  }, []);

  return (
    <Card
      className={styles.card}
      title={t('Sincronizza articoli da e-commerce')}
    >
      <SetRoutes routes={routes} />
    </Card>
  );
};

export default SynchronizeECommerce;
