import axios from 'axios';
import UIHelper from 'helpers/UIHelper';
import { t } from 'i18next';
import Api from 'libs/api';

const api = new Api();

const handleDownloadPDF = async (shop_id, prod_id) => {
  console.log('Got into DownloadPDF', shop_id, prod_id);
  try {
    const url = await api.getPDFReceipt(shop_id, prod_id);
    console.log('PDF URL:', url);

    let downloadUrl;
    let filename = `receipt_${shop_id}_${prod_id}.pdf`;

    if (typeof url === 'string') {
      const response = await axios.get(url, { responseType: 'blob' });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      downloadUrl = window.URL.createObjectURL(pdfBlob);
    } else {
      downloadUrl = window.URL.createObjectURL(url);
    }

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    UIHelper.openNotificationWithIcon(
      'success',
      'L’operazione e’ stata completata',
    );
  } catch (e) {
    const error = e?.response?.data?.errors || [
      t('Non e’ stato possibile creare l’etticheta'),
    ];
    UIHelper.openNotificationWithIcon('error', error.join('\n'));
  }
};
export default handleDownloadPDF;
