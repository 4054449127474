import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Modal, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CashierOrderCard from './order-card';
import {
  getProductStatusTest,
  getOrderTypeText,
  timeFormat,
  getOrderStatusText,
} from 'helpers/utils';
import classNames from 'classnames';
import Button from '../button';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import OrdersConfirmationApi from '../../libs/api/ordersConfirmationApi';
import ShopsApi from '../../libs/api/shops';
import { OrderTypeEnums } from '../../constants/orders';
import { useNavigate } from '../../hooks/useNavigate';
import UIHelper from 'helpers/UIHelper';

const shopApi = new ShopsApi();

const InfoView = ({ value, title }) => {
  if (!value) return null;

  return (
    <p>
      <strong>{title}</strong>: {value}
    </p>
  );
};

const OrderViewModalConfirm = ({
  onClose,
  admin,
  handelSubmitSuccess,
  edit,
  isConfirm,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [shop, setShop] = useState({
    invoice_company_name: undefined,
  });
  const [order, setOrder] = useState({
    final_amount: undefined,
    invoice_code_sdi: undefined,
    invoice_pec: undefined,
    invoice_company_code: undefined,
    invoice_tax_code: undefined,
    invoice_surname: undefined,
    invoice_name: undefined,
    invoice_company: undefined,
    invoice_address: undefined,
    zip_code: undefined,
    mall_title: undefined,
    shop_title: undefined,
    delivery_amount: undefined,
    is_stock_picking: undefined,
    customer_id: undefined,
    created_at: undefined,
    payment: undefined,
  });
  const [variants, setVariants] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const length = variants?.length || 0;
  let orderNumber = '';

  const orderId = searchParams.get('order');
  const shopId = searchParams.get('shop_id');

  const confirmationApi = useMemo(
    () => new OrdersConfirmationApi(shopId),
    [shopId],
  );

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { shop: shopD },
        } = await shopApi.get(shopId);
        setShop(shopD);
        const { data } = await confirmationApi.get(orderId);
        setOrder(data.order);
        const variants = [];
        data.product_variants?.forEach((i) => {
          const productOrder = data.order_product_variants.find(
            (variantOrder) => variantOrder.product_variant_id === i.id,
          );
          if (productOrder) {
            variants.push({
              ...i,
              count: productOrder.count,
            });
          } else {
            variants.push({
              ...i,
            });
          }
        });
        setVariants(variants);
        setRequestData(() => {
          const result = [];
          data.order_product_variants?.forEach((i) => {
            result.push({
              product_variant_id: i.product_variant_id,
              // TODO ask this moment
              count: i.count,
            });
          });
          return result;
        });
      } catch (e) {
        console.log(e);
        if (e?.response?.status === 404) {
          navigate('/404');
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback((item, data) => {
    setVariants((s) => {
      return s.map((i) => {
        if (i.id === item.id) {
          return { ...i, [data.name]: data.value };
        }
        return i;
      });
    });
    setRequestData((s) => {
      const find = s.find((i) => i.product_variant_id === item.id);
      if (!find) {
        s.push({
          product_variant_id: item.id,
          [data.name]: data.value,
        });
        return s;
      }
      return s.map((i) => {
        if (i.product_variant_id === item.id) {
          return { ...i, [data.name]: data.value };
        }
        return i;
      });
    });
  }, []);

  const onChangesSize = useCallback((item, newItemId) => {
    const matchVariant = item.size_variants?.find(
      (i) => i.product_variant_id === parseInt(newItemId),
    );
    if (!matchVariant) return;
    setRequestData((s) => {
      return s.map((i) => {
        if (i.product_variant_id === parseInt(item.id)) {
          return { ...i, product_variant_id: parseInt(newItemId) };
        }
        return i;
      });
    });
    setVariants((s) => {
      return s.map((i) => {
        if (i.id === item.id) {
          return { ...i, size: matchVariant.size };
        }
        return i;
      });
    });
  }, []);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await confirmationApi.put(order.id, {
        data: {
          order_product_variants_attributes: requestData,
        },
      });
      if (order.order_type !== OrderTypeEnums.click_and_ship && !edit) {
        await confirmationApi.complete(order.id);
      }
      navigate('/orders/new');
      handelSubmitSuccess();
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    confirmationApi,
    order.id,
    order.order_type,
    requestData,
    navigate,
    handelSubmitSuccess,
    edit,
  ]);

  const onRemoveItem = useCallback((item) => {
    setVariants((s) => {
      return s.filter((i) => item.id !== i.id);
    });
    setRequestData((s) => {
      return s.filter((i) => i.product_variant_id !== item.id);
      // TODO maybe in feature need remove with this argument
      // const find = s.find((i) => i.product_variant_id === item.id);
      // if (!find) {
      //   s.push({
      //     product_variant_id: item.id,
      //     _destroy: true,
      //   });
      //   return s;
      // }
      //
      // return s.map((i) => {
      //   if (i.product_variant_id === item.id) {
      //     return { ...i, _destroy: true };
      //   }
      //   return i;
      // });
    });

    let price = item.retail_price;
    if (item.discounted_price !== '0,00') {
      price = item.discounted_price;
    }

    setOrder((s) => ({
      ...s,
      amount: (parseInt(s.amount) - parseInt(price))
        .toFixed(2)
        .replace('.', ','),
    }));
  }, []);

  // eslint-disable-next-line
  const isPayed =
    order?.payment?.status === 'paid' ||
    order?.payment?.status === 'Effettuato';

  return (
    <Modal visible={true} width={'100%'} onCancel={onClose} footer={false}>
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {getOrderTypeText(order?.order_type, order?.is_stock_picking)}
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Title level={5} strong>
              {shop.title}
            </Typography.Title>
          </Col>
        </Row>
        <div className={styles.modalBody} style={{ flexDirection: 'column' }}>
          <div style={{ flex: 1 }}>
            {variants?.map((i, index) => (
              <Fragment key={i.id}>
                <CashierOrderCard
                  admin={admin}
                  isShowMode={order.status === 'shipped'}
                  article={index + 1}
                  length={length}
                  data={i}
                  order={order}
                  onChange={onChange}
                  onRemoveItem={onRemoveItem}
                  onChangesSize={onChangesSize}
                />
                <Divider
                  style={{
                    margin: '32px 0px',
                  }}
                />
              </Fragment>
            ))}
          </div>
          <div
            className={classNames(
              styles.orderInfoBlock,
              styles.orderInfoBlock_line,
            )}
          >
            {order.status && (
              <p>
                <strong>{t('main.orderStatus')}</strong>:{' '}
                {getOrderStatusText(getProductStatusTest(order.status))}
              </p>
            )}
            {order.order_type && (
              <p>
                <strong>Tipo ordine</strong>:{' '}
                {getOrderTypeText(order.order_type, order?.is_stock_picking)}
              </p>
            )}
            {order.created_at && (
              <p>
                <strong>Data ordine</strong>: {timeFormat(order.created_at)}
              </p>
            )}
            {order.customer_id && (
              <p>
                <strong>Codice cliente</strong>: {order.customer_id}
              </p>
            )}
            {order.name && (
              <p>
                <strong>Nome</strong>: {order.name}
              </p>
            )}
            {order.surname && (
              <p>
                <strong>Cognome</strong>: {order.surname}
              </p>
            )}
            {order.email && (
              <p>
                <strong>Email</strong>: {order.email}
              </p>
            )}
            {order.phone && (
              <p>
                <strong>Cellulare</strong>: {order.phone}
              </p>
            )}
            {order.count && (
              <p>
                <strong>Nr articoli</strong>: {order.count}
              </p>
            )}
            {order.amount && (
              <p>
                <strong>Importo ordine €</strong>: {order.amount}
              </p>
            )}
            {order?.delivery_amount && (
              <p style={{ marginBottom: 12 }}>
                <b>Costo spedizione:</b> {order?.delivery_amount} Euro
              </p>
            )}
            {order.shop_title && (
              <p>
                <strong>{t('home.productsTable.shop')}</strong>:{' '}
                {order.shop_title}
              </p>
            )}
            {order.mall_title && (
              <p>
                <strong>{t('home.productsTable.mall')}</strong>:{' '}
                {order.mall_title}
              </p>
            )}
            {order.mall_title && (
              <p>
                <strong>{t('currentOrder.table.orderNumber')}</strong>:{' '}
                {orderNumber}
              </p>
            )}
            {order?.address && (
              <InfoView
                value={`${order?.address}, ${order?.city}, ${order?.zip_code}`}
                title={'Indirizzo di spedizione'}
              />
            )}
            {order?.invoice_company && (
              <InfoView
                value={order?.invoice_company}
                title={t('main.invoice_company')}
              />
            )}
            {order?.invoice_address && (
              <InfoView
                value={order?.invoice_address}
                title={'Indirizzo di fatturazione'}
              />
            )}
            <InfoView
              value={order?.invoice_company}
              title={t('main.invoice_company')}
            />
            <InfoView
              value={order?.invoice_name}
              title={t('main.invoice_name')}
            />
            <InfoView
              value={order?.invoice_surname}
              title={t('main.invoice_surname')}
            />
            <InfoView
              value={order?.invoice_tax_code}
              title={t('main.invoice_tax_code')}
            />
            <InfoView
              value={order?.invoice_company_name}
              title={t('main.invoice_company_name')}
            />
            <InfoView
              value={order?.invoice_company_code}
              title={t('main.invoice_company_code')}
            />
            <InfoView
              value={order?.invoice_pec}
              title={t('main.invoice_pec')}
            />
            <InfoView
              value={order?.invoice_code_sdi}
              title={t('main.invoice_code_sdi')}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.orderFooterLeftSide}>
            <InfoCircleOutlined className={styles.infoIcon} />
            <div className={styles.orderFooterLeftSideTitles}>
              {/* <span className='footer-title'>
              L'ordine {isPayed ? '' : 'non '}è stato pagato
            </span> */}
              <span className={styles.footerPrice}>
                Importo dell'ordine: {order?.final_amount} Euro{' '}
              </span>
            </div>
          </div>
          <Button onClick={onSubmit}>
            {isConfirm ? 'Evadi ordine' : 'Salva modifiche'}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default OrderViewModalConfirm;
