import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OrdersApi from 'libs/api/orders';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useApi } from 'hooks/useApi';
import { timeFormat } from 'helpers/utils';
import Helpers from 'helpers';
import useDataExport from '../data-export/adminView/useDataExport';
import { useFilter } from 'hooks/useFilter';

const api = new OrdersApi();

const useBilling = () => {
  const { t } = useTranslation();
  const { loading: ordersExportLoading, handleDownloadExcelBillings } =
    useDataExport();
  const { searchParams } = useFilter();
  const user = useRecoilValue(getUser);
  const [additionalFilters, setAdditionalFilters] = useState({
    orders_type: 'orders_billing',
  });
  const { loading, data, onChangeTableFilter } = useApi({
    resourceKey: 'orders',
    singleResourceKey: 'order',
    api,
    isMainPage: true,
    additionalFilters,
  });

  const columns = useMemo(
    () => [
      {
        title: t('Data ordine'),
        dataIndex: 'created_at',
        sorter: () => {},
        render: (data) => timeFormat(data),
      },
      {
        title: t('Codice ordine'),
        dataIndex: 'order_id',
        sorter: () => {},
      },
      {
        title: t('Totale ordine'),
        dataIndex: 'final_amount',
        sorter: () => {},
        render: (data) => Helpers.numberWithCommas(data, ' €'),
      },
      {
        title: t('Commissione DUC'),
        dataIndex: 'duc_commission',
        sorter: () => {},
        render: (data, row) => row?.duc_commission,
      },
    ],
    [],
  );

  const columnsAdmin = [
    {
      title: t('Data ordine'),
      dataIndex: 'created_at',
      sorter: () => {},
      render: (data) => timeFormat(data),
    },
    {
      title: t('Codice ordine'),
      dataIndex: 'order_id',
      sorter: () => {},
    },
    {
      title: t('Insegna ordine'),
      sorter: () => {},
      dataIndex: 'shops.title',
      render: (data, row) => row?.shop?.title,
    },
    {
      title: t('Totale ordine'),
      dataIndex: 'final_amount',
      sorter: () => {},
      render: (data) => Helpers.numberWithCommas(data, ' €'),
    },
    {
      title: t('Commissione DUC'),
      dataIndex: 'duc_commission',
      sorter: () => {},
      render: (data) => `${data} €`,
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca ordine'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data ordine'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  useEffect(() => {
    if (user?.currentShop?.id && !user?.isAdmin) {
      setAdditionalFilters((s) => {
        if (s.shop_id !== user?.currentShop?.id) {
          return {
            shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id, user?.isAdmin]);

  const handleExport = useCallback(
    async (async) => {
      const currentParams = Object.fromEntries([...searchParams]);
      await handleDownloadExcelBillings(
        {
          ...currentParams,
          ...additionalFilters,
          export_type: 'orders_billing',
        },
        'Fatture',
      );
    },
    [searchParams, additionalFilters, handleDownloadExcelBillings],
  );

  return {
    columnsAdmin,
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    user,
    ordersExportLoading,
    handleExport,
  };
};

export default useBilling;
