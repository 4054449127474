import { useEffect, useMemo } from 'react';
import Layout from 'components/layout';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import styles from '../style.module.scss';
import AdminView from '../adminView';
import ManagerView from '../managerView';
import useBreadcrumb from 'hooks/useBreadcrumb';

const TeamPage = () => {
  const user = useRecoilValue(getUser);
  const { setBreadcrumb } = useBreadcrumb();

  const breadcrumb = useMemo(() => {
    if (user.isAdmin) {
      return [
        {
          title: 'Super admin',
          link: '#',
        },
        {
          title: 'Team',
          link: '',
        },
      ];
    }

    if (user.isOwner) {
      return [
        {
          title: 'Owner',
          link: '#',
        },
        {
          title: 'Team',
          link: '',
        },
      ];
    }

    return [
      {
        title: user?.currentShop?.title,
        link: '#',
      },
      {
        title: 'Team',
        link: '/team  ',
      },
    ];
  }, [user]);

  useEffect(() => {
    setBreadcrumb(breadcrumb);
  }, []);

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className={styles.container}>
        {user.isManager && <ManagerView />}
        {(user.isAdmin || user.isOwner) && <AdminView />}
      </div>
    </Layout>
  );
};

export default TeamPage;
