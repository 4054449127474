import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from 'hooks/useBreadcrumb';
import Filters from 'components/filters';
import useTeam from '../useTeam';
import { Route, Routes } from 'react-router-dom';
import DeleteModal from 'components/deleteModalView';
import ViewModal from './viewModal';

const TeamList = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    filters,
    columns,
    data,
    loading,
    onChangeTableFilter,
    updateInProcess,
    onDelete,
    singleItem,
  } = useTeam(true);

  useLayoutEffect(() => {
    removeLastBreadcrumb('/products/articles');
    addNewBreadcrumb(t('Elenco membri del team'), '/products/articles');
    return () => {
      removeLastBreadcrumb('/products/articles');
    };
  }, []);

  return (
    <Card className={styles.card} title={t('Elenco membri del team')}>
      <Row>
        <Col span={24}>
          <Filters filters={filters} />
        </Col>
        <Col span={24}>
          <br />
          <Table
            bordered
            dataSource={data.users}
            pagination={data.pagination}
            onChange={onChangeTableFilter}
            columns={columns}
            scroll={{ x: 800 }}
            loading={loading}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path={':id/delete'}
          element={
            <DeleteModal loading={updateInProcess} onClickDelete={onDelete} />
          }
        />
        <Route
          path={':id/view'}
          element={<ViewModal singleItem={singleItem} />}
        />
      </Routes>
    </Card>
  );
};

export default TeamList;
