import { Layout as AntLayout, Spin } from 'antd';
import styles from './style.module.scss';
import Footer from '../footer';
import Sidebar from './sidebar';
import Header from './header';
import classNames from 'classnames';
import useSteps from '../../hooks/useSteps';
import { useFilter } from '../../hooks/useFilter';
import { getPendingRequestState } from 'hooks/useApi';
import { useRecoilValue } from 'recoil';

const { Content } = AntLayout;

const Layout = ({ children, hideLayout, hideSideBar, breadcrumb = [] }) => {
  const { step } = useSteps();
  const { inProgress } = useRecoilValue(getPendingRequestState);
  useFilter('', true);
  if (hideLayout) {
    return children;
  }

  return (
    <AntLayout hasSider>
      {!hideLayout && !hideSideBar && <Sidebar />}
      <AntLayout
        className={classNames(
          styles.siteLayout,
          (hideLayout || hideSideBar) && styles.siteLayoutHide,
        )}
      >
        {!hideLayout && (
          <Header breadcrumb={breadcrumb} fullWidth={hideSideBar} />
        )}
        <Content
          className={classNames(
            styles.content,
            step.show && styles.contentWithSteps,
            hideLayout && styles.contentHide,
          )}
        >
          {/*<Spin spinning={inProgress} className={styles.spin}>*/}
          <div className={styles.container}>{children}</div>
          {/*</Spin>*/}
          <Footer />
        </Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
