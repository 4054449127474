import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Col, Descriptions, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useProfile from '../useProfile';

const OwnerView = () => {
  const { t } = useTranslation();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { ownerData, loading } = useProfile({
    isMainPage: false,
    isOwnerPage: true,
  });

  useLayoutEffect(() => {
    addNewBreadcrumb(t('Anagrafica gestore'), '/profile/owner');
    return () => {
      removeLastBreadcrumb('/profile/owner');
    };
  }, []);

  return (
    <Card className={styles.card} title={t('Anagrafica gestore')}>
      <Row className={styles.row} gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={loading}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label={t('Nome')}>
                {ownerData.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('Cognome')}>
                {ownerData.surname}
              </Descriptions.Item>
              <Descriptions.Item label={t('Email')}>
                {ownerData.email}
              </Descriptions.Item>
              <Descriptions.Item label={t('Numero telefono')}>
                {ownerData.phone}
              </Descriptions.Item>

              <Descriptions.Item label={t('Codice fiscale')}>
                {ownerData.tax_number}
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default OwnerView;
