import { Col, Row, Tabs } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';
import ProductStatisticsView from './tabs/product-statistics';
import OrdersAndReturnsStatisticsView from './tabs/orders-and-returns-statistics';
import StoreStatisticsView from './tabs/store-statistics';
import UserStatisticsView from './tabs/userStatistics';

const { TabPane } = Tabs;

const AdminView = () => {
  const navigate = useNavigate();
  const handleChangeTab = (key) => {
    if (key === '/') {
      navigate('/statistica');
    } else {
      navigate(`/statistica/${key}`);
    }
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <Col span={24}>
            <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
              <TabPane tab='Statistiche prodotti' key='/' />
              <TabPane
                tab='Statistiche ordini e resi'
                key='orders-and-returns-statistics'
              />
              <TabPane tab='Statistiche negozi' key='store-statistics' />
              <TabPane tab='Statistiche utenti' key='user-statistics' />
            </Tabs>
          </Col>
          <Col span={24}>
            <Routes>
              <Route exact path={'/'} element={<ProductStatisticsView />} />
              <Route
                path={'/orders-and-returns-statistics'}
                element={<OrdersAndReturnsStatisticsView />}
              />
              <Route
                path={'/store-statistics'}
                element={<StoreStatisticsView />}
              />
              <Route
                path={'/user-statistics'}
                element={<UserStatisticsView />}
              />
            </Routes>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminView;
