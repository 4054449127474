import { useCallback, useEffect, useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import ExportApi from 'libs/api/export';
import Helpers from '../../../helpers';
import UIHelper from '../../../helpers/UIHelper';

const api = new ExportApi();

const downloadTypes = {
  product: 'product',
  orders: 'orders',
  users: 'users',
  billing: 'billing',
  ordersNew: 'ordersNew',
  askToRefund: 'askToRefund',
};

const useDataExport = () => {
  const user = useRecoilValue(getUser);
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState('');
  const [downloadType, setDownloadType] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadId, setUploadId] = useState('');
  const [saveFileName, setSaveFileName] = useState('');

  const sectionDataAdmin = useMemo(
    () => [
      {
        title: t('Export prodotti'),
        description: '',
        btnText: 'Scarica excel',
        btnAction: () => downloadFile(null, null, downloadTypes.product),
        loading: downloadType === downloadTypes.product && loading,
      },
      {
        title: t('Export ordini'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.orders),
        loading: downloadType === downloadTypes.orders && loading,
      },
      {
        title: t('Export clienti'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () =>
          downloadFile(
            null,
            {
              by_role: 'customer',
            },
            downloadTypes.users,
          ),
        loading: downloadType === downloadTypes.users && loading,
      },
      {
        title: t('Export dati fatturazione'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () =>
          handleDownloadExcelBillings(
            {
              export_type: 'orders_billing',
            },
            'Fatture',
          ),
        loading: downloadType === downloadTypes.billing && loading,
      },
    ],
    [t, loading, downloadType],
  );

  const sectionDataManager = useMemo(
    () => [
      {
        title: t('Export prodotti'),
        description: '',
        btnText: 'Scarica excel',
        btnAction: () => downloadFile(null, null, downloadTypes.product),
        loading: downloadType === downloadTypes.product && loading,
      },
      {
        title: t('Export richieste di ordine'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.ordersNew),
        loading: downloadType === downloadTypes.ordersNew && loading,
      },
      {
        title: t('Export richieste di reso'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.askToRefund),
        loading: downloadType === downloadTypes.askToRefund && loading,
      },
      {
        title: t('Export archivio ordini'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.orders),
        loading: downloadType === downloadTypes.orders && loading,
      },
      {
        title: t('Export clienti'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.users),
        loading: downloadType === downloadTypes.users && loading,
      },
      {
        title: t('Export dati fatturazione'),
        description: '',
        btnText: t('Scarica excel'),
        btnAction: () => downloadFile(null, null, downloadTypes.billing),
        loading: downloadType === downloadTypes.billing && loading,
      },
    ],
    [t],
  );

  const downloadFile = useCallback(
    async (e, additional = {}, type) => {
      let params = {
        ...additional,
      };
      if (user.isManager) {
        params.shop_id = user.currentShop.id;
      }

      try {
        setLoading(true);

        let downloadId;

        const currentType = type;

        if (type) {
          setDownloadType(type);
        }

        console.log('------Curent type', currentType);

        switch (currentType) {
          case downloadTypes.product: {
            const {
              data: { id },
            } = await api.exportProducts(params);
            downloadId = id;
            break;
          }
          case downloadTypes.billing:
          case downloadTypes.orders: {
            const {
              data: { id },
            } = await api.exportOrders(params);
            downloadId = id;
            break;
          }
          case downloadTypes.ordersNew: {
            const {
              data: { id },
            } = await api.exportOrders({ ...params, status: 'created' });
            downloadId = id;
            break;
          }
          case downloadTypes.askToRefund: {
            const {
              data: { id },
            } = await api.exportOrders({ ...params, status: 'ask_to_refund' });
            downloadId = id;
            break;
          }
          case downloadTypes.users: {
            const {
              data: { id },
            } = await api.exportUsers(params);
            downloadId = id;
            break;
          }
          default: {
            setLoading(false);
            return;
          }
        }

        setUploadId(downloadId);
        setLoading(true);
        // setUploadLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [downloadType, user],
  );

  useEffect(() => {
    let interval;

    const fetchStatus = async () => {
      try {
        const {
          data: { export_log },
        } = await api.getUploadStatus(uploadId);

        if (export_log.status === 'success') {
          clearInterval(interval);
          setFileUrl((s) => (!s ? export_log.file_url : s));
        } else if (export_log.status === 'failed') {
          clearInterval(interval);
          setLoading(false);
          setUploadId('');
          UIHelper.serverError();
        }
      } catch (e) {
        console.log('eeee', e);
      }
    };

    if (uploadId) {
      interval = setInterval(async () => {
        await fetchStatus();
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [uploadId, downloadType]);

  useEffect(() => {
    if (fileUrl) {
      let fileName = '';
      switch (downloadType) {
        case downloadTypes.product: {
          fileName = 'products.xlsx';
          break;
        }
        case downloadTypes.orders: {
          fileName = 'orders.xlsx';
          break;
        }
        case downloadTypes.askToRefund: {
          fileName = 'Return requests.xlsx';
          break;
        }
        case downloadTypes.ordersNew: {
          fileName = 'Order requests.xlsx';
          break;
        }
        case downloadTypes.users: {
          fileName = 'users.xlsx';
          break;
        }
        default: {
          fileName = 'data.xlsx';
        }
      }

      if (saveFileName) {
        fileName = saveFileName + '.xlsx';
      }

      Helpers.downloadFileFromUri(fileUrl, fileName);
      setSaveFileName('');

      setLoading(false);
      setFileUrl('');
      setUploadId('');
    }
  }, [fileUrl]);

  const handleDownloadExcelProducts = useCallback(
    async (additionalInfo = {}) => {
      await downloadFile(null, additionalInfo, downloadTypes.product);
    },
    [downloadFile, downloadTypes],
  );

  const handleDownloadExcelUser = useCallback(
    async (additionalInfo = {}, name) => {
      setSaveFileName(name);
      await downloadFile(null, additionalInfo, downloadTypes.users, name);
    },
    [downloadFile, downloadTypes],
  );

  const handleDownloadExcelBillings = useCallback(
    async (additionalInfo = {}, name) => {
      setSaveFileName(name);
      await downloadFile(null, additionalInfo, downloadTypes.billing, name);
    },
    [downloadFile, downloadTypes],
  );

  return {
    downloadType,
    setDownloadType,
    loading,
    downloadTypes,
    downloadFile,
    sectionData: user.isAdmin ? sectionDataAdmin : sectionDataManager,
    handleDownloadExcelProducts,
    handleDownloadExcelUser,
    handleDownloadExcelBillings,
  };
};

export default useDataExport;
