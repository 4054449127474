import {
  Button,
  Modal,
  Row,
  Col,
  Image,
  Typography,
  Divider,
  Select,
  Input,
  Tag,
} from 'antd';
import { getImage } from 'helpers/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import OrdersApi from 'libs/api/orders';

import styles from './styles.module.scss';
import { OrderTypeEnums } from 'constants/orders';
import UIHelper from 'helpers/UIHelper';
import Helpers from 'helpers';

const api = new OrdersApi();

const StockModal = ({
  data = [],
  handleCancel,
  visible,
  handleOk,
  loading,
  customerId,
  customerName,
  orderId,
  userData,
}) => {
  const [inProcess, setInProcess] = useState(false);
  const [editableView, setEditableView] = useState(true);
  const [requestData, setRequestData] = useState({
    order_type: OrderTypeEnums.click_and_reserve,
    order_product_variants_attributes: [],
  });
  const [showCustomer, setShowCustomer] = useState(!!customerId);

  useEffect(() => {
    const order_product_variants_attributes = [];
    data.forEach((i) => {
      order_product_variants_attributes.push({
        product_variant_id: i.id,
        count: 1,
      });
    });
    setRequestData((s) => {
      return {
        ...s,
        order_product_variants_attributes,
      };
    });
  }, [data]);

  const onClickContinue = useCallback(() => {
    setEditableView(false);
  }, []);

  const onClickCancel = useCallback((e) => {
    setEditableView(true);
  }, []);

  const onChange = useCallback((e, item) => {
    const {
      target: { name, value },
    } = e;
    setRequestData((s) => {
      return {
        ...s,
        order_product_variants_attributes:
          s.order_product_variants_attributes.map((i) => {
            if (i.product_variant_id === item.id) {
              return { ...i, [name]: parseInt(value) };
            }

            return i;
          }),
      };
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    setInProcess(true);
    try {
      const params = {
        ...requestData,
        user_id: userData?.id,
      };

      if (showCustomer) {
        params.refer_user_id = +customerId || undefined;
        params.refer_id = +orderId;
      }

      await api.createOrder({
        data: {
          ...params,
          lombardy: true,
        },
      });
      setRequestData({
        order_type: OrderTypeEnums.click_and_reserve,
        order_product_variants_attributes: [],
      });
      handleOk();
    } catch (e) {
      console.log('e', e);
      UIHelper.openNotificationWithIcon(
        'error',
        e.response?.data?.order || 'Errore, per favore contatta la direzione',
      );
    } finally {
      setInProcess(false);
    }
  }, [handleOk, requestData, userData, api, showCustomer, customerId]);

  const retailPrice = useMemo(() => {
    let price = 0;
    requestData.order_product_variants_attributes?.forEach((i) => {
      const item = data.find((d) => d.id === i.product_variant_id);
      if (item) {
        let itemPrice = item?.retail_price?.replaceAll(',', '.');
        if (item.discounted_price !== '0,00') {
          itemPrice = item?.discounted_price?.replaceAll(',', '.');
        }

        price += itemPrice * 100 * i.count * 10;
      }
    });

    return price;
  }, [data, requestData]);

  return (
    <Modal
      className='clc_stockModal'
      width={640}
      visible={visible}
      title='Stock picking'
      onClick={!editableView ? onClickContinue : handleSubmit}
      // onCancel={editableView ? onClickCancel : handleCancel}
      onCancel={handleCancel}
      footer={[
        <Row justify='space-between' gutter={[10, 10]}>
          <Col span={24}>
            {showCustomer && (
              <Tag
                closable
                style={{
                  fontSize: 20,
                }}
                onClose={() => setShowCustomer(false)}
              >
                Cliente: {customerName}
              </Tag>
            )}
          </Col>
          <Col span={6}>
            <span key={1} className={styles.mainPrice}>
              Totale {'  '} {Helpers.numberWithCommas(retailPrice)}€
            </span>
          </Col>
          <Col span={16}>
            <Button
              size='large'
              key='back'
              className={`${styles.button} ${styles.cancelButton}`}
              onClick={handleCancel}
              // onClick={!editableView ? onClickCancel : handleCancel}
            >
              {/*{editableView ? 'Cancella' : 'Modifica ordine '}*/}
              Annulla
            </Button>
            <Button
              size='large'
              key='submit'
              type='primary'
              className={`${styles.button} ${styles.successButton}`}
              loading={loading || inProcess}
              // onClick={editableView ? onClickContinue : handleSubmit}
              onClick={handleSubmit}
            >
              {/*{editableView ? 'Chiudi ordine' : 'Vai a sales tracking'}*/}
              Conferma
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Row gutter={[0, 0]}>
        {data?.map((i, index) => {
          const isLastElement = index + 1 === data.length;
          const image = getImage(i.product_variant_images);
          const currentData =
            requestData.order_product_variants_attributes?.find(
              (item) => item.product_variant_id === i.id,
            );

          let price = i.retail_price.replaceAll(',', '.');
          if (i.discounted_price !== '0,00') {
            price = i.discounted_price.replaceAll(',', '.');
          }

          return (
            <Col span={24} key={i.id}>
              <Row>
                <Col span={6}>
                  <Image
                    height={editableView ? 80 : 48}
                    width={editableView ? 80 : 48}
                    src={image.image}
                  />
                </Col>
                <Col span={18}>
                  <Row justify='space-between' style={{ marginBottom: 8 }}>
                    <Col span={18}>
                      <Typography.Text
                        ellipsis={true}
                        className={styles.title}
                        level={5}
                      >
                        {i.title} - {i.product_number}
                      </Typography.Text>
                    </Col>
                    <Col span={6}>
                      <Typography.Paragraph
                        ellipsis={true}
                        style={{ textAlign: 'right', margin: 0 }}
                        level={5}
                        className={styles.priceColored}
                      >
                        {Helpers.numberWithCommas(price)} €
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                  <Row gutter={[12, 0]}>
                    <Col span={9}>
                      <Typography.Title
                        className={styles.subTitle}
                        level={5}
                        style={{ fontSize: 12 }}
                      >
                        Colore: {!editableView && i.main_color}
                      </Typography.Title>
                      {editableView && (
                        <Select
                          defaultValue={i.main_color}
                          disabled={true}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Col>
                    <Col span={9}>
                      <Typography.Title
                        className={styles.subTitle}
                        level={5}
                        style={{ fontSize: 12 }}
                      >
                        Taglia: {!editableView && i.size}
                      </Typography.Title>
                      {editableView && (
                        <Select
                          defaultValue={i.size}
                          disabled={true}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Col>
                    <Col span={6}>
                      <Typography.Title
                        className={styles.subTitle}
                        level={5}
                        style={{
                          fontSize: 12,
                          textAlign: editableView ? 'unset' : 'right',
                        }}
                      >
                        Quantita: {!editableView && currentData?.count}
                      </Typography.Title>
                      {editableView && (
                        <Input
                          value={currentData?.count}
                          name='count'
                          min={1}
                          // max={i.quantity_available}
                          onChange={(e) => onChange(e, i)}
                          type='number'
                          style={{ width: '100%' }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {!isLastElement && <Divider />}
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default StockModal;
