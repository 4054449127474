import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';
import useCompanies from '../useCompanies';

const AddCompany = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, handleCreateOrUpdate, updateInProcess } = useCompanies({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    const breadcrumb = {
      title: isEdit ? t('Modifica società') : t('Crea società'),
      path: isEdit ? `/companies/list/${id}/edit` : '/shops/add',
    };

    addNewBreadcrumb(breadcrumb.title, breadcrumb.path);
    return () => {
      removeLastBreadcrumb(breadcrumb.path);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    await handleCreateOrUpdate(values, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  const title = isEdit ? t('Modifica società') : t('Crea società');

  return (
    <Card className={styles.card} title={title}>
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              title: '',
              tax_code: '',
              address: '',
              ateco_code: '',
              invoice_code: '',
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Ragione sociale') + ' *'} name='title'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Codice Ateco') + ' *'} name='ateco_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Partita IVA') + ' *'} name='tax_code'>
                  <Input
                    autoComplete='new-password'
                    placeholder={t('Inserisci testo')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Codice SDI') + ' *'} name='invoice_code'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Sede legale') + ' *'} name='address'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button
            type={'secondary'}
            onClick={() => navigate('/profile/companies/list')}
          >
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddCompany;
