import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'icons';
import { getUser } from 'hooks/useUser';
import UsersApi from 'libs/api/users';
import LinkWithParams from '../../../../components/linkWithParams';
import useDataExport from '../../../data-export/adminView/useDataExport';

const api = new UsersApi();

const ownerFilter = {
  by_role: 'owner',
};

const useOwner = ({ isMainPage = true, isEdit } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const user = useRecoilValue(getUser);
  const { t } = useTranslation();
  const { handleDownloadExcelUser, loading: downloadExcelLoading } =
    useDataExport();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
    searchParams,
  } = useApi({
    resourceKey: 'users',
    singleResourceKey: 'user',
    api,
    isMainPage,
    additionalFilters: ownerFilter,
  });

  console.log('searchParams', searchParams);

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      render: (data, row) => (
        <Row
          gutter={16}
          style={{ minWidth: 150 }}
          justify={'left'}
          onClick={(e) => e.stopPropagation()}
        >
          <Col>
            <LinkWithParams to={`${row.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </LinkWithParams>
          </Col>
          <Col>
            <Link to={`${row.id}/edit`}>
              <Button icon={<EditIcon />} />
            </Link>
          </Col>
          <Col>
            <LinkWithParams to={`${row.id}/delete`}>
              <Button icon={<DeleteIcon />} />
            </LinkWithParams>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'name',
    },
    {
      title: t('Cognome'),
      dataIndex: 'surname',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
    },
    {
      title: t('Numero telefono'),
      dataIndex: 'phone',
    },
    {
      title: t('Codice fiscale'),
      dataIndex: 'tax_number',
    },
    {
      title: t('Insegne'),
      dataIndex: 'tax_number',
      render: (data, row) => {
        return row?.shops?.map((shop) => shop.title).join(', ');
      },
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca gestore'),
        type: 'input',
        name: 'by_text',
        values: [],
        allowClear: true,
      },
    ];
  }, [t]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          user: {
            ...values,
          },
        };
        if (user.isAdmin) {
          data.user.role = 'owner';
        }
        if (!id) {
          await api.create({ data });
        } else {
          await api.put(id, { data });
        }
        if (user.isOwner) {
          navigate('/profile');
        } else {
          navigate('/profile/owners/list');
        }
      } catch (e) {
        const errors = { ...(e?.response?.data || {}) };
        if (!values.password_confirmation) {
          errors.password_confirmation = ['Non può essere lasciato in bianco'];
        }
        form.setFields(ErrorHelper.getErrorsForForm(errors));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [user, form, navigate, setUpdateInProcess],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
  }, [singleItem, isEdit]);

  const handleDownloadExcel = () => {
    const searchParamsObj = Object.fromEntries([...searchParams]);

    handleDownloadExcelUser(
      {
        ...ownerFilter,
        ...(searchParamsObj || {}),
      },
      'Gestori',
    );
  };

  return {
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
    handleDownloadExcel,
    downloadExcelLoading,
  };
};

export default useOwner;
