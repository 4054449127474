import axios from 'axios';
import UIHelper from 'helpers/UIHelper';

export default function configureAxios() {
  axios.interceptors.response.use(
    (response) => {
      const isSignInResponse = response?.config?.url.includes('/auth/signin');
      const isConfirmationResponse = response?.config?.url.includes('/confirmation');
      const isCreateOrUpdateResponse = [
        'post',
        'put',
        'patch',
        'delete',
      ].includes(response?.config?.method);

      // if (
      //   response.status < 300 &&
      //   !isSignInResponse &&
      //   !isConfirmationResponse &&
      //   isCreateOrUpdateResponse
      // ) {
      //   UIHelper.openNotificationWithIcon(
      //     'success',
      //     'L’operazione è stata conclusa con successo!\n',
      //   );
      // }
      return response;
    },
    (error) => {
      if (error?.response?.status >= 500) {
        UIHelper.serverError();
      }
      return Promise.reject(error);
    },
  );
}
