import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';

const defaultState = [];

const breadcrumbState = atom({
  key: 'breadcrumbState',
  default: defaultState,
});

const useBreadcrumb = () => {
  const [breadcrumb, setBreadcrumb] = useRecoilState(breadcrumbState);
  const addNewBreadcrumb = useCallback(
    (title, link) => {
      setBreadcrumb((s) => {
        const find = s.find((i) => i.link === link);
        if (find) {
          return s;
        }

        return [
          ...s,
          {
            title,
            link,
          },
        ];
      });
    },
    [setBreadcrumb],
  );

  const removeLastBreadcrumb = useCallback(
    (link) => {
      setBreadcrumb((s) => s.filter((i) => i.link !== link));
    },
    [setBreadcrumb],
  );

  return {
    breadcrumb,
    setBreadcrumb,
    addNewBreadcrumb,
    removeLastBreadcrumb,
  };
};

export default useBreadcrumb;
