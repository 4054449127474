import { Col, Row, Space, Typography } from 'antd';
import Input from 'antd/es/input/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShowError from '../../../../../components/showError';

const ShopifyForm = ({ eCommerceData, handleChangeData }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={14}>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Typography.Text strong>{t('Shopify token')}</Typography.Text>
          <Input
            onChange={handleChangeData}
            status={eCommerceData?.errors['shopify_token'] ? 'error' : ''}
            placeholder={t('Inserisci il testo')}
            name={'shopify_token'}
            value={eCommerceData.shopifyData.shopify_token}
          />
          <ShowError errors={eCommerceData?.errors['shopify_token']} />
          <Typography.Text strong>{t('Shopify domain')}</Typography.Text>
          <Input
            status={eCommerceData?.errors['shopify_domain'] ? 'error' : ''}
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'shopify_domain'}
            value={eCommerceData.shopifyData.shopify_domain}
          />
          <ShowError errors={eCommerceData?.errors['shopify_domain']} />
        </Space>
      </Col>
    </Row>
  );
};

export default ShopifyForm;
