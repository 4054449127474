import { Fragment, useEffect, useMemo } from 'react';
import { Col, Divider, Modal, Row, Spin, Typography } from 'antd';

import CashierOrderCard from './order-card';
import {
  getProductStatusTest,
  getOrderTypeText,
  timeFormat,
  getOrderStatusText,
} from 'helpers/utils';
import classNames from 'classnames';
import Button from '../button';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const InfoView = ({ value, title }) => {
  if (!value) return null;

  return (
    <p>
      <strong>{title}</strong>: {value}
    </p>
  );
};

const OrderViewModal = ({
  order = {},
  onClose,
  variant = 'sap',
  variants = [],
  onChange = () => {},
  onRemoveItem = () => {},
  onChangesSize = () => {},
  onSubmit = () => {},
  orderNumber = 123,
  setCurrentOrderId,
  currentOrderId,
  loading,
  shop = {},
  refound,
  admin,
  onChangeRefundItem,
  hideActionButtons = false,
}) => {
  const { t, i18n } = useTranslation();
  const length = variants?.length || 0;
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const shopId = searchParams.get('shop_id');

  useEffect(() => {
    if (+currentOrderId !== +id) {
      setCurrentOrderId(id);
    }
  }, [id]);

  const totalProductsCount = useMemo(() => {
    let count = 0;
    if (refound) {
      // order?.refund_order_product_variants?.forEach(i => {
      //   count += i.wants_a_refund;
      // });
      count = order?.count_of_returned_items;
    } else {
      order?.order_product_variants?.forEach((i) => {
        count += i.count;
      });
    }

    return count;
  }, [order, refound]);
  console.log('ord', order);

  const isPayed =
    order?.payment_status === 'paid' || order?.payment_status === 'Effettuato';

  const payedText = useMemo(() => {
    switch (order?.payment_status) {
      case 'processing':
      case 'Non pagato':
        return 'L’ordine non è stato pagato';
      case 'canceled':
        return 'L’ordine è stato cancellato';
      case 'Pagato':
      case 'Effettuato':
        return 'L’ordine è stato pagato';
      case 'refunded':
        return 'L’ordine è stato rimborsato';
      default:
        return `L'ordine ${isPayed ? '' : 'non '}è stato pagato`;
    }
  }, [order, isPayed]);

  console.log('t', t('Home'));

  return (
    <Modal visible={true} width={'100%'} onCancel={onClose} footer={false}>
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {getOrderTypeText(order?.order_type, order?.is_stock_picking)}
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Title level={5} strong>
              {order?.shop?.title}
            </Typography.Title>
          </Col>
        </Row>
        <div
          className={styles.modalBody}
          style={{ flexDirection: variant === 'sap' ? 'row' : 'column' }}
        >
          <div style={{ flex: 1 }}>
            {variants?.map((i, index) => (
              <Fragment key={i.id}>
                <CashierOrderCard
                  admin={admin}
                  isShowMode={order.status === 'shipped' || variant === 'sap'}
                  isDisabled={variant === 'sap'}
                  article={index + 1}
                  length={length}
                  data={i}
                  order={order}
                  onChange={onChange}
                  onRemoveItem={onRemoveItem}
                  onChangesSize={onChangesSize}
                  refound={refound}
                  onChangeRefundItem={onChangeRefundItem}
                  hideActionButtons={hideActionButtons}
                />
                <Divider
                  style={{
                    margin: '32px 0px',
                  }}
                />
              </Fragment>
            ))}
          </div>
          <div
            className={classNames(
              styles.orderInfoBlock,
              variant !== 'sap' && styles.orderInfoBlock_line,
            )}
          >
            {order.id && (
              <p>
                <strong>{t('ID ordine')}</strong>:{' '}
                {getOrderStatusText(getProductStatusTest(order.order_id))}
              </p>
            )}
            {order.status && (
              <p>
                <strong>{t('Main OrderStatus')}</strong>:{' '}
                {getOrderStatusText(getProductStatusTest(order.status))}
              </p>
            )}
            {order.order_type && (
              <p>
                <strong>Tipo ordine</strong>:{' '}
                {getOrderTypeText(order.order_type, order?.is_stock_picking)}
              </p>
            )}
            {order.created_at && (
              <p>
                <strong>Data ordine</strong>: {timeFormat(order.created_at)}
              </p>
            )}
            {order.customer_id && (
              <p>
                <strong>Codice cliente</strong>: {order.customer_id}
              </p>
            )}
            {order.name && (
              <p>
                <strong>Nome</strong>: {order.name}
              </p>
            )}
            {order.surname && (
              <p>
                <strong>Cognome</strong>: {order.surname}
              </p>
            )}
            {order.email && (
              <p>
                <strong>Email</strong>: {order.email}
              </p>
            )}
            {order.phone && (
              <p>
                <strong>Cellulare</strong>: {order.phone}
              </p>
            )}
            {order.count && (
              <p>
                <strong>Nr articoli</strong>: {totalProductsCount}
              </p>
            )}
            {order.amount && (
              <p>
                <strong>Importo ordine €</strong>: {order.amount}
              </p>
            )}
            <p>
              <strong>{t('Importo rimborsato')}</strong> €:{' '}
              {order.refund_amount}
            </p>
            {order?.delivery_amount &&
              order.order_type === 'click_and_ship' && (
                <p style={{ marginBottom: 12 }}>
                  <b>Costo spedizione €:</b> {order?.delivery_amount} Euro
                </p>
              )}
            {order.shop_title && (
              <p>
                <strong>{t('home.productsTable.shop')}</strong>:{' '}
                {order.shop_title}
              </p>
            )}
            {order.mall_title && (
              <p>
                <strong>{t('home.productsTable.mall')}</strong>:{' '}
                {order.mall_title}
              </p>
            )}
            {order.mall_title && (
              <p>
                <strong>{t('currentOrder.table.orderNumber')}</strong>:{' '}
                {orderNumber}
              </p>
            )}
            {order?.address && (
              <InfoView
                value={`${order?.address}, ${order?.province || ''} ${
                  order?.city
                }, ${order?.zip_code}`}
                title={'Indirizzo di spedizione'}
              />
            )}
            {order?.invoice_company && (
              <InfoView
                value={order?.invoice_company}
                title={t('main.invoice_company')}
              />
            )}
            {order?.invoice_address && (
              <InfoView
                value={order?.invoice_address}
                title={'Indirizzo di fatturazione'}
              />
            )}
            <InfoView
              value={order?.invoice_company}
              title={t('main.invoice_company')}
            />
            <InfoView
              value={order?.invoice_name}
              title={t('main.invoice_name')}
            />
            <InfoView
              value={order?.invoice_surname}
              title={t('main.invoice_surname')}
            />
            <InfoView
              value={order?.invoice_tax_code}
              title={t('main.invoice_tax_code')}
            />
            <InfoView
              value={order?.invoice_company_name}
              title={t('main.invoice_company_name')}
            />
            <InfoView
              value={order?.invoice_company_code}
              title={t('main.invoice_company_code')}
            />
            <InfoView
              value={order?.invoice_pec}
              title={t('main.invoice_pec')}
            />
            <InfoView
              value={order?.invoice_code_sdi}
              title={t('main.invoice_code_sdi')}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.orderFooterLeftSide}>
            <InfoCircleOutlined className={styles.infoIcon} />
            <div className={styles.orderFooterLeftSideTitles}>
              <span className='footer-title'>{payedText}</span>
              <span className={styles.footerPrice}>
                Importo dell'ordine: {order?.amount} Euro{' '}
              </span>
            </div>
          </div>
          {variant !== 'sap' && <Button onClick={onSubmit}>Submit</Button>}
          {refound && !hideActionButtons && (
            <Row gutter={8}>
              {!admin && (
                <Col>
                  <Button width={100} type={'secondary'} onClick={onClose}>
                    {t('Annulla')}
                  </Button>
                </Col>
              )}
              {!admin && (
                <Col>
                  <Button width={100} onClick={onSubmit}>
                    {t('Continua')}
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default OrderViewModal;
