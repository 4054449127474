import React from 'react';
import { Descriptions, Modal, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UIHelper from '../../../../../helpers/UIHelper';
import useTeam from '../../useTeam';

const ViewModal = () => {
  const { t } = useTranslation();
  const { singleItem } = useTeam({ isMainPage: false });
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname) + search);
  };

  return (
    <Modal
      title={t('Utente')}
      visible={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Descriptions column={1} bordered>
        {singleItem ? (
          <>
            <Descriptions.Item label={t('Nome')}>
              {singleItem.name}
            </Descriptions.Item>

            <Descriptions.Item label={t('Cognome')}>
              {singleItem.surname}
            </Descriptions.Item>

            <Descriptions.Item label={t('Email')}>
              {singleItem.email}
            </Descriptions.Item>

            <Descriptions.Item label={t('Numero telefono')}>
              {singleItem.phone}
            </Descriptions.Item>

            <Descriptions.Item label={t('Ruolo')}>
              {singleItem.role}
            </Descriptions.Item>

            <Descriptions.Item label={t('Insegna/e')}>
              {singleItem.shops?.map((i) => i.title)?.join(', ')}
            </Descriptions.Item>
          </>
        ) : (
          <Spin size={'large'} />
        )}
      </Descriptions>
    </Modal>
  );
};

export default ViewModal;
