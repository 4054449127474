import React from 'react';
import { Descriptions, Modal, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UIHelper from '../../../../../helpers/UIHelper';
import usePromotions from '../../usePromotions';
import Helpers from '../../../../../helpers';

const ViewModal = () => {
  const { t } = useTranslation();
  const { singleItem } = usePromotions({ isMainPage: false });
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const handleCancel = () => {
    history(UIHelper.getListRoute(pathname) + search);
  };

  return (
    <Modal
      title={t('Promozioni')}
      visible={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Descriptions column={1} bordered>
        {singleItem ? (
          <>
            <Descriptions.Item label={t('Titolo')}>
              {singleItem.title}
            </Descriptions.Item>

            <Descriptions.Item label={t('total_promotion_amount_cents')}>
              {Helpers.numberWithCommas(
                singleItem.total_promotion_amount_cents,
              )}
            </Descriptions.Item>

            <Descriptions.Item label={t('Descrizione')}>
              {singleItem.description}
            </Descriptions.Item>

            <Descriptions.Item label={'value_cents'}>
              {singleItem.value_cents}
            </Descriptions.Item>

            <Descriptions.Item label={'order_sum_amount'}>
              {singleItem.order_sum_amount}
            </Descriptions.Item>

            <Descriptions.Item label={'order_sum_count'}>
              {singleItem.order_sum_count}
            </Descriptions.Item>

            <Descriptions.Item label={'order_more_than_amount'}>
              {singleItem.order_more_than_amount}
            </Descriptions.Item>

            <Descriptions.Item label={'max_signup_invites'}>
              {singleItem.max_signup_invites}
            </Descriptions.Item>

            <Descriptions.Item label={'order_type'}>
              {UIHelper.humanizeString(singleItem.order_type)}
            </Descriptions.Item>

            {/*<Descriptions.Item label={'promotion_type'}>*/}
            {/*  {UIHelper.humanizeString(singleItem.promotion_type)}*/}
            {/*</Descriptions.Item>*/}

            <Descriptions.Item label={'start_date'}>
              {UIHelper.formattedDate(singleItem.start_date)}
            </Descriptions.Item>

            <Descriptions.Item label={'end_date'}>
              {UIHelper.formattedDate(singleItem.end_date)}
            </Descriptions.Item>
          </>
        ) : (
          <Spin size={'large'} />
        )}
      </Descriptions>
    </Modal>
  );
};

export default ViewModal;
