import React, { useLayoutEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './style.module.scss';
import { Card, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import FormHelper from 'helpers/FormHelper';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';
import useOwner from '../useOwner';
import { getUser } from 'hooks/useUser';

const AddCompany = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useRecoilValue(getUser);
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const { form, handleCreateOrUpdate, updateInProcess, filterData } = useOwner({
    isMainPage: false,
    isEdit,
  });

  useLayoutEffect(() => {
    addNewBreadcrumb(
      isEdit ? t('Modifica') : t('Crea gestore'),
      '/profile/owners/add',
    );
    return () => {
      removeLastBreadcrumb('/profile/owners/add');
    };
  }, [isEdit]);

  const onFinish = async (values) => {
    let newValues = { ...values };
    if (!newValues.password) {
      delete newValues.password;
      delete newValues.password_confirmation;
    }
    await handleCreateOrUpdate(newValues, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickSave = () => {
    form.submit();
  };

  return (
    <Card
      className={styles.card}
      title={user.isAdmin && !isEdit ? t('Crea Gestore') : t('Modifica')}
    >
      <Row className={styles.row} justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            name='basic'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              name: '',
              surname: '',
              email: '',
              phone: '',
              ficsal_code: '',
              shops: '',
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <input
              autoComplete={'false'}
              name={'hidden'}
              type={'text'}
              style={{
                display: 'none',
              }}
            />
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label={t('Nome') + '*'} name='name'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Cognome') + '*'} name='surname'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Email') + '*'} name='email'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Numero Telefono')} name='phone'>
                  <Input placeholder={t('Inserisci testo')} />
                </Form.Item>
              </Col>
              {!isEdit && (
                <>
                  <Col span={9}>
                    <Form.Item label={t('Password') + '*'} name='password'>
                      <Input
                        autoComplete='new-password'
                        type={'password'}
                        placeholder={t('Inserisci testo')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      autoComplete='off'
                      label={t('Password confirmation') + '*'}
                      name='password_confirmation'
                    >
                      <Input
                        type={'password'}
                        placeholder={t('Inserisci testo')}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={9}>
                <Form.Item label={t('Codice fiscale')} name='ficsal_code'>
                  <Input
                    placeholder={t('Inserisci testo')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={t('Insegne')} name='shop_ids'>
                  <Select
                    mode={'multiple'}
                    placeholder={t('Selezioni opzione')}
                  >
                    <Select.Option value={''}>
                      {t('Selezioni opzione')}
                    </Select.Option>
                    {filterData.shops.map(({ value, title }) => (
                      <Select.Option key={value} value={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button
            type={'secondary'}
            onClick={() => navigate('/profile/owners/list')}
          >
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickSave} loading={updateInProcess}>
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddCompany;
