import { Col, Row, Space, Typography } from 'antd';
import Input from 'antd/es/input/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WixForm = ({ eCommerceData, handleChangeData }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={14}>
        <Space direction={'vertical'}>
          <Typography.Text strong>wix_api_key</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'wix_api_key'}
            value={eCommerceData.wixData.wix_api_key}
          />
          <Typography.Text strong>wix_site_id</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'wix_site_id'}
            value={eCommerceData.wixData.wix_site_id}
          />
          <Typography.Text strong>wix_account_id</Typography.Text>
          <Input
            onChange={handleChangeData}
            placeholder={t('Inserisci il testo')}
            name={'wix_account_id'}
            value={eCommerceData.wixData.wix_account_id}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default WixForm;
