import React from 'react';
import styles from './style.module.scss';
import { Spin, Typography } from 'antd';
import classNames from 'classnames';

const StatisticCard = ({
  smallTextSize,
  count,
  text,
  big = false,
  loading = false,
}) => {
  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.card, big && styles.cardBig)}>
        <Typography
          className={classNames(
            styles.count,
            smallTextSize && styles.smallCount,
          )}
        >
          {count}
        </Typography>
        <span className={styles.text}>{text}</span>
      </div>
    </Spin>
  );
};

export default React.memo(StatisticCard);
