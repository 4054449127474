import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Col, Form, Row } from 'antd';
import { getFilter } from 'hooks/useFilter';
import { useRecoilValue } from 'recoil';
import { useApi } from 'hooks/useApi';
import { useNavigate } from 'hooks/useNavigate';
import ErrorHelper from 'helpers/ErrorHelper';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import ShopsApi from 'libs/api/shops';
import LinkWithParams from '../../../../../components/linkWithParams';

const api = new ShopsApi();

const useShops = ({ isMainPage = true, isEdit } = {}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const filterData = useRecoilValue(getFilter);
  const { t } = useTranslation();
  const {
    singleItem,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    updateInProcess,
    setUpdateInProcess,
    handleDownloadExcel,
  } = useApi({
    resourceKey: 'shops',
    singleResourceKey: 'shop',
    api,
    isMainPage,
  });

  const columns = [
    {
      title: t('Azioni'),
      dataIndex: '',
      // width: 200,
      render: (data, row) => (
        <Row gutter={16} justify={'left'} onClick={(e) => e.stopPropagation()}>
          <Col>
            <LinkWithParams to={`${row?.id}/view`}>
              <Button type={'primary-white'} icon={<EyeOutlined />} />
            </LinkWithParams>
          </Col>
          {/*<Col>*/}
          {/*  <Link to={`${row.id}/edit`}>*/}
          {/*    <Button icon={<EditIcon />} type={'secondary'} />*/}
          {/*  </Link>*/}
          {/*</Col>*/}
          {/*<Col>*/}
          {/*  <Link to={`${row.id}/delete`}>*/}
          {/*    <Button icon={<DeleteIcon />} />*/}
          {/*  </Link>*/}
          {/*</Col>*/}
        </Row>
      ),
    },
    {
      title: t('Nome insegna'),
      dataIndex: 'shop.title',
      render: (data, row) => row.name,
    },
    {
      title: t('Società associata'),
      dataIndex: 'company',
      render: (data) => data?.title || '',
    },
    {
      title: t('Categorie'),
      dataIndex: 'categories_shop',
      render: (data) =>
        !!data?.length &&
        data.reduce(
          (total, shop) =>
            data.length === 1
              ? shop.category.title
              : `${total}, ${shop.category.title}`,
          '',
        ),
    },
    {
      title: t('Punti vendita'),
      dataIndex: 'sales_points',
      render: (data) =>
        data?.length
          ? data.reduce(
              (total, point) =>
                data.length === 1
                  ? point.address
                  : `${total}, ${point.address}`,
              '',
            )
          : '',
    },
    {
      title: t('Email'),
      dataIndex: 'email_support',
    },
    {
      title: t('Numero telefono'),
      dataIndex: 'phone_support',
    },
    {
      title: t('Sito web'),
      dataIndex: 'website',
    },
    {
      title: t('Descrizione attività'),
      dataIndex: 'description_it',
    },
    {
      title: t('Immagine'),
      dataIndex: 'image',
      render: (data) => <>{data && <Image width={50} src={data} />}</>,
    },
    {
      title: t('Servizi / modalità evasione'),
      dataIndex: '',
      render: (_, row) => (
        <>
          {row?.is_click_collect && (
            <p style={{ whiteSpace: 'nowrap' }}>Click Collect</p>
          )}
          {row?.is_click_reserve && (
            <p style={{ whiteSpace: 'nowrap' }}>Click Reserve</p>
          )}
          {row?.is_online_shop && (
            <p style={{ whiteSpace: 'nowrap' }}>Shop online</p>
          )}
        </>
      ),
    },
  ];

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca insegna'),
        type: 'input',
        name: 'query',
        values: [],
        allowClear: true,
      },
    ];
  }, [t]);

  const handleCreateOrUpdate = useCallback(
    async (values, id) => {
      setUpdateInProcess(true);
      try {
        const data = {
          shop: {
            ...values,
          },
        };
        if (!id) {
          await api.create({ data });
        } else {
          await api.put(id, { data });
        }
        navigate('/shops/list');
      } catch (e) {
        form.setFields(ErrorHelper.getErrorsForForm(e?.response?.data || {}));
      } finally {
        setUpdateInProcess(false);
      }
    },
    [form, navigate, setUpdateInProcess],
  );

  useEffect(() => {
    if (isEdit) {
      let fieldsValues = [];
      for (let key in singleItem) {
        switch (key) {
          case 'shops': {
            fieldsValues.push({
              name: 'shop_ids',
              value: singleItem[key]?.map((i) => i.id),
            });
            break;
          }
          default: {
            fieldsValues.push({
              name: key,
              value: singleItem[key],
            });
          }
        }
      }
      form.setFields(fieldsValues);
    }
  }, [singleItem, isEdit]);

  return {
    handleCreateOrUpdate,
    form,
    columns,
    filters,
    filterData,
    updateInProcess,
    data,
    loading,
    onChangeTableFilter,
    onDelete,
    singleItem,
    handleDownloadExcel,
  };
};

export default useShops;
