import Api from 'libs/api';

class ShopsApi extends Api {
  constructor() {
    super('admin/shops/');
  }

  merchantAttributeValues(id, params) {
    return this.buildQuery('get', params, `${id}/merchant_attribute_values`);
  }

  merchantAttributeValuesBulkUpdate(id, data) {
    return this.buildQuery(
      'put',
      {
        data,
      },
      `${id}/merchant_attribute_values/bulk_update`,
    );
  }

  merchantAttributesBulkUpdate(id, data) {
    return this.buildQuery(
      'put',
      {
        data,
      },
      `${id}/merchant_attributes/bulk_update`,
    );
  }

  merchantAttributes(id) {
    return this.buildQuery('get', {}, `${id}/merchant_attributes`);
  }

  merchantAttributeImports(id) {
    return this.buildQuery('post', {}, `${id}/merchant_attribute_imports`);
  }

  showProgressMerchantAttributeImports(shopId, id) {
    return this.buildQuery(
      'get',
      {},
      `${shopId}/merchant_attribute_imports/${id}`,
    );
  }

  productImports(id) {
    return this.buildQuery('post', {}, `${id}/product_imports`);
  }

  countProductsImports(id) {
    return this.buildQuery('get', {}, `${id}/product_imports/need_to_sync`);
  }

  productImportsStatus(shopId, id) {
    return this.buildQuery('get', {}, `${shopId}/product_imports/${id}`);
  }
  merchantCategoriesRelations(id, data) {
    return this.buildQuery(
      'post',
      {
        data,
      },
      `${id}/merchant_categories/relations`,
    );
  }

  getMerchantCategories(id) {
    return this.buildQuery('get', {}, `${id}/merchant_categories`);
  }

  createShop(data) {
    return this.buildQuery(
      'post',
      {
        headers: {
          'content-type':
            'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
        data,
      },
      '',
    );
  }
  updateShop(id, data) {
    return this.buildQuery(
      'put',
      {
        headers: {
          'content-type':
            'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
        data,
      },
      id,
    );
  }
}

export default ShopsApi;
