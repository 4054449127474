import React from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import LeftCardsSection from 'components/leftCardsSection';
import useDataExport from './useDataExport';
import UploadModal from './uploadModal';

const ManagerView = () => {
  const { t } = useTranslation();
  const { sectionData } = useDataExport();

  return (
    <Row className={styles.container} gutter={20}>
      <Col span={6}>
        <LeftCardsSection title={t('Export dati')} sectionData={sectionData} />
      </Col>
      <Col span={18}></Col>
    </Row>
  );
};

export default ManagerView;
