export const OrderActions = {
  edit: 'modifica',
  refund: 'refund',
  dispatch: 'evadi',
  delete: 'annulla',
  view: 'view',
};

export const OrderStatuses = {
  created: 'created',
  pending: 'pending',
  canceled: 'canceled',
  completed: 'completed',
  expired: 'expired',
  shipped: 'shipped',
  paid: 'paid',
  refunded: 'refunded',
  ask_to_refund: 'ask_to_refund',
  refused: 'refused',
  waiting_for_products_refund: 'waiting_for_products_refund',
};

export const Deliveries = {
  DHL: 'dhl',
  GLS: 'gls',
  SDA: 'sda',
  BRT: 'brt',
  UPS: 'ups',
};

export const OrderTypes = {
  allOrders: 'allOrders',
  completed: 'completed',
  canceled: 'canceled',
  expired: 'expired',
  refunded: 'refunded',
  ask_to_refund: 'ask_to_refund',
};

export const OrderTypeEnums = {
  click_and_collect: 'click_and_collect',
  click_and_reserve: 'click_and_reserve',
  click_and_ship: 'click_and_ship',
};

export const ProductTypesEnum = {
  online: 'online',
  offline: 'offline',
  moderation: 'moderation',
};

export const OrderTypesNames = {
  click_and_collect: 'Click & Collect',
  click_and_reserve: 'Click & Reserve',
  click_and_ship: 'Shop online',
};

export const OrderTypesFullNames = {
  created_by_day: 'Created',
  canceled_by_day: 'Canceled',
  shipped_by_day: 'Shipped',
  completed_by_day: 'Completed',
  refunded_by_day: 'Refunded',
  expired_by_day: 'Expired',
  ask_to_refund_by_day: 'Ask to refund',
  waiting_for_products_refund: 'Wait for refund',
};

export const OrderRefundReasons = {
  did_not_like: 'did_not_like',
  poor_quality: 'poor_quality',
  item_damaged: 'item_damaged',
  wrong_size: 'wrong_size',
  wrong_item_arrived: 'wrong_item_arrived',
};

export const OrderRefundReasonsNames = {
  did_not_like: 'Non è piaciuto',
  poor_quality: 'Scarsa qualità',
  item_damaged: 'Articolo danneggiato',
  wrong_size: 'Taglia sbagliata',
  wrong_item_arrived: 'è arrivato un articolo sbagliato',
};
