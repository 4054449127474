import Api from 'libs/api';
import Auth from 'services/Auth';

class OrdersConfirmationApi extends Api {
  constructor(shopId) {
    super(`admin/shops/${shopId || Auth.getCurrentShop()}/orders/`);
    // super('admin/orders/');
  }

  dispatchTrackOrder(itemId, data) {
    return this.buildQuery(
      'post',
      {
        data,
      },
      `${itemId}/dispatch_track`,
    );
  }

  confirmation = (itemId, data) => {
    return this.buildQuery('post', { data }, `${itemId}/confirmation`);
  };

  complete = (itemId) => {
    return this.buildQuery('post', {}, `${itemId}/complete`);
  };

  cancelOrder = (itemId) => {
    return this.buildQuery('post', {}, `${itemId}/cancel`);
  };
  dataFLow = (itemId, data) => {
    return this.buildQuery('post', { data }, `${itemId}/pick_up`);
  };
}

export default OrdersConfirmationApi;
