import FirstStep from './firstStep';
import SecondStep from './secondStep';
import ShopifyChooseStep from './shopifyChooseStep';
import ThirdStep from './thirdStep';
import ForthStep from './forthStep';
import FifthStep from './fifthStep';
import SixthStep from './sixthStep';

const routes = [
  {
    path: '/*',
    Component: FirstStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/choose-shopify-type',
    Component: ShopifyChooseStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/step2',
    Component: SecondStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/step3',
    Component: ThirdStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/step4',
    Component: ForthStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/step5',
    Component: FifthStep,
    props: {
      // exact: true,
    },
  },
  {
    path: '/step6',
    Component: SixthStep,
    props: {
      // exact: true,
    },
  },
];

export default routes;
