import { Col, Row, Spin } from 'antd';
import StatisticCard from 'components/statisticCard';
import LineChart from 'components/charts/lineChart';
import BarChangeChart from 'components/charts/barChange';
import { useTranslation } from 'react-i18next';
import useStatisticaManager from './useStatisticaManager';
import Filters from 'components/filters';
import { OrderTypesNames } from 'constants/orders';

const secondBarKeys = [
  OrderTypesNames.click_and_collect,
  OrderTypesNames.click_and_ship,
  OrderTypesNames.click_and_reserve,
];

const OrdersAndReturnsStatisticsView = () => {
  const { t } = useTranslation();
  const {
    barChartByOrderType,
    barChartByOrderStatus,
    orderStatusKeysForChart,
    data,
    filters,
    loading,
  } = useStatisticaManager();
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Filters hideClearAll filters={filters} />
          <br />
        </Col>
        <Col span={6}>
          <StatisticCard
            loading={loading}
            big
            count={data.orders_count}
            text={t('Ordini')}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            loading={loading}
            big
            count={data.refund_orders_count}
            text={t('Resi')}
          />
        </Col>
        <Col span={12} style={{ height: 260 }}>
          <LineChart
            loading={loading}
            colors={['#faac18']}
            title={t('Evoluzione temporale ordini')}
            tableData={data.lineChartOrders.data || []}
            data={data.lineChartOrders}
          />
        </Col>
        <Col span={24}>
          <BarChangeChart
            title={t('Distribuzione ordini per stato')}
            keys={orderStatusKeysForChart}
            data={barChartByOrderStatus}
            translateXBar={-20}
            hideLine
            indexBy={'date'}
            groupMode={'stacked'}
            itemWidth={90}
          />
        </Col>
        <Col span={24}>
          <BarChangeChart
            title={t('Distribuzione ordini modalità di evasione')}
            keys={secondBarKeys}
            data={barChartByOrderType}
            itemWidth={130}
            translateXBar={-20}
            hideLine
            indexBy={'date'}
            groupMode={'stacked'}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default OrdersAndReturnsStatisticsView;
