import React, { useLayoutEffect } from 'react';
import styles from './style.module.scss';
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useSettings from './useSettings';
import { useNavigate } from 'hooks/useNavigate';
import FormHelper from 'helpers/FormHelper';
import TimeSection from './timeSection';
import NotificationSection from './notificationSections';
import InputWithLabel from '../../../../components/inputWithLabel';
import MySelect from '../../../../components/mySelect';
import NotificationsV2 from 'pages/orders/managerView/settings/notificationsV2';
import useUser from 'hooks/useUser';

const SettingsPage = () => {
  const { t } = useTranslation();
  const { user: userInfo } = useUser();
  const navigate = useNavigate();
  const { addNewBreadcrumb, removeLastBreadcrumb } = useBreadcrumb();
  const {
    form,
    handleChangeDay,
    openingHoursAttributes,
    handleAddMoreHours,
    handleDeleteHours,
    handleChangeTime,
    weekDays,
    notificationTypes,
    notifications,
    handleChangeNotificationTypes,
    filterData,
    data,
    setData,
    handleUpdate,
    loading,

    // onChangeNotificationValue,
    // handleAddMoreNotificationValues,
    // handleDeleteNotificationValues,
  } = useSettings();

  const isOnlineShop = Form.useWatch('is_online_shop', form);

  useLayoutEffect(() => {
    removeLastBreadcrumb('/orders/settings');
    addNewBreadcrumb(
      t('Modifica impostazioni ordini e resi'),
      '/orders/settings',
    );
    return () => {
      removeLastBreadcrumb('/orders/settings');
    };
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async (values) => {
    await handleUpdate(values);
  };

  const notifications_allow = Form.useWatch('notifications_allow', form);

  return (
    <Card
      className={styles.card}
      title={t('Modifica impostazioni ordini e resi')}
    >
      <Row>
        {/*<Col span={24}>*/}
        {/*  <Button onClick={handleExport} loading={ordersExportLoading}>*/}
        {/*    {t('Scarica excel')}*/}
        {/*  </Button>*/}
        {/*</Col>*/}
        <Col span={24}>
          <Form
            form={form}
            name='settings'
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              opening_hours_attributes: [],
              notifications_allow: [],
              delivery_box_price: null,
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            onFieldsChange={FormHelper.resetFormsFieldErrors(form)}
          >
            <Row>
              <Col span={24}>
                <Typography className={styles.title}>
                  {t('A quali servizi vuoi aderire?')}
                </Typography>
                <Typography className={styles.subtitle}>
                  {t(
                    'Scegli le modalità di evasione delle quali potranno usufruire i clienti del tuo negozio.',
                  )}
                </Typography>
              </Col>
              <Col span={12}>
                <Divider />
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Row justify={'space-between'}>
                  <Col>
                    <Typography className={styles.title}>
                      {t('Click & Collect')}
                    </Typography>
                  </Col>
                  <Col>
                    <Form.Item
                      className={styles.switchItem}
                      name='is_click_collect'
                      valuePropName='checked'
                    >
                      <Switch className={styles.switch} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography className={styles.subtitle}>
                      {t('Aquisto e pagamento online, ritiro in negozio')}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Divider />
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Row justify={'space-between'}>
                  <Col>
                    <Typography className={styles.title}>
                      {t('Click & Reserve')}
                    </Typography>
                  </Col>
                  <Col>
                    <Form.Item
                      className={styles.switchItem}
                      name='is_click_reserve'
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography className={styles.subtitle}>
                      {t('Aquisto online, pagamento e ritiro in negozio')}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Divider />
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Row justify={'space-between'}>
                  <Col>
                    <Typography className={styles.title}>
                      {t('Shop online')}
                    </Typography>
                  </Col>
                  <Col>
                    <Form.Item
                      className={styles.switchItem}
                      name='is_online_shop'
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography className={styles.subtitle}>
                      {t('Aquisto e pagamento online, consegna a domicilio')}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <Divider />
              </Col>
              <Col span={24}>
                <Divider className={styles.dividerMain} />
              </Col>
              <Col span={24}>
                <Typography className={styles.title}>
                  {t('Indica i giorni e gli orari di apertura del tuo negozio')}
                </Typography>
                <Checkbox.Group
                  style={{
                    width: '100%',
                  }}
                  onChange={handleChangeDay}
                  value={openingHoursAttributes.map((i) => i.day_number)}
                >
                  <Row className={styles.daysContainer} gutter={[0, 14]}>
                    {weekDays.map((i) => {
                      return (
                        <Col span={24}>
                          <Checkbox value={i.id}>{i.title}</Checkbox>
                          <TimeSection
                            day={i.id}
                            openingHoursAttributes={openingHoursAttributes}
                            handleAddMoreHours={handleAddMoreHours}
                            handleDeleteHours={handleDeleteHours}
                            onChangeTime={handleChangeTime}
                            t={t}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
              <Col span={24}>
                <Divider className={styles.dividerMain} />
              </Col>
              <Col span={24}>
                <Typography className={styles.title}>
                  {t(
                    'Come vuoi essere notificato di ordini e richieste di reso?',
                  )}
                </Typography>
                <Form.Item name='notifications_allow'>
                  <Checkbox.Group>
                    <Row className={styles.daysContainer} gutter={[0, 14]}>
                      {notificationTypes.map((i) => {
                        const show = notifications_allow?.includes(i.name);
                        return (
                          <Col span={24} key={i.label}>
                            <Checkbox value={i.name}>{i.title}</Checkbox>
                            {show && (
                              <NotificationsV2
                                label={i.label}
                                key={i.label}
                                addLabel={i.addLabel}
                                name={i.name}
                                rules={i.rules}
                              />
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                {/*<Checkbox.Group*/}
                {/*  style={{*/}
                {/*    width: '100%',*/}
                {/*  }}*/}
                {/*  onChange={handleChangeNotificationTypes}*/}
                {/*  value={notifications.map((i) => i.type)}*/}
                {/*>*/}
                {/*  <Row className={styles.daysContainer} gutter={[0, 14]}>*/}
                {/*    {notificationTypes.map((i) => {*/}
                {/*      return (*/}
                {/*        <Col span={24}>*/}
                {/*          <Checkbox value={i.id}>{i.title}</Checkbox>*/}
                {/*          <NotificationSection*/}
                {/*            type={i.id}*/}
                {/*            label={i.label}*/}
                {/*            addLabel={i.addLabel}*/}
                {/*            notifications={notifications}*/}
                {/*            handleAddMoreNotificationValues={*/}
                {/*              handleAddMoreNotificationValues*/}
                {/*            }*/}
                {/*            handleDeleteNotificationValues={*/}
                {/*              handleDeleteNotificationValues*/}
                {/*            }*/}
                {/*            onChangeNotificationValue={*/}
                {/*              onChangeNotificationValue*/}
                {/*            }*/}
                {/*            t={t}*/}
                {/*          />*/}
                {/*        </Col>*/}
                {/*      );*/}
                {/*    })}*/}
                {/*  </Row>*/}
                {/*</Checkbox.Group>*/}
              </Col>
              <Col span={24}>
                <Divider className={styles.dividerMain} />
              </Col>
              <Col span={12}>
                <Typography className={styles.title}>
                  {t('Quali sono le politiche di reso del tuo negozio?')}
                </Typography>
                <br />
                <Typography className={styles.subtitle}>
                  {t(
                    'Descrivi i termini di reso, eventuali tempistiche e modalità accettate. Specifica anche eventuali prodotti per cui non verrà accettato il reso.',
                  )}
                </Typography>
                <Form.Item className={styles.textArea}>
                  <InputWithLabel
                    placeholder={t('aggiungi descrizione')}
                    label={t('Descrizione')}
                    type={'textarea'}
                    value={data.return_product_it}
                    onChange={(e) => {
                      setData({
                        ...data,
                        return_product_it: e,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider className={styles.dividerMain} />
              </Col>
              <Col span={isOnlineShop ? 12 : 0}>
                <Typography className={styles.title}>
                  {t('Come gestirai le spedizioni?')}
                </Typography>
                <br />
                <Typography className={styles.subtitle}>
                  {t(
                    'Scegli il corriere al quale affiderai le tue spedizioni.',
                  )}
                </Typography>
                <Form.Item
                  className={styles.textArea}
                  name='courier_service_id'
                >
                  <MySelect
                    options={filterData?.courier_services?.map((i) => ({
                      value: i.value,
                      label: i.title,
                    }))}
                    onChange={(value) => {
                      setData({
                        ...data,
                        courier_service_id: value,
                      });
                    }}
                    selected={data.courier_service_id}
                  />
                </Form.Item>
                <br />
                <Typography className={styles.subtitle}>
                  {t('Definisci una tariffa fissa per le spedizioni.')}
                </Typography>
                <br />
                <Form.Item name='some' noStyle={true} />
                <Form.Item
                  name='delivery_box_price'
                  // validateTrigger={['onChange', 'onBlur']}
                  // rules={[
                  //   {
                  //     type: 'number',
                  //     transform: (v) => +v,
                  //     message: 'Dovrebbe essere il numero',
                  //   },
                  // ]}
                >
                  <InputWithLabel label={`${t('Tariffa')} €`} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'end'} gutter={16}>
        <Col>
          <Button type={'secondary'} onClick={() => navigate('/orders')}>
            {t('Annulla')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              form.submit();
            }}
            loading={loading}
          >
            {t('Salva')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default SettingsPage;
