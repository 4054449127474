import React, { useEffect } from 'react';
import styles from '../style.module.scss';
import { Col, Radio, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import useECommerce from '../useECommerce';
import useSteps from 'hooks/useSteps';

const ShopifyChooseStep = () => {
  const { setStep } = useSteps();

  const {
    handleSetDefaultState,
    eCommerceData,
    handleSetShopifyAttribute,
    handleSaveShopifyStep,
    loading,
  } = useECommerce();
  const { t } = useTranslation();

  useEffect(() => {
    setStep((s) => ({
      ...s,
      current: 2,
    }));
  }, [setStep]);

  return (
    <Row className={styles.row} justify={'space-between'}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text strong>
              {t('Seleziona quale tipologia di organizzazione dei prodotti vuoi utilizzare per ')}
              <br />
              {t('l’importazione da Shopify')}:
            </Typography.Text>
          </Col>
          <Col span={10}>

          </Col>
          <Col span={24}>
            <br />
            <Radio.Group
              onChange={handleSetShopifyAttribute}
              value={eCommerceData.shopify_category_attribute}
            >
              <Space direction='vertical'>
                <Radio value={'product_type'}>Tipi di prodotto</Radio>
                <Radio value={'collection'}>Collezioni</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </Col>
      <Col flex={1}>
        <Row
          className={styles.btnsRow}
          align={'bottom'}
          justify={'end'}
          gutter={8}
        >
          <Col>
            <Link to={'/products'}>
              <Button onClick={handleSetDefaultState}>{t('Annulla')}</Button>
            </Link>
          </Col>
          <Col>
            <Button
              onClick={handleSaveShopifyStep}
              loading={loading}
              disabled={!eCommerceData.currentECommerceType}
            >
              {t('Salva e continua')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShopifyChooseStep;
