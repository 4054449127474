import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getUser } from 'hooks/useUser';
import { useFilter } from 'hooks/useFilter';
import { useApi } from 'hooks/useApi';
import { getOrderStatusText, timeFormat } from 'helpers/utils';

import OrdersApi from 'libs/api/orders';
import { useNavigate } from 'hooks/useNavigate';
import Button from 'components/button';
import { EyeOutlined } from '@ant-design/icons';
import UIHelper from 'helpers/UIHelper';
import useDataExport from '../../../data-export/adminView/useDataExport';

const api = new OrdersApi();

const refundedOrdersStatuses = {
  all: 'all',
  ask_to_refund: 'ask_to_refund',
  refunded: 'refunded',
  refused: 'refused',
  waiting_for_products_refund: 'waiting_for_products_refund',
};

const useOrders = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(getUser);
  const {
    downloadFile,
    downloadTypes,
    loading: ordersExportLoading,
  } = useDataExport();
  const { searchParams } = useFilter();
  const navigate = useNavigate();
  const [refoundedProducts, setRefoundedProducts] = useState([]);
  const [showRefoundReasonModal, setShowRefoundReasonModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [, setCurrentRefoundOrderId] = useState('');
  const [refoundReason, setRefoundReason] = useState('');
  const [additionalFilters, setAdditionalFilters] = useState({
    shop_id: user?.currentShop?.id,
    status: 'ask_to_refund,refunded,waiting_for_products_refund,refused',
  });
  const {
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    setUpdateInProcess,
    singleItem,
    setSingleItem,
    fetchList,
  } = useApi({
    resourceKey: 'orders',
    singleResourceKey: 'order',
    api,
    isMainPage: true,
    additionalFilters,
    findByAdditionalId: currentOrderId,
  });

  const columns = useMemo(
    () => [
      {
        title: t('Azioni'),
        dataIndex: 'id',
        width: 200,
        render: (data) => (
          <Button
            onClick={() => {
              navigate(`view/${data}`);
            }}
            type={'primary-white'}
            icon={<EyeOutlined />}
          />
        ),
      },
      {
        title: t('ID ordine'),
        dataIndex: 'order_id',
        width: 200,
      },
      {
        title: t('Data richiesta reso'),
        width: 250,
        dataIndex: 'refund_created_at',
        render: (data) => timeFormat(data),
      },
      {
        title: t('Nr articoli'),
        width: 200,
        dataIndex: 'count',
        // render: (data, row) => row.refund_order_product_variants.length,
        render: (data, row) =>
          row.refund_order_product_variants.reduce(
            (acc, i) => i.wants_a_refund + acc,
            0,
          ),
      },
      {
        title: t('Codice cliente'),
        width: 200,
        dataIndex: 'customer_id',
      },
      {
        title: t('Nome'),
        width: 200,
        dataIndex: 'name',
      },
      {
        title: t('Cognome'),
        width: 200,
        dataIndex: 'surname',
      },
      {
        title: t('Email'),
        width: 200,
        dataIndex: 'email',
      },
      {
        title: t('Numero telefono'),
        width: 200,
        dataIndex: 'phone',
      },
      {
        title: t('Stato pratica'),
        dataIndex: 'status',
        width: 200,
        render: (data) => getOrderStatusText(data),
      },
      {
        title: t('Importo'),
        width: 200,
        dataIndex: 'refund_amount',
        render: (data) => UIHelper.formatPrice(data),
      },
      // {
      //   title: t('Quantità'),
      //   width: 200,
      //   dataIndex: 'refund_order_product_variants',
      //   render: (data) => data.reduce((acc, item) => acc + item.wants_a_refund, 0),
      // },
      // {
      //   title: t('Motivo richiesta di reso'),
      //   width: 200,
      //   dataIndex: 'refund_reason_for_rejection',
      // },
    ],
    [t],
  );

  const filters = useMemo(() => {
    return [
      {
        title: t('Cerca ordine'),
        type: 'input',
        name: 'search',
        values: [],
      },
      {
        title: t('Filtro data ordine'),
        type: 'range',
        name: 'created_from',
        keys: ['created_from', 'created_to'],
      },
    ];
  }, [t]);

  const handleChangeTab = (key) => {
    switch (key) {
      case 'all': {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: 'ask_to_refund,refunded,waiting_for_products_refund,refused',
        });
        setSingleItem({});
        setCurrentOrderId('');
        break;
      }
      case refundedOrdersStatuses.ask_to_refund: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: refundedOrdersStatuses.ask_to_refund,
        });
        setSingleItem({});
        setCurrentOrderId('');
        break;
      }
      case refundedOrdersStatuses.refunded: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: `${refundedOrdersStatuses.refunded},refused`,
        });
        setSingleItem({});
        setCurrentOrderId('');
        break;
      }
      case refundedOrdersStatuses.waiting_for_products_refund: {
        setAdditionalFilters({
          shop_id: user?.currentShop?.id,
          status: refundedOrdersStatuses.waiting_for_products_refund,
        });
        setSingleItem({});
        setCurrentOrderId('');
        break;
      }
    }
  };

  useEffect(() => {
    if (user?.currentShop?.id) {
      setAdditionalFilters((s) => {
        if (s.shop_id !== user?.currentShop?.id) {
          return {
            shop_id: user?.currentShop?.id,
          };
        }
        return s;
      });
    }
  }, [user?.currentShop?.id]);

  const handleAddProductToRefound = (e, data) => {
    const { value } = e.target;

    if (value === 'cancele') {
      setRefoundedProducts((s) => s.filter((i) => i.id !== data.id));
    } else {
      setRefoundedProducts((s) => [...s, data]);
    }
  };

  const handleSubmitRefoundModal = async () => {
    setCurrentRefoundOrderId(singleItem?.id);
    try {
      setUpdateInProcess(true);
      const method =
        singleItem.status === 'ask_to_refund' &&
        singleItem.order_type === 'click_and_ship'
          ? api.waitingForRefund
          : api.refundOrder;
      await method(singleItem?.id, {
        data: {
          product_variants: refoundedProducts?.map((i) => ({
            product_variant_id: i.id,
            count: i.count,
            refund_reason_for_rejection: i.refund_reason_for_rejection || null,
            refunded: i.refunded,
          })),
          // refund_reason_for_rejection: '',
        },
      });
      navigate('/orders/refound');
      await fetchList();
    } catch (e) {
      // const error = e?.response?.data?.order || [];
      // UIHelper.openNotificationWithIcon('error', error?.join(','));
      UIHelper.showErrorFromAxios(e);
    } finally {
      setUpdateInProcess(false);
    }
    // setCurrentRefoundOrderId(singleItem?.id);
    // if (
    //   singleItem?.refund_order_product_variants?.length !==
    //   refoundedProducts?.length
    // ) {
    //   navigate('/orders/refound');
    //   setShowRefoundReasonModal(true);
    // } else {
    //   try {
    //     setUpdateInProcess(true);
    //     await api.refundOrder(singleItem?.id, {
    //       data: {
    //         product_variants: refoundedProducts?.map((i) => ({
    //           product_variant_id: i.id,
    //           count: i.count,
    //           refund_reason_for_rejection: i.refund_reason_for_rejection,
    //         })),
    //         // refund_reason_for_rejection: '',
    //       },
    //     });
    //     navigate('/orders/refound');
    //   } catch (e) {
    //     console.log('e=', e);
    //     const error = e?.response?.data?.order || [];
    //     UIHelper.openNotificationWithIcon('error', error?.join(','));
    //   } finally {
    //     setUpdateInProcess(false);
    //   }
    // }
  };
  const handleCancelRefoundModal = () => {
    navigate('/orders/refound');
    setRefoundedProducts([]);
  };

  const handleCancelReasonModal = () => {
    setShowRefoundReasonModal(false);
    setRefoundedProducts([]);
    setCurrentRefoundOrderId('');
    setRefoundReason('');
  };
  const handleSubmitReasonModal = async () => {
    try {
      setUpdateInProcess(true);
      await api.refundOrder(singleItem?.id, {
        data: {
          product_variants: refoundedProducts?.map((i) => ({
            product_variant_id: i.id,
            count: i.count,
            refund_reason_for_rejection: i.refund_reason_for_rejection || null,
            refunded: !singleItem?.is_refunded ? i.refunded : true,
          })),
          // refund_reason_for_rejection: refoundReason,
        },
      });
      setShowRefoundReasonModal(false);
      setRefoundedProducts([]);
      setCurrentRefoundOrderId('');
      await fetchList();
      setRefoundReason('');
    } catch (e) {
      const error = e?.response?.data?.order || [];
      UIHelper.openNotificationWithIcon('error', error?.join(','));
    } finally {
      setUpdateInProcess(false);
    }
  };

  useEffect(() => {
    if (singleItem?.refund_order_product_variants) {
      setRefoundedProducts(singleItem?.refund_order_product_variants);
    }
  }, [singleItem]);

  const handleChangeRefoundReason = (value) => {
    setRefoundReason(value);
  };

  const onChangeRefundItem = useCallback(({ name, value }, item) => {
    setRefoundedProducts((s) => {
      return s.map((i) => {
        if (i.id === item.id) {
          const data = {
            ...i,
            [name]: value,
          };
          if (name === 'refunded' && !!value) {
            data['refund_reason_for_rejection'] = '';
          }
          return data;
        }
        return i;
      });
    });
  }, []);

  const handleExport = useCallback(async () => {
    const currentParams = Object.fromEntries([...searchParams]);
    await downloadFile(
      null,
      { ...currentParams, ...additionalFilters },
      downloadTypes.orders,
    );
  }, [searchParams, downloadTypes, additionalFilters]);

  return {
    columns,
    filters,
    loading,
    data,
    onChangeTableFilter,
    updateInProcess,
    handleChangeTab,
    user,
    singleItem,
    setCurrentOrderId,
    currentOrderId,
    refundedOrdersStatuses,
    handleAddProductToRefound,
    showRefoundReasonModal,
    handleCancelRefoundModal,
    handleSubmitRefoundModal,
    handleCancelReasonModal,
    handleSubmitReasonModal,
    handleChangeRefoundReason,
    refoundReason,
    ordersExportLoading,
    handleExport,
    onChangeRefundItem,
  };
};

export default useOrders;
